/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getOriginFromLink, getUnauthorizedURL } from "../utilities/Utilites";
import CampaignProgressCard from "./CampaignProgressCard";
import { Row, Col } from "react-bootstrap";
// import Button from "../components/sub-components/Button";
import { useParams } from "react-router-dom";

export default function CampaignBox(props) {

	const [campaignDetails, setCampaignDetails] = useState();

	useEffect(() => {

		(async () => {
			console.log(props.tenantId,"tenant in campaign box");
			const { result } = await getUnauthorizedURL(`/v1/campaign/${props.campaignId}`, { id: null, tenant_id: props.tenantId });

			if (result) {

				setCampaignDetails(result.message);
			}

		})();

	}, [props]);

	if(parseInt(props.templateId)===1){
		return (
			<div className="campaign-box-template-1" id="campaign-box-template-1">
	
				<Row className="campaign-box-container">
	
					<Col md="7" sm="12" className="d-flex flex-column campaign-box-description mt-3 mt-md-0"  >
						<div className="campaign-box-description-header flex-column flex-md-row" style={{ borderBottom: `2px solid ${props.primaryColor}` }}>
							{
								campaignDetails &&
								<img src={`//logo.clearbit.com/${getOriginFromLink(campaignDetails.charity.charity_url)}`} alt="icon" />
							}
							<span className="cs-hero3 text-left" style={{ color: "#525252" }}>{campaignDetails && campaignDetails.charity.name}</span>
						</div>
	
						<div style={{ padding: "20px 0px 0px 0px" }}>
							<h6 className="heading2-t2 text-md-left text-center" style={{ color: `${props.primaryColor}` }}>About the charity</h6>
							<div className="mt-1 d-flex" style={{ height: "120px", overflow: "hidden" }}>
								<p className="cs-p2-t2 mb-0 cloud-burst-color text-center text-md-left">
									{campaignDetails && campaignDetails.charity.description}
								</p>
							</div>
						</div>
					</Col>
	
	
	
	
					<Col md="5" sm="12">
						{
							campaignDetails && (
								<CampaignProgressCard primaryColor={props.primaryColor} campaignDetails={campaignDetails} templateId={parseInt(props.templateId)}/>
							)
						}
					</Col>
				</Row>
			</div>
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<div className="campaign-box-template-2" id="campaign-box-template-2">
	
				<Row className="campaign-box-container">
	
					<Col md="6" sm="12" className="d-flex flex-column campaign-box-description align-self-center changeOrder1 mt-3 mt-md-0"  >
						<div className="campaign-box-description-header d-flex ">
							{
								campaignDetails &&
								<img src={`//logo.clearbit.com/${getOriginFromLink(campaignDetails.charity.charity_url)}`} alt="icon" className="mr-2" style={{width:"50px",height:"50px"}}/>
							}
							<div className="cs-hero3 text-left align-self-center" style={{ color: props.primaryColor }}>{campaignDetails && campaignDetails.charity.name}</div>
						</div>
	
						<div style={{ padding: "20px 0px 0px 0px" }}>
							<h6 className="heading2-t2 text-left" style={{  }}>About the charity</h6>
							<div className="mt-1 d-flex" style={{ height: "120px", overflow: "hidden" }}>
								<p className="cs-p2-t2 mb-0 cloud-burst-color text-left">
									{campaignDetails && campaignDetails.charity.description}
								</p>
							</div>
						</div>
					</Col>
	
	
	
	
					<Col md="6" sm="12" className="d-flex justify-content-end campaignBoxItself mt-2 mt-md-0 changeOrder2">
						{
							campaignDetails && (
								<div style={{width:"100%"}} >
									<CampaignProgressCard primaryColor={props.primaryColor} campaignDetails={campaignDetails} templateId={parseInt(props.templateId)}/>
								</div>
								
							)
						}
					</Col>
				</Row>
			</div>
		);
	}
	else{
		return(
			<>
			</>
		);
		
	}

}