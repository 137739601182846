import React from "react";
import ObjectiveStatusCard from "../../../sub-component/dashboard/campaign-progress/ObjectiveStatusCard";
import ParticipantCard from "../../../sub-component/dashboard/campaign-progress/ParticipantCard";
import Graph from "../../../sub-component/dashboard/campaign-progress/Graph";
import { ReactComponent as AddButton } from "../../../../assets/icons/Genral/icon.svg";
import { useAuth } from "../../../../context/AuthContext";

export default function CampaignProgressCard(props) {

	const { userProfile } = useAuth();

	return (

		<div className="d-flex flex-column position-relative"
			style={props.hasBorder ? { paddingTop: "20px", borderTop: `1px solid ${props.cardColor}`, ...props.style } : { ...props.style }}
		// onClick={props.onClick}
		>
			{
				props.noData &&
				<div
					className="d-flex align-items-center justify-content-center"
					style={{ width: "100%", height: "100%", position: "absolute" }}>
					{(userProfile.role_assignment.id === 12 || userProfile.role_assignment.id === 14) && (
						<AddButton style={{ zIndex: "11" }} className="pointer" onClick={() => window.location.replace("/dashboard/campaigns/create")} />
					)}
					<div
						style={{ background: "white", height: "100%", width: "100%", position: "absolute", zIndex: "10", opacity: "0.5" }}>
					</div>
				</div>

			}
			<div></div>
			<span className="b2 licorice-color pointer" style={{ textAlign: "left" }} onClick={props.onClick}>{props.campaignName || "Campaign Name"}</span>
			<span className="b4 hoki-color" style={{ textAlign: "left", margin: "5px 0 0 0" }}>{props.charityName || "Charity"}</span>

			<div className="d-flex flex-column flex-lg-row m-0">

				<div className="graph" >
					{
						// (props.dataSeries && props.dataSeries.length) > 0 &&
						<Graph cardColor={props.cardColor} dataSeries={props.dataSeries} />
					}
				</div>

				{
					props.enableStatusCards && (
						<div className="d-flex flex-row flex-lg-column p-0 justify-content-around justify-content-lg-between status-card-container">

							<ObjectiveStatusCard
								current={props.currentObjective || "0"}
								total={props.totalObjective || "0"} cardColor={props.cardColor} />
							<ParticipantCard participants={props.participants || "0"} cardColor={props.cardColor} />

						</div>
					)

				}

			</div>

		</div>

	);

}