import React from "react";
import PhoneInput from "react-phone-input-2";
import { Container } from "reactstrap";

import "react-phone-input-2/lib/bootstrap.css";

import infoIcon from "../../assets/icons/notifications/info.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/basic/search-1.svg";

/**
 * Wizard Card
 * @param {object} props Form input component
 * @param {String} props.label Form input label
 * @param {String} props.placeholder Placeholder for input
 * @param {boolean} props.domainInput Form input with domain name
 * @param {boolean} props.phoneNumber Form input with formated phone number
 * @param {String} props.type Form input field type
 * @param {Function} props.onChange Form input onchange callback function
 * @param {Function} props.onClick Form input onclick callback function
 * @param {Function} props.onBlur Form input onblur callback function
 * @param {Function} props.onFocus Form input onfocus callback function
 * @param {name} props.name Form input with name field
*/
export default function Input(props) {


	if (props.domainInput) {
		return (
			<Container style={props.style} className="d-flex flex-column align-items-start">
				<span className="b3" >{props.label || "Lorem Ipsum"}</span>

				<div
					style={{
						width: "100%",
						height: "40px",
						padding: "0px 10px 0 0",
						borderRadius: "5px",
						border: "none",
						boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
						background: "#FFFFFF",
						overflow: "hidden",
						marginTop: "10px"
					}}
					className="d-flex"
				>
					<div className="mr-auto" style={{ width: "calc(100% - (72px + 18.33px))" }}>
						<input
							placeholder={props.placeholder || ""}
							value={props.value || ""}
							style={{
								width: "100%",
								height: "100%",
								padding: "0px 10px",
								border: "none",
							}}
							name={props.name}
							type={props.type}
							min={props.min}
							max={props.max}
							onChange={props.onChange}
							onClick={props.onClick}
							onBlur={props.onBlur}
							onFocus={props.onFocus}
						/>
					</div>

					<div style={{ color: "#007AFF", width: "72px" }}>
						<p style={{ fontSize: "12px", lineHeight: "40px" }}>.altrunic.com</p>
					</div>

					<div style={{ marginLeft: "10px", marginRight: "10.83px", padding: "8px 0" }}>
						<div style={{ height: "18.33px", width: "18.33px" }}>
							<img alt="info" src={infoIcon} style={{ height: "100%", width: "100%" }} />
						</div>
					</div>

				</div>

			</Container >
		);
	}
	if (props.tableInput) {
		return (
			<div>
				<input
					placeholder={props.placeholder || ""}
					style={{
						width: "100%",
						height: "23px",
						padding: "0px 10px",
						borderRadius: "30px",
						border: "none",
						boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
					}}
					className={props.className}
					type={props.type}
					min={props.min}
					max={props.max}
					value={props.value}
					id={props.id}
					onChange={props.onChange}
					onClick={props.onClick}
					onBlur={props.onBlur}
					onFocus={props.onFocus}

				/>
			</div>
		);
	}
	if (props.tableNumberInput) {

		return (
			<div>
				<input
					placeholder={props.placeholder || ""}
					style={{
						width: "100%",
						height: "23px",
						padding: "0px 10px",
						borderRadius: "30px",
						border: "none",
						boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
					}}
					className={props.className}
					type={"number"}
					min={props.min}
					max={props.max}
					value={props.value}
					id={props.id}
					onChange={props.onChange}
					onClick={props.onClick}
					onBlur={props.onBlur}
					onFocus={props.onFocus}
					onKeyPress={e => {
						if (e.key === "-") {
							e.preventDefault();
						}
					}}

				/>
			</div>
		);
	}

	else if (props.textArea) {
		return (
			<div style={props.style} className="d-flex flex-column align-items-start">
				<span className="b3" >{props.label}</span>

				<div className="w-100" style={{ marginTop: "10px" }}>
					<textarea
						className={`b4 ${props.className}`}
						placeholder={props.placeholder || ""}
						style={{
							width: "100%",
							height: "229px",
							padding: "5px 10px",
							borderRadius: "5px",
							border: "none",
							boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
							resize: "none"
						}}

						readOnly={props.readOnly}
						value={props.value}
						type={props.type}
						minLength={props.minLength}
						maxLength={props.maxLength}
						onChange={props.onChange}
						onKeyPress={props.onKeyPress}
						onClick={props.onClick}
						onBlur={props.onBlur}
						onFocus={props.onFocus}
						name={props.name}
						disabled={props.disabled}
						id={props.id}
						max={props.max}
						min={props.min}
					/>
				</div>
			</div>
		);
	} else if (props.underLine) {

		return (
			<input
				className={props.className || "input-underline"}
				placeholder={props.placeholder || "Enter Here"}
				name={props.name}
				onChange={props.onChange}
				onClick={props.onClick}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				type={props.type}
				min={props.min}
				max={props.max}
				disabled={props.disabled}
				value={props.value}
				id={props.id}
			/>
		);

	} else if (props.sideInput) {
		return (
			<div style={props.style} className="d-flex flex-column align-items-start">
				<span className="b4" >{props.label || "Lorem Ipsum"}</span>

				<div className="w-100" style={{ marginTop: "5px" }}>
					<input
						type="text"
						style={{
							width: "100%",
							height: "30px",
							padding: "8px 10px",
							borderRadius: "5px",
							border: "none",
							boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
						}}
						className={props.className}
						onChange={props.onChange}
					/>
				</div>
			</div>
		);

	} else if (props.phoneNumber) {

		return (
			<div style={props.style} className="d-flex flex-column align-items-start">
				<span className="b3" >{props.label || "Lorem Ipsum"}</span>

				<div className="w-100" style={{ marginTop: "10px" }}>

					<PhoneInput
						country={"us"}
						regions={["north-america"]}
						value={props.value || ""}
						inputStyle={{
							width: "100%",
							height: "40px",
							borderRadius: "5px",
							border: "none",
							boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)"
						}}
						buttonStyle={{ border: "none" }}
						disableDropdown={true}
						onChange={phone => { props.onChange(phone); }}
					/>
				</div>
			</div>
		);

	}
	else if (props.hollow) {
		return (
			<div style={props.style} className="d-flex flex-column align-items-start">
				<span className="b3" >{props.label}</span>

				<div className="w-100" style={{ marginTop: "10px" }}>

					<input
						placeholder={props.placeholder || ""}
						style={{
							width: "100%",
							height: "40px",
							padding: "0px 10px",
							borderRadius: "5px",
							border: "1px solid #0054DC",
							// boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
							...props.inputStyle
						}}
						readOnly={props.readOnly}
						value={props.value}
						type={props.type}
						minLength={props.minLength}
						maxLength={props.maxLength}
						onChange={props.onChange}
						onKeyPress={props.onKeyPress}
						onClick={props.onClick}
						onBlur={props.onBlur}
						onFocus={props.onFocus}
						name={props.name}
						className={props.className}
						disabled={props.disabled}
						id={props.id}
						max={props.max}
						min={props.min}
					/>
				</div>
			</div>
		);
	}
	else if (props.searchInput) {
		return (
			<div style={props.style} className="d-flex flex-column align-items-start">
				{
					props.label &&
					<span className={`b3 ${props.labelClass}`} style={{ marginBottom: "10px" }}>{props.label || "Lorem Ipsum"}</span>
				}

				<div className="w-100 h-100" style={{ position: "relative" }}>
					<SearchIcon style={{ position: "absolute", top: "17px", left: "26px" }} />
					<input
						placeholder={props.placeholder || ""}
						style={{
							width: "100%",
							minHeight: "40px",
							height: "100%",
							maxHeight: "57px",
							padding: "0px 10px",
							borderRadius: "5px",
							border: "none",
							boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
							...props.inputStyle
						}}
						readOnly={props.readOnly}
						value={props.value}
						type={props.type}
						minLength={props.minLength}
						maxLength={props.maxLength}
						onChange={props.onChange}
						onKeyPress={props.onKeyPress}
						onClick={props.onClick}
						onBlur={props.onBlur}
						onFocus={props.onFocus}
						name={props.name}
						className={props.className}
						disabled={props.disabled}
						id={props.id}
						max={props.max}
						min={props.min}
					/>
					{
						props.postLable
						&&
						<span className="b3 ml-3">{props.postLable}</span>
					}
				</div>
				<span className="b4 mt-1" style={{ color: "#459A54" }}>{props.description}</span>
			</div>
		);
	} else {
		return (
			<div style={props.style} className="d-flex flex-column align-items-start">
				{
					props.label &&
					<span className={`${props.boldLabel}?${props.labelClass}:b3 ${props.labelClass}`} >{props.label || "Lorem Ipsum"}</span>
				}

				<div className="w-100" style={{ marginTop: "10px" }}>
					<input
						placeholder={props.placeholder || ""}
						style={{
							width: "100%",
							height: "40px",
							padding: "0px 10px",
							borderRadius: "5px",
							border: "none",
							boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
							...props.inputStyle
						}}
						readOnly={props.readOnly}
						value={props.value}
						type={props.type}
						minLength={props.minLength}
						maxLength={props.maxLength}
						onChange={props.onChange}
						onKeyPress={props.onKeyPress}
						onClick={props.onClick}
						onBlur={props.onBlur}
						onFocus={props.onFocus}
						name={props.name}
						className={props.className}
						disabled={props.disabled}
						id={props.id}
						max={props.max}
						min={props.min}
					/>
					{
						props.postLable
						&&
						<span className="b3 ml-3">{props.postLable}</span>
					}
				</div>
				<span className="b4 mt-1" style={{ color: "#459A54" }}>{props.description}</span>
			</div>
		);

	}
}