import React from "react";
import BillingCard from "../../assets/icons/Genral/MaskGroup.svg";
import PencilEdit from "../../assets/icons/basic/pencil-edit.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/basic/pencil-edit.svg";

export default function BillingDetailsCard(props) {
	return (
		<div className="billing-details-card pb-2">

			<div className="spacing-billing-card" style={{ position: "relative" }}>
				<img
					src={BillingCard}
					alt=""
					style={{ width: "100%",height:"auto",maxHeight:"180px" }}
				/>
				<div style={{ position: "absolute", bottom: "17px", left: "40px", color: "#FFFFFF" }}>
					<span><span style={{ fontSize: "20px" }}>&#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;</span> {props.lastDigits}</span>
					<p>{props.name}</p>
				</div>
			</div>
			<div className="d-flex">
				<div className="spacing-billing-details">
					<div>
						<span className="s3" style={{ color: "white" }}>Billing Details</span>
					</div>
					<div>
						<span className="b4" style={{ color: "white" }}>Name : {props.name}</span>
					</div>
					{/* <div className="top-margin">
						<span className="b4" style={{ color: "white" }}>Address : {props.address || "Not provided"}</span>
					</div>
					<div className="top-margin">
						<span className="b4" style={{ color: "white" }}>Phone : {props.phone || "Not provided"}</span>
					</div> */}
					<div className="top-margin">
						<span className="b4" style={{ color: "white" }}>Expiry Date : {props.expiryDate}</span>
					</div>
					<div style={{ marginTop: "50px" }}>
						<div className="b4"
							style={{
								width: "fit-content",
								background: "white",
								padding: "5px 16px",
								color: "#5E7488",
								borderRadius: "12px"
							}}
						>
							{`Upcoming Bill: $${props.plan}`}
							{/*  <span className="loading-skeleton" />*/}
						</div>
						{/* <Button
							whiteButton
							title={`Upcoming Bill: $${props.plan}`}
							titleStyle={{ color: "#5E7488" }}
							style={{ width: "300px", height: "23px", border: "none", padding: "5px 16px" }}

						/> */}
					</div>
				</div>
				<div className="d-flex justify-content-end">
					<div>
						<img
							src={PencilEdit}
							alt=""
							style={{ width: "19px", height: "19px" }}
						/>
					</div>
					<EditIcon className="pointer" fill="#FFFFFF" onClick={props?.onUpdateCard} />
				</div>
			</div>

		</div>

	);
}