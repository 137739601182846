import React from "react";
import ReactRoundedImage from "react-rounded-image";
import { Link } from "react-router-dom";
import { ReactComponent as LoginIcon } from "../../assets/icons/Genral/Logout.svg";

export default function Button(props) {

	if (props.busy) {

		return (
			<button style={{ ...props.style, height: "100%" }} disabled className="d-flex justify-content-center align-items-center busy-button">
				<div>
					<span className="busy-indicator"></span>
				</div>
			</button>
		);

	} else if (props.profileBtn) {
		return (
			<div className="btn-profile" style={{ display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer", ...props.style }} onClick={props.onClick}>
				<span className="text-colour" style={{ margin: "0 15px 0 5px" }}>{`${props.username || "John"}`}</span>
				{
					props.nonRoundedImage 
						?
						<>
							{props.image}
						</>
						:
						<ReactRoundedImage roundedColor="none" image={props.avatar} roundedSize="0" imageWidth="27px" imageHeight="27px" />
				}
			
			</div>
		);
	}
	else if (props.profileBtn2) {
		return (
			<div className="btn-profile" style={{ display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer", ...props.style }} onClick={props.onClick}>
				<span className="text-colour" style={{ margin: "0 15px 0 5px" }}>{`${props.username || "John"}`}</span>
				{
					props.nonRoundedImage 
						?
						<>
							{props.image}
						</>
						:
						<img src={props.avatar} style={{width:"27px",height:"27px",borderRadius:"50%"}} />
				}
			
			</div>
		);
	}
	else if (props.login) {
		return (
			<div className="btn-profile" style={{ display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer", ...props.style }}>
				<Link to="/login">
					<span className="text-colour" style={{ paddingLeft: "10px" }}>{`${props.username || "John"}`}</span>
					<LoginIcon width="27px" height="27px" />
				</Link>
			</div>
		);
	}
	else if (props.text) {
		return (
			<div className="btn-text" onClick={props.onClick} style={{ backgroundColor: "#4267B2", cursor: "pointer",display:"flex",alignItems:"center",justifyContent:"center", ...props.style }}>
				<span className={`text-colour cs-description ${props.className}`}>{props.title || "Text"}</span>
			</div>
		);
	}
	else if (props.charityLogin) {
		return (
			<div style={{ ...props.style }}
				onClick={props.onClick}
				className="charity-login-button d-flex justify-content-center align-items-center">
				<div className="d-flex justify-content-center align-items-center">
					<span style={{ color: "#FFFFFF", ...props.titleStyle }} className="cb">{props.title || "Next"}</span>
				</div>
			</div>
		);
	}
	else if (props.whiteButton) {
		return (
			<div className="btn-small" onClick={props.onClick} style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: `${props.color || "white"}`, cursor: "pointer", ...props.style }}>
				<span className={`cs-b1 ${props.className}`} style={{ color: "#1A1A1A" }}>{props.title || "Button Small"}</span>
			</div>
		);
	}
	else if (props.normalButton) {
		return (
			<div className="btn-small" onClick={props.onClick} style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: `${props.color || "white"}`, cursor: "pointer", ...props.style }}>
				<span className={` ${props.className}`} >{props.title || "Button Small"}</span>
			</div>
		);
	}
	else if (props.campaignCardButton){
		return (
			<button type={props.type} className="" disabled={props.disabled} onClick={props.onClick} style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "none",backgroundColor: `${props.color || "#4267B2"}`, cursor: "pointer", ...props.style }}>
				<span className={`cs-b1-t2 text-colour ${props.className}`} >{props.title || "Button Medium"}</span>
			</button>
		);
	}
	else {
		return (
			<button type={props.type} className="btn-medium" disabled={props.disabled} onClick={props.onClick} style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "none",backgroundColor: `${props.color || "#4267B2"}`, cursor: "pointer", ...props.style }}>
				<span className={`cs-b1-t2 text-colour ${props.className}`} >{props.title || "Button Medium"}</span>
			</button>
		);
	}

}