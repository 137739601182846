import React from "react";

import avatar_1 from "../../../../assets/icons/avatars/supervised_user_circle.svg";

/**
 * 
 * @param {Object} props  Props object
 * @param {string} props.cardColor rgba color for card
 * @param {number} props.participants number of participants
 */
export default function ParticipantCard(props) {

	return (

		<div className="d-flex flex-row participant-card" style={{backgroundColor: `${props.cardColor}`}}>

			<div className="d-flex flex-column">

				<span className="h4" style={{ textAlign: "left", margin: "0", color: "#fff" }}>{props.participants || 0}</span>
				<span className="b5" style={{ fontSize: "10px", textAlign: "left", color: "#2A3440" }}>Participant</span>

			</div>

			<div className="d-flex justify-content-center participant-avatar">
				<img src={avatar_1} />
			</div>

		</div>

	);

}