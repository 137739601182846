import React from "react";
import Carousel from "react-multi-carousel";
import { useMediaQuery } from "react-responsive";
import trophy from "../../assets/images/template_2_images/trophy.png";
import CampaignCard from "../CampaignCard";
import TestimonialCard from "./TestimonialCard";
import MemberCard from "./MemberCard";
import "react-multi-carousel/lib/styles.css";

const CarouselComponent = (props) => {
	const isMobile = useMediaQuery({
		query: "(max-device-width: 768px)"
	});


	console.log("data in carosel",props)

	const responsiveForOnGoingCampaigns = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 5
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3
		},
		tablet: {
			breakpoint: { max: 1024, min: 768 },
			items: 2
		},
		mobile: {
			breakpoint: { max: 768, min: 0 },
			items: 1
		}
	};
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 5
		},
		desktop: {
			breakpoint: { max: 3000, min: 768 },
			items: 3
		},
		tablet: {
			breakpoint: { max: 768, min: 425 },
			items: 1
		},
		mobile: {
			breakpoint: { max: 425, min: 0 },
			items: 1
		}
	};
	const responsiveSingle = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 768 },
			items: 1
		},
		tablet: {
			breakpoint: { max: 768, min: 425 },
			items: 1
		},
		mobile: {
			breakpoint: { max: 425, min: 0 },
			items: 1
		}
	};
	function hexToRgbA(hex){
		let c=0;
		if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
			c= hex.substring(1).split("");
			if(c.length== 3){
				c= [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c= "0x"+c.join("");
			
			return "rgba("+[(c>>16)&255, (c>>8)&255, c&255].join(",")+",.8)";
		}
		throw new Error("Bad Hex");
	}
	const CustomDot = ({ onClick, ...rest }) => {
		const { index, active } = rest;
		// const carouselItems = [...props.data.length];
		// onMove means if dragging or swiping in progress.
		// active is provided by this lib for checking if the item is active or not.
		return (
			<button
				className={active ? "active" : "inactive"}
				style={{background:"none",border:"none",width:"20px"}}
				onClick={() => onClick()}
			>
				{
					isMobile?(
						<>
							<div style={{width:"10px",height:"10px",background:`${(active)?(parseInt(props.templateId)===1 && (props.isVolunteer || props.isTestimonyCard))?"white":props.primaryColor:"#A4AEB8"}`,borderRadius:"50%",margin:"2px"}} key={index} onClick={()=>{
								onClick();
							}}></div>	
						</>
					):(
						<>
							<div style={{width:"10px",height:"10px",background:`${(active)?(parseInt(props.templateId)===1 && (props.isTestimonyCard || props.isVolunteer))?"white":props.primaryColor:"#A4AEB8"}`,borderRadius:"50%",margin:"2px"}} key={index} onClick={()=>{
								onClick();
							}}></div>
						</>
					)
				}
			</button>
		);
	};
	if(props.isCampaignCard){
		return (
			<div className="carouselComponent mt-3 w-100">
				<div className="carouselBody" style={{ height:`${props.onGoingCampaigns ? (props.templateId === 2) ? "680px" : "auto" : (props.templateId === 2) ? "540px" : "auto" }`}}>
					{(isMobile || props.data.length>3)?
						<>
							<Carousel responsive={responsiveForOnGoingCampaigns}
								autoPlay={false}
								ssr={true}
								showDots
								customDot={<CustomDot />}
								arrows={false} 
								className="h-100 d-flex align-items-start pt-3"
								
							>
								{props.data.map((campaign, index) => {
									return (  
										<div key={index} className=" d-flex justify-content-center pl-2 pr-2 mt-3" style={{flex:"1"}} >
											<div className="">
												{props.onGoingCampaigns &&
													<CampaignCard
														componentId={campaign.id}
														to={`/campaign/${campaign.id}`}
														heroHeaderImage={campaign.heroHeaderImage}
														// showPopup={showPopup}
														campaignCardTitle={campaign.title}
														campaignCardImpact={campaign.impact}
														campaignCardMatching={campaign.matching}
														campaignCardDescription={campaign.description}
														primaryColor={props.primaryColor}
														campaignId={campaign.id}
														minDonate={Number(campaign.min_donate)}
														target={Number(campaign.target)}
														donations={campaign.donations}
														isActive={true}
														templateId={parseInt(props.templateId)}
													/>
												}
											</div>
											<div className="">
												{props.completedCampaigns &&
													<CampaignCard
														componentId={campaign.id}
														to={`/campaign/${campaign.id}`}
														heroHeaderImage={campaign.heroHeaderImage}
														campaignCardTitle={campaign.title}
														campaignCardImpact={campaign.impact}
														campaignCardMatching={campaign.matching}
														campaignCardDescription={campaign.description}
														primaryColor={props.primaryColor}
														target={Number(campaign.target)}
														donations={campaign.donations}
														charityDescription={campaign.charityDescription}
														campaignEndDate={campaign.campaignEndDate}
														templateId={parseInt(props.templateId)}
													/>
												}
											</div>
										</div>
									);
								})}
							</Carousel>
						</>
						:
						<div className="d-flex justify-content-center">
							{props.data.map((campaign, index) => {
								return (  
									<div key={index} className="mt-5 d-flex justify-content-center pl-2 pr-2 " style={{flex:"1"}} >
										<div className="">
											{props.onGoingCampaigns &&
												<CampaignCard
													componentId={campaign.id}
													to={`/campaign/${campaign.id}`}
													heroHeaderImage={campaign.heroHeaderImage}
													// showPopup={showPopup}
													campaignCardTitle={campaign.title}
													campaignCardImpact={campaign.impact}
													campaignCardMatching={campaign.matching}
													campaignCardDescription={campaign.description}
													primaryColor={props.primaryColor}
													campaignId={campaign.id}
													minDonate={Number(campaign.min_donate)}
													target={Number(campaign.target)}
													donations={campaign.donations}
													isActive={true}
													templateId={parseInt(props.templateId)}
												/>
											}
										</div>
										<div className="">
											{props.completedCampaigns &&
												<CampaignCard
													componentId={campaign.id}
													to={`/campaign/${campaign.id}`}
													heroHeaderImage={campaign.heroHeaderImage}
													campaignCardTitle={campaign.title}
													campaignCardImpact={campaign.impact}
													campaignCardMatching={campaign.matching}
													campaignCardDescription={campaign.description}
													primaryColor={props.primaryColor}
													target={Number(campaign.target)}
													donations={campaign.donations}
													charityDescription={campaign.charityDescription}
													campaignEndDate={campaign.campaignEndDate}
													templateId={parseInt(props.templateId)}
												/>
											}
										</div>
									</div>
								);
							})}
						</div>	
					}
				</div>
			</div>
		);
	}
	else if(props.isTestimonyCard){
		return (
			<div className="carouselComponent mt-3 w-100" >
				<div className="carouselBody " style={{maxWidth:"100%",height:"300px"}}>
					<Carousel responsive={responsive}
						autoPlay={false}
						arrows={false} 
						className="h-100"
						showDots
						customDot={isMobile?<CustomDot />:<></>}
					>
						{props.data.map((testimony, index) => {
							return (  
								
								<div className="d-flex justify-content-center pl-2 pr-2" key={index} style={{flex:"1"}}>
									<TestimonialCard
										templateId={parseInt(props.templateId)}
										color={props.primaryColor}
										image={testimony.inputDetails[0].value}
										name={testimony.inputDetails[1].value}
										profession={testimony.inputDetails[2].value}
										description={testimony.inputDetails[3].value}
	
									/>

										
								</div>

							);
						})}
					</Carousel>
				</div>
              
         
			</div>
		);
	}
	else if(props.isVolunteer){
		return(
			<div className="carouselComponent mt-0 mt-md-3 w-100" >
				<div className="carouselBody " style={{maxWidth:"100%",height:"400px"}}>
					<Carousel responsive={responsive}
						autoPlay={false}
						arrows={false} 
						className="h-100 align-items-start pt-5 pt-md-0 align-items-md-center"
						showDots
						customDot={isMobile?<CustomDot />:<></>}
					>
						{props.data.map((member, index) => {
							return (  
								<div className="d-flex justify-content-center pl-2 pr-2" key={index} style={{flex:"1"}}>
									<MemberCard
										templateId={parseInt(props.templateId)}
										color={props.primaryColor}
										cardImage={member.inputDetails[0].value} cardTitle={member.inputDetails[1].value} cardSubTitle={member.inputDetails[2].value} />
								</div>

							);
						})}
					</Carousel>
				</div>
			</div>
		);
	}
	else if(props.leaderboardCarousel){
		return(
			<div className="carouselComponent w-100" >
				<div className="carouselBody" style={{maxWidth:"100%"}}>
					<Carousel responsive={responsiveSingle}
						autoPlay={true}
						arrows={false} 
						infinite={true}
						autoPlaySpeed={3000}
						className=" h-100"
					>
						{props.data.map((member, index) => {
							if(parseInt(props.templateId)===2){
								return (  
									<div className=" pt-4 pl-4 pr-4  text-left d-flex flex-column" key={index} style={{width:"84%",height:"300px",marginLeft:"11%",
										color:"white",background:props.primaryColor,borderRadius:"24px"}}>
										
										<div style={{width:"94%",height:"84%",background:hexToRgbA(props.primaryColor),position:"absolute",zIndex:"-1",left:"6%",top:"8%",borderRadius:"24px"}}>
	
										</div>
								
										
										<div className="cs-b1 mb-3" style={{color:"white"}}>Impact Made</div>
										<div>
											{member}
										</div>
										<div className="imageContainer mt-auto ml-auto">
											<img src={trophy} />
										</div>
									</div>
	
								);
							}
							else if(parseInt(props.templateId)===1){

								return (  
									<div className=" pt-4 pl-4  text-left d-flex flex-column" key={index} style={{width:"100%",height:"250px",
										color:"white",background:props.primaryColor}}>
										<div className="cs-b1 mb-3" style={{color:"white"}}>Impact Made</div>
										<div className=" mr-5 pr-5">
											{member}
										</div>
										<div className="imageContainer mt-auto ml-auto">
											<img src={trophy} />
										</div>
									</div>
	
								);
							}
							
						})}
					</Carousel>
				</div>
			</div>
		);
	}

	
};


export default CarouselComponent;