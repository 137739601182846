import React from "react";
const StepProgressBar=(props)=>{
	return(
		<div className="w-100 h-100" style={{maxWidth:"100%"}}>
			<div className="stepBar d-flex">
				<div className="circleContainer">
					<div className={`${props.step===1?"activeCircle":"doneCircle"}`}></div>
					<div className="stepName" style={{maxWidth:"15vw"}}>
                        Sign Up
					</div>
				</div>
				<div className="dottedLine"></div>
				<div className="circleContainer">
					<div className={`${props.step===2?"activeCircle":props.step===3?"doneCircle":"undoneCircle"}`}></div>
					<div className="stepName" style={{maxWidth:"15vw"}}>
                        Confirm Plan
					</div>
				</div>
				
                
				<div className="dottedLine"></div>
				<div className="circleContainer">
					<div className={`${props.step===3?"activeCircle":"undoneCircle"}`}></div>
					<div className="stepName" style={{maxWidth:"15vw"}}>
                        Enter Payment Info
					</div>
				</div> 
				
			</div>
			
            
		</div>
	);
};

export default StepProgressBar;