import React, { useEffect } from "react";
import { ReactComponent as FacebookLogo } from "../../assets/icons/social-icons/Facebook.svg";
import { ReactComponent as InstagramLogo } from "../../assets/icons/social-icons/Instagram.svg";
import { ReactComponent as TwitterXLogo } from "../../assets/icons/social-icons/twitterx.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/icons/social-icons/entypo-social_linkedin-with-circle.svg";
// import Button from "./Button";
// import { ReactComponent as MapIcon } from "../../assets/icons/maps/map.svg";
// import { ReactComponent as PhoneIcon } from "../../assets/icons/call/call-phone.svg";
// import { ReactComponent as MailIcon } from "../../assets/icons/basic/mail.svg";
import { Fragment } from "react";
// import { useRouteMatch } from "react-router";

export default function Footer(props) {

	useEffect(() => {
		console.log("footer props are",props);
	}, [props]);
	// const match = useRouteMatch();
	return (
		<Fragment>
			{

				<div className="d-flex flex-column">

					<div className={`${props.className}  cs-footer`}>
						<div className="logo-section-container">
							<div className="cs-footer-logo">
								{
									props.logo ?
										<img className="cs-nav-logo" alt="Logo" src={props.logo} />
										:
										<span className={`${props.textClass} + heading1 `} style={{ marginTop: "0px", width: "min-content" }}>Logo</span>
								}
							</div>
							{/* <div className="footer-donate-button">
								<Button whiteButton title="Donate Now" />
							</div> */}
						</div>
						<div className="link-section-container">
							<div className="link-section-inner-container">
								{/* <span className={`${props.textClass} + heading1 section-header`}>Quick Links</span> */}
								<div className="link-container">
									<a href={"/"}>
										<span className={`${props.textClass} + cs-p2`}>Home</span>
									</a>
									{
										(props.headerLink !== undefined && props.headerLink) && props.headerLink.map((headerLink, index) => {
											return (
												<Fragment key={index}>
													<a href={headerLink.url} className="link-container-link" style={{ textDecoration: "none" }}>
														<span className={`${props.textClass} + cs-p2 `}>{headerLink.label}</span>
													</a>
												</Fragment>
											);
										})
									}
								</div>
							</div>
						</div>

						<div className="social-media-links">
							{
								(props.footerLinks !== undefined && props.footerLinks) && props.footerLinks.map((socialLink, index) => {
									return (
										<Fragment key={index}>
											{
												socialLink.label === "Facebook" &&
												<FacebookLogo
													className="pointer"
													onClick={() => { window.open(`${socialLink.url || "#"}` , "_blank"); }}
													style={index !== 0 ? { marginLeft: "12px" } : { marginLeft: "0px" }} fill={"white"} />
											}
											{
												socialLink.label === "Instagram" &&
												<InstagramLogo
													className="pointer"
													onClick={() => { window.open(`${socialLink.url || "#"}` , "_blank"); }}
													style={index !== 0 ? { marginLeft: "12px" } : { marginLeft: "0px" }} fill={"white"} />
											}
											{
												socialLink.label === "Twitter" &&
												<TwitterXLogo
													className="pointer"
													onClick={() => { window.open(`${socialLink.url || "#"}` , "_blank"); }}
													style={index !== 0 ? { marginLeft: "12px" } : { marginLeft: "0px" }}/>
											}
											{
												socialLink.label === "Linkedin" &&
												<LinkedinLogo
													className="pointer"
													width= {36}
													height={36}
													onClick={() => { window.open(`${socialLink.url || "#"}` , "_blank"); }}
													style={index !== 0 ? { marginLeft: "12px" } : { marginLeft: "0px" }} fill={"white"} />
											}
										</Fragment>
									);
								})
							}

						</div>

					</div>

					<div className="copyright-footer">
						<span className="cs-p2 ">{`Copyright ©2024 ${props.companyName}, All rights reserved. -   `}<a className="cs-p2 " rel="noreferrer" href="https://altrunic.com/" target="_blank">Powered by Altrunic ®</a></span>
					</div>
				</div >
			}
		</Fragment>
	);
}