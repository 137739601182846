import React from "react";
import cross from "../assets/icons/basic/cross.svg";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDonation } from "../context/DonationContext";
import stripePKey from "../utilities/StripePKey";

export default function DonationCardDetails(props) {

	const donation = useDonation();
	const stripePromise = loadStripe(stripePKey);
	return (
		<div id="popup" className="donation-modal">
			<img src={cross} className="cross-sign" alt="close" onClick={donation.clearDonation} />
			<span className="s3 w-100" style={{ borderBottom: "1px solid #CED5DB", padding: "0 0 10px 0", marginBottom: "10px" }}>Checkout</span>


			<Elements stripe={stripePromise}>
				<CheckoutForm
					primaryColor={props.primaryColor}
				/>
			</Elements>

		</div>
	);
}