import React, { useState, useEffect, useRef } from "react";
import { Fragment } from "react";
import { ReactComponent as LockIcon } from "../../assets/icons/basic/lock.svg";


export default function SwitchButton(props) {

	const forState = props.forState || null;
	const [isChecked, setIsChecked] = useState(props.isChecked || false);
	const switchBtn = useRef();

	function handleChange(e) {
		// setIsChecked(!isChecked);
		props.onChange({ value: e.target.checked, forState: forState });
	}

	useEffect(() => {
		setIsChecked(props.value);
	}, [props.value, isChecked]);

	return (

		<Fragment>
			{
				props.isLocked
					? <div className="switch-container" style={props.style}>
						< label >
							<input ref={switchBtn} checked={isChecked} onChange={() => { }} className="switch" type="checkbox" id={props.id}/>
							<div style={{ backgroundColor: "#5E7488" }}>
								<div style={{ backgroundColor: "white" }} className="d-flex justify-content-center align-items-center"><LockIcon style={{ fill: `${props?.lockColor}` }} /></div>
							</div>
						</label >
					</div >
					:
					<div className="switch-container" style={props.style}>
						<label>
							<input ref={switchBtn} checked={isChecked} onChange={e => handleChange(e)} className="switch" type="checkbox" id={props.id}/>
							<div >
								<div></div>
							</div>
						</label>
					</div>
			}
		</Fragment>


	);




}
