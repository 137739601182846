/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { Col, Row } from "reactstrap";
import Button from "../sub-component/Button";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

/**
 * @param {Object} props Props object of component
 * @param {String} props.height Modal package confirm
 * @param {String} props.width Modal package confirm
 * @param {String} props.className Modal package confirm
 * @param {String} props.style Modal package confirm
 * @param {String} props.confirmBtnTitle Modal package confirm
 * @param {Function} props.onClose Modal close function
 * @param {Function} props.onConfirm Modal package confirm
 * @param {String} props.onOtherConfirmTitle Modal second optional button title
 * @param {Function} props.onOtherConfirmTitle Modal second optional method
 */

export default function ModalComp(props) {
	
	return (
		<div className="custom-modal-wrap">

			<div className={`custom-modal  ${props.className}`} style={{ height: `${props.height}`, width: `${props.width}`, overflowY: "auto", ...props.style }}>
				<div className="custom-modal-closebtn" onClick={props.onClose} />
				<div className="d-flex w-100 mb-3">
					<h3 className="modal-title h2 text-center w-100" style={{ fontWeight: "800" }}>{props.title}</h3>
				</div>
				<div className="modal-content" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
					<div style={{padding: "10px"}}>
						{props.children}
					</div>
				</div>

				{/* {props.children} */}
				{
					props?.confirmBtnTitle &&
					<Row className="modal-button-container">
						<Col>
							<Button
								busy={props?.confirmBtnBusyStatus}
								title={props.confirmBtnTitle}
								style={{ width: "max-content", height: "40px", padding: "10px 70px", margin: "auto" }}
								onClick={props.onConfirm}
							/>
						</Col>
					</Row>
				}

				{
					props?.onOtherConfirmTitle &&
					<Row style={{ marginTop: "22px" }}>
						<Col className="w-100 d-flex justify-content-center">
							<span className="b2 navy-blue-color pointer" style={{ textDecoration: "underline" }} onClick={props?.onOtherConfirmMethod}
							>
								{props?.onOtherConfirmTitle}
							</span>
						</Col>
					</Row>
				}
			</div>
		</div>
	);
}
