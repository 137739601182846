/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { CardNumberElement, useStripe, useElements, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import Button from "../sub-component/Button";
import { openURL } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";
import { Container, Row, Col } from "reactstrap";
import AlertBox from "../common/AlertBox";

export default function UpdateStripCardForm(props) {

	const auth = useAuth();
	const stripe = useStripe();
	const elements = useElements();

	const [nameOnCard, setNameOnCard] = useState();

	const [error, setError] = useState();
	const [processing, setProcessing] = useState();
	const [succeeded, setSucceeded] = useState();
	const [disabled, setDisabled] = useState(false);

	const [alert, setAlert] = useState(null);
	const cardNumberStyle = {
		style: {
			base: {
				fontSize: "20px",
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		},
		showIcon: true,
		placeholder: "Card Number",
	};
	const cardStyle = {
		style: {
			base: {
				fontSize: "20px",
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		},
		showIcon: true,
	};

	async function handleSubmit(event) {

		event.preventDefault();
		console.log("here");

		if (!nameOnCard || nameOnCard.length < 3) {
			setAlert(
				<AlertBox color="danger" title="Error" description={"Enter Name on Card"} />
			);
			return;
		}

		setProcessing(true);

		const { paymentMethod, error } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardNumberElement),
			billing_details: {
				name: nameOnCard
			}
		});

		if (error) {
			// console.log(error.message);
			setAlert(
				<AlertBox color="danger" title="Error" description={error.message} />
			);
			setProcessing(false);
		} else {

			const { result, error } = await openURL("/v1/stripe_customers/attach_payment_method", "POST", auth, {
				payment_method_id: paymentMethod.id,
			});

			if (error) {
				setAlert(
					<AlertBox color="danger" title="Error" description={error.message} />
				);
			} else if (result) {
				setAlert(
					<AlertBox color="success" title="Success" description={"Card Updated"} />
				);
				setDisabled(true);
				props?.onCardUpdate();
			}

			setProcessing(false);

		}

	}

	return (
		<div>
			{/* <h3>Stripe Card Form</h3>		 */}
			<form id="payment-form" className="update-card-form-container" onSubmit={handleSubmit}>
				<input className="card-input-element w-100 b2" placeholder="Name on card" onChange={(e) => setNameOnCard(e.target.value)} />
				<CardNumberElement className="card-input-element" options={cardNumberStyle} />
				<Container fluid={true} className="p-0">
					<Row>
						<Col sm="6" className="pl-0">
							<CardExpiryElement className="card-input-element" options={cardStyle} />
						</Col>
						<Col sm="6" className="pr-0">
							<CardCvcElement className="card-input-element cvc-icon" options={cardStyle} />
						</Col>
					</Row>
				</Container>
				<div className="d-flex justify-content-center" style={{ width: "100%", height: "40px", marginTop: "10px" }}>
					<Button title="Save" type="submit" style={{ width: "max-content", height: "100%", padding: "10px 50px" }} busy={processing} disabled={disabled} onClick={(event) => { handleSubmit(event); }} />
				</div>
			</form>
			<div style={{ position: "relative" }}>
				{alert}
			</div>
		</div>
	);
}