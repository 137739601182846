import React from "react";
import EmployeeSetupForm from "../../../../components/SetupWizad/EmployeeSetupForm";
import EmployeesSettings from "./EmployeesSettings";



export default function UserSettings() {
	return (
		<div style={{ marginTop: "25px", display: "flex", flexDirection: "column", alignItems: "center" }}>
			<div className="setup-form-setup-employee d-flex flex-column align-items-start">
				<span className="b3" style={{ fontWeight: "700" }}>Platform Users</span>
				<div className="dashboard-user-table">
					<EmployeeSetupForm />
				</div>
			</div>
			<div className="setup-form-setup-employee d-flex flex-column align-items-start" style={{marginTop:"-100px"}}>
				<span className="b3" style={{ fontWeight: "700" }}>Employees</span>
				<div className="dashboard-user-table">
					<EmployeesSettings />
				</div>
			</div>
		
		</div>
	);
}