import React from "react";
// import { Row, Col } from "reactstrap";

/**
 * Wizard Card
 * @param {object} props Component props
 * @param {String} props.title Set title of the card
 * @param {String|ImageData} props.image Icon of the card
 * @param {boolean} props.selected decide if the card is selected or not
 * @param {Function} props.onClick Callback for onclick function
 */

export default function WizardCard(props) {
	// var color=props.selected?"white":"red";
	return (
		<div
			className={props.selected ? "wizard-card-selected" : "wizard-card-not-selected"}
			style={props.style}
			onClick={props.onClick}
		>
			<div className="d-flex flex-column align-items-center jutify-content-center">
				<div className="wizard-card-icon">
					{/* <img
						src={props.image || "https://icons.iconarchive.com/icons/mahm0udwally/all-flat/256/Photos-icon.png"}
						alt="icon"
						style={{
							height: "100%",
							width: "100%",
							color:"red"
						}}
					/> */}
					{props.image || "https://icons.iconarchive.com/icons/mahm0udwally/all-flat/256/Photos-icon.png"}
				</div>
				<span className= {props.selected ? "wizard-card-text-selected" : "card-text-not-selected wizard-card-text-not-selected"}>{props.title}</span>
			</div>
		</div>
	);

}