import React, { useEffect, useState } from "react";
import Button from "../sub-components/Button";
import Image from "../../assets/icons/avatars/default_user.png";
import { ReactComponent as  LogoutIcon} from "../../assets/icons/Genral/Logout.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/basic/nav-menu.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/basic/cross.svg";

import { useAuth } from "../../context/AuthContext";
import { useHistory, useRouteMatch } from "react-router";
import { Fragment } from "react";
import { openURL, removeCookie } from "../../utilities/Utilites";

export default function Navbar(props) {
	const auth = useAuth();
	const match = useRouteMatch();
	const history = useHistory();

	const [user, setUser] = useState({
		first_name: "",
		last_name: "",
		image_url: ""
	});

	const [openMobileMenu, setOpenMobileMenu] = useState(false);

	const openMobileMenuHandle = () => {
		if (openMobileMenu) {
			setOpenMobileMenu(false);
			document.getElementById("cs-nav-menu-id").classList.remove("cs-nav-menu-mobile-active");
		}
		else {
			document.getElementById("cs-nav-menu-id").classList.add("cs-nav-menu-mobile-active");
			setOpenMobileMenu(true);
		}

	};


	useEffect(() => {
		
		// console.log(match.url);
		// getUser();
		(async () => {

			if (!auth?.user_id) {
				return;
			}

			const { result } = await openURL(`/v1/user_profile/${auth?.user_id}`, "GET", auth);

			if (result) {

				setUser(result.data);

			}
		})();


	}, [auth]);


	return (
		<Fragment>

			{
				(!match.path.includes("/login")) &&
				<div className="w-100">
					<div className="cs-top-navbar w-100 d-flex align-items-center"
						style={
							props.isTransparent ?
								{ backgroundColor: "transparent", ...props.style }
								:
								{ backgroundColor: "white", ...props.style }
						}>
						<div className="cs-nav-logo">
							{
								props.logo &&
								<img className="cs-nav-logo pointer" alt="img" src={props.logo} onClick={() => {
									if(!auth.is_editing){
										history.push("/");
									}
								}} />
							}

						</div>
						<div className="cs-nav-menu">
							{
								props.headerLinks && props.headerLinks.map((link, index) => {
									if (link.url) {
										var url = link.url;
										if (!url.includes("https://")) {
											url = `https://${url}`;
										}
									}
									return (
										<a
											style={{ marginRight: "15px", backgroundColor: `${props.primaryColor}`, padding: "5px 20px", borderRadius: "48px", cursor: "pointer"}}
											key={index}
											className="vb-link"
											href={url} target="_blank" rel="noopener noreferrer"
										>
											<span className="cs-b2 white-color">{link.label}</span>
										</a >
									);
								})
							}
							<div>
								{
									(auth && auth.access_token)
										?
										<div className="d-flex">
											<Button
												style={{ backgroundColor: `${props.primaryColor}` }}
												profileBtn
												username={"Leaderboard"}
												onClick={()=>{
													history.push("/leaderboard");
												}}
											/>

											<Button
												style={{ backgroundColor: `${props.primaryColor}` ,marginLeft: "15px" }}
												profileBtn2
												username={user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : "Guest"}
												avatar={user.image_url || Image}
												onClick={()=>{
													history.push("/profilepage");
												}}
											/>

											<Button
												style={{ backgroundColor: `${props.primaryColor}`, marginLeft: "15px" }}
												nonRoundedImage
												profileBtn
												username={"Logout"}
												image={<LogoutIcon style={{width: "20px", height: "20px", fill: "white"}}/>}
												onClick={() => {
													removeCookie("token");
													window.location = "/";
												}}
											/>
										</div>
										:
										<div className="d-flex">
											<Button
												style={{ backgroundColor: `${props.primaryColor}` }}
												profileBtn
												username={"Leaderboard"}
												onClick={()=>{
													if (!auth.is_editing) {
														history.push("/leaderboard");
													}
												}}
											/>
											<div
												onClick={() => {
													if (!auth.is_editing) {
														history.push("/login");
													}
												}}
												style={{ backgroundColor: `${props.primaryColor}`, padding: "5px 20px", borderRadius: "48px", cursor: "pointer",marginLeft:"15px" }}>
												<span className="cs-b2 white-color">Login</span>
											</div>
										</div>
								}
							</div>
						</div>

						<div className="cs-nav-menu-mobile-container">
							{
								openMobileMenu
									?
									<CrossIcon style={{ width: "17px", height: "16px", fill: "black" }} onClick={() => openMobileMenuHandle()} />
									:
									<MenuIcon fill={"black"} onClick={() => openMobileMenuHandle()} />
							}

							<div id="cs-nav-menu-id" className="cs-nav-menu-mobile">
								<div className="cs-nav-menu-items" style={{ borderTop: `1px solid ${props.primaryColor}` }}>
									<a
										className="vb-link"
										href={`/`} target="_blank" rel="noopener noreferrer"
										style={{ marginTop: "30px" }}
									>
										<span className="cs-b2" style={{ color: `${props.primaryColor}`, fontSize: "16px", lineHeight: "18px" }}>Home</span>
									</a >
									{
										props.headerLinks && props.headerLinks.map((link, index) => {
											return (
												<a
													key={index}
													className="vb-link"
													href={`${link.url}`} target="_blank" rel="noopener noreferrer"
													style={{ marginTop: "30px" }}>
													<span className="cs-b2" style={{ color: `${props.primaryColor}`, fontSize: "16px", lineHeight: "18px" }}>{link.label}</span>
												</a >
											);
										})
									}
								</div>
								<div
									className="d-flex justify-content-center align-items-center"
									style={{
										marginTop: "100px", padding: "15px",
										borderTop: `1px solid ${props.primaryColor}`, borderBottom: `1px solid ${props.primaryColor}`
									}}>
									{
										(auth && auth.access_token) 
											? 
											(
												<div className="d-flex flex-column align-items-center w-100">
													
													<Button
														style={{ backgroundColor: `${props.primaryColor}` }}
														profileBtn
														username={`${user.first_name} ${user.last_name}` || ""}
														avatar={user.image_url || Image}
													/>
													
													<Button
														style={{ backgroundColor: `${props.primaryColor}`, marginTop: "15px" }}
														nonRoundedImage
														profileBtn
														username={"Logout"}
														image={<LogoutIcon style={{ width: "25px", height: "25px", fill: "white" }} />}
														onClick={() => {
															removeCookie("token");
															window.location.reload();
														}}
													/>

												</div>
											) : (
											// <div
											// 	onClick={() => { history.push("/Login"); }}
											// 	style={{ backgroundColor: `${props.primaryColor}`, padding: "5px 42px", borderRadius: "48px", cursor: "pointer" }}>
											// 	<span className="cs-b2 white-color">Signin</span>
											// </div>
												<span
													onClick={() => { history.push("/login"); }}
													className="cs-b2"
													style={{ color: `${props.primaryColor}`, fontSize: "16px", lineHeight: "18px" }}>
													Signin
												</span>
																							
											)
									}
								</div>
							</div>
						</div>
					</div>
				</div >
			}
		</Fragment >

	);
}