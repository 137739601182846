import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function Graph(props) {

	const [state, setState] = useState();
	useEffect(() => {

		setState({

			options: {
				colors: [`${props.cardColor || "#CE8DFF"}`],
				chart: {
					id: "apexchart-example",
					zoom: {
						type: "x",
						enabled: true,
					},
					menu: {
						enabled: true,
					},
					toolbar: {
						show: true,
						tools: {
							download: true,
							// reset_zoon: false
						}
					},
					fill: {
						colors: [`${props.cardColor || "#CE8DFF"}`]
					},
					dropShadow: {
						enabled: true,
						top: 5,
						left: 0,
						blur: 10,
						opacity: 0.75,
						color: `${props.cardColor || "#CE8DFF"}`
					}
				},
				markers: {
					size: 0,
				},

				xaxis: {
					min: new Date("2021 01 01"),
					max: new Date("2021 03 05"),
					// categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
					// categories: [
					// 	1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
					// 	11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
					// 	21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
					// ]
					type: "date"
				},
				stroke: {
					curve: "straight"
				},
				grid: {
					column: {
						borderColor: "#000",
						opacity: 1,
						colors: ["transparent"],
					},
					yaxis: {
						lines: {
							show: false
						}
					},

					xaxis: {
						lines: {
							show: true,
						}
					}
				},

			},
			series: [{
				type: "line",
				name: "Donation",
				data: props.dataSeries || [1, 5, 9, 3, 4, 5, 3, 5, 14, 20, 15, 12]
			}],

		});

	}, [props.dataSeries]);

	return (
		<div className="graph-container">
			{
				state &&
				<Chart options={state.options} series={state.series} type="line" width="100%" height="100%" />
			}
		</div>
	);

}