import React, { useEffect, useState } from "react";

/**
 * 
 * @param {object} props props object
 * @param {Array} props.options Array of options
 * @returns 
 */
export default function DropDown(props) {

	const [selected, setSelected] = useState({ key: "", value: "" });
	const [search, setSearch] = useState({ key: "", value: "" });

	const onSelect = (object) => {

		setSelected(object);
		setSearch({ key: "", value: "" });

		if (props.onSelect) {
			props.onSelect(object);
		}

	};

	const handleInput = (event) => {

		setSearch({ ...search, value: event.target.value });
		setSelected({ ...selected, value: event.target.value });

	};

	useEffect(() => {
		if (props.selectedKey) {
			const _selected = props.options.find(option => option.key === props.selectedKey);
			setSelected(_selected);
		} else {
			setSelected({ key: "", value: "" });
		}
	}, []);

	return (
		<div className="drop-down-box" tabIndex="1" id={props.id}>
			<input
				className="drop-down-input"
				placeholder="Select"
				onChange={handleInput}
				value={
					(props.maxCharacter && selected.value.length >= props.maxCharacter) ? `${selected.value.substring(0, props.maxCharacter)}...` : selected.value
				}
			/>
			<div className="drop-down-datalist">

				{
					(() => {
						const options = props.options.filter(option => option.value.toLowerCase().includes(search.value.toLowerCase()));

						return options.map((option, index) =>
							<button onMouseDown={() => onSelect({ key: option.key, value: option.value })} key={index}>{option.value}</button>
						);
					})()
				}

			</div>
		</div>
	);

}