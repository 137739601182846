import React, { useState, useEffect, useContext } from "react";
import Button from "../../../components/sub-component/Button";
import { openURL } from "../../../utilities/Utilites";
import { useAuth } from "../../../context/AuthContext";
import { VisualBuilderContext } from "../../../context/VisualBuilderContext";
import VBDropDown from "../../../components/sub-component/visual-builder/VBDropDown";

export default function Navbar() {
	const { editorDataValues, setEditorDataValues } = useContext(VisualBuilderContext);
	const auth = useAuth();

	const [pages, setPages] = useState([{ id: 0, value: "Home" }]);
	const [selectedPage, setSelectedPage] = useState({ id: 0, value: "Home" });
	const [isPublishBtnDisable, setisPublishBtnDisable] = useState(true);

	const [isBusy, setIsBusy] = useState({
		dataHasChanged: false,
		isSaving: false,
		isPublishing: false
	});
	
	const getSelectedPage = (selectedPage) => {
		// console.log(selectedPage, "Selected Page");
		if (selectedPage.id === 0) {
			setEditorDataValues({ ...editorDataValues, selectedPageIndex: selectedPage.id, selectedPage: editorDataValues.data.home });
		}
		else {
			setEditorDataValues({ ...editorDataValues, selectedPageIndex: selectedPage.id, selectedPage: editorDataValues.data.campaigns[selectedPage.id - 1].campaign_components });
		}
		setSelectedPage(selectedPage);

	};

	const saveCharitySite = async () => {
		setIsBusy({ ...isBusy, isSaving: true });
		const { result, error } = await openURL(`/v1/charity_site_data/${editorDataValues.charity_site_id}`, "PATCH", auth, { charitySiteData: editorDataValues.data });
		if (error) {
			console.log("geCompletedCampaign Error:", error);
		}
		else if (result) {
			console.log("save charity site",result.data);
			setisPublishBtnDisable(!result.data.hasDraftSaves);
		}
		setEditorDataValues({ ...editorDataValues, dataHasChanged: false });
		setIsBusy({ ...isBusy, isSaving: false, dataHasChanged: false });
	};

	const publishCharitySite = async () => {

		setIsBusy({ ...isBusy, isPublishing: true });
		const { result, error } = await openURL("/v1/publish_charity_site/", "POST", auth, { id: `${editorDataValues.charity_site_id}` });

		setIsBusy({ ...isBusy, isPublishing: false });

		if (error) {
			console.log(error);
		} else {
			setEditorDataValues({ ...editorDataValues, isPublishing: result.data.isPublishing });
			setisPublishBtnDisable(true);
			// checkingStatus();
		}
	};

	useEffect(() => {
		const tempArray = [{ id: 0, value: "Home" }];
		editorDataValues.data.campaigns.map((campaign, index) => {
			console.log(campaign.campaign_name, index + 1);
			tempArray.push({ id: index + 1, value: campaign.campaign_name });
		});
		setPages(tempArray);
	}, [editorDataValues.totalCampaigns, editorDataValues.data.campaigns]);

	useEffect(() => {
		if (editorDataValues.dataHasChanged) {
			setIsBusy({ ...isBusy, dataHasChanged: editorDataValues.dataHasChanged });
		}
	}, [editorDataValues.dataHasChanged]);

	useEffect(() => {

		setisPublishBtnDisable(!editorDataValues.hasDraftSaves);

	}, [editorDataValues.hasDraftSaves]);

	
	// const checkingStatus = () => {

	// setTimeout(async () => {
	// 	const { result, error } = await openURL(`/v1/charity_site_data/${editorDataValues.charity_site_id}`, "GET", auth);

	// 	if (result) {
	// 		if (result.data.isPublishing) {
	// 			checkingStatus();
	// 		}
	// 		else {
	// 			setEditorDataValues({ ...editorDataValues, isPublishing: false });
	// 			setisPublishBtnDisable(true);
	// 		}
	// 	}
	// 	else {
	// 		console.log(error);
	// 	}
	// }, 30000);
	// };

	// useEffect(() => {
	// 	if (isBusy.isPublishing) {
	// 		checkingStatus();
	// 	}
	// }, [isBusy.isPublishing, editorDataValues.charity_site_id]);
	// const socket = new WebSocket("ws://localhost:4005/v1/publish_charity_site/");
	// useEffect(async () => {
	// console.log(isBusy.isPublishing, "...Publish status");

	// if (isBusy.isPublishing) {
	// 	const interval = setInterval(() => {

	// 		// socket.send(`${editorDataValues.charity_site_id}`);
	// 	}, 1000);
	// 	return () => clearInterval(interval);
	// }
	// }, []);

	// socket.addEventListener("open", function () {
	// 	console.log("Connected to websocket");
	// });

	// socket.addEventListener("message", function (event) {
	// 	if (event.data === "false") {
	// 		setEditorDataValues({ ...editorDataValues, isPublishing: false });
	// 	}
	// });

	return (
		<div
			className=" d-flex align-items-center justify-content-end w-100"
			style={{ padding: "0px 48.5px 0px 0px", height: "100%" }}>

			{/* <div style={{ marginRight: "33px", width: "23px", height: "23px", borderRadius: "50px", boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)", cursor: "pointer", backgroundImage: `url(${help})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }} /> */}
			{/* <div style={{ marginRight: "30px", width: "14px", height: "22px", cursor: "pointer" }}><img src={mobile} /></div> */}
			{/* <div style={{ marginRight: "45px", width: "34px", height: "34px", borderRadius: "5px", border: "1px solid", cursor: "pointer", backgroundImage: `url(${web})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }} /> */}
			<div className="w-100 d-flex justify-content-end">
				<div style={{ marginRight: "15px", width: "80%" }}>
					<VBDropDown
						selectedOption={selectedPage} options={pages} getSelectedOption={getSelectedPage} />
				</div>
			</div>

			<div className="w-50 d-flex justify-content-end">
				<Button isDisabled={!isBusy.dataHasChanged} visualBuilderButton={isBusy.isSaving ? false : true} busy={isBusy.isSaving ? true : false} title="Save as Draft"
					style={{ marginRight: "15px", width: `${isBusy.isSaving ? "57px" : "max-content"}` }} onClick={saveCharitySite} />

				{/* <Button visualBuilderButton={true} isDisabled={isBusy.isPublishing ? true : false} title="View Charity Site"
					style={{ marginRight: "15px", width: "max-content" }} onClick={() => { !isBusy.isPublishing && window.open(`https://${props.siteURL}`, "_blank"); }} /> */}

				<Button isDisabled={isPublishBtnDisable} visualBuilderButton={isBusy.isPublishing ? false : true} busy={isBusy.isPublishing} title="Publish"
					style={{ width: `${isBusy.isPublishing ? "70.1px" : "max-content"}` }}
					onClick={() => { publishCharitySite(); }} />
			</div>
		</div>
	);
}
