/* eslint-disable react/no-string-refs */
import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrows/chevron-bottom.svg";

export default function MultiSelectComponent(props) {

	const [selectedValues, setSelectedValues] = useState([]);
	const [options, setOptions] = useState(props.options);
	const [searchInput, setSearchInput] = useState("");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [goalsToBeChecked,setGoalsToBeChecked]=useState([]);
	useEffect(() => {
		setOptions(props.options);
	}, [props.options]);

	const selectbarRef = useRef();
	const dropdownRef = useRef();

	const filterOnSearch = (e) => {
		setSearchInput(e.target.value);
		if (e.target.value !== "") {
			const tempFilteredOptionsArr = [];
			options.forEach(option => {
				option.subCatagories.forEach(subCatagory => {
					if (subCatagory.toLowerCase().includes(e.target.value.toLowerCase())) {
						const hasSubCatagory = tempFilteredOptionsArr.filter((filteredOption) => { return filteredOption === subCatagory; });
						if (hasSubCatagory.length === 0) {
							const index = tempFilteredOptionsArr.findIndex(item => item.catagory === option.catagory);
							if (index === -1) {
								tempFilteredOptionsArr.push({ catagory: option.catagory, subCatagories: [subCatagory] });
							}
							else {
								tempFilteredOptionsArr[index].subCatagories.push(subCatagory);
							}
						}
					}
				});
			});
			setOptions(tempFilteredOptionsArr);
		}
		else {
			setOptions(props.options);
		}
	};

	useEffect(() => {

		var bool = isDropdownOpen;
		document.getElementById("googleMapCharitiesDropdown").addEventListener("blur", () => {

			console.log("Blured");
		}, false);
		if (bool) {
			dropdownRef.current.focus();

		}
		else {
			dropdownRef.current.blur();
		}

	}, [isDropdownOpen]);
	useEffect(()=>{
		if(props.shouldApplyFilter){
			const goalsInLocalStorage=JSON.parse(localStorage.getItem("goal"));
			if(goalsInLocalStorage){
				setGoalsToBeChecked(goalsInLocalStorage);
				setSelectedValues(goalsInLocalStorage);
				props.getFillteredOptions(goalsInLocalStorage);
			}
			
		}

	},[props.shouldApplyFilter]);
	return (
		<div className="multi-select-comp" ref={selectbarRef}>
			<div className="multi-select-bar pointer" id="map-search-id-xxx"
				onClick={() => setIsDropdownOpen(prev => !prev)} >
				<span className="b4">{props?.placeholder || "--Select--"}</span>
				<ArrowDown />
			</div>
			{

				<div className={`multi-select-dropdown`} id="googleMapCharitiesDropdown" tabIndex="0"
					ref={dropdownRef}>
					<input className="multi-select-search-input" placeholder="Search..." value={searchInput} onChange={(e) => filterOnSearch(e)} id="multi-select-search-input"/>
					<div className="multi-select-catagories-container">
						{
							options?.length > 0 && options.map((option, index) => {
								return (
									<div key={index} className="multi-select-catagories " style={{height:"fit-content"}}>
										<span className="b3" style={{ fontWeight: "700" }}>{option.catagory}</span>
										{
											option.subCatagories.map((subCatagory, index) => {
												return (
													<div key={index} className="multi-select-sub-catagories">

														<input type="checkbox" id="multi-select-single-checkbox-input"
															defaultChecked={goalsToBeChecked.indexOf(subCatagory)!=-1?true:false}
															onClick={() => {
																let tempArr = [...selectedValues];
																if (tempArr.filter(item => item === subCatagory).length === 0) {
																	tempArr.push(subCatagory);
																}
																else {
																	tempArr = tempArr.filter(item => item !== subCatagory);
																}
																setSelectedValues(tempArr);
																props.getFillteredOptions(tempArr);
															}} />
														<span className="b3">{subCatagory}</span>
													</div>
												);
											})
										}
									</div>
								);
							})
						}

					</div>
				</div>

			}
		</div >
	);
}
