import React, { useContext } from "react";
import { VisualBuilderContext } from "../../../context/VisualBuilderContext";
import { ReactComponent as AddIcon } from "../../../assets/icons/visual-builder/add.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/visual-builder/delete.svg";

import DropDown from "../../../components/sub-component/visual-builder/VBDropDown";

export default function AddLinks(props) {


	const { editorDataValues, setEditorDataValues } = useContext(VisualBuilderContext);

	const options = [
		{ id: 1, value: "Facebook" },
		{ id: 2, value: "Instagram" },
		{ id: 3, value: "Twitter" },
		{ id: 4, value: "Linkedin" }
	];

	const getSelectedOption = (option, selectedOptionFor) => {
		// console.log(option, selectedOptionFor);
		setLinkValues(option.value, parseInt(selectedOptionFor.for, 10));
	};

	const addLink = (linksPlacement) => {

		var links = editorDataValues.data[`${linksPlacement}`].props[`${linksPlacement}Links`];
		links = [...links, { url: null, label: null }];
		const tempProps = { ...editorDataValues.data[`${linksPlacement}`].props, [`${linksPlacement}Links`]: links };
		const tempHeaderComponent = { ...editorDataValues.data[`${linksPlacement}`], props: tempProps };
		const tempData = { ...editorDataValues.data, [`${linksPlacement}`]: tempHeaderComponent };
		setEditorDataValues({ ...editorDataValues, data: tempData });
	};

	const removeLink = (linksPlacement, index) => {
		var links = editorDataValues.data[`${linksPlacement}`].props[`${linksPlacement}Links`];

		links.splice(index, 1);
		const tempProps = { ...editorDataValues.data[`${linksPlacement}`].props, [`${linksPlacement}Links`]: links };
		const tempHeaderComponent = { ...editorDataValues.data[`${linksPlacement}`], props: tempProps };
		const tempData = { ...editorDataValues.data, [`${linksPlacement}`]: tempHeaderComponent };
		setEditorDataValues({ ...editorDataValues, data: tempData });
	};

	const setLinkValues = (e, index) => {
		var tempObj = "";
		var tempHeaderComponent = "";
		var tempData = "";
		if (props.headerLinks) {
			const headerLinks = editorDataValues.data.header.props.headerLinks;
			tempObj = headerLinks[index];
			tempObj = { ...tempObj, [`${e.target.name === "cs-link-url" ? "url" : "label"}`]: e.target.value };
			headerLinks[index] = tempObj;

			const tempProps = { ...editorDataValues.data.header.props, headerLinks: headerLinks };
			tempHeaderComponent = { ...editorDataValues.data.header, props: tempProps };
			tempData = { ...editorDataValues.data, header: tempHeaderComponent };

		}
		else if (props.socialLinks) {
			const footerLinks = editorDataValues.data.footer.props.footerLinks;
			tempObj = footerLinks[index];
			tempObj = { ...tempObj, [`${e.target !== undefined ? "url" : "label"}`]: `${e.target !== undefined ? e.target.value : e}` };
			footerLinks[index] = tempObj;

			const tempProps = { ...editorDataValues.data.footer.props, footerLinks: footerLinks };
			tempHeaderComponent = { ...editorDataValues.data.footer, props: tempProps };
			tempData = { ...editorDataValues.data, footer: tempHeaderComponent };

		}
		setEditorDataValues({ ...editorDataValues, data: tempData, dataHasChanged: true });
	};
	return (
		<div className="W-100" style={{ ...props.style }}>
			{

				<div>
					<div className="d-flex justify-content-between">
						<span className="roboto-b2 licorice-color">{props.headerLinks && "Header Links"} {props.socialLinks && "Social Links"} </span>
						{
							(props.headerLinks && editorDataValues.data.header.props.headerLinks.length < 4) &&
							<AddIcon className="pointer vb-icon-effect" id="add-header-social-links" style={{ marginRight: "4.5px" }} onClick={() => addLink("header")} />
						}
						{
							(props.socialLinks && editorDataValues.data.footer.props.footerLinks.length < 4) &&
							<AddIcon className="pointer vb-icon-effect" id="add-footer-social-links" style={{ marginRight: "4.5px" }} onClick={() => addLink("footer")} />
						}

					</div>
					<div style={{ marginTop: "15px" }}>
						{
							(props.headerLinks && editorDataValues.data.header.props.headerLinks) && editorDataValues.data.header.props.headerLinks.map((headerLink, index) => {
								return (
									<div key={index} className="vb-cs-link-container vb-header-links-container" style={{ boxShadow: " 2px 8px 15px rgba(0, 0, 0, 0.1)" }}>
										<div className="cs-link-url-input">
											<input name="cs-link-url" placeholder="URL" value={headerLink.url || ""} onChange={(e) => setLinkValues(e, index)}></input>
											<DeleteIcon className="pointer vb-icon-effect" style={{ marginRight: "4.5px" }} onClick={() => removeLink("header", index)} />
										</div>
										<div className="cs-link-label-input">
											<input style={{width:"100%"}} name="cs-link-label" placeholder="Label" value={headerLink.label || ""} onChange={(e) => setLinkValues(e, index)}></input>
										</div>
									</div>
								);
							})
						}
						{
							(props.socialLinks && editorDataValues.data.footer.props.footerLinks) && editorDataValues.data.footer.props.footerLinks.map((footerLink, index) => {
								return (
									<div key={index} className="vb-cs-link-container vb-footer-links-container" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 2px 8px 15px" }}>
										<div className="cs-link-url-input">
											<input name="cs-link-url" placeholder="URL" value={footerLink.url || ""} onChange={(e) => setLinkValues(e, index)}></input>
											<DeleteIcon className="pointer vb-icon-effect" style={{ marginRight: "4.5px" }} onClick={() => removeLink("footer", index)} />
										</div>
										<div className="cs-link-label-dropdown">
											<DropDown
												isVbDrobdown
												iconColorClass="vb-icon-effect"
												height="100%" width="100%"
												selectedOption={footerLink.label ? { value: `${footerLink.label}` } : null}
												options={options} selectedOptionFor={index} getSelectedOption={getSelectedOption} />
										</div>
									</div>
								);
							})
						}
					</div>
				</div>
			}
		</div>
	);
}
