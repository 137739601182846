import React from "react";


/**
 * 
 * @param {object} props Props object for component
 * @param {string} props.color class name for alert
 * @param {string} props.title Title of the alert
 * @param {string} props.description Description of the alert
 * @returns 
 */
export default function AlertBox(props) {

	return (
		<div className={`d-flex flex-column alert ${props.color || "success"}`}>
			<span style={{textAlign: "left", fontWeight: "bold"}}>{props.title}</span>
			<span style={{textAlign: "left"}}>{props.description}</span>
		</div>
	);

}