import React from "react";
import { Col, Container, Row } from "reactstrap";

/**
 * @param {Object} props Prop object
 * @param {Array} options array of objects for segment
 */
export default function SegmentToggle(props) {

	return (
		<div className="segment-toggle-container" id={props.id}>

			<Container fluid={true} className="p-0">
				<Row>
					{
						props.options.map((option, index) => {
							if (props.selected.key === option.key) {
								return (
									<Col key={index} style={{ fontWeight: "normal" }} className={`${props?.isOnLandingSite ? "ls-s0" : "b2"} p-0 segment-toggle selected text-center `}  >
										{props.disabled && (
											<span className="busy-indicator-blue" style={{borderColor: "#FFF #FFF #FFF transparent", marginRight: "5px"}} />
										)}
										{option.value}
									</Col>
								);
							} else {
								return (
									<Col key={index} style={{ fontWeight: "normal" }} className={`${props?.isOnLandingSite ? "ls-s0" : "b2"} p-0 segment-toggle not-selected text-center `}
										onClick={() => !props.disabled && props.onSwitch && props.onSwitch(option)}
									>
										{option.value}
									</Col>
								);
							}
						})
					}
					{/* <Col className="p-0 segment-toggle not-selected text-center">Yearly</Col> */}
				</Row>
			</Container>

		</div>
	);


}