import React, { useEffect, useRef, useState } from "react";
import { Row } from "reactstrap";

import uploadIcon from "../../assets/icons/basic/share-arrow.svg";

import { useAuth } from "../../context/AuthContext";

import { imageUploadURL } from "../../utilities/Utilites";
import Button from "./Button";

/**
 * Image Picker
 * @param {object} props Image Picker
 * @param {Function} props.getFile callback function to get selected image file
 */
export default function ImagePicker(props) {

	console.log(props,"props of image picker");

	const auth = useAuth();

	const [image, setImage] = useState(props.image || null);
	const [images, setImages] = useState(props.images || []);

	const [isImageSelected, setIsImageSelected] = useState(false);
	const [files, setFiles] = useState(null);

	const [isBtnBussy, setIsBtnBussy] = useState(false);

	const inputRef = useRef(null);
	// const [isCrossVisible, setisCrossVisible] = useState(initialState);

	const pickImage = () => {

		inputRef.current.click();

	};

	const multiImages = [];


	const showCross = (index) => {
		document.getElementById(`ImageCross${index}`).classList.remove("d-none");
	};

	const hideCross = (index) => {
		document.getElementById(`ImageCross${index}`).classList.add("d-none");
	};

	const deleteImage = (index) => {

		if(files != null){
			var tempObj = {};
			console.log((Object.keys(files).length - (images.length - index)), "Object index");
			for (let i = 0; i < Object.keys(files).length; i++) {
				if ((Object.keys(files).length - (images.length - index)) != i) {
					var secondTempObj = { [i]: files[i] };
					tempObj = { ...tempObj, ...secondTempObj };
				}

			}
			setFiles(tempObj);
		}

		var tempImages = [];
		images.map((image, innerIndex) => {
			if(index != innerIndex){
				tempImages.push(image);
			}
		});

		setImages(tempImages);

		if (index <= props.images.length-1){
			if (props.multipleImages && props.getFiles) {
				props.getFiles({ files: tempImages, imageFor: props.imageFor });
			}
		}		
	};

	const onImagePick = async (event) => {
		setIsImageSelected(true);
		
		if (props.multipleImages) {
			{
				
				var allSelectedFiles = event.target.files;

				// if(files){

				// 	var objMerge = files;
				// 	for (let i = 0; i < (allSelectedFiles.length); i++) {
				// 		const index = Object.keys(files).length + i;						
				// 		const obj = { [index]: allSelectedFiles[i]};
				// 		objMerge = { ...objMerge, ...obj};
				// 	}
				// 	allSelectedFiles = objMerge;
				// }

				// console.log(allSelectedFiles, Object.keys(allSelectedFiles).length , "Images");
				
				const temp = allSelectedFiles;
				for (let i = 0; i < Object.keys(temp).length; i++) {
					const url = URL.createObjectURL(temp[i]);
					multiImages.push(url);
				}
				// console.log(multiImages);

				setFiles(allSelectedFiles);
				var tempArr = [];
				props.images.map((image) => {
					tempArr.push(image.image);
				});
				setImages(tempArr.concat(multiImages));
				// if (props.getFiles) {
				// 	props.getFiles({ files: multiImages, imageFor: props.imageFor });
				// }
			}
		}
		else {
			setFiles(event.target.files);
			// console.log(event.target.files, "Images");
			console.log(event.target.files[0]);

			const file = URL.createObjectURL(event.target.files[0]);
			setImage(file);
			// if (props.getFile) {
			// 	props.getFile({ file: event.target.files[0], imageFor: props.imageFor });
			// }
		}

	};

	useEffect(() => {
		// console.log(files, "files123");
		console.log(images, "images123");
	}, [files, images]);

	const uploadImage = async () => {
		// console.log(files);
		if (Object.keys(files).length > 0) {
			setIsBtnBussy(true);
			console.log(files, "uploading");
			console.log(props,"props========");
			const { result, error } = await imageUploadURL("/v1/charity_site_content/", auth, files);
			if (result) {
				console.log(result, "result in imagepicker");
				var tempArr = [];
				result.data.map((image) => {
					tempArr.push(image.location.replaceAll("%20", " "));
				});
				console.log(tempArr, "temp in imagepicker===========");
				setIsBtnBussy(false);
				console.log("1st");
				if (props.multipleImages && props.getFiles) {
					// console.log(tempArr, , "Images");
					console.log("2nd");
					tempArr.map((image, index) => {
						console.log(images.length - tempArr.length + index , "indexes");
						images[images.length - tempArr.length + index] = image;
					});
					props.getFiles({ files: images, imageFor: props.imageFor });
					setFiles(null);
				}
				else if (!props.multipleImages && props.getFile) {
					console.log("3rd");
					props.getFile({ file: tempArr[0], imageFor: props.imageFor });
				}
				console.log("4th");
			}
			else {
				console.log(error, "error");
			}
			setIsBtnBussy(false);
			setIsImageSelected(false);
		}
	};

	useEffect(() => {
		setImage(props.image);
	}, [props.image]);

	useEffect(() => {
		var tempArr = [];
		if (props.images) {
			props.images.map((image) => {
				tempArr.push(image.image);
			});
			setImages(tempArr);
		}

	}, [props.images]);


	return (
		<div className="d-flex flex-column align-items-start">
			<span className="b3">{props.label}</span>
			<div
				onClick={pickImage}
				style={{
					height: `${props.height}px`,
					width: `${props.width}px`,
					borderRadius: "5px",
					cursor: "pointer",
					background: "#FFFFFF",
					boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)",
					overflow: "hidden",
					marginTop: "10px",
					// eslint-disable-next-line react/prop-types
					...props.style
				}}
			>

				<div style={{ position: "relative", height: "100%", width: "100%" }}
					className="d-flex justify-content-center align-items-center">

					{
						(() => {
							if (image) {
								return (
									<div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
										<img
											id={props.id}
											alt="image"
											src={image}
											style={{ objectFit: "cover" }}
										/>
									</div>
								);
							} else {
								return (
									<img
										alt="upload icon"
										src={uploadIcon}
										style={{ height: "50%", width: "50%" }}
									/>
								);
							}
						})()
					}


				</div>

				<input type="file" id="images" multiple hidden={true} ref={inputRef} onChange={onImagePick} accept="image/x-png,image/jpeg" />

				{/* <input type="file" hidden={true} ref={inputRef} onChange={props.onSelect} accept="image/x-png,image/jpeg" /> */}
			</div>
			{props.multipleImages &&
				<div className="selected-images">
					<Row>
						{images.map((value, index) => {
							return (
								<div key={index} style={{ padding: "5px", position: "relative" }} onMouseEnter={() => showCross(index)} onMouseLeave={() => hideCross(index)}>
									<div className="d-none" id={`ImageCross${index}`} style={{ width: "50px", height: "50px", position: "absolute"}} onClick={()=>deleteImage(index)}>
										{/* <span className="white-color">X</span> */}
										<div style={{width:"100%", height: "100%", backgroundColor: "black", opacity: "0.5"}}></div>
										<span className="white-color" style={{position: "absolute", top: "0", left: "16px", fontSize: "30px"}}>X</span>
									</div>
									<img src={value} style={{ height: "50px", width: "50px" }} />
								</div>
							);
						})
						}
					</Row>
				</div>
			}

			{
				isImageSelected &&
				<div className="w-100 d-flex justify-content-end">
					<Button visualBuilderButton={isBtnBussy ? false : "blue"} busy={isBtnBussy ? true : false}
						style={{ width: `${isBtnBussy ? "69px" : "max-content"}`, height: `${isBtnBussy ? "33px" : "max-content"}` }}
						title="Upload" onClick={() => uploadImage()} />
				</div>
			}

		</div>
	);

}