import React from "react";
import { Link } from "react-router-dom";

import altrunc_logo from "../../assets/icons/logo.png";

export default function AuthMessageCard(props) {

	return (
		<div className="message-card d-flex flex-column " >

			<div className="message-card-logo w-100 mb-0 d-flex -align-items-center justify-content-center" >

				<Link to="/"><img src={altrunc_logo} /></Link>

			</div>

			<div className="d-none d-lg-flex align-items-center justify-content-center">
				<h3 className="s1" style={{ fontSize: "40px", lineHeight: "60px", textAlign: "left", maxWidth: "70%", marginTop: "" }}>
					{
						props.paragraph || "Giving is not just about make a <span style={{ color: '#0054DC' }}>donation</span>, it’s about makeing a <span style={{ color: '#0054DC' }}>impact.</span>"
					}
				</h3>
			</div>

		</div>
	);

}