import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AlertBox from "../components/common/AlertBox";
import baseURL from "../utilities/BaseURL";
import { getCookie, SubscriptionStatus } from "../utilities/Utilites";

const AuthContext = createContext({
	userProfile: {
		id: "",
		first_name: "",
		last_name: "",
		email: "",
	},
	subscription: {
		status: SubscriptionStatus.status.PENDING,
	}

});

export function useAuth() {
	return useContext(AuthContext);
}

class AuthStatus {

	static get pending() {
		return "PENDING";
	}

	static get unauthorized() {
		return "UNAUTHORIZED";
	}

	static get authorized() {
		return "AUTHORIZED";
	}

}

// const unAuthRestrictedPaths = [
// 	"/dashboard",
// 	"/setup",
// 	"/sitebuilder",
// 	"/subscription",
// 	"/account_suspended",
// ];

const authRestrictedPaths = [
	"/",
	"/login",
	"/signup",
	"/forgotpassword",
	"/resetpassword",
	"/profile_setup",
	"/setup/enterprise",
	"/setup/charitysite",
	"/subscription"
	// "/activation",
	// "/activation/"
];

export default function AuthProvider({ children }) {

	const [authStatus, setAuthStatus] = useState(AuthStatus.pending);
	const [auth, setAuth] = useState(null);
	const [tenantProfile, setTenantProfile] = useState();
	const [userProfile, setUserProfile] = useState();
	const [subscription, setSubscription] = useState();

	const [hasImpactSiteUrl, setHasImpactSiteUrl] = useState(true);

	const history = useHistory();

	async function refreshToken() {

		try {

			const response = await fetch(`${baseURL}/v1/auth/refresh`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
				},
				body: JSON.stringify({ refresh_token: getCookie("token") })
				// credentials: "include",
			});

			if (!response.ok) {
				return { authentication: false };
			}

			const json = await response.json();

			return { authentication: true, json };

		} catch (err) {
			return { authentication: false };
		}

	}

	async function hasTenantProfile(tenant_id, access_token) {

		try {

			const response = await fetch(`${baseURL}/v1/tenant_profile/${tenant_id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
					"Authorization": `Bearer ${access_token}`,
				}
			});

			if (!response.ok) {
				return false;
			}

			const json = await response.json();
			setTenantProfile(json.data);

			return json.data.is_active;

		} catch (err) {
			return false;
		}

	}

	async function hasCharitySite(access_token) {

		try {

			const response = await fetch(`${baseURL}/v1/charity_site/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
					"Authorization": `Bearer ${access_token}`,
				}
			});

			if (!response.ok) {
				return false;
			}

			const sites = await response.json();

			return sites;

		} catch (err) {
			return false;
		}

	}

	async function getUserProfile(userId, access_token) {

		try {

			const user = await fetch(`${baseURL}/v1/user/${userId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
					"Authorization": `Bearer ${access_token}`,
				}
			});

			const user_profile = await fetch(`${baseURL}/v1/user_profile/${userId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
					"Authorization": `Bearer ${access_token}`,
				}
			});

			var profile_data = { first_name: "", last_name: "" };
			if (user_profile.ok) {
				profile_data = await user_profile.json();
				// return false;
			}

			const profile = await user.json();

			const data = { ...profile, ...profile_data.data };


			return data;

		} catch (err) {
			// console.log(err);
			// return false;
		}

	}

	async function getTenantSubscription(access_token) {

		try {

			const response = await fetch(`${baseURL}/v1/subscriptions/?with_package=true`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
					"Authorization": `Bearer ${access_token}`,
				},
			});
			const json = await response.json();

			if (!response.ok) {
				return false;
			}

			setSubscription(json.data[0]);

			return json.data[0];

		} catch (err) {
			return false;
		}

	}

	async function authRefresh() {
		setAuthStatus(AuthStatus.pending);

		const data = await refreshToken();

		if (!data.authentication) {
			if (history.location.pathname.includes("/dashboard") || history.location.pathname.includes("/setup") || history.location.pathname.includes("/sitebuilder") || history.location.pathname.includes("/subscription") || history.location.pathname.includes("/account_suspended")) {
				history.push("/");
			}
			// console.log(unAuthRestrictedPaths.indexOf(history.location.pathname), "FLAG");
			// if (unAuthRestrictedPaths.indexOf(history.ocation.pathname) < 0) {
			// 	history.push("/");
			// }
			setAuthStatus(AuthStatus.unauthorized);
			return;
		}

		setAuth(data.json);



		const profile = await getUserProfile(data.json.user_id, data.json.access_token);

		if (profile) {
			setUserProfile(profile);

			if (!profile.is_active) {
				if (!window.location.pathname.includes("/activation")) {
					history.push("/activation");
				}
				setAuthStatus(AuthStatus.unauthorized);
				return;
			}

			if (!profile.first_name || profile.first_name === "") {
				history.push("/profile_setup");
				setAuthStatus(AuthStatus.unauthorized);
				return;
			}

		}


		const tenantSubscription = await getTenantSubscription(data.json.access_token);

		if (!tenantSubscription) {
			history.push("/subscription");
			setAuthStatus(AuthStatus.unauthorized);
			return;
		} else if (tenantSubscription.status === SubscriptionStatus.status.PAST_DUE) {
			history.push("/account_suspended");
			setAuthStatus(AuthStatus.unauthorized);
			return;
		}


		const isTenantActive = await hasTenantProfile(data.json.tenant_id, data.json.access_token);


		if (!isTenantActive) {
			history.push("/setup/enterprise");
			setAuthStatus(AuthStatus.unauthorized);
			return;
		}

		const charitySites = await hasCharitySite(data.json.access_token);

		if (charitySites && charitySites.data.length === 0) {
			history.push("/setup/charitysite");
			setAuthStatus(AuthStatus.unauthorized);
			return;
		} else if (charitySites && !charitySites.data[0].site_url) {
			history.push("/dashboard/settings/charity");
			setAuthStatus(AuthStatus.authorized);
			setHasImpactSiteUrl(false);
			return;
		}


		if (authRestrictedPaths.includes(history.location.pathname.toLowerCase())) {
			history.push("/dashboard/");
		}

		setAuthStatus(AuthStatus.authorized);

	}
	// useMemo(async ()=>{
	// 	try {
	// 		const data = await refreshToken();
	// 		getTenantSubscription(data.json.access_token);
	// 	} catch (error) {
	// 		console.log(error.message);
	// 	}

	// },[refreshTime]);
	useEffect(() => {
		authRefresh();
	}, []);

	return (
		<AuthContext.Provider value={{ ...auth, authRefresh: authRefresh, userProfile, tenantProfile, subscription, setHasImpactSiteUrl }}>
			<>
				{
					!hasImpactSiteUrl && (
						<div style={{ width: "calc(100vw - 120px)", paddingTop: "20px", margin: "auto" }}>
							<AlertBox type="global-notification" color="danger" title={"Alert!"}
								description={<p className="b3 m-0" style={{ color: "#FC4848" }}>You do not have a impact site url.
									<font style={{ color: "#0054DC" }} className="pointer" onClick={() => { history.push("/dashboard/settings/charity"); }}> Click here</font> to set impact site url.</p>
								} />
						</div>
					)
				}

			</>
			{
				authStatus !== AuthStatus.pending ?
					children
					:
					<></>
			}
		</ AuthContext.Provider>
	);

}