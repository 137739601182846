/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import Map from "./Map/Map";
import {
	withGoogleMap,
	withScriptjs
} from "react-google-maps";
import ReactStars from "react-stars";
import SelectedCharity from "./SelectedCharity";
import { ReactComponent as CrossIcon } from "../../assets/icons/basic/circle-x.svg";
import MultiSelectComponent from "../sub-component/MultiSelectComponent";
import { ReactComponent as SearchIcon } from "../../assets/icons/basic/search.svg"; //circle.svg
import { ReactComponent as CircleIcon } from "../../assets/icons/notifications/circle.svg";
import { getUnauthorizedURL, getOriginFromLink } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";
import ModalComp from "../common/Modal";
import { useDebounce } from "../../hooks/useDebounce";

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default function SearchAndSelectCharity(props) {

	const Api_Key = "AIzaSyBVs9QYaOM6NBJ1CUdfoPIzNY2o3swWgFs";
	const auth = useAuth();

	const [tempSelectedCharity, setTempSelectedCharity] = useState(props.selectedCharity);

	const [charities, setCharities] = useState(props.charities);
	const [impacts, setImpacts] = useState([]);

	const [hoveredCharity, setHoveredCharity] = useState(null);
	const [mapCenter, setMapCenter] = useState({
		lat: null,
		lng: null
	});

	const [IsCharitiesLoading, setIsCharitiesLoading] = useState(false);
	const [isCharityModalOpen,setIsCharityModalOpen]=useState(false);
	const [searchByTitle, setSearchByTitle] = useState("");
	const [searchByZipcode, setSearchByZipcode] = useState("");
	const [searchByState, setSearchByState] = useState("");
	const [searchByImpacts, setSearchByImpacts] = useState([]);
	const [selectedCharity,setSelectedCharity]=useState(props?.selectedCharity);
	const debouncedSearchByTitle = useDebounce(searchByTitle);
	const debouncedSearchByZipcode = useDebounce(searchByZipcode);
	const debouncedSearchByState = useDebounce(searchByState);

	useEffect(async () => {
		if (props.charities.length > 0) {
			setCharities(props.charities);
			setMapCenter({ lng: props.charities[0].longitude, lat: props.charities[0].latitude });
			const tempSDGsArr = new Set();
			const tempImpactWRTSDGsArr = [];
			const { result } = await getUnauthorizedURL(`/v1/impact/`, { id: auth?.userProfile ? auth?.userProfile.id : null, tenant_id: null });

			if (result && result.message.length > 0) {
				result.message.forEach((impact) => tempSDGsArr.add(impact?.sdg));

				tempSDGsArr.forEach((sdg) => {
					const obj = { catagory: sdg, subCatagories: [] };
					result.message.forEach((impact) => {
						if (impact?.sdg === sdg) {
							const hasImpact = obj?.subCatagories?.filter(charitIimpact => { return charitIimpact?.toLowerCase()?.includes(impact?.factor?.toLowerCase()); });
							if (hasImpact?.length === 0) {
								obj?.subCatagories?.push(impact?.factor);
							}
						}
					});
					tempImpactWRTSDGsArr?.push(obj);
				});
			}

			setImpacts(tempImpactWRTSDGsArr);
		}
	}, []);

	const getSelectedCharity=(charity)=>{
		if(charity){
			setSelectedCharity({
				impact_id: charity?.charity_impacts[0]?.impact_id,
				selectedImpact: charity?.charity_impacts[0],
				website: charity?.charity_url,
				...charity
			});
			setIsCharityModalOpen(false);
	
		}
		else{
			setSelectedCharity(null);
			setIsCharityModalOpen(false);
		}
	};

	const getTempSelectedCharity = (charity) => {
		if (charity) {
			setTempSelectedCharity({
				impact_id: charity?.charity_impacts[0]?.impact_id,
				selectedImpact: charity?.charity_impacts[0],
				website: charity?.charity_url,
				...charity
			});
			setIsCharityModalOpen(true);
		}
		else {
			setTempSelectedCharity(null);
			setIsCharityModalOpen(false);
		}
	};

	const getSelectedImpact = (impact) => {
		setSelectedCharity({
			...selectedCharity, selectedImpact: impact, impact_id: impact?.impact_id
		});
	};

	useEffect(() => {
		props?.getSelectedCharites(selectedCharity);
	}, [selectedCharity]);

	useEffect(() => {
		if (props?.selectedCharity) {
			setSelectedCharity(props?.selectedCharity);
			if (props?.selectedCharity) {
				setMapCenter({ lng: props.selectedCharity?.longitude, lat: props.selectedCharity?.latitude });
			}
		}
	}, [props?.selectedCharity]);

	const getFillteredOptions = (options) => {
		setSearchByImpacts(options);
	};

	const getCurrentCenter = (mapCenterCoordinates) => {
		setTimeout(() => setMapCenter(mapCenterCoordinates), 1000);
	};

	const getCharitiesByFilters = async () => {
		const mapQuery = new URLSearchParams({
			impacts: searchByImpacts,
			search: debouncedSearchByTitle,
			zip_code: debouncedSearchByZipcode,
			state: debouncedSearchByState
		}).toString();

		const mapCharitiesResult = await getUnauthorizedURL(`/v1/charities/?${mapQuery}`, { id: auth?.userProfile ? auth?.userProfile.id : null, tenant_id: null });
		if (mapCharitiesResult.result) {
			setCharities(mapCharitiesResult.result.data);
		}
		setIsCharitiesLoading(false);
	};

	useEffect(() => {
		if (mapCenter.lat !== null && mapCenter.lng !== null) {
			setIsCharitiesLoading(true);
			setTimeout(() => getCharitiesByFilters(), 500);
		}
	}, [mapCenter, debouncedSearchByTitle, searchByImpacts, debouncedSearchByZipcode, debouncedSearchByState]);

	const FilteredCharites = (filterByMapCenterArr) => {
		return (
			<div className="SSCBC-charities-section h-100">
				<>
					{
						IsCharitiesLoading
							?
							<div className="w-100 d-flex justify-content-center align-items-center" style={{ height: "95px" }}>
								<span className="busy-indicator-blue " style={{ height: "20px", width: "20px" }}></span>
							</div>
							:
							<>
								{
									filterByMapCenterArr && filterByMapCenterArr.map((charity, index) => {
										return (
											<Fragment key={index}>
												{charity?.charity_impacts[0].charity_id!==selectedCharity?.charity_impacts[0].charity_id && <>
													<div
														id={index}
														className="pointer SSCBC-charities-section-item mr-2 ml-2"
														style={index !== (filterByMapCenterArr.length - 1) ? { padding: "10px 20px", borderBottom: "1px solid #CBE3FF" } : { padding: "10px 20px" }}
														onClick={() => getTempSelectedCharity(charity)}
														onMouseOver={() => { setHoveredCharity(charity); }}
														onMouseLeave={() => { setHoveredCharity(null); }}
													>
														<div className="d-flex align-items-center">
															<img src={`https://logo.clearbit.com/${getOriginFromLink(charity?.charity_url)}`} className="charity-logo-image" style={{width:"37px",height:"37px"}}/>
															<span className="b4" style={{ marginLeft: "5px" }}>{charity?.name}</span>
															<CircleIcon className="mx-2 mt-1" />
															<span className="b4">{charity?.charity_impacts?.length > 0 && charity?.charity_impacts[0]?.impact?.sdg}</span>
														</div>
														<ReactStars
															className="SSCSC-rating-card-stars"
															count={5}
															value={Number(charity.score)/20}
															size={16}
															edit={false}
															half={true}
															color1={"#C4C4C4"}
															color2={"#0054DC"} />
													</div>
												</>

													
												}
												{charity?.charity_impacts[0].charity_id===selectedCharity?.charity_impacts[0].charity_id && <>
													<div
														id={index}
														className="pointer SSCBC-selected-charities-section-item mr-2 ml-2 d-flex"
														style={index !== (filterByMapCenterArr.length - 1) ? { padding: "10px 20px" } : { padding: "10px 20px" }}
														// onClick={() => getTempSelectedCharity(charity)}
														// onMouseOver={() => { setHoveredCharity(charity); }}
														// onMouseLeave={() => { setHoveredCharity(null); }}
													>
														<div className="d-flex align-items-start mr-2">
															<img src={`https://logo.clearbit.com/${getOriginFromLink(charity?.charity_url)}`} className="charity-logo-image" />
														
														</div>
														<div className=" d-flex flex-column w-100 ml-3">
															<div className="b4 d-flex w-100 justify-content-between" >
																<div className="b3 mb-1">
																	{charity?.name}
																</div>
																<div className="mt-1 b4">
																	<CrossIcon className="pointer" onClick={()=>{setSelectedCharity(null);}} />
																</div>
															</div>
															{/* <CircleIcon className="mx-2 mt-1" /> */}
															<span className="b4 mb-1">{charity?.charity_impacts?.length > 0 && charity?.charity_impacts[0]?.impact?.sdg}</span>
															<ReactStars
																className="SSCSC-rating-card-stars"
																count={5}
																value={Number(charity.score)/20}
																size={16}
																edit={false}
																half={true}
																color1={"#C4C4C4"}
																color2={"#0054DC"} />
															<span className="b4 hoki-color">Impact Factor</span>
															<span className="b4 SSCBC-charities-section-item pl-2">{charity?.charity_impacts[0]?.impact_statement}</span>
														</div>
														
													</div>
													<div style={{borderBottom:"2.5px solid #CBE3FF"}} className="m-2 mb-3"></div>
												</>

													
												}

											</Fragment>
										);
									})
								}
							</>
					}
				</>
			</div>
		);
	};
	useMemo(()=>{
		if(props.selectedCharity===null){
			setSelectedCharity(null);
		}
	},[props.selectedCharity]);
	const FilteredCharitesMemo = useMemo(() => FilteredCharites(charities), [charities, IsCharitiesLoading,selectedCharity]);

	const charityRef = useRef();

	return (
		<div className="search-select-charity-box-container">
			{!props.isLowWidth ? (
				<div className={`d-flex mx-3 ${!props.isLowWidth ? "pt-4" : ""}`}>
					<div className="SSCBC-input-section">
						<div
							className="px-2"
							style={{ borderRight: "1px solid rgb(0, 122, 255)" }}
						>
							<SearchIcon fill={"rgb(0, 122, 255)"} className="pointer" />
						</div>
						<input
							className="b3 w-100"
							placeholder="Search..."
							value={searchByTitle}
							id="select-charity-search-input"
							onChange={(e) => setSearchByTitle(e.target.value)}
						/>
					</div>
					<div className="SSCBC-input-section ml-3">
						<div
							className="px-2"
							style={{ borderRight: "1px solid rgb(0, 122, 255)" }}
						>
							<SearchIcon fill={"rgb(0, 122, 255)"} className="pointer" />
						</div>
						<input
							className="b3 w-100"
							placeholder="Zipcode"
							value={searchByZipcode}
							pattern="[0-9\s]*"
							min="0"
							max="99999"
							maxLength="5"
							id="select-charity-search-input"
							onChange={(e) => 
								setSearchByZipcode((prev) =>
									e.target.validity.valid && 
									(+e.target.min <= +e.target.value && +e.target.value <= +e.target.max) &&
									e.target.value.length <= 5
										? e.target.value : prev
								)
							}
						/>
					</div>
					<div className="SSCBC-input-section ml-3">
						<div
							className="px-2"
							style={{ borderRight: "1px solid rgb(0, 122, 255)" }}
						>
							<SearchIcon fill={"rgb(0, 122, 255)"} className="pointer" />
						</div>
						<input
							className="b3 w-100"
							placeholder="State"
							value={searchByState}
							pattern="[a-zA-Z]*"
							id="select-charity-search-input"
							onChange={(e) =>
								setSearchByState((prev) =>
									e.target.validity.valid ? e.target.value : prev
								)
							}
						/>
					</div>
					<div className="w-100 ml-3">
						<MultiSelectComponent
							options={impacts}
							shouldApplyFilter={props.shouldApplyFilter}
							placeholder="Select Impact"
							getFillteredOptions={getFillteredOptions}
						/>
					</div>
				</div>
			) : null}
			<div
				className={`d-flex mt-2 ${props.isLowWidth ? "flex-column" : ""}`}
				style={{ height: `${props.isLowWidth ? "700px" : "550px"}` }}
			>
				<div
					className="w-100"
					style={{
						height: `${props.isLowWidth ? "350px" : "549px"}`,
						padding: "15px",
						borderRadius: "10px",
					}}
				>
					{charities && mapCenter.lat !== null && (
						<MapWrapped
							googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${Api_Key}`}
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `100%` }} />}
							mapElement={<div style={{ height: `100%`,borderRadius:"12px" }} />}
							center={mapCenter}
							getCurrentCenter={getCurrentCenter}
							getSelectedCharityFromMap={getSelectedCharity}
							charities={charities}
							hoveredCharity={hoveredCharity}
							selectedCharity={selectedCharity}
						/>
					)}
				</div>
				<div
					className="w-100"
					style={{
						height: `${props.isLowWidth ? "310px" : "549px"}`,
						padding: "15px",
						borderRadius: "10px",
					}}
				>
					{props.isLowWidth ? (
						<div className={`d-flex mx-2 ${!props.isLowWidth ? "pt-4" : ""}`}>
							<div className="SSCBC-input-section">
								<div
									className="px-2"
									style={{ borderRight: "1px solid rgb(0, 122, 255)" }}
								>
									<SearchIcon fill={"rgb(0, 122, 255)"} className="pointer" />
								</div>
								<input
									className="b3 w-100"
									placeholder="Search..."
									value={searchByTitle}
									id="select-charity-search-input"
									onChange={(e) => setSearchByTitle(e.target.value)}
								/>
							</div>
							<div className="SSCBC-input-section ml-3">
								<div
									className="px-2"
									style={{ borderRight: "1px solid rgb(0, 122, 255)" }}
								>
									<SearchIcon fill={"rgb(0, 122, 255)"} className="pointer" />
								</div>
								<input
									className="b3 w-100"
									placeholder="Zipcode"
									value={searchByZipcode}
									pattern="[0-9\s]*"
									min="0"
									max="99999"
									maxLength="5"
									id="select-charity-search-input"
									onChange={(e) =>
										setSearchByZipcode((prev) =>
											e.target.validity.valid && 
											(+e.target.min <= +e.target.value && +e.target.value <= +e.target.max) &&
											e.target.value.length <= 5
												? e.target.value : prev
										)
									}
								/>
							</div>
							<div className="SSCBC-input-section ml-3">
								<div
									className="px-2"
									style={{ borderRight: "1px solid rgb(0, 122, 255)" }}
								>
									<SearchIcon fill={"rgb(0, 122, 255)"} className="pointer" />
								</div>
								<input
									className="b3 w-100"
									placeholder="State"
									value={searchByState}
									pattern="[a-zA-Z]*"
									id="select-charity-search-input"
									onChange={(e) =>
										setSearchByState((prev) =>
											e.target.validity.valid ? e.target.value : prev
										)
									}
								/>
							</div>
							<div className="w-100 ml-3">
								<MultiSelectComponent
									options={impacts}
									shouldApplyFilter={props.shouldApplyFilter}
									placeholder="Select Impact"
									getFillteredOptions={getFillteredOptions}
								/>
							</div>
						</div>
					) : null}
					<div
						className="w-100 h-100"
						style={{
							backgroundColor: "white",
							borderRadius: "5px",
							overflow: "scroll",
						}}
					>
						{FilteredCharitesMemo}
					</div>
				</div>
			</div>

			{isCharityModalOpen && (
				<>
					<ModalComp
						onClose={() => setIsCharityModalOpen(false)}
					>
						<div ref={charityRef}>
							{tempSelectedCharity && (
								<>
									<SelectedCharity
										charity={tempSelectedCharity}
										getSelectedImpact={getSelectedImpact}
										onCharitySelect={getSelectedCharity}
									/>
								</>
							)}
						</div>
					</ModalComp>
				</>
			)}
		</div>
	);
}
