import React from "react";
import logo_white from "../../../assets/icons/logo/Base Large Dark-min (2).png";
import facebook_white from "../../../assets/icons/landing page 2/Facebook.svg";
import linkedin_white from "../../../assets/icons/landing page 2/linkedin.svg";
import twitter_white from "../../../assets/icons/landing page 2/twitter-x.svg";
// import email from "../../../assets/icons/landing page 2/footer_email.svg";
// import contact from "../../../assets/icons/landing page 2/footer_contact.svg";
// import location from "../../../assets/icons/landing page 2/footer_location.svg";
import { Row, Col } from "react-bootstrap";
import Button from "../../../components/sub-component/Button";
import { Link, useHistory } from "react-router-dom";


export default function Footer() {

	const footerLinks = [
		{ name: "Product", list: [{ value: "Features", link: "/features" }, { value: "Pricing", link: "/pricing" }, { value: "Charities", link: "/charities" }] },
		{ name: "Resources", list: [{ value: "Help Center", link: "https://help.altrunic.com/en/", isOpenInNewTab: true }, { value: "Community", link: "https://join.slack.com/t/altrunic/shared_invite/zt-wpe1bd02-4ylI05yuRhiMggxxOjHNfQ", isOpenInNewTab: true }] },
		{ name: "Company", list: [{ value: "About", link: "/about" }] },
	];

	const history = useHistory();

	return (
		<div>
			<div className="try-it-out-box try-it-out-box-sm">
				<span className="ls-h4 white-color try-text try-text-sm" style={{ width: "max-content", lineHeight: "30px" }}>Start your Social Impact journey with Altrunic</span>
				<Link to="/pricing"><Button landingPage="white" className="ls-b0-5" btnContainerClass="try-it-out-box-btn-sm" title="Start For Free" /></Link>
			</div>
			<div className="landing-page-footer">
				<Row className="w-100">
					<Col md="6" className="d-flex flex-column">
						<img src={logo_white} style={{ maxHeight: "55px", maxWidth: "155px" }} />
						<div className="d-flex flex-column" style={{ width: "100%", maxWidth: "488px", marginTop: "50px" }}>
							<span className="ls-b1 white-color">Every man must decide whether he will walk in the light of creative altruism or in the darkness of destructive selfishness.</span>
							<span className="mt-4 ls-b1 white-color">― Martin Luther King, Jr</span>
						</div>
					</Col>
					<Col md="6">
						<Row className="footer-list">
							{/* <Col xs="12" sm="4">
							</Col> */}
							{
								footerLinks.map((item, index) => {
									return (
										<Col sm="4" xs="12" key={index} className="footer-list-items footer-list-items-xs p-0">
											<span className="s3 white-color" style={{ width: "150px" }}>{item.name}</span>
											<div className="footer-text-container">
												{item.list.map((list_item, list_index) => {
													return (
														<>
															{
																list_item?.isOpenInNewTab
																	?
																	<Link className="txt" onClick={() => { window.open(list_item.link, "_blank"); }} key={list_index} style={{ color: "#FFFFFF" }}> <span className="ls-b1 white-color">{list_item.value}</span> </Link>
																	:
																	<Link className="txt" to={list_item.link} key={list_index} style={{ color: "#FFFFFF" }}> <span className="ls-b1 white-color">{list_item.value}</span> </Link>
															}
														</>

													);
												})}
											</div>
										</Col>
									);
								})
							}
						</Row>
					</Col>

				</Row>

				{/* <hr style={{ backgroundColor: "white", height: "2px", width: "100%", opacity: "0.2" }} /> */}

				<Row className="footer-lower-links-container" >
					<Col md="10" className="footer-rights-container">
						<span className="b3 white-color" style={{ paddingRight: "15px" }}>© 2024 Altrunic - All Rights Reserved</span>
						<hr className="white-bg one-bar" style={{ height: "20px", width: "1px", marginRight: "5px", marginLeft: "5px", backgroundColor: "white" }} />
						<div className="d-inline-flex align-items-center footer-bottom-mobile" >
							<a className="b3 white-color" style={{ cursor: "pointer", padding: "0 15px" }}
								onClick={() => { history.push("/privacy_policy"); }}
							>Privacy Policy</a>
							<hr className="white-bg" style={{ height: "20px", width: "1px", marginRight: "5px", marginLeft: "5px", backgroundColor: "white" }} />
							<a className="b3 white-color" style={{ cursor: "pointer", padding: "0 15px" }}
								onClick={() => { history.push("/terms_and_condition"); }}>Terms and Conditions</a>
							{/* <hr className="white-bg" style={{ height: "20px", width: "1px", marginRight: "5px", marginLeft: "5px", backgroundColor: "white" }} />
						<span className="b3 white-color" style={{ cursor: "pointer" }}>Cookies</span> */}
						</div>
					</Col>
					<Col md="2" className="footer-social-media-container ">
						<div className="d-flex justify-content-between" style={{ width: "108px" }}>
							<img src={facebook_white} className="pointer" onClick={() => { window.open("https://www.facebook.com/altrunic", "_blank"); }} />
							<img src={linkedin_white} className="pointer" onClick={() => { window.open("https://www.linkedin.com/company/altrunic", "_blank"); }} />
							<img src={twitter_white} className="pointer" onClick={() => { window.open("https://twitter.com/altrunic", "_blank"); }} />
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}