import React from "react";
import { Fragment } from "react";
import { getOriginFromLink } from "../../../utilities/Utilites";
import CharityCard from "../../common/CharityCard";

export default function SuggestedCharitiesCard(props) {

	console.log("=================>", props, "FLAG");

	return (
		<div>
			<Fragment>
				{
					props.charities.length > 0 &&
					<Fragment>
						<div style={{ padding: "0px" }}>
							{
								props.isLabel &&
								<span className="s3">Suggested Charities</span>
							}
						</div>
						{
							props.charities.map((charity, index) => {
								return (
									<div key={index}>
										<CharityCard
											title={charity.title}
											image={charity.logo_url || `//logo.clearbit.com/${getOriginFromLink(charity?.charity_url)}`}
											description={charity.description}
											details={charity.details}
											impact={charity.charity_impacts[0].impact_statement}
										/>
									</div>
								);
							})
						}

					</Fragment>
				}
			</Fragment>


		</div>
	);
}