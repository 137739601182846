import React from "react";
import CharitySiteSetup from "../../../../components/SetupWizad/CharitySiteSetup";
import { useAuth } from "../../../../context/AuthContext";
// import Input from "../../../../components/sub-component/Input";

export default function CharitySettings() {

	const auth = useAuth();

	const hasHeaderLine = <p className="b3" style={{ marginTop: "20px", fontSize: "121dp", textAlign: "left" }}>
		You’re currently using
		<font color="#0054DC"> {auth.userProfile && auth.userProfile.email.split("@")[1]}</font>  domain and those who will sign up using this domain will be added to your employee list.
	</p>;

	return (
		<div className="d-flex justify-content-start" style={{ marginTop: "40px" }}>
			<div className="form-handling" style={{ width: "100%" }}>
				<CharitySiteSetup
					shouldUpdate={true}
					hasHeaderLine={hasHeaderLine}
				/>
			</div>
		</div>
	);
}