import React, { useEffect } from "react";

import { useAuth } from "../context/AuthContext";
import CryptoJS from "crypto-js";
import hexDigest from "crypto-js/enc-hex";


export default function IntercomProvider({ children }) {

	const auth = useAuth();

	useEffect(async () => {


		var APP_ID = `${window._env_.REACT_APP_INTERCOM_APP_ID}`;

		if (auth.access_token) {
			var secret = `${window._env_.REACT_APP_INTERCOM_KEY}`;

			const { userProfile, tenantProfile } = auth;
			// console.log(userProfile, tenantProfile, "Result");
			const USER_HASH = hexDigest.stringify(CryptoJS.HmacSHA256(`${auth.user_id}`, secret.replaceAll(" ", "")));
			const USER_NAME = `${userProfile?.first_name} ${userProfile?.last_name}`;


			window.intercomSettings = {
				app_id: APP_ID,
				user_id: auth.user_id,
				name: USER_NAME, // Full name
				email: userProfile?.email,
				company: {
					company_id: tenantProfile?.tenant_id,
					name: tenantProfile?.tenant_name,
					website: tenantProfile?.web_url
				},
				user_hash: USER_HASH
			};

			// (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic("reattach_activator"); ic("update", w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement("script"); s.type = "text/javascript"; s.async = true; s.src = "https://widget.intercom.io/widget/m62t03lr"; var x = d.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent("onload", l); } else { w.addEventListener("load", l, false); } } })();

			(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic("reattach_activator"); ic("update", w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement("script"); s.type = "text/javascript"; s.async = true; s.src = "https://widget.intercom.io/widget/" + APP_ID; var x = d.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === "complete") { l(); } else if (w.attachEvent) { w.attachEvent("onload", l); } else { w.addEventListener("load", l, false); } } })();


		}
		else {
			window.intercomSettings = {
				app_id: APP_ID,
			};

			(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic("reattach_activator"); ic("update", w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement("script"); s.type = "text/javascript"; s.async = true; s.src = "https://widget.intercom.io/widget/" + APP_ID; var x = d.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === "complete") { l(); } else if (w.attachEvent) { w.attachEvent("onload", l); } else { w.addEventListener("load", l, false); } } })();

		}



	}, [auth.access_token]);




	return (
		<>
			{children}
		</>
	);
}
