import React from "react";
import CampaignStatusCard from "../../sub-component/dashboard/CampaignStatusCard";
import { Row, Col } from "reactstrap";
import UserIcon from "../../../assets/icons/basic/user.svg";
import InboxIcon from "../../../assets/icons/basic/inbox.svg";
import BarChartIcon from "../../../assets/icons/basic/bar-chart.svg";
import SettleMentIcon from "../../../assets/icons/basic/analysis.svg";

export default function CampaignCard(props) {
	const campaignStatuses = [
		{
			path: UserIcon,
			number: props.participants || "0",// ,
			text: "Participants",
			color: "#CE8DFF"
		},
		{
			path: InboxIcon,
			number: `$${props.currentObjective}` || "$0",//"$12.5K",
			text: "Donations",
			color: "#7ED0FF"
		},
		{
			path: BarChartIcon,
			number: ((props.currentObjective / props.totalObjective) * 100).toFixed(1) + "%" || "0%",// "54%",
			text: "Growth",
			color: "#106290"
		},
		{
			path: SettleMentIcon,
			number: `$${props.settlementAmount || 0}`,
			text: "Expected Settlement",
			color: "#106290"
		},
	];

	return (
		<div className="dashboard-card-inverse" style={{ marginTop: "25px" }}>
			<span className="white-color text" style={{ color: "white" }}>{props.name}</span>
			<div className="d-flex justify-content-center">
				<div className="d-flex justify-content-center dashboard-card-inner-wrapper">
					<div className="w-100" style={{ marginTop: "11px", marginBottom: "10px" }}>
						<Row className="w-100 m-0 justify-content-between align-items-center">
							{
								campaignStatuses.map((campaignStatus, index) => {
									return (
										<Col key={index} md="3" xs="6"
											className={"p-0 d-flex justify-content-center " + `${index > 1 ? "on-mobile-status-card" : ""}`}>
											<CampaignStatusCard
												path={campaignStatus.path}
												number={campaignStatus.number}
												text={campaignStatus.text}
												style={{ backgroundColor: `${campaignStatus.color}`, color: "white" }}
											/>
										</Col>
									);
								})
							}

						</Row>
					</div>
				</div>
			</div>
		</div>
	);
}