/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { VisualBuilderContext } from "../../../context/VisualBuilderContext";

import Input from "../../../components/sub-component/Input";
import ImagePicker from "../../../components/sub-component/ImagePicker";
import Switch from "../../../components/sub-component/SwitchButton";
// import Button from "../../../components/sub-component/Button";
import { Fragment } from "react";
import MultiInput from "../../../components/visual-builder/MultiInput";

// import { Row, Col } from "react-bootstrap";

export default function EditorRightComp() {

	var campaign_id = 0;

	const { editorDataValues, setEditorDataValues } = useContext(VisualBuilderContext);

	console.log("prop from usecontext:",editorDataValues);

	// const [IsSavingComponent, setIsSavingComponent] = useState(false);

	// const [selectedPage, setSelectedPage] = useState(editorDataValues.selectedPage);
	// const [selectedComponent, setSelectedComponent] = useState(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex]);
	// const [selectedComponentProps, setSelectedComponentProps] = useState(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props);
	// const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);

	const setPropsOfSelectedComponents = (value, propId) => {


		const tempUpdatedComponentProp = { ...editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props, [`${propId}`]: value };
		console.log("update comp prop",tempUpdatedComponentProp);
		const tempUpdatedComponent = { ...editorDataValues.selectedPage[editorDataValues.selectedComponentIndex], props: tempUpdatedComponentProp };
		const tempSelectedPage = editorDataValues.selectedPage;
		tempSelectedPage[editorDataValues.selectedComponentIndex] = tempUpdatedComponent;
		setEditorDataValues({ ...editorDataValues, selectedPage: tempSelectedPage, dataHasChanged: true });
		// if (!isSaveButtonActive) {
		// 	setIsSaveButtonActive(true);
		// }
	};

	const setPropsOfSelectedComponentsOnging = (value, propId, componentIndex) => {

		const tempUpdatedComponentProp = { ...editorDataValues.data?.campaigns[componentIndex].campaign_components[0]?.props, [`${propId}`]: value };
		console.log("1 prince",editorDataValues);

		const tempUpdatedCampaignComponent = { ...editorDataValues.data?.campaigns[componentIndex].campaign_components[0], props: tempUpdatedComponentProp };

		console.log("2 prince",editorDataValues);

		var tempCampaignComponent = editorDataValues.data?.campaigns[componentIndex].campaign_components;

		console.log("3 prince",editorDataValues);

		tempCampaignComponent[0] = tempUpdatedCampaignComponent;

		console.log("4 prince",editorDataValues);

		const tempAfterCampaaign = {...editorDataValues.data?.campaigns[componentIndex], campaign_components:tempCampaignComponent};

		console.log("5 prince",editorDataValues);

		const tempCampaign = editorDataValues.data?.campaigns;

		console.log("6 prince",editorDataValues);

		tempCampaign[componentIndex] = tempAfterCampaaign;

		console.log("7 prince",editorDataValues);

		const tempdata = {...editorDataValues.data, campaigns: tempCampaign};

		console.log("8 prince",editorDataValues);

		console.log("update temp component",tempdata);

		setEditorDataValues({ ...editorDataValues, data: tempdata, dataHasChanged: true });
		// if (!isSaveButtonActive) {
		// 	setIsSaveButtonActive(true);
		// }
	};

	const getFileOngoing = (data, componentIndex) => {
		setPropsOfSelectedComponentsOnging(data.file, data.imageFor,componentIndex);
	};

	const getFile = (data) => {
		setPropsOfSelectedComponents(data.file, data.imageFor);
	};

	const getFiles = (data) => {
		console.log(data, "PARENT COMP DATA");
		const tempArr = [];
		data.files.map((file,) => {
			tempArr.push({ image: file });
		});
		console.log(tempArr, "image Array");
		setPropsOfSelectedComponents(tempArr, data.imageFor);
	};

	const getCheckValue = (data) => {
		setPropsOfSelectedComponents(data.value, data.forState);
	};

	const getMultiInputData = (data) => {
		console.log(data);
		setPropsOfSelectedComponents(data.data, data.dataFor);
	};

	const getValidInputType = (prop,componentIndex) => {
		console.log("component index",componentIndex);
		campaign_id = componentIndex;
		console.log(editorDataValues,"prop for image picker prince:");
		console.log("prop of get :", prop);
		// console.log(componentIndex, "...index");
		// const componentIndex = editorDataValues.selectedComponentIndex;
		// console.log(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`]);
		if (prop.type === "input") {
			// console.log(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`], "selecred");
			return (
				<div style={{ marginTop: "5px" }} className="input-type-input">
					<Input
						className="b4"
						textArea
						label=""
						type={prop.inputType}
						value={editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`] || ""}
						onChange={(e) => setPropsOfSelectedComponents(e.target.value, prop.id)}
						// maxLength={`${prop?.max_characters}`}
						inputStyle={prop.inputType === "number" ? { maxWidth: "max-content", width: "75px" } : null}
						postLable={prop.postLable}
					/>
				</div>
			);
		}
		else if (prop.type === "textareaInput") {
			// console.log(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`], "selecred");
			return (<div style={{ marginTop: "5px" }} className="input-type-text-area">
				<Input
					textArea
					label=""
					value={editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`] || ""}
					onChange={(e) => setPropsOfSelectedComponents(e.target.value, prop.id)}
					maxLength={`${prop.max_characters}`}
				/>
			</div>
			);
		}
		else if (prop.type === "image") {
			console.log(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`], "selecred");
			return (
				<div style={{ marginTop: "5px" }} className="input-type-image">
					{/* <span className="b3">{"Upload Image"}</span> */}
					<ImagePicker
						height={76}
						width={76}
						image={editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`]}
						getFile={getFile}
						imageFor={prop.id}
					/>
				</div>
			);
		}
		else if (prop.type === "ongoing-campaign-image") {
			return (
				<div style={{ marginTop: "5px" }} className="input-type-image">
					{/* <span className="b3">{"Upload Image"}</span> */}
					<ImagePicker
						height={76}
						width={76}
						image={editorDataValues.data?.campaigns[componentIndex].campaign_components[0]?.props.heroHeaderImage}
						// image={editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`]}
						getFile={(data) => getFileOngoing(data, componentIndex)}
						imageFor={editorDataValues.data?.campaigns[componentIndex].campaign_components[0]?.props_types[0].id}
					/>
				</div>
			);
		}
		else if (prop.type === "multi-image") {
			// console.log(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`], "selecred");
			return (
				<div style={{ marginTop: "5px" }} className="input-type-multi-image">
					{/* <span className="b3">{"Upload Image"}</span> */}
					<ImagePicker
						multipleImages={true}
						height={76}
						width={76}
						image={null}
						images={editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`]}
						getFiles={getFiles}
						imageFor={prop.id}
					/>
				</div>
			);
		}
		else if (prop.type === "switch") {
			// console.log(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`], "selecred");
			return (
				<div style={{ marginTop: "5px" }} className="input-type-switch">
					<span className="b3" >{"Edit Check"}</span>
					<Switch
						value={editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`]}
						onChange={getCheckValue}
						forState={prop.id}
					/>
				</div>
			);
		}
		else if (prop.type === "multi-input") {
			// console.log(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`], "selecred");
			console.log(editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`], "MultiInput");
			return (
				<div style={{ marginTop: "5px" }} className="input-type-multi-input">
					<MultiInput
						labels={prop.labels}
						value={editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].props[`${prop.id}`] || ""}
						inputTypes={prop.input_types}
						getMultiInputData={getMultiInputData}
						length={prop.maxLenght}
						dataFor={prop.id}
					/>
				</div>
			);
		}
	};

	// const saveComponent = () => {
	// 	setIsSavingComponent(true);
	// 	var tempData = editorDataValues.data;
	// 	if (editorDataValues.selectedPageIndex === 0) {
	// 		tempData = { ...tempData, home: editorDataValues.selectedPage };
	// 	}
	// 	setEditorDataValues({ ...editorDataValues, data: tempData });
	// 	setTimeout(() => {
	// 		setIsSavingComponent(false);
	// 	}, 1500);
	// };

	useEffect(() => {
		console.log("changed");
		console.log("after index change:", editorDataValues);
	}, [editorDataValues.selectedComponentIndex]);

	console.log("Editor data values in right:", editorDataValues);

	return (
		<div className="right-section-container" id="right-section-container">
			<span className="s3" style={{ marginTop: "30px" }}>{`${editorDataValues.selectedPage[editorDataValues.selectedComponentIndex].component_title}`}</span>
			<span className="s3" style={{ marginTop: "10px" }}>Settings</span>
			<div className="options-container">
				{(editorDataValues.selectedComponentIndex !== undefined && editorDataValues.selectedComponentIndex !== null) &&
					editorDataValues.selectedPage[editorDataValues.selectedComponentIndex]?.props_types?.map((props, componentIndex) => (
						<Fragment key={componentIndex}>
							<div className="d-flex flex-column" id="input-container" style={componentIndex !== 0 ? { borderTop: "2px solid #CBE3FF", padding: "15px 0px" } : { padding: "15px 0px" }}>
								<span className="b3 nero-color" style={{ fontWeight: "normal" }}>
									{props.label || "no title"}
								</span>
								{getValidInputType(props, componentIndex)}
							</div>
						</Fragment>
					))
				}
				{(editorDataValues.selectedComponentIndex !== undefined && editorDataValues.selectedComponentIndex !== null && editorDataValues.selectedComponentIndex == 3)&&(editorDataValues.data !== undefined && editorDataValues.data !== null && editorDataValues.data?.campaigns !== null && editorDataValues.data?.campaigns.length > 0) &&
					editorDataValues.data?.campaigns.map((campaign, componentIndex) => (campaign.campaign_components[0]?.props_types?.map((props) => (
						<Fragment key={componentIndex}>
							<div className="d-flex flex-column" id="input-container" style={componentIndex !== 0 ? { borderTop: "2px solid #CBE3FF", padding: "15px 0px" } : { padding: "15px 0px" }}>
								<span className="b3 nero-color" style={{ fontWeight: "normal" }}>
									{campaign.campaign_name || "no title"}
								</span>
								{getValidInputType(props, componentIndex)}
							</div>
						</Fragment>
					))))
				}
			</div>
		</div>
	);

}
