import React from "react";
import { ReactComponent as DangerIcon } from "../../assets/icons/basic/warning.svg";
import Button from "../sub-component/Button";

/**
 * 
 * @param {object} props Props object for component
 * @param {string} props.color class name for alert
 * @param {string} props.title Title of the alert
 * @param {string} props.description Description of the alert
 * @returns 
 */
export default function AlertBox(props) {

	const confirm = () => {
		props.action(true);
	};

	const cancel = () => {
		props.action(false);
	};

	if (props.type == "confirmation") {
		return (
			<div
				className="d-flex"
				style={{
					padding: "10px 15px", background: "white", width: "288px",
					/* Small Card Shadow */
					boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
					borderRadius: "12px"
				}}>
				<div>
					<DangerIcon />
				</div>
				<div style={{ marginLeft: "10px" }}>
					{
						props.statements && props.statements.map((statement, index) => {
							return (
								<p key={index} className="b4 mb-0" style={{ marginTop: `${index != 0 ? "5px" : "0px"}` }}>{statement}</p>
							);
						})
					}
					<div className="d-flex justify-content-end" style={{ marginTop: "10px" }}>
						<Button isHollow fontStyle="b4" title="Cancel"
							style={{ padding: "2px 8px", background: "white", border: "1px solid #0054DC" }} textStyle={{ color: "#0054DC" }} onClick={cancel} />
						<Button className="b4" ButtonSize="md" title="Confirm" style={{ marginLeft: "8px", width: "80px", height: "30px", ...props.confirmButtonStyle }} id={props.id} onClick={confirm} busy={props.processing} />
					</div>
				</div>
			</div>
		);
	}
	else if (props.type === "global-notification") {
		return (
			<div className={`w-100 d-flex align-items center text-left alert m-0 ${props.color || "success"}`} style={{ minHeight: "85px", borderRadius: "15px" }}>
				<div className="d-flex align-items-center" style={{ paddingRight: "20px", borderRight: `2px solid ${props.color === "danger" ? "#FC4848" : props.color || "success"}` }}>
					<DangerIcon />
					<span className="roboto-s1 ml-1" style={{ color: `${props.color === "danger" ? "#FC4848" : props.color || "success"}`, lineHeight: "unset" }}>{props.title}</span>
				</div>
				<div className="d-flex align-items-center" style={{ paddingLeft: "20px" }}>
					{props.description}
				</div>
			</div>
		);
	}

	else {
		return (
			<div className={`d-flex flex-column text-left alert ${props.color || "success"}`}>
				<span className="roboto-s2 mb-1">{props.title}</span>
				<span className="roboto-b1">{props.description}</span>
			</div>
		);
	}

}