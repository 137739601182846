/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
// import Table from "../../../../components/sub-component/Table/Tabel";
import PostTextImage from "../../../../components/sub-component/PostTextImage";
import { useAuth } from "./../../../../context/AuthContext";
import { openURL } from "../../../../utilities/Utilites";
import AlertBox from "../../../../components/common/AlertBox";

export default function EmployeesSettings() {

	const auth = useAuth();
	const [users, setUsers] = useState([]);
	const [userProfiles, setUserProfiles] = useState([]);
	const [processingRowId, setProcessingRowId] = useState(null);
	const [alert, setAlert] = useState(null);
	const timer=useRef();
	
	async function getUsers() {

		const response_user = await openURL("/v1/user/?employees=true", "GET", auth);

		if (response_user.error) {
			return;
		}

		

		const userIds = response_user.result.data.map((user) => user.id);

		const response_user_profile = await openURL(`/v1/user_profile/?ids=${userIds}`, "GET", auth);

		if (response_user_profile.error) {
			return;
		}
		
		setUserProfiles(response_user_profile.result.data);

		setUsers(response_user.result.data);


	}
	function handleAlert(color, title, message) {

		clearTimeout(timer.current);

		setAlert(
			<AlertBox color={color} title={title} description={message} />
		);

		timer.current = setTimeout(() => {
			setAlert(null);
		}, 3000);

	}
	async function deleteUser(id) {

		setProcessingRowId(id);

		const { error } = await openURL(`/v1/user/${id}`, "DELETE", auth);

		setProcessingRowId(null);

		if (error) {

			handleAlert("danger", "Error", error.message);
			return;

		}

		handleAlert("success", "Success", "User deleted successfully");

		getUsers();

	}

	useEffect(() => {
		getUsers();
	}, []);

	return (

		<div className="d-flex flex-column pl-3 pr-3">


			<div className="mt-1 pr-3" style={{ width: "100%", minWidth: "550px" }}>

				<div style={{ marginTop: "15px" }}>

					<div className="d-flex flex-row justify-content-around table-header pt-3 pb-3">
						<div className="b4" style={{ width: "33%", textAlign: "center" }}>Name</div>
						<div className="b4" style={{ width: "33%", textAlign: "center" }}>Email</div>
						<div className="b4" style={{ width: "33%", textAlign: "center" }}>Action</div>
					</div>

					<div className="table-body">


						{
							users.map((user, index) => {

								return (
									<div className="d-flex flex-row justify-content-around table-row" key={index}>
										<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0" }}>
											{`${userProfiles[index]?.first_name} ${userProfiles[index]?.last_name}`}
										</div>
										<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0" }}>
											{user.email}
										</div>

										<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0" }}>
											{
												processingRowId === user.id ? (
													<span className="busy-indicator-blue"></span>
												) : (
													<span>
														<div className="delete-icon" onClick={() => { deleteUser(user.id); }} />
													</span>
												)

											}
										</div>
									</div>
								);
							})
						}


					</div>
				</div>


				{alert}

			</div>


		</div >

	);
}
