import React, { useEffect, useState } from "react";

import Logo from "../../assets/icons/logo.png";
import { useAuth } from "../../context/AuthContext";
import { openURL } from "../../utilities/Utilites";
import ImageButton from "../sub-component/ImageButton";
import Button from "../sub-component/Button";
// eslint-disable-next-line no-unused-vars
import { removeAllCookies, removeCookie } from "../../utilities/Utilites";
import { useHistory } from "react-router-dom";


export default function Topbar() {

	const [user, setUser] = useState();
	// eslint-disable-next-line no-unused-vars
	const history = useHistory();
	const auth = useAuth();

	useEffect(async () => {

		const { result } = await openURL(`/v1/user_profile/${auth.user_id}`, "GET", auth);

		if (result) {

			// console.log(result.data, "RESULT");
			setUser(result.data);

		}

	}, []);

	return (
		<div className="topbar">

			<img src={Logo} alt="logo"
				style={{ width: "97px", height: "34px" }}
			/>
			<div className="d-flex">
				<Button title="Logout" hasLogoutIcon isHollow onClick={async () => {
					// removeCookie("token");
					removeAllCookies();
					localStorage.removeItem("setupWizardStepNo");
					window.location.reload();
					// history.push("/");
				}} fontStyle={{ fontSize: "12px", lineHeight: "13px", letterSpacing: "-0.015em" }} style={{ padding: "1px 15px" }} />
				<ImageButton className="ml-3" title={`${user && (user.first_name + " " + user.last_name)}`} roundedButton forDefaultUser imageRight />

			</div>
		</div>
	);
}
