import baseURL from "./BaseURL";

/**
 * 
 * @param {string} email Email to be validated 
 * @returns {boolean} true if email is valid else false
 */
export const isEmailValid = function (email) {

	const validationRegex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
	return validationRegex.test(email);

};

/**
 * 
 * @param {string} password Password to be validated
 */
export const isPasswordValid = function (password) {

	const validationRegex = /(^(?=.*[~|`|!|@|#|$|%|^|&|*|(|)|_|=|+|-])[0-9a-zA-Z~`!@#$%^&*()_=+-]{10,})/g;
	return validationRegex.test(password);

};

/**
 * @param {string} text Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isTextValid = function (text) {
	const validationRegex = new RegExp(/[a-zA-Z]{3,}/);
	return text ? validationRegex.test(text) : false;
};

/**
 * @param {Number} number Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isNumberValid = function (number) {
	const validationRegex = new RegExp(/[0-9]/);
	return validationRegex.test(number);
};

/**
 * @param {string} webUrl Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isUrlValid = function (webUrl) {
	const validationRegex = new RegExp(/^((https):\/\/)(www.)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/);
	return validationRegex.test(webUrl);
};


/**
 * @param {string} zipcode Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isZipcodeValid = function (zipcode) {
	const validationRegex = new RegExp(/[0-9]{5,5}/);
	return validationRegex.test(zipcode);
};

/**
 * 
 * @param {string} cname Cookie name
 * @param {string} cvalue Cookie value
 * @param {number} exdays Cookie expiration days
 */
export const setCookie = function (cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

/**
 * 
 * @param {string} cname Cookie name
 * @returns 
 */
export const getCookie = function (cname) {

	var ca = document.cookie.split(";");

	const token = ca.find(c => {
		return c.replace(" ", "").split("=")[0] === cname;
	});

	if (!token) {
		return "";
	}

	return token.split("=")[1];

};

/**
 * 
 * @param {string} cname Cookie name
 */
export const removeCookie = function (cname) {
	document.cookie = cname + "=; Max-Age=-99999999;";
};

export const getOriginFromLink = function (link) {

	try {
		return new URL(link).origin;
	} catch (err) {
		return null;
	}

};

/**
 * 
 * @summary This function will perform fetch api request and return result or error
 * @param {string} path request url path
 * @param {string} method Request method
 * @param {object} auth AuthContext for the app
 * @param {object} bodyData Request body data
 * @returns 
 */
export const openURL = async function (path, method, authContext, bodyData) {

	if (!authContext || !method) {
		return { error: { message: "Unauthorized request" } };
	}

	const requesOptions = {
		method: method,
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
			"Authorization": `Bearer ${authContext.access_token}`,
			// "Authorization": `Bearer ${localStorage.getItem("token")}`,
		},
	};

	if ((method !== "GET" || method !== "DELETE") && bodyData) {
		requesOptions["body"] = JSON.stringify(bodyData);
	}

	const { expiration_timestamp } = authContext;

	const timestamp = Math.floor(Date.now() / 1000);

	if (timestamp > expiration_timestamp) {

		await authContext.authRefresh();

	}

	try {

		const response = await fetch(`${baseURL}${path}`, requesOptions);

		const json = await response.json();

		if (!response.ok) {
			return { error: json };
		}

		return { result: json };
	}
	catch (err) {
		return { error: "Something went wrong" };
	}

};


export const getUnauthorizedURL = async function (path, headers) {

	if (!headers) {
		return { error: { message: "Unauthorized request" } };
	}

	const requesOptions = {
		method: "GET",
		headers: {
			"Accept": "application/json",
			"user": JSON.stringify(headers)
		},
	};

	try {
		const response = await fetch(`${baseURL}${path}`, requesOptions);
		const json = await response.json();
		if (!response.ok) {
			return { error: json };
		}

		return { result: json };
	}
	catch (err) {
		return { error: "Something went wrong" };
	}

};