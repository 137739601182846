import React, { useState } from "react";
// import { Link } from "react-router-dom";
import AuthMessageCard from "../../components/auth/AuthMessageCard";
import AlertBox from "../../components/common/AlertBox";
import Button from "../../components/sub-component/Button";
import Input from "../../components/sub-component/Input";
// import { useAuth } from "../../context/AuthContext";
// import baseURL from "../../utilities/BaseURL";
import altrunc_logo from "../../assets/icons/logo.png";
import { openURL } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";


export default function MiniSignup() {

	const auth = useAuth();

	const [isBusy, setIsBusy] = useState(false);

	const [user, setUser] = useState({
		first_name: null,
		last_name: null,
	});

	const [errors, setErrors] = useState({
		first_name: false,
		last_name: false,
	});

	const [alert, setAlert] = useState();

	function handleInput(event) {

		setUser({ ...user, [event.target.name]: event.target.value });

	}

	function onBlur(event) {

		if (!user[event.target.name]) {
			setErrors({ ...errors, [event.target.name]: true });
			return;
		}
		setErrors({ ...errors, [event.target.name]: false });

	}

	function validateForm() {

		if (errors.first_name || !user.first_name) {
			return { isValid: false, message: "First Name is required" };
		}

		if (errors.last_name || !user.last_name) {
			return { isValid: false, message: "Last name is required" };
		}

		return { isValid: true, message: "" };

	}

	async function handleSubmit(event) {

		event.preventDefault();

		const validation = validateForm();

		if (!validation.isValid) {
			setAlert(
				<AlertBox color="danger" title="Error" description={validation.message} />
			);
			return;
		}

		setIsBusy(true);

		const { result, error } = await openURL(`/v1/user_profile/${auth.user_id}`, "PATCH", auth, { first_name: user.first_name, last_name: user.last_name });

		if (error) {
			setAlert(
				<AlertBox color="danger" title="Error" description={error.message} />
			);
		} else if (result) {
			setAlert(
				<AlertBox color="success" title="Success" description="Profile added" />
			);
			window.location.reload();
		}


	}

	console.log(auth.userProfile.email);

	return (

		<div className="d-flex flex-lg-row flex-column justify-content-between">

			<div className="d-sm-flex message-card-mobile" style={{ width: "100%", height: "auto", position: "relative", }}>
				<AuthMessageCard paragraph={
					["Giving is not just about making a ",
						<span style={{ color: "#0054DC" }} key="0">donation</span>,
						", its about making an ",
						<span style={{ color: "#0054DC" }} key="1">impact</span>,
						"."]}
				/>
			</div>

			<div className="d-flex flex-column justify-content-center auth-form signup-form">

				{/* {alert} */}

				<div className="d-flex flex-column align-self-center" style={{ height: "auto", width: "100%", paddingBottom: "40px" }}>

					<div className="message-card-logo-mobile">

						<img src={altrunc_logo} />

					</div>

					<span className="s2" style={{ textAlign: "left" }}>Add Profile</span>

					<form onSubmit={handleSubmit} action="#">
						<div style={{ width: "100%", marginTop: "30px" }}>
							<Input
								disabled={true}
								placeholder="Email"
								label=""
								name="email"
								underLine
								value={auth.userProfile.email}
								// onChange={handleInput}
								className={errors.email ? "input-underline-error" : "input-underline"}
								// onBlur={onBlur}
							/>
						</div>
						<div style={{ width: "100%", marginTop: "30px" }}>
							<Input
								placeholder="First Name"
								label=""
								name="first_name"
								onChange={handleInput}
								onBlur={onBlur}
								className={errors.first_name ? "input-underline-error" : "input-underline"}
								underLine
							/>
						</div>

						<div style={{ width: "100%", marginTop: "30px" }}>
							<Input
								placeholder="Last Name"
								label=""
								name="last_name"
								onChange={handleInput}
								onBlur={onBlur}
								className={errors.last_name ? "input-underline-error" : "input-underline"}
								underLine
							/>
						</div>


						<div style={{ width: "100%", marginTop: "41px" }}>
							<Button title="Add profile" style={{ width: "100%", height: "40px" }} onClick={handleSubmit} busy={isBusy} />
						</div>
					</form>

				</div>
				{/* <span className="b3" style={{ margin: "15px 0", textAlign: "center" }}>Already a user?</span>
				<div className="d-flex justify-content-center" >
					<Link to="/login" >
						<Button title="Login " whiteButton />
					</Link>
				</div>*/}

				<div style={{ position: "relative" }}>
					<div style={{ position: "absolute", width: "100%" }}>
						{alert}
					</div>
				</div>

			</div>

		</div>
	);

}