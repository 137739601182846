import React, { useState, useEffect,useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

// import OnboardingStatus from "../../../components/dashboard/left-side-menu/OnboardingStatus";
// import CompanyBadge from "../../../components/dashboard/left-side-menu/CompanyBadge";
import logo from "../../../assets/icons/logo.png";
import DashboardMenuIcon from "../../../components/dashboard/left-side-menu/DashboardMenuIcon";

import { ReactComponent as DashboardIcon } from "../../../assets/icons/grid/elements.svg";
import { ReactComponent as GoalsIcon } from "../../../assets/icons/basic/flag.svg";
import { ReactComponent as CampaignsIcon } from "../../../assets/icons/basic/layers.svg";
import { ReactComponent as AuditIcon } from "../../../assets/icons/basic/calculator.svg";
import { ReactComponent as VisualBuilderIcon } from "../../../assets/icons/basic/options.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/basic/settings.svg";
import user_logo from "../../../assets/icons/basic/user_logo.svg";
import { ReactComponent as ArrowUp }  from "../../../assets/icons/arrows/chevron-top.svg";
import { useAuth } from "../../../context/AuthContext";
import { openURL } from "../../../utilities/Utilites";
// import Button from "../../../components/sub-component/Button";
// eslint-disable-next-line no-unused-vars
import { removeCookie, removeAllCookies } from "../../../utilities/Utilites";


const dashboardPages = [
	{ label: "Dashboard", route: "" },
	{ label: "Impact Objectives", route: "goals/list" },
	{ label: "Campaigns", route: "campaigns" },
	{ label: "Audit", route: "audit" },
	{ label: "Site Builder", route: "sitebuilder" },
	{ label: "Settings", route: "settings" },
	{ label: "Company", route: "company" },
	{ label: "Users", route: "users" },
	{ label: "Impact Site Settings", route: "charity" },
	// { label: "Employees", route: "employees" },
	{ label: "Billing", route: "billing" },
];


export default function DashbordMenu(props) {

	const auth = useAuth();

	const history = useHistory();
	const match = useRouteMatch();
	const [selectedPage, setSelectedPage] = useState({
		pageIndex: 0,
		pageSubmenuIndex: null
	});

	const [menu, setMenu] = useState([
		{ icon: <DashboardIcon />, label: "Dashboard" },
		{ icon: <GoalsIcon />, label: "Impact Objectives" },
		{
			icon: <CampaignsIcon />, label: "Campaigns"
		},

		auth.userProfile.role_assignment.id === 12 &&
		{ icon: <AuditIcon />, label: "Audit" },

		(auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14) &&
		{ icon: <VisualBuilderIcon />, label: "Site Builder" },

		auth.userProfile.role_assignment.id === 12 &&
		{
			icon: <SettingsIcon />, label: "Settings",
			submenu: [{ label: "Company" }, { label: "Users" }, { label: "Impact Site" }, { label: "Billing" }],
			isMenuOpen: false
		}
	]);

	const setOption = (index) => {
		if (index === 5) {
			setSelectedPage({ pageIndex: index, pageSubmenuIndex: null });
			setSubOption(5, 0);
		}
		else if (index === 4) {
			const win = window.open(`/${dashboardPages[index].route}`, "_blank");
			if (win != null) {
				win.focus();
			}
		}
		else {
			history.push(`${match.path}/${dashboardPages[index].route}`);
			setSelectedPage({ pageIndex: index, pageSubmenuIndex: null });
			//Handeling useState of array of objects for sub menu
			props.getSelectedItem(index, dashboardPages[index].label, index);

		}
		document.getElementById("mobile-menu").classList.remove("menu-slide-right");
		props.setIsMenuOpen(!props.isMenuOpen);
		openSubmenu(index);
	};

	const setSubOption = (pageIndex, subPageIndex) => {
		console.log(pageIndex, subPageIndex, "yosuh");
		history.push(`${match.path}/${dashboardPages[pageIndex].route}/${dashboardPages[(subPageIndex) + menu.length].route}`);
		setSelectedPage({ ...selectedPage, pageSubmenuIndex: subPageIndex });
	};

	const openSubmenu = (menuItemIndex, mustOpen) => {
		if (menu[menuItemIndex].submenu) {
			setMenu(
				menu.map((item, itemIndex) =>
					itemIndex === menuItemIndex
						?
						{ ...item, isMenuOpen: (mustOpen ? true : !menu[menuItemIndex].isMenuOpen) }
						:
						item
				)
			);
		}
	};

	const setSubMenuByURL = (menuItemIndex, subMenuItemIndex) => {
		openSubmenu(menuItemIndex, true);
		setSelectedPage({ pageIndex: menuItemIndex, pageSubmenuIndex: subMenuItemIndex });

	};
	useEffect(() => {
		const url = history.location.pathname;
		// console.log(selectedPage, "in user");
		if (url.includes("/dashboard/goals/list")) {
			setSelectedPage({ pageIndex: 1 });
			props.getSelectedItem(0, "Impact Objectives", 1);

		}
		else if (url.includes("/dashboard/campaigns")) {
			if (url.includes("/dashboard/campaigns/create")) {
				props.getSelectedItem(selectedPage.pageSubmenuIndex, "Create Campaign", 9);
			}
			else {
				props.getSelectedItem(2, "Campaigns", 2);
			}
			setSelectedPage({ ...selectedPage, pageIndex: 2 });
		}
		else if (url.includes("/dashboard/audit")) {
			setSelectedPage({ pageIndex: 3 });
			props.getSelectedItem(3, "Audit", 3);
		}
		else if (url.includes("/dashboard/site_builder")) {
			setSelectedPage({ pageIndex: 4 });
			props.getSelectedItem(4, "Site Builder", 4);
		}
		else if (url.includes("/dashboard/settings")) {
			if (url.includes("/dashboard/settings/company")) {
				props.getSelectedItem(selectedPage.pageSubmenuIndex, dashboardPages[6].label, 9);
				setSubMenuByURL(5, 0);
			}
			else if (url.includes("/dashboard/settings/user")) {
				props.getSelectedItem(selectedPage.pageSubmenuIndex, dashboardPages[7].label, 9);
				setSubMenuByURL(5, 1);
			}
			// else if (url.includes("/dashboard/settings/employee")) {
			// 	props.getSelectedItem(selectedPage.pageSubmenuIndex, dashboardPages[8].label, 2);
			// 	setSubMenuByURL(5, 2);
			// }
			else if (url.includes("/dashboard/settings/charity")) {
				props.getSelectedItem(selectedPage.pageSubmenuIndex, dashboardPages[8].label, 9);
				setSubMenuByURL(5, 2);
			}
			else if (url.includes("/dashboard/settings/billing")) {
				props.getSelectedItem(selectedPage.pageSubmenuIndex, dashboardPages[9].label, 9);
				setSubMenuByURL(5, 3);
			}
			else {
				setSelectedPage({ ...selectedPage, pageIndex: 5 });
				props.getSelectedItem(5, "Settings", 2);
			}

		}
		else if (url.includes("/dashboard/ongoing_campaign")) {
			props.getSelectedItem(selectedPage, "Campaign Details", 6);
		}
		else if (url.includes("/dashboard/completed_campaign")) {
			props.getSelectedItem(selectedPage, "Completed Campaign Details", 7);
		}
		else if (url.includes("/dashboard/goals")) {
			props.getSelectedItem(selectedPage, "Goal Details", 8);
		}
		else {
			setSelectedPage({ pageIndex: 0 });
			props.getSelectedItem(0, "Dashboard", 0);
		}

	}, [history.location.pathname]);

	const [userButton, setUserButon] = useState(false);

	function handleUserButton() {
		
		setUserButon(!userButton);
		console.log("user button",userButton);
	}

	const [user, setUser] = useState();


	useEffect(async () => {

		const { result } = await openURL(`/v1/user_profile/${auth.user_id}`, "GET", auth);

		if (result) {
			setUser(result.data);

		}

	}, []);
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);
	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setUserButon(false);
					// handleUserButton();
				}
			}
	
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	return (
		<div className="d-flex flex-column justify-content-between h-100" >
			<div className="dashboard-side-menu d-flex flex-column align-items-start" id="dashboard-side-menu" >
				<img
					className="on-laptop"
					src={logo}
					alt="logo"
					style={{ width: "145px", height: "50px", marginBottom: "20px" }}
				/>
				<div>
					{
						menu.map((item, index) => {

							if (item) {
								return (
									<div key={index}>
										<DashboardMenuIcon
											style={{ marginTop: "15px", marginLeft: "20px" }}
											image={item.icon}
											label={item.label}
											selected={index === selectedPage.pageIndex ? true : false}
											onClick={() => setOption(index)}
										/>
										{
											(item.submenu && item.isMenuOpen) &&
											<div className="w-100 d-flex flex-column justify-content-end">
												{

													item.submenu.map((subItem, innerIndex) => {

														return (
															<DashboardMenuIcon
																key={innerIndex}
																style={{
																	height: "min-content", marginLeft: "55px",
																	padding: "0px 0px 12px 0px", justifyContent: "flex-end"
																}}
																label={subItem.label}
																selected={
																	(innerIndex === selectedPage.pageSubmenuIndex) ? true : false}
																onClick={() => setSubOption(index, innerIndex)}
															/>

														);
													})
												}
											</div>
										}
									</div>
								);
							}
						})
					}
				</div>
			</div>
			<div className="d-flex flex-column justify-content-end userButton pb-5" ref={wrapperRef}>
				{
					userButton &&
					<div className="menu-logout-button" style={{ marginBottom: "10px",background:"white" }}  onClick={() => {
						removeAllCookies();
						window.location.reload();
					}}>

						{/* <img src={logout_icon} style={{ paddingRight: "10px" }} /> */}
						<span className="logout-icon" style={{ marginRight: "10px" }} />
						<span className="b4 logout-text" style={{ paddingRight: "20px" }} onBlur={handleUserButton}
						>Logout</span>
					</div>
				}
				<div className="d-flex justify-content- align-items-center w-100" style={{ height: "fit-content" }} >
					<img src={auth.userProfile && auth.userProfile.image_url || user_logo} style={{ paddingRight: "5px", width: "50px", objectFit: "contain", borderRadius: "25px" }} />

					<div className="d-flex" onClick={handleUserButton} style={{ cursor: "pointer" }}>
						<div className="d-flex flex-column" style={{ paddingRight: "5px" }}>
							<span className="b3 licorice-color"
								style={{ paddingBottom: "5px", maxWidth: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
							>{`${user && (user.first_name + " " + user.last_name)}`}</span>
							<span className="b4 mischka-color">{auth.tenantProfile && auth.tenantProfile.tenant_name}</span>
						</div>
						<div
							className="ml-1 d-flex align-items-center justify-content-center log-out-arrow-up"
							style={{ width: "15.5px", height: "15.5px", borderRadius: "50%", boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)"}}>
							<ArrowUp style={{ width: "10px", height: "10px", fill: "#0054DC"}}/>
						</div>
						
					</div>

				</div>

				{/* <OnboardingStatus />
				<CompanyBadge /> */}
			</div>
		</div >
	);
}