/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Button from "../../../../components/sub-component/Button";
import ProgressBar from "../../../../components/sub-component/ProgressBar";
import Tabel from "../../../../components/sub-component/Table/Tabel";

import { getUnauthorizedURL, openURL } from "../../../../utilities/Utilites";
import { useAuth } from "../../../../context/AuthContext";
import AlertBox from "../../../../components/common/AlertBox";
import CampaignCard from "../../../../components/dashboard/mid-page/compaign/CampaignCard";

const header = ["Campaign Name", "Impact Factor", "Target Amount", "Progress", "Details"];
const draftHeader = ["Campaign Name", "Impact Factor", "Target Amount", "Details"];

// const progressBar = <ProgressBar key={0} isPercentageLabeled completed={34} barColor="#007AFF" width="90px" height="7px" />;
// const viewButton = <Button title="view" key={1} style={{ padding: "5px 28px" }} className="b4" />;

export default function Campaign() {
	const auth = useAuth();
	const [onGoingCampaigns, setOnGoingCampaigns] = useState([]);
	const [completedCampaigns, setCompletedCampaigns] = useState([]);
	const [onDraftCampaigns, setOnDraftCampaigns] = useState([]);

	const campaings = [
		{ name: "Ongoing Campaigns", list: onGoingCampaigns },
		{ name: "Completed Campaigns", list: completedCampaigns },
		// { name: "Draft", list: onGoingCampaignList }
	];
	const history = useHistory();
	const match = useRouteMatch();

	const [processingRowId, setProcessingRowId] = useState();
	const [alert, setAlert] = useState();

	const deleteDraftCampaign = async (id) => {

		setProcessingRowId(id);

		const { result, error } = await openURL(`/v1/campaign/${id}`, "DELETE", auth);

		setProcessingRowId(null);

		if (error) {
			setAlert(
				<AlertBox color="danger" title="Error" description={error.message} />
			);
		} else if (result) {
			setAlert(
				<AlertBox color="success" title="Success" description="Campaign deleted successfully" />
			);

			const remainingCampaigns = onDraftCampaigns.filter(campaign => campaign.id !== id);
			console.log(remainingCampaigns);
			setOnDraftCampaigns(remainingCampaigns);

		}

		setTimeout(() => {
			setAlert(null);
		}, 4000);

	};

	const geDraftCampaign = async () => {

		const draftedCampaignsTemp = [];
		const { result, error } = await getUnauthorizedURL("/v1/campaign/?parameter=DRAFT", { id: auth.user_id, tenant_id: auth.tenant_id });

		if (result) {
			setOnDraftCampaigns(result.message);

		}

	};


	const getOnGoingCampaign = async () => {
		try {
			const { result, error } = await getUnauthorizedURL("/v1/campaign/?parameter=ON_GOING", { id: auth.user_id, tenant_id: auth.tenant_id });
			if (error) {
				console.log("Add User Error:", error);
			} else if (result) {
				// const onGoingCam = [];
				// result.message.map((campaign) => {
				// 	const donations = calculateDunation(campaign.donations);
				// 	const progressBar = <ProgressBar key={0} isPercentageLabeled completed={(donations / Number(campaign.target) * 100).toFixed(1)} barColor="#007AFF" width="90px" height="7px" />;
				// 	onGoingCam.push([
				// 		"",
				// 		campaign.name,
				// 		campaign.impact.factor,
				// 		campaign.target,
				// 		progressBar,
				// 		<Button title="view" key={1} style={{ padding: "5px 28px" }} className="b4" onClick={() => history.push(`${match.path}/id=${campaign.id}`)} />
				// 		// viewButton
				// 	]);
				// });
				setOnGoingCampaigns(result.message);
			}
		} catch (error) {
			console.log("Add User Error:", error);
		}
	};

	const calculateDunation = (dunations) => {
		let amount = 0;
		dunations.map((obj) => {
			amount = amount + Number(obj.amount);
		});
		return amount;
	};

	const geCompletedCampaign = async () => {
		try {

			const { result } = await getUnauthorizedURL("/v1/campaign/?parameter=COMPLETED", { id: auth.user_id, tenant_id: auth.tenant_id });


			if (result) {
				// const completeCam = [];
				// result.message.map((campaign) => {
				// 	const donations = calculateDunation(campaign.Donation);
				// 	const progressBar = <ProgressBar key={0} isPercentageLabeled completed={(donations / Number(campaign.target) * 100).toFixed(1)} barColor="#007AFF" width="90px" height="7px" />;
				// 	completeCam.push([
				// 		"",
				// 		campaign.name,
				// 		campaign.Impact.factor,
				// 		campaign.target,
				// 		progressBar,
				// 		<Button title="view" key={1} style={{ padding: "5px 28px" }} className="b4" onClick={() => history.push(`${match.path}/id=${campaign.id}`)} />
				// 		// viewButton
				// 	]);
				// });
				console.log(result.message, "yeah");
				setCompletedCampaigns(result.message);
			}
		} catch (error) {
			console.log("geCompletedCampaign Error:", error);
		}
	};

	useEffect(() => {

		geCompletedCampaign();
		getOnGoingCampaign();
		geDraftCampaign();

	}, []);

	useEffect(() => {

		console.log(onGoingCampaigns, "hey");

	}, [onGoingCampaigns]);

	return (
		<div className="w-100 d-flex justify-content-center ">
			<div className="dashboard-form-wrapper" id="dashboard-form-wrapper">

				{
					campaings.map((campaign, index) => {
						return (
							<div key={index} style={{ marginTop: "25px" }}>
								<div className=" w-100 d-flex justify-content-between">
									<span className="b3">{campaign.name}</span>
									{
										(auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14) && (

											index === 0 &&
											<Button
												style={{ padding: "5px 28px" }}
												className="b4"
												title="Create New"
												id="create-new-campaign-button"
												onClick={() => history.push(`${match.path}/create`)} />
										)
									}
								</div>
								{campaign.list.length > 0
									?
									campaign.list.map((_campaign, i) => {
										const donations = calculateDunation(index !== 0 ? _campaign.Donation : _campaign.donations);
										return (
											<CampaignCard key={i}
												style={{ marginTop: "15px" }}
												title={_campaign.name}
												subTitle={index === 0 ? _campaign.impact.factor : _campaign.Impact.factor}
												target={`$${_campaign.target}`}
												completedInPercentage={(donations / Number(_campaign.target) * 100).toFixed(1)}
												showProgressBar
												completed={donations}
												barColor={index === 0 ? "#CBE3FF" : "#00D69A"}
												suffix="collected"
												isViewEnable
												onView={() => history.push(`${match.path}/id=${_campaign.id}`)}
											/>
										);
									})
									:
									<div className="w-100 d-flex align-items-center justify-content-center p-3">
										<span className="b3 mischka-color">
											{
												index === 0
													?
													"No Ongoing Campaign"
													:
													"No Completed Campaign"
											}
										</span>
									</div>
								}


							</div>
						);
					})
				}



				{
					(auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14) && (

						<div style={{ marginTop: "25px" }} >
							<div className="d-flex flex-column justify-content-between form-table">
								<span className="b3">{"Draft Campaigns"}</span>

								<div style={{ marginTop: "15px" }} >
									{
										onDraftCampaigns.length > 0
											?
											onDraftCampaigns.map((_campaign, i) => {
												const donations = calculateDunation(_campaign.donations);
												return (
													<CampaignCard key={i}
														style={{ marginTop: "15px" }}
														title={_campaign.name}
														subTitle={_campaign.impact.factor}
														target={`$${_campaign.target}`}
														completedInPercentage={(donations / Number(_campaign.target) * 100).toFixed(1)}
														completed={donations}
														showProgressBar
														suffix="collected"
														isEditEnable
														onEdit={() => history.push(`/dashboard/campaigns/update/id=${_campaign.id}`)}
														isDeleteEnable
														onDelete={() => deleteDraftCampaign(_campaign.id)}
													/>
												);
											})
											:
											<div className="w-100 d-flex align-items-center justify-content-center p-3">
												<span className="b3 mischka-color">No Draft Campaign</span>
											</div>
									}
								</div>

								{
									alert
								}

							</div>
						</div>

					)
				}

			</div>
		</div>
	);
}
