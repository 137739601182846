import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Button from "../components/sub-components/Button";
import cross from "../assets/icons/basic/cross.svg";
import { isPasswordValid, setCookie } from "../utilities/Utilites";
import baseURL from "../utilities/BaseURL";
import { useAuth } from "../context/AuthContext";
import google from "../assets/icons/charity/google_icon.svg";
import microsoftIcon from "../assets/icons/microsoft.png";
import AlertBox from "./sub-components/AlertBox";
import { useDonation } from "../context/DonationContext";


export default function DonationSignupCard(props) {

	const auth = useAuth();
	const donation = useDonation();

	const [credential, setCredential] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		confirm_password: ""
	});

	const [alert, setAlert] = useState(null);
	const [processing, setProcessing] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

	const submitForm = async () => {

		if (!credential.first_name) {
			setAlert(
				<AlertBox color="danger" title="Missing first name" description="First name is required" />
			);
			return;
		}

		if (!credential.last_name) {
			setAlert(
				<AlertBox color="danger" title="Missing last name" description="Last name is required" />
			);
			return;
		}

		if (!isEmailValid(credential.email)) {
			setAlert(
				<AlertBox color="danger" title="Invalid Email" description="Please enter a valid email address" />
			);
			return;
		}

		if (!credential.password || !isPasswordValid(credential.password)) {
			setAlert(
				<AlertBox color="danger" title="Weak password" description="Must have 10 characters containing 1 special charater" />
			);
			return;
		}

		if (credential.confirm_password !== credential.password) {
			setAlert(
				<AlertBox color="danger" title="Mismatch" description="Password did not match" />
			);
			return;
		}

		setProcessing(true);

		const response = await fetch(`${baseURL}/v1/auth/register`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
			},
			body: JSON.stringify({ email: credential.email, password: credential.password })
		});

		if (!response.ok) {
			return;
		}

		const data = await response.json();

		setCookie("token", data.refresh_token, 7);

		auth.handleAuth(data);

		if (props.loginSuccess) {
			props.loginSuccess();
		}

	};

	const isEmailValid = (email) => {
		const validationRegex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
		return validationRegex.test(email);
	};


	const handleInput = (event) => {
		setCredential({ ...credential, [event.target.name]: event.target.value });
	};

	function googleOAuth() {
		window.location.replace(`${baseURL}/v1/oauth/google`);
	}

	function azureOAuth() {
		window.location.replace(`${baseURL}/v1/oauth/azure`);
	}

	return (
		<div className="donation-login-card border">
			<img src={cross} className="cross-sign" onClick={donation.clearDonation} alt="cross" />
			<span className="s2">Donation</span>
			<hr className="w-100" style={{ border: "1px solid #CED5DB" }} />

			<div className="login-fields px-4" onKeyPress={(event) => (event.key === "Enter") && submitForm()}>
				<span className="s2">Sign Up</span>

				<input className="b3 hoki-color" type="text" placeholder="First Name" style={{ border: "none", borderBottom: "3px solid #5E7488", padding: "8px", marginTop: "10px" }} name="first_name" onChange={handleInput} />

				<input className="b3 hoki-color" type="text" placeholder="Last Name" style={{ border: "none", borderBottom: "3px solid #5E7488", padding: "8px", marginTop: "10px" }} name="last_name" onChange={handleInput} />

				<input className="b3 hoki-color" type="email" placeholder="Email" style={{ border: "none", borderBottom: "3px solid #5E7488", padding: "8px", marginTop: "10px" }} name="email" onChange={handleInput} />

				<div className="position-relative">
					<input
						className="b3 hoki-color w-100"
						type={showPassword ? "text" : "password"}
						placeholder="Password"
						style={{ border: "none", borderBottom: "3px solid #5E7488", padding: "8px", marginTop: "10px" }}
						name="password"
						onChange={handleInput}
					/>
					{showPassword ? 
						<FaRegEye style={{ position: "absolute", right: "8px", top: "20px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
						:
						<FaRegEyeSlash style={{ position: "absolute", right: "8px", top: "20px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
					}
				</div>
				<ul>
					<li style={{ color: "#a1a1a1" }}>Must have 10 characters containing 1 speacial charater</li>
				</ul>

				<div className="position-relative">
					<input 
						className="b3 hoki-color w-100"
						type={showConfirmedPassword ? "text" : "password"}
						placeholder="Confirm Password"
						style={{ border: "none", borderBottom: "3px solid #5E7488", padding: "8px", marginTop: "10px" }}
						name="confirm_password"
						onChange={handleInput}
					/>
					{showConfirmedPassword ? 
						<FaRegEye style={{ position: "absolute", right: "8px", top: "20px" }} onClick={() => setShowConfirmedPassword(prev => !prev)} size={20} />
						:
						<FaRegEyeSlash style={{ position: "absolute", right: "8px", top: "20px" }} onClick={() => setShowConfirmedPassword(prev => !prev)} size={20} />
					}
				</div>
				<span style={{ padding: "15px 0", }}>By clicking Sign Up, you agree to our <a href="/">Terms of Use </a> and <a href="/">Privacy Policy</a>.</span>

				<div className="d-flex align-items-center flex-column">
					<div style={{ width: "220px", height: "35px" }}>
						<Button title="Sign Up" className="d-flex" onClick={submitForm} busy={processing} style={{ backgroundColor: props.primaryColor, width: "100%", height: "100%" }} />
					</div>
					<span style={{ margin: "10px 0" }}>
						<span>Already have an account?</span>
						<span onClick={() => donation.setIsSigningUp(false)} style={{ color: "#0086ff", marginLeft: "10px", cursor: "pointer" }} >Login</span>
					</span>
				</div>

				<div className="d-flex justify-content-center">
					<img src={google} style={{ cursor: "pointer" }} alt="google" onClick={googleOAuth} />
					<img src={microsoftIcon} style={{ cursor: "pointer", width: "30px", marginLeft: "20px" }} alt="google" onClick={azureOAuth} />
				</div>

				<div style={{ position: "relative" }}>
					<div style={{ position: "absolute", width: "100%" }}>
						{alert}
					</div>
				</div>

			</div>
		</div>
	);
}