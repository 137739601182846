import React from "react";

import { ReactComponent as Arrow } from "../../assets/icons/arrows/chevron-right.svg";
import { ReactComponent as Arrow_down } from "../../assets/icons/visual-builder/button_drop.svg";

import { ReactComponent as Logout } from "../../assets/icons/basic/Vector.svg";

/**
 * Arrow Button
 * @param {object} props Arrow Button with default no arrow
 * @param {string} props.title Title for button
 * @param {boolean} props.leftArrow Button with arrow on left side
 * @param {boolean} props.rightArrow Button with arrow on right side
 */
export default function Button(props) {
	if (props.busy) {

		return (
			<div style={{ ...props.style }} disabled className="d-flex justify-content-center align-items-center busy-button">
				<div>
					<span className="busy-indicator"></span>
				</div>
			</div>
		);

	}

	else if (props.rightArrow) {

		return (
			<button style={{ width: "100%", padding: "10px 20px", backgroundColor: "#0054DC", borderRadius: "29px", cursor: "pointer", ...props.style }}
				onClick={props.onClick}
				className="d-flex justify-content-center align-items-center">
				<div style={{ display: "inline-block", marginRight: "10px" }}>
					<Arrow fill="white" />
				</div>
				<div>
					<span className="button-text-lg">{props.title || "Next"}</span>
				</div>
			</button>
		);

	} else if (props.leftArrow) {

		return (
			<button style={{ ...props.style }} onClick={props.onClick} type={props.type || "submit"} className="d-flex justify-content-center align-items-center arrow-button" id={props.id}>

				<div className="d-flex justify-content-center align-items-center">
					<span className="button-text-lg">{props.title || "Next"}</span>
				</div>
				<div style={{ display: "inline-block", marginLeft: "10px" }}>
					<Arrow fill="white" />
				</div>
			</button>
		);

	} else if (props.whiteButton) {
		return (
			<div style={{ width: "fit-content", padding: "10px 40px", backgroundColor: "#ffffff", borderRadius: "29px", cursor: "pointer", border: "1px solid #0054DC", ...props.style }}
				onClick={props.onClick}
				className="d-flex justify-content-center align-items-center">
				<div className="d-flex justify-content-center align-items-center">
					<span style={{ color: "#000000", ...props.titleStyle }} className="b4">{props.title || "Next"}</span>
				</div>
			</div>
		);
	} else if (props.charityLogin) {
		return (
			<div style={{ ...props.style }}
				onClick={props.onClick}
				className="charity-login-button d-flex justify-content-center align-items-center">
				<div className="d-flex justify-content-center align-items-center">
					<span style={{ color: "#FFFFFF", ...props.titleStyle }} className="cb">{props.title || "Next"}</span>
				</div>
			</div>
		);
	}

	else if (props.visualBuilderButton) {
		if (props.visualBuilderButton === "blue") {
			return (
				<div style={{ cursor: "pointer", ...props.style }}
					onClick={props.onClick}
					className={!props.disabled ? "vb-active-button" : "vb-disable-button"}>
					<div className="d-flex justify-content-center align-items-center">
						<span className={"button-text-md" + ` ${props.className}`}>{props.title || "Next"}</span>
					</div>
				</div >
			);
		}
		else if (props.visualBuilderButton === "arrow") {
			return (
				<div style={{ width: "118px", height: "40px", padding: "4.5px 15px", backgroundColor: "#FFFFFF", borderRadius: "5px", boxShadow: " 2px 8px 15px rgba(0, 0, 0, 0.1)", ...props.style }}
					onClick={props.onClick}
					className="d-flex justify-content-center align-items-center" >
					<div className="d-flex justify-content-center align-items-center">
						<span className="visual-builder-button" style={{ color: "#A4AEB8" }}>{props.title || "Next"}</span>
					</div>
					<div style={{ display: "inline-block", marginLeft: "10px" }}>
						<Arrow_down fill="white" />
					</div>
				</div>
			);
		}

		else return (
			<div style={{ width: "fit-content", height: "40px", padding: "10px 15px", backgroundColor: `${props.isDisabled ? "lightgray" : "#FFFFFF"}`, cursor: `${props.isDisabled ? "not-allowed" : "pointer"}`, borderRadius: "5px", boxShadow: " 2px 8px 15px rgba(0, 0, 0, 0.1)", ...props.style }}
				onClick={props.isDisabled ? () => { } : props.onClick}
				className="d-flex justify-content-center align-items-center" >
				<div className="d-flex justify-content-center align-items-center">
					<span className={"visual-builder-button" + ` ${props.className}`} style={{ color: "#2A3440" }}>{props.title || "Next"}</span>
				</div>
			</div>
		);
	}

	if (props.landingPage === "white") {
		return (
			<div className={`d-flex justify-content-center align-items-center ${props.btnContainerClass}`} style={{ width: "226px", height: "57px", padding: "10px", backgroundColor: "#FFFFFF", borderRadius: "30px", cursor: "pointer", ...props.style }}
				onClick={props.onClick} >
				<div className="d-flex justify-content-center align-items-center">
					<span className={"s3 navy-blue-color" + ` ${props.className}`}>{props.title || "Next"}</span>
				</div>
			</div>
		);
	}

	else if (props.ButtonSize) {
		if (props.ButtonSize === "md") {
			
			return (
				<div style={{ width: "fit-content", padding: "7px 10px", backgroundColor: "#0054DC", borderRadius: "50px", cursor: "pointer", ...props.style }}
					onClick={props.onClick} id={props.id}
					className="d-flex justify-content-center align-items-center" >
					<div className="d-flex justify-content-center align-items-center">
						<span className={"button-text-md" + ` ${props.className}`} style={props.textStyle}>{props.title || "Next"}</span>
					</div>
				</div>
			);
		}
		else if (props.ButtonSize === "sm") {
			return (
				<div style={{ width: "fit-content", padding: "3px 10px", backgroundColor: "#0054DC", borderRadius: "50px", cursor: "pointer", ...props.style }}
					onClick={props.onClick}
					className="d-flex justify-content-center align-items-center" >
					<div className="d-flex justify-content-center align-items-center">
						<span className={"button-text-sm" + ` ${props.className}`}>{props.title || "Next"}</span>
					</div>
				</div>
			);
		}

	}
	else if (props.isHollow) {
		
		return (

			<div style={{ width: "fit-content", padding: "10px 20px", border: "1px solid #0054DC", borderRadius: "29px", cursor: "pointer", ...props.style }}
				onClick={props.onClick}
				id={props.id}
				className="d-flex justify-content-center align-items-center" >
				{
					props.hasLogoutIcon &&
					<Logout className="mr-2" />
				}
				{
					props.leftIcon &&
					props.leftIcon
				}
				<div className="d-flex justify-content-center align-items-center">
					<span className={`${props.fontStyle ?? ""} ${props.className}`}
						style={{ color: "#0054DC" }}
					>{props.title || "Next"}</span>
				</div>
			</div>
		);
	}
	else {
		
		return (
			<button style={{ width: "fit-content", padding: "10px 20px", backgroundColor: "#0054DC", border: "none", outline: "none", borderRadius: "29px", cursor: "pointer", ...props.style }}
				onClick={props.onClick}
				disabled={props.disabled}
				id={props.id}
				className={`d-flex justify-content-center align-items-center ${props.btnContainerClass}`} >
				<div className="d-flex justify-content-center align-items-center">
					<span className={"button-text-md" + ` ${props.className}`}>{props.title || "Next"}</span>
				</div>
			</button>
		);
	}

}