import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import AuthMessageCard from "../../components/auth/AuthMessageCard";
import AlertBox from "../../components/common/AlertBox";
import Button from "../../components/sub-component/Button";
import Input from "../../components/sub-component/Input";
// import { useAuth } from "../../context/AuthContext";
import baseURL from "../../utilities/BaseURL";
import { isEmailValid, setCookie } from "../../utilities/Utilites";
import altrunc_logo from "../../assets/icons/logo.png";
// import { useAuth } from "../../context/AuthContext";

export default function Login() {

	// const history = useHistory();
	// const auth = useAuth();
	const [isBusy, setIsBusy] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const [credentials, setCredentials] = useState({
		email: null,
		password: null
	});

	const [alert, setAlert] = useState();

	function handleInput(event) {

		setCredentials({ ...credentials, [event.target.name]: event.target.value });

	}

	function validateFrom() {

		if (!isEmailValid(credentials.email)) {
			return { isValid: false, message: "Invalid email" };
		}

		return { isValid: true, message: "" };

	}

	function handleSubmit() {

		const validation = validateFrom();

		if (!validation.isValid) {

			setAlert(
				<AlertBox color="danger" title="Error" description={validation.message} />
			);
			return;

		}

		setIsBusy(true);

		fetch(`${baseURL}/v1/auth/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(credentials)

		}).then(async (res) => {

			const jsonRes = await res.json();

			if (res.ok) {
				return jsonRes;
			} else {
				return Promise.reject(jsonRes);
			}

		}).then((response) => {

			// auth.authLogin(response);
			setCookie("token", response.refresh_token, 7);
			window.location.reload();

		}).catch((err) => {
			setAlert(
				<AlertBox color="danger" title="Error" description={err.message} />
			);
			setIsBusy(false);
		});

	}

	return (

		<div className="d-flex flex-lg-row flex-column"
			onKeyPress={(event) => {
				if (event.key === "Enter") {
					handleSubmit();
				}
			}}>



			<div className="d-sm-flex message-card-mobile" style={{ width: "100%", height: "fit-content", position: "relative", }}>
				<AuthMessageCard paragraph={
					["Giving is not just about making a ",
						<span style={{ color: "#0054DC" }} key="0">donation</span>,
						", its about making an ",
						<span style={{ color: "#0054DC" }} key="1">impact</span>,
						"."]}
				/>
			</div>

			<div className="d-flex flex-column align-items-center justify-content-center auth-form login-form">

				<div className="d-flex flex-column align-self-center" style={{ height: "auto", width: "100%", borderBottom: "2px solid #CBE3FF", paddingBottom: "40px" }}>

					<div className="message-card-logo-mobile">

						<img src={altrunc_logo} />

					</div>

					<span className="s2" style={{ textAlign: "left" }}>Log in.</span>

					<div style={{ width: "100%", marginTop: "56px" }}>
						<Input placeholder="Email" label="" name="email" id="email" underLine onChange={handleInput} />
					</div>

					<div style={{ width: "100%", marginTop: "30px", display: "flex", position: "relative" }}>
						<Input placeholder="Password" label="" name="password" id="password" underLine onChange={handleInput} type={showPassword ? "text": "password"} />
						{showPassword ? 
							<FaRegEye style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
							:
							<FaRegEyeSlash style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
						}
					</div>

					<div className="d-flex flex-row align-items-start" style={{ marginTop: "25px" }}>
						<div>
							<input type="checkbox" />
						</div>
						<div className="mr-auto">
							<label className="b3" style={{ marginLeft: "10px", marginTop: "-2.5px" }} >Remember me</label>
						</div>
						<div>
							<Link to="/forgotpassword">
								<label className="b3" style={{ marginLeft: "10px", marginTop: "-2.5px", cursor: "pointer" }} >Forgot Password?</label>
							</Link>
						</div>
					</div>

					<div style={{ width: "100%", marginTop: "41px", }}>
						<Button title="Login" style={{ width: "100%", height: "40px" }} onClick={handleSubmit} busy={isBusy} id="loginButton"/>
					</div>

				</div>

				<span className="b3" style={{ margin: "15px 0" }}>New to Altrunic? Start your Social Impact journey now.</span>
				<div className="d-flex justify-content-center">
					<Link to="/signup">
						<Button title="Sign up now" whiteButton />
					</Link>
				</div>

				<div style={{ position: "relative", width: "100%" }}>
					<div style={{ position: "absolute", width: "100%" }}>
						{alert}
					</div>
				</div>

			</div>

		</div>
	);

}