import React from "react";
//import Button from "../../builder/components/sub-components/Button";
import { ReactComponent as Arrow } from "../../assets/icons/arrows/chevron-left.svg";
import { useLocation,useHistory } from "react-router-dom";

const DomainSetup = (props)=>{

   
	// const { isSetupPage } = props.location;
    

	const history = useHistory();
	const location = useLocation();

	const handlePush = () =>{
		history.location.pathname.includes("dashboard") && history.push("/dashboard/settings/charity");
		history.location.pathname.includes("setup") && history.push("/setup/charitysite");
    
	};

	// {location.pathname.includes("dashboard") ? className="w-75 mx-auto" : location.pathname.includes("setup") ? className="d-flex flex-column setup-form w-100":null }
    
	return(
		location?.state?.isSetupPage && location?.state?.isDashboardPage===false ?
			<div className={location?.state?.isSetupPage && location?.state?.isDashboardPage===false ? ("w-50 mx-auto") : ("w-75 mx-auto")} style={{ marginTop: "40px" }}>
				{/* {!props.isModal && <div onClick={(e)=>handlePush(e)} style={{ color: "#0054DC", width: "110px",background:"#0054DC",padding: "7px 2px 7px 20px", backgroundColor: "#ffffff", borderRadius: "29px", cursor: "pointer", border: "1px solid #0054DC" }}><Arrow fill="#0054DC"/>Back</div>} */}
				{!props.isModal && <div onClick={(e)=>handlePush(e)} style={{ color: "#0054DC",padding: "2px 2px 2px 20px", cursor: "pointer"}}><Arrow fill="#0054DC"/></div>}
           
				<div style={{ marginTop: "10px" }}><h1 className="text-center p-1">Setup instructions for setting up Custom Domain</h1></div>
     
				<div >
					<ul>
						<li className="mb-4 fs-1">
							<b>Access Your Domain Provider&apos;s Website: </b>
                        Login to the website of the company where you purchased your domain (e.g., GoDaddy, Namecheap, Google Domains).
						</li>
						<li className="mb-4 fs-1">
							<b>Locate DNS Settings: </b>
                        Find the DNS management section. This might be labeled as &quot;DNS Settings,&quot; &quot;DNS Management,&quot; &quot;DNS Records,&quot; or something similar.
						</li>
						<li className="mb-4 fs-1">
							<b>Find the Domain You Want to Update: </b>
                        If you have multiple domains, select the one you want to configure.
						</li>
						<li className="mb-4 fs-1">
							<b>Add a CNAME Record: </b>
                        You need to add a DNS record in your domain with Type: <b>CNAME</b> and Name: <b>www</b> and the value should be <b>S3 Bucket URL.</b><br/>
						eg: Type: <b><i>CNAME</i></b>, Name: <b><i>www</i></b>, Value: <b><i>xyz.altrunic.com.s3.amazonaws.com</i></b>
							<ul className="mt-4">
								<li>
									<b>Hostname or Alias: </b>
                                Enter the subdomain or root domain you want to map. For example, if you want <b>www.yourdomain.com</b> to point to your bucket, you&apos;d enter <b>www</b> for your domain.
								</li>
								<li>
									<b>Value or Destination: </b>
                                Enter the bucket URL provided by your cloud storage provider. This could be something like xyz.altrunic.org.s3.amazonaws.com for AWS S3 or xyz.altrunic.org.storage.googleapis.com for Google Cloud Storage.
								</li>
							</ul>
						</li>
						<li className="mb-4 fs-1">
							<b>Save Changes: </b>
                        After entering the necessary information, save your changes. This might involve clicking a <b>Save</b> or <b>Update</b> button depending on your domain provider&apos;s interface.
						</li>
						<li className="mb-4 fs-1">
							<b>Add SSL Certificate:</b> Follow the instructions provided in the link below to install your SSL certificate:<br/><br/>
							<a href="https://in.godaddy.com/help/request-my-ssl-certificate-562" rel="noreferrer" target="_blank">Get SSL</a><br/>
							<a href="https://in.godaddy.com/help/install-my-ssl-certificate-16623" rel="noreferrer" target="_blank">Add SSL</a><br/>
						</li>
						<li className="mb-4 fs-1">
							<b>Propagation: </b>
                        DNS changes can take some time to propagate across the internet. It can range from a few minutes to several hours. During this time, your domain might still point to the old location.
						</li>
					</ul>
				</div>
			</div>
			:
			<div className={"w-75 mx-auto"} style={{ marginTop: "40px" }}>
				{/* {!props.isModal && <div onClick={(e)=>handlePush(e)} style={{ color: "#0054DC", width: "110px",background:"#0054DC",padding: "7px 2px 7px 20px", backgroundColor: "#ffffff", borderRadius: "29px", cursor: "pointer", border: "1px solid #0054DC" }}><Arrow fill="#0054DC"/>Back</div>} */}
				{!props.isModal && <div onClick={(e)=>handlePush(e)} style={{ color: "#0054DC", cursor: "pointer"}}><Arrow fill="#0054DC"/></div>}
      
				<div style={{ marginTop: "10px" }}><h1 className="text-center p-1">Setup instructions for setting up Custom Domain</h1></div>

				<div >
					<ul>
						<li className="mb-4 fs-1">
							<b>Access Your Domain Provider&apos;s Website: </b>
                   Login to the website of the company where you purchased your domain (e.g., GoDaddy, Namecheap, Google Domains).
						</li>
						<li className="mb-4 fs-1">
							<b>Locate DNS Settings: </b>
                   Find the DNS management section. This might be labeled as &quot;DNS Settings,&quot; &quot;DNS Management,&quot; or something similar.
						</li>
						<li className="mb-4 fs-1">
							<b>Find the Domain You Want to Update: </b>
                   If you have multiple domains, select the one you want to configure.
						</li>
						<li className="mb-4 fs-1">
							<b>Add a CNAME Record: </b>
                    You need to add a DNS record in your domain with Type: <b>CNAME</b> and Name: <b>www</b> and the value should be <b>S3 Bucket URL.</b><br/>
                    eg: Type: <b><i>CNAME</i></b>, Name: <b><i>www</i></b>, Value: <b><i>xyz.altrunic.com.s3.amazonaws.com</i></b>
							<ul className="mt-4">
								<li>
									<b>Hostname or Alias: </b>
                            Enter the subdomain or root domain you want to map. For example, if you want <b>www.yourdomain.com</b> to point to your bucket, you&apos;d enter <b>www</b> for your domain.
								</li>
								<li>
									<b>Value or Destination: </b>
                            Enter the bucket URL provided by your cloud storage provider. This could be something like xyz.altrunic.org.s3.amazonaws.com for AWS S3 or xyz.altrunic.org.storage.googleapis.com for Google Cloud Storage.
								</li>
							</ul>
						</li>
						<li className="mb-4 fs-1">
							<b>Save Changes: </b>
                    After entering the necessary information, save your changes. This might involve clicking a <b>Save</b> or <b>Update</b> button depending on your domain provider&apos;s interface.
						</li>
						<li className="mb-4 fs-1">
							<b>Add SSL Certificate:</b> Follow the instructions provided in the link below to install your SSL certificate:<br/><br/>
							<a href="https://in.godaddy.com/help/request-my-ssl-certificate-562" rel="noreferrer" target="_blank">Get SSL</a><br/>
							<a href="https://in.godaddy.com/help/install-my-ssl-certificate-16623" rel="noreferrer" target="_blank">Add SSL</a><br/>
						</li>
						<li className="mb-4 fs-1">
							<b>Propagation: </b>
                   DNS changes can take some time to propagate across the internet. It can range from a few minutes to several hours. During this time, your domain might still point to the old location.
						</li>
					</ul>
				</div>
			</div>
	);
};
export default DomainSetup;