import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "../../../components/sub-component/Button";
import laptop1 from "../../../assets/images/landing/laptop/Laptop1-min.png";
import { useHistory } from "react-router";

import laptop2 from "../../../assets/images/landing/laptop/Laptop2-5-min.png";
import laptop3 from "../../../assets/images/landing/laptop/Laptop2-min.png";
import laptop4 from "../../../assets/images/landing/laptop/Laptop4-min.png";
import laptop5 from "../../../assets/images/landing/laptop/Laptop5-min.png";

import laptop1_sm from "../../../assets/images/Landing Page 2/Laptop1.png";
import laptop2_sm from "../../../assets/images/Landing Page 2/Laptop2-5-min.png";
import laptop3_sm from "../../../assets/images/Landing Page 2/Laptop3.png";
import laptop4_sm from "../../../assets/images/Landing Page 2/Laptop4.png";
import laptop5_sm from "../../../assets/images/Landing Page 2/Laptop5.png";

import design1 from "../../../assets/icons/landing page 2/design2.svg";
import design2 from "../../../assets/icons/landing page 2/design1.svg";
import design3 from "../../../assets/icons/landing page 2/design.svg";
import design4 from "../../../assets/icons/landing page 2/design3.svg";

export default function Section2() {

	var impacts = [
		{ id: 1, title: "1. Setup Impact Objectives", description: "Easily set your impact targets with your objectives in mind and set a specific target for your campaign.", image: laptop1, image_mobile: laptop1_sm },
		{ id: 2, title: "2. Launch a campaign with a top-rated charity ", description: "After setting the Impact, you can start a donation campaign with listed charities/your custom charity.", image: laptop2, image_mobile: laptop2_sm },
		{ id: 3, title: "3. Publish a branded impact website", description: "Publish your full-fledged charity website after a bunch of customization options with our Visual Builder. You can edit/adjust any section according to your needs and your website is ready to Go Live.", image: laptop3, image_mobile: laptop3_sm },
		{ id: 4, title: "4. Invite employees and customers to contribute", description: "As you are done with your charity website and first donation campaign, it’s time to invite users to your platform no matter if they are your colleagues or your customers.", image: laptop4, image_mobile: laptop4_sm },
		{ id: 5, title: "5. Share and celebrate impact you just made!", description: "Share your social impact activity with your friends and family via Social Sharing buttons so that they can also be able to make contributions to your donation.", image: laptop5, image_mobile: laptop5_sm }

	];

	const [selectedImpact, setSelectedImpact] = useState(impacts[0]);


	function handleImpactChange(index) {
		setSelectedImpact(impacts[index]);
	}
	const history = useHistory();



	return (
		<div style={{ overflow: "hidden" }}>
			<div className="section2 container-fluid">
				<Row>
					<Col>
						<span className="ls-h4 licorice-color">What we deliver at Altrunic</span>
					</Col>
				</Row>

				<div className="card-section container-fluid ">
					<Row className="d-flex justify-content-between w-100">
						<Col className="p-0 d-flex justify-content-center" lg="3">
							<div className="cards">
								<div className="blue-bg" style={{ backgroundImage: `url(${design1})` }}></div>
								<span className="ls-s0 cloud-burst-color">Measurable Impact</span>
								<span className="ls-b1 hoki-color" style={{ marginTop: "15px", marginBottom: "15px", height: "132px" }}>Every charity on Altrunic has transparent impact statements so that you know exactly where your donation goes.</span>
								<Link onClick={() => { localStorage.setItem("scrollTo", "featureFirstBoxRef"); history.push("/features"); }}><Button whiteButton title="Learn more" /></Link>
							</div>
						</Col>

						<Col className="p-0 d-flex justify-content-center" lg="3">
							<div className="cards">
								<div className="blue-bg" style={{ backgroundImage: `url(${design2})` }}></div>
								<span className="ls-s0 cloud-burst-color">Impact Website</span>
								<span className="ls-b1 hoki-color" style={{ marginTop: "15px", marginBottom: "15px", height: "132px" }}>Branded website for your social impact initiatives where your customers and employees can visit and contribute.</span>
								<Link onClick={() => { localStorage.setItem("scrollTo", "featureSecondBoxRef"); history.push("/features"); }}><Button whiteButton title="Learn more" /></Link>
							</div>
						</Col>

						<Col className="p-0 d-flex justify-content-center" lg="3">
							<div className="cards">
								{/* <div className="blue-bg" style={{ backgroundImage: `url(${design3})`, position: "relative" }}>
									<div style={{ backgroundColor: "#A4AEB8", borderRadius: "4px", position: "absolute", bottom: "-7px", left: "-10px", width: "110px" }}><span className="roboto-b2 white-color">Coming Soon</span></div>
								</div> */}
								<div className="blue-bg" style={{ backgroundImage: `url(${design3})` }}></div>
								<span className="ls-s0 cloud-burst-color">Campaigns</span>
								<span className="ls-b1 hoki-color" style={{ marginTop: "15px", marginBottom: "15px", height: "132px" }}>Make a positive impact on your community by using an advanced Campaigns module where you can select a charity by navigating on Charity Map.</span>
								<Link onClick={() => { localStorage.setItem("scrollTo", "featureThirdBoxRef"); history.push("/features"); }}><Button whiteButton title="Learn more" /></Link>
							</div>
						</Col>

						<Col className="p-0 d-flex justify-content-center" lg="3">
							<div className="cards">
								<div className="blue-bg" style={{ backgroundImage: `url(${design4})`, position: "relative" }}></div>
								<span className="ls-s0 cloud-burst-color">Advanced Giving</span>
								<span className="ls-b1 hoki-color" style={{ marginTop: "15px", marginBottom: "15px", height: "132px" }}>Engage as a company with your employees and customers with advanced giving modules such as donation matching and checkout giving.</span>
								<Link onClick={() => { localStorage.setItem("scrollTo", "featureFourthBoxRef"); history.push("/features"); }}><Button whiteButton title="Learn more" /></Link>
							</div>
						</Col>
					</Row>
				</div>

				<div className=" d-flex justify-content-center">
					<span className="ls-h4 licorice-color" style={{ lineHeight: "30px", paddingBottom: "10px", paddingTop: "50px" }}>Demonstrable impact in 5 quick steps</span>
				</div>
			</div>

			<div className="laptop-box">  {/*//style={{:"2px solid red"}}*/}
				<div className="blue-box">
					{
						impacts.map((impact, index) => {
							if (impact.id === selectedImpact.id)
								return (
									<div className=" impact-description" style={{ background: "white", zIndex: "2" }} key={index} onClick={() => handleImpactChange(index)}>
										<div className="ls-s0 navy-blue-color ">{impact.title}</div>
										{/* <div className="ls-b1 description-mobile navy-blue-color" style={{ paddingTop: "10px", paddingLeft: "25px", fontWeight: "normal" }}>{impact.description}</div> */}
									</div>
								);
							else return (
								<div className="ls-s0 white-color impact-button " key={index} onClick={() => handleImpactChange(index)}
									style={{ fontWeight: "bold", zIndex: "2" }}
								>{impact.title}</div>
							);
						})

					}

				</div>

				<div className="d-flex justify-content-end section2-img" style={{ position: "absolute", width: "65%", height: "100%", right: "-100px", top: "-15%", maxHeight: "670px" }}>  {/*,:"2px solid black"*/}
					<img src={selectedImpact.image} className="laptop-web" style={{ maxWidth: "100%", Height: "100%" }} />
					<img src={selectedImpact.image_mobile} className="laptop-mobile" style={{ maxWidth: "100%", Height: "100%", objectFit: "contain" }} />
				</div>
			</div>
		</div>
	);
}