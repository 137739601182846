import React from "react";
import { Fragment } from "react";
import Rectangle from "../../../assets/icons/avatars/Rectangle.svg";
import Button from "../../../components/sub-component/Button";

export default function SuggestedCampaignsCard(props) {
	return (
		<div>
			{
				props.campaigns.length > 0 &&
				<Fragment>

					<div>
						{
							props.headTitle &&
							<span className="s3">Suggested Help Article</span>
						}
					</div>

					{props.campaigns.map((campaign, index) => {
						return (
							<div className="suggested-campaign-card" key={index}>
								<span className="b4 hoki-color">{campaign.headerTitle}</span>
								<span className="b3 licorice-color mt-2" >{campaign.title}</span>
								<div style={{ width: "100%", height: "122px", marginTop: "15px", marginBottom: "15px" }}>
									<img style={{ objectFit: "cover", height: "inherit", width: "inherit", borderRadius: "10px" }} src={campaign.image || Rectangle} alt=""></img>
								</div>
								<p className="b4">{campaign.description}</p>
								<div className="w-100 d-flex align-items-center justify-content-end" style={{ marginTop: "5px" }}>
									<Button
										onClick={() => { window.open(campaign.link, "_blank"); }}
										className="b4"
										title={props.btnTitle}
										style={{ width: "max-content", height: "33px", borderRadius: "10px", backgroundColor: "#007AFF" }}
									/>
								</div>
							</div >

						);
					})}
				</Fragment>
			}
		</div >
	);
}