import React from "react";

export default function DashboardMenuIcon(props) {
	return (
		<div
			onClick={props.onClick}
			className={props.selected ? "dashboard-side-menu-icon-selected pointer" : "dashboard-side-menu-icon pointer"}
			style={props.style}
		>
			<div>{props.image}</div>
			<span className="b4" style={{ marginLeft: "12px" }}>{props.label}</span>
		</div>
	);
}
