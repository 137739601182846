import React, { useState, useEffect } from "react";
import Footer from "../Landing Website/Footer";
import Navbar from "../Landing Website/Navbar";
import Input from "../../../components/sub-component/Input";
import Button from "../../../components/sub-component/Button";
import BlogCard from "../../../components/blogs/BlogCard";
import { Row, Col } from "react-bootstrap";
// import { getUnauthorizedURL } from "../../../utilities/Utilites";

export default function Blogs() {

	const [tags, setTags] = useState([]);
	// const tags = [
	// 	"Donations", "Food", "Environment", "Technology", "Volunteering", "Education", "Health"
	// ];

	const getData = () => {
		fetch("http://localhost:1337/categories")
			.then(res => {
				if (res.ok) {
					return res.json();
				}
				else {
					throw res;
				}
			})
			.then(data => {
				console.log(data, "aa");
				setTags(data);
				return data;
			})
			.catch(error => {
				console.log(console.log(error));
				return error;
			});
	};

	useEffect(() => {
	}, []);


	// const blogs = [
	// 	{
	// 		tag: "Technology",
	// 		blogs: [
	// 			{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "TECHNOLOGY", date: "April 8th, 2021" },
	// 			{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "TECHNOLOGY", date: "April 8th, 2021" },
	// 			{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "TECHNOLOGY", date: "April 8th, 2021" },
	// 			{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "TECHNOLOGY", date: "April 8th, 2021" },
	// 		]
	// 	},
	// 	{
	// 		tag: "Health",
	// 		blogs: [
	// 			{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
	// 			{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
	// 			{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
	// 			{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
	// 		]
	// 	}
	// ];

	const otherBlogs = [
		{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
		{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
		{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
		{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
		{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
		{ header: "Lorem Ipsum Dolor Sit Amit.", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquam enim eleifend eros. Donec integer ac ut etiam facilisis pretium. Id aliquet arcu, massa cursus donec amet nec.", tag: "HEALTH", date: "April 8th, 2021" },
	];

	const blogsHeader = () => {
		return (
			<div className="blog-header">
				<div className="upper-header">
					<span className="ls-h4 white-color">Altrunic Blog</span>
					<Input searchInput placeholder="Search..."
						style={{ width: "100%", height: "57px", maxWidth: "756px", marginTop: "25px" }} className="w-100"
						inputStyle={{ paddingLeft: "55px", borderRadius: "50px" }} />
				</div>
				<div className="lower-header">
					<div className="tags-container">
						{
							tags.map((tag, index) => {
								return (
									<Button key={index} title={tag.name} style={{ height: "30px" }} />
								);
							})
						}
					</div>
				</div>

			</div>
		);
	};
	return (
		<>
			<Navbar />


			<button onClick={getData}>CLICK ME</button>
			<div className="w-100 d-flex flex-column align-items-center">
				{
					blogsHeader()
				}
				<div className="d-flex flex-column align-items-center" style={{ width: "100%", maxWidth: "1250px" }}>
					{
						tags.map((tag, index) => {
							return (
								<div key={index} className="w-100 d-flex flex-column" style={{ marginTop: "60px" }}>
									<span className="navy-blue-color ls-h4">{tag.name}</span>
									<Row style={{ marginTop: "20px" }}>
										<Col md="6" className="m-0 p-0">
											<BlogCard size="md" header={tag.blogs[0].title} description={tag.blogs[0].description} tag={tag.name} date={tag.blogs[0].published_at} image={tag.blogs[0].image} />
										</Col>
										<Col md="6">
											<div className="h-100 d-flex flex-column justify-content-between">
												{
													tag.blogs.map((_blog, index) => {
														if (index !== 0) {
															return (
																<div className="w-100" key={index}>
																	<BlogCard size="sm" header={_blog.title} description={_blog.description} tag={tag.name} date={_blog.published_at} image={_blog.image} />
																</div>
															);
														}
														else {
															return <></>;
														}
													})
												}
											</div>
										</Col>
									</Row>
								</div>
							);
						})
					}
					<div className="join-our-mailing-list" style={{ marginTop: "70px" }}>
						<span className="white-color ls-h4" style={{ fontWeight: "600" }}>Join Our Mailing List</span>
						<span className="white-color ls-b0-5" style={{ maxWidth: "788px", marginTop: "18px", textAlign: "center" }} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi massa id ipsum auctor volutpat. Nisi massa id ipsum auctor volutpat.</span>
						<div className="d-flex">

						</div>
					</div>
					<Row className="w-100" style={{ marginTop: "40px" }}>
						{
							otherBlogs.map((blog, index) => {
								return (
									<Col key={index} md="6" style={{ marginTop: "33px" }}>
										<BlogCard size="sm" header={blog.header} description={blog.description} tag={blog.tag} date={blog.date} />
									</Col>
								);
							})
						}
					</Row>
				</div>
			</div>
			<Footer />
		</>
	);
}
