import React from "react";
import ProgressBar from "../../../sub-component/ProgressBar";

export default function ImpactAndObjectivesDetailsCard(props) {

	return (
		<div className="IAOD-Card">
			<div className="IAOD-Card-header">
				<div className="IAOD-Card-headings">
					<span className="b2" style={{ fontSize: "18px", fontWeight: "700" }}>Impact Factor </span>
					<span className="b2" style={{ fontSize: "18px", fontWeight: "700" }}>Objective Period</span>
				</div>
				<div className="IAOD-Card-stats">
					<span className="b3">{props.impactInfo.impact_factor && props.impactInfo.impact_factor}</span>
					<span className="b3">{props.impactInfo.objective_period && props.impactInfo.objective_period}</span>
				</div>
			</div>
			<div className="w-100 " style={{ marginTop: "35px" }}>
				<ProgressBar
					achieved={props.impactInfo.achieved && props.impactInfo.achieved}
					total={props.impactInfo.total && props.impactInfo.total}
					type={"DonationProgressBar"}
					height="15px"
					width="100%"
					completed={props.impactInfo.achievedTarget}
					barColor="#0054DC"
					isPercentageLabeled
					suffix={props.impactInfo.suffix && props.impactInfo.suffix}
				/>
			</div>
		</div>
	);
}
