import React, { createContext, useContext, useState } from "react";
import SettlementCard from "../components/SettlementCard";
import SettlementDoneCard from "../components/SettlementDoneCard";

const SettlementContext = createContext();

export function useSettlementContext() {
	return useContext(SettlementContext);
}


export default function SettlementProvider({ children }) {

	const [isSettlingAmount, setIsSettlingAmount] = useState(false);
	const [isSettlementDone, setSettlementDone] = useState(false);

	const [settlementDetails, setSettlementDetails] = useState({
		settlementAmount: null,
		campaignId: null,
	});

	return (
		<SettlementContext.Provider value={{ setIsSettlingAmount, setSettlementDetails, settlementDetails, setSettlementDone }}>
			{
				isSettlementDone ? (
					<div className="settlement-modal-wrap">
						<SettlementDoneCard />
					</div>
				) : isSettlingAmount && (
					<div className="settlement-modal-wrap">
						<SettlementCard />
					</div>
				)
			}
			{
				children
			}
		</ SettlementContext.Provider>
	);

}