import React, { Fragment } from "react";
import Image from "../../assets/images/Image.png";
import { ReactComponent as DefaultUser } from "../../assets/icons/basic/default-user.svg";
import { ReactComponent as Add } from "../../assets/icons/basic/add-plus-round-icon.svg";

export default function MemberCard(props) {

	const getPureImageURL = (image) => {
		// &#x2F;
		const img = image.replaceAll("&#x2F;", "/");
		return img;
	};
	if(parseInt(props.templateId)===1){
		return (
			<Fragment>
				{
					props.defaultMemberCard
						?
						<div className="member-image-card">
							<div style={{ background: "white" }}>
								<DefaultUser fill={props.color} />
							</div>
	
							<div className="member-card">
	
								<div className="member-card-add-btn" style={{ backgroundColor: `${props.color}` }}>
									<span className="cs-b1 text-colour">Add Volunteer</span>
									<Add fill={props.color} width="22px" height="22px" />
								</div>
							</div>
						</div>
						:
						<div className="member-image-card">
							<div style={{ backgroundColor: "white" }}>
								{
									props.cardImage
										?
										<img
											src={getPureImageURL(props.cardImage) || Image}
											alt=""
											style={{ width: "266px", height: "270px" }}
										/>
										:
										<DefaultUser />
								}
	
							</div>
							<div className="member-card">
								<span className="heading1">{props.cardTitle || "Robin Hood"}</span>
								<span className="cs-description">{props.cardSubTitle || "Cheif Organizer"}</span>
							</div>
						</div>
	
				}
	
			</Fragment>
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<Fragment>
				{
					props.defaultMemberCard
						?
						<div className="member-image-card">
							<div style={{ background: "white" }}>
								<DefaultUser fill={props.color} />
							</div>
	
							<div className="member-card">
	
								<div className="member-card-add-btn" style={{ backgroundColor: `${props.color}` }}>
									<span className="cs-b1 text-colour">Add Volunteer</span>
									<Add fill={props.color} width="22px" height="22px" />
								</div>
							</div>
						</div>
						:
						<div className="member-image-card">
							<div style={{ backgroundColor: "white" }}>
								{
									props.cardImage
										?
										<img
											src={getPureImageURL(props.cardImage) || Image}
											alt=""
											style={{ width: "266px", height: "270px" }}
										/>
										:
										<DefaultUser />
								}
	
							</div>
							<div className="member-card" style={{ background: `${props.color}` }}>
								<span className="heading1 white-color" >{props.cardTitle || "Robin Hood"}</span>
								<span className="cs-description white-color">{props.cardSubTitle || "Cheif Organizer"}</span>
							</div>
						</div>
	
				}
	
			</Fragment>
		);
	}
	else{
		return(
			<>
			</>
		);
	}
	
}