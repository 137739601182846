/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CampaignCard from "./CampaignCard";
import { useMediaQuery } from "react-responsive";
import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { getUnauthorizedURL } from "../utilities/Utilites";
import { Link, useRouteMatch } from "react-router-dom";
import CarouselComponent from "./sub-components/CarouselComponents";
export default function CompletedCampaigns(props) {

	const isMobile = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	// const [campaignBoxLimit, setCampaignBoxLimit] = useState(isMobile ? 4 : 3);

	// const campaigns = [
	// 	{
	// 		title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	},
	// 	{
	// 		title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	},
	// 	{
	// 		title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	},
	// 	{
	// 		title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	}
	// ];

	// const isMobile = useMediaQuery({
	// 	query: "(max-device-width: 767px)"
	// });

	const match = useRouteMatch();
	const [campaignBoxLimit, setCampaignBoxLimit] = useState(isMobile ? 4 : 3);
	const [completedCampaigns, setCompletedCampaigns] = useState([]);

	const getCompletedCampaign = async () => {
		console.log("props tenant id ofr completed is",props.tenant_id);
		const { result, error } = await getUnauthorizedURL("/v1/campaign/?parameter=COMPLETED", { id: null, tenant_id: props.tenantId });
		if (error) {
			setCompletedCampaigns([]);
		}
		else {
			const tempArr = [];
			result.message.map((campaign) => {
				// console.log(props.campaigns, props.campaigns.filter(item => item?.campaign_id === campaign.id)[0].campaign_components[0].props.heroHeaderImage, "Campaign");
				return (
					tempArr.push({
						id: campaign.id,
						title: campaign.name,
						heroHeaderImage: props?.campaigns?.length > 0 ? props.campaigns.filter(item => item?.campaign_id === campaign.id)[0]?.campaign_components[0].props.heroHeaderImage : null,
						impact: campaign.Impact.factor,
						matching: `We will participate ${campaign.matching_ratio} as much as you donate`,
						description: campaign.Charity.name,
						charityDescription: campaign.Charity.description,
						target: campaign.target,
						donations: campaign.Donation,
						campaignEndDate: new Date(campaign.end_date).toDateString(),
					})
				);
			});
			setCompletedCampaigns(tempArr);
		}
	};
	useEffect(() => {
		setCampaignBoxLimit(isMobile ? 4 : 3);
	}, [isMobile]);

	useEffect(() => {
		// console.log(props, "checking from completed campaign");
		getCompletedCampaign();
	}, [props.campaigns]);

	useEffect(() => {
	}, [completedCampaigns]);
	if(parseInt(props.templateId)===1){
		return (
			<div id="completed-campaigns-template-1">
				{
					completedCampaigns.length > 0
						?
						<div className="cs-ongoing-campaign-container-template-1">
							<span className="cs-hero3" style={{ color: `${props.primaryColor}` }}>{props.CompletedCampaignsTitle}</span>
							<div className="cs-ongoing-campaign-inner-container">
								<div className="cs-campaign-container row w-100 "  style={{ justifyContent: completedCampaigns.length <= 2 ? "space-around" : "space-around" }}>
									{
										// completedCampaigns.map((campaign, index) => {
										// 	return (
										// 		<Fragment key={index}>
										// 			{
										// 				// index !== campaignBoxLimit &&
										// 				<Fragment>
										// 					{
	
										// 						<Col xs="6" md="4" className="cs-campaign-box-container"
										// 							style={isMobile ? (index % 2 === 0 ? { padding: "0px 12.5px 0px 0px" } : { padding: "0px 0px 0px 12.5px" }) : { marginTop: "0px", padding: "0px" }}
										// 						>
										// 							<CampaignCard
										// 								componentId={campaign.id}
										// 								to={`${match.path}campaign/${campaign.id}`}
										// 								heroHeaderImage={campaign.heroHeaderImage}
										// 								campaignCardTitle={campaign.title}
										// 								campaignCardImpact={campaign.impact}
										// 								campaignCardMatching={campaign.matching}
										// 								campaignCardDescription={campaign.description}
										// 								primaryColor={props.primaryColor}
										// 								target={Number(campaign.target)}
										// 								donations={campaign.donations}
										// 								charityDescription={campaign.charityDescription}
										// 								campaignEndDate={campaign.campaignEndDate}
										// 								templateId={parseInt(props.templateId)}
										// 							/>
										// 						</Col>
										// 					}
										// 				</Fragment>
										// 			}
										// 		</Fragment>
										// 	);
	
										// })
									}
									
									<CarouselComponent data={completedCampaigns} templateId={parseInt(props.templateId)} isCampaignCard={true} 
										primaryColor={props.primaryColor} completedCampaigns/>
								</div>
							</div>
	
						</div >
						:
						<Fragment>
							{
								props.isInEditingInProcess &&
								<div style={{ width: "100%", height: "200px" }} className="d-flex justify-content-center align-items-center">
									<span className="cs-hero3">There is No Completed Campaigns</span>
								</div>
							}
						</Fragment>
				}
			</div>
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<div id="completed-campaigns-template-2">
				{
					completedCampaigns.length > 0
						?
						<div className="cs-ongoing-campaign-container-template-2" style={{padding:"2vh 8vw"}}>
							<div className="cs-hero3 w-100 text-center" style={{ color: `${props.primaryColor}` }}>{props.CompletedCampaignsTitle}</div>
							<div className="cs-ongoing-campaign-inner-container d-flex justify-content-center p-0">
								<div className="cs-campaign-container row w-100 "  style={{ justifyContent: completedCampaigns.length <= 2 ? "space-around" : "space-around" }}>
									{
										// completedCampaigns.map((campaign, index) => {
										// 	return (
										// 		<div key={index} className="col-12 col-md-4 d-flex justify-content-center">
													
										// 			{

										// 				<div className="cs-campaign-box-container mb-5 " 
															
										// 				>
										// 					<CampaignCard
										// 						componentId={campaign.id}
										// 						to={`${match.path}campaign/${campaign.id}`}
										// 						heroHeaderImage={campaign.heroHeaderImage}
										// 						campaignCardTitle={campaign.title}
										// 						campaignCardImpact={campaign.impact}
										// 						campaignCardMatching={campaign.matching}
										// 						campaignCardDescription={campaign.description}
										// 						primaryColor={props.primaryColor}
										// 						target={Number(campaign.target)}
										// 						donations={campaign.donations}
										// 						charityDescription={campaign.charityDescription}
										// 						campaignEndDate={campaign.campaignEndDate}
										// 						templateId={parseInt(props.templateId)}
										// 					/>
										// 				</div>
														
										// 			}
										// 		</div>
										// 	);
	
										// })
									}
									<CarouselComponent data={completedCampaigns} templateId={parseInt(props.templateId)} isCampaignCard={true} 
										primaryColor={props.primaryColor} completedCampaigns/>
								</div>
							</div>
	
						</div >
						:
						<Fragment>
							{
								props.isInEditingInProcess &&
								<div style={{ width: "100%", height: "200px" }} className="d-flex justify-content-center align-items-center">
									<span className="cs-hero3">There is No Completed Campaigns</span>
								</div>
							}
						</Fragment>
				}
			</div>
		);
	}
	else{
		return(
			<></>
		);
	}
}
