import React, { useState, useEffect } from "react";
import ProgressBar from "../../../../components/sub-component/ProgressBar";
import Table from "../../../../components/sub-component/Table/Tabel";

import { openURL } from "../../../../utilities/Utilites";
import { useAuth } from "../../../../context/AuthContext";


const participantsTableHeader = ["Goal Name", "Period", "Impact Factor", "Target", "Progress"];
// const progressBar = <ProgressBar key={0} completed={34} barColor="#007AFF" height="7px" width="115px" isPercentageLabeled />;
// const rows = [
// 	["Lorum Ipsum", "Lorum Ipsum", "help needy people", "3000", progressBar],
// 	["Lorum Ipsum", "Lorum Ipsum", "help needy people", "3000", progressBar],
// 	["Lorum Ipsum", "Lorum Ipsum", "help needy people", "3000", progressBar],
// 	["Lorum Ipsum", "Lorum Ipsum", "help needy people", "3000", progressBar],
// ];

export default function GoalsDetails() {
	const auth = useAuth();
	const [rows, setRows] = useState([]);

	const getGoal = async () => {
		try {
			const { result, error } = await openURL("/v1/goal/", "GET", auth, null);
			if (error) {
				console.log("getGole Error:", error);
			}
			else if (result) {
				var rowArray = [];
				result.message.map((goal) => {
					var totalDonation = 0;
					var percentage = 0;
					goal.Impact.Campaign.map((campaign) => {
						campaign.Donation.map((donate) => {
							totalDonation += Number(donate.amount);
						});
					});
					percentage = ((totalDonation / Number(goal.target)) * 100).toFixed(1);
					const progressBar = <ProgressBar key={0} completed={percentage} barColor="#007AFF" height="7px" width="115px" isPercentageLabeled />;
					rowArray.push([
						"",
						goal.name,
						`${new Date(goal.Goal_Period.starting_date.split("T")[0]).toDateString()} To ${new Date(goal.Goal_Period.ending_date.split("T")[0]).toDateString()}`,
						goal.Impact.factor,
						goal.target,
						progressBar
					]);
				});
				setRows([...rowArray]);
			}
		} catch (error) {
			console.log("getGole Error:", error);
		}
	};

	useEffect(() => {

		getGoal();

		// setDummy(dummy);
	}, []);

	return (
		<div>
			<div style={{ marginTop: "25px", display: "flex", flexDirection: "column" }}>
				<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-center">
					<Table
						isEditable={false}
						headers={participantsTableHeader}
						rows={rows}
						style={{ marginTop: "15px" }}
						alignText="center"
					/>

				</div>
			</div>
		</div>
	);
}
