import React, { useRef, useState } from "react";
import SuggestedCharitiesCard from "../../../components/dashboard/right-side-menu/SuggestedCharitiesCard";
import Line from "../../../assets/icons/Genral/Line.png";
import ActiveStatus from "../../../assets/icons/Genral/Ellipse.svg";
import icon from "../../../assets/icons/notifications/icon.svg";
import Upload from "../../../assets/icons/basic/share-arrow.svg";
import FacebookLogo from "../../../assets/icons/symbols/facebook1.svg";
import TwitterLogo from "../../../assets/icons/symbols/twitter1.svg";
import GoogleLogo from "../../../assets/icons/symbols/google1.svg";

const charities = [
	{
		title: "Unicef",
		description: "Humanitarian aid organization",
		details: "Lorem  Aliquet sed pellentesque amet, viverra volutpat leo adipiscing quis. Praesent eu sit vitae nam nunc pharetra massa duis euismod. Ut ut in libero sit id etiam cras. Molestie euismod ac sit molestie dui porta nunc sed tellus. "
	}
];

export default function CompletedCampaign() {

	const fileInput = useRef(null);

	const handleClick = () => {
		fileInput.current.click();
	};

	const [image, setImage] = useState(null);

	const fileSelectedHandler = (event) => {
		setImage({ file: URL.createObjectURL(event.target.files[0]) });
		console.log(event.target.files[0]);
	};

	const [isActive] = useState(true);

	// const ActivityStatus = () => {
	// 	setActive(false);
	// };

	const [isShown, setIsShown] = useState(false);
	const [isShow, setIsShow] = useState(false);

	return (
		<div>
			<div>
				<span className="s3">Settings</span>
			</div>
			<div className="" style={{ marginTop: "20px", width: "100%" }}>
				<SuggestedCharitiesCard charities={charities}
				/>
			</div>
			<div style={{ height: "0px" }}>
				<img src={Line} alt="">
				</img>
			</div>
			<div style={{ marginTop: "25px" }} className="d-flex flex-column align-items-start">
				<span className="b4" > Campaign Status</span>
			</div>
			<div className="d-flex align-items-start" style={{ marginTop: "3px" }}>
				<div style={{ width: "6px", height: "6px", marginRight: "5px" }} >
					<img
						src={ActiveStatus}
						alt=""
					>
					</img>
				</div>
				<div >
					<span className="b4" style={{ color: "#00D69A" }}>{isActive ? "Active" : "Completed"}</span>
				</div>
			</div>

			<div style={{ marginTop: "5px" }} className="d-flex flex-column align-items-start">
				<span className="b4">Settlement Amount</span>
			</div>
			<div style={{ height: "30px", width: "100%", backgroundColor: "white", borderRadius: "5px", padding: "0px 10px", boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)", marginTop: "5px" }}>
				<span className="b4">$10,000</span>
			</div>
			<div style={{ height: "0px" }}>
				<img src={Line} alt="">
				</img>
			</div>
			<div style={{ marginTop: "25px" }} className="d-flex flex-column align-items-start">
				<span className="b4">Impact Factor</span>
			</div>
			<div style={{ height: "30px", width: "100%", backgroundColor: "white", borderRadius: "5px", padding: "0px 10px", boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)", marginTop: "5px" }}>
				<span className="b4">100 poor children got free education</span>
			</div>

			<div style={{ marginTop: "25px" }}>
				<span className="s3"> Upload Media</span>
			</div>
			<div className="d-flex">
				<div style={{ marginTop: "10px" }} className="flex-column align-items-start">
					<div onClick={handleClick} style={{ width: "50px", height: "50px", borderRadius: "5px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)" }}>
						<img
							src={Upload}
							style={{ width: "12px", height: "13px" }}
						>
						</img>
						<input style={{ display: "none" }} type="file" onChange={fileSelectedHandler} ref={fileInput}></input>
					</div>
				</div>
				<div style={{ width: "17px", height: "17px", marginTop: "18px", marginLeft: "230px", position: "relative" }}>
					<img src={icon} alt="" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}></img>
					{isShown ?
						<div style={{ width: "162px", height: "auto", borderRadius: "10px", backgroundColor: "white", position: "absolute", right: "40px", bottom: "-145px", boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)" }}>
							<div style={{ display: "flex", flexDirection: "column", padding: "5px 6px 5px 7px" }}>
								<div>
									<span className="b4">Why Upload Media?</span>
								</div>
								<div>
									<p className="b4" style={{ color: "#5E7488" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et maecenas a</p>
								</div>
								<div style={{ marginTop: "-15px" }}>
									<span className="b4">What to Upload?</span>
								</div>
								<div>
									<p className="b4" style={{ color: "#5E7488" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et maecenas a</p>
								</div>
							</div>
						</div>
						: <></>}
				</div>

			</div>
			{ image ?
				<div>
					<img src={image ? image.file : null} alt="" style={{ width: "200px", height: "200px" }} >
					</img>
				</div>
				: <></>
			}

			<div style={{ marginTop: "5px" }}>
				<span className="b4">Share on Social Media</span>
			</div>

			<div className="d-flex">
				<div style={{ marginRight: "24px" }}>
					<img
						src={FacebookLogo}
						style={{ width: "20px", height: "20px" }}
					>
					</img>
				</div>
				<div style={{ marginRight: "24px" }}>
					<img
						src={FacebookLogo}
						style={{ width: "20px", height: "20px" }}
					>
					</img>
				</div>
				<div style={{ marginRight: "24px" }}>
					<img
						src={TwitterLogo}
						style={{ width: "20px", height: "20px" }}
					>
					</img>
				</div>
				<div style={{ marginRight: "24px" }}>
					<img
						src={GoogleLogo}
						style={{ width: "20px", height: "20px" }}

					>
					</img>
				</div>
				<div style={{ width: "17px", height: "17px", marginLeft: "103px", position: "relative" }}>
					<img src={icon} alt="" onMouseEnter={() => setIsShow(true)} onMouseLeave={() => setIsShow(false)}></img>
					{isShow && (
						<div style={{ width: "162px", height: "auto", borderRadius: "10px", backgroundColor: "white", position: "absolute", right: "40px", bottom: "-115px", boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)" }}>
							<div style={{ display: "flex", flexDirection: "column", padding: "5px 6px 5px 7px" }}>
								<div>
									<p className="b4" style={{ color: "#5E7488" }}>We have made an impact by providing free education to 100 poor children.Let the world hear about this victory.</p>
								</div>
								<div>
									<p className="b4" style={{ color: "#5E7488" }}>Let the world hear about the victory</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}