import React, { useState, useEffect } from "react";
import { CardNumberElement, useStripe, useElements, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import Button from "../sub-component/Button";
import { openURL } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";
import { Container, Row, Col } from "reactstrap";
import AlertBox from "../common/AlertBox";
import { Color, Title } from "../../utilities/AppConstant";

export default function StripeCardForm(props) {
	const auth = useAuth();
	const stripe = useStripe();
	const elements = useElements();

	const [nameOnCard, setNameOnCard] = useState();

	const [error, setError] = useState();
	const [processing, setProcessing] = useState();
	const [succeeded, setSucceeded] = useState();
	const [disabled, setDisabled] = useState(false);
	const [coupon, setCoupon] = useState(props.coupon || null);

	const cardNumberStyle = {
		style: {
			base: {
				fontSize: "20px",
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		},
		showIcon: true,
		placeholder: "Card Number",
	};
	const cardStyle = {
		style: {
			base: {
				fontSize: "20px",
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		},
		showIcon: true,
	};

	async function handleSubmit(event) {

		event.preventDefault();

		if (!props.packageId) {
			setError("No Package Selected");
			return;
		}
		if (!props.pricingId) {
			setError("No Package Interval Selected");
			return;
		}

		if (!nameOnCard || !nameOnCard.trim() || nameOnCard.length < 3) {
			setError("Enter Name on Card");
			return;
		}

		console.log("packageId",props.packageId);
		localStorage.setItem("packageId",props.packageId);

		setProcessing(true);

		const { paymentMethod, error } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardNumberElement),
			billing_details: {
				name: nameOnCard.trim()
			}
		});

		console.log("stripe payment ", paymentMethod);

		if (error) {
			console.log(error.message);
			setError(error.message);
			setProcessing(false);
		} else {
			const body = {
				name: nameOnCard.trim(),
				package_id: props.packageId,
				pricing_id: props.pricingId,
				payment_method_id: paymentMethod.id,
				coupon: coupon
			};

			const { result, error } = await openURL("/v1/subscriptions/", "POST", auth, body);

			if (error) {
				setError(error.message);
				if(!error.message) {
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				}
			} else if (result) {
				setSucceeded("Subscription Created");
				setDisabled(true);
				if (localStorage.getItem("selected-package")) {
					localStorage.removeItem("selected-package");
				}
				if (localStorage.getItem("package-interval")) {
					localStorage.removeItem("package-interval");
				}
				window.location.reload();
			}

			setProcessing(false);

		}
	}

	useEffect(() => {
		setCoupon(props.coupon);
	}, [props.coupon]);

	return (
		<div>
			{/* <h3>Stripe Card Form</h3>		 */}
			<form id="payment-form" onSubmit={handleSubmit}>
				<input className="card-input-element w-100 b2" placeholder="Name on card" id="payment-card-name-input" onChange={(e) => setNameOnCard(e.target.value)} />
				<CardNumberElement className="card-input-element" id="payment-card-number-input" options={cardNumberStyle} />
				<Container fluid={true} className="p-0">
					<Row>
						<Col sm="6" className="pl-0">
							<CardExpiryElement className="card-input-element" id="payment-card-expiry-input" options={cardStyle} />
						</Col>
						<Col sm="6" className="pr-0">
							<CardCvcElement className="card-input-element cvc-icon" id="payment-card-cvc-input" options={cardStyle} />
						</Col>
					</Row>
				</Container>
				<div style={{ width: "100%", height: "40px", marginTop: "40px" }}>
					<Button title="Start Free Trial" id="payment-card-submissiong-confirmation-button" type="submit" style={{ width: "100%", height: "100%" }} busy={processing} disabled={disabled} />
				</div>
			</form>
			<div style={{ position: "relative" }}>

				{
					error && (
						<AlertBox color={Color.ERROR} title={Title.TITLE_ERROR} description={error} />
					)
				}
				{succeeded && (
					<AlertBox color={Color.SUCCESS} title={Title.TITLE_SUCCESS} description={"Payment succeeded."} />
				)}
			</div>
		</div>

	);

}