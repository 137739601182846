import React, { useEffect, useState } from "react";

import { ReactComponent as Bell } from "../../assets/icons/notifications/bell.svg";
import { ReactComponent as Bell1 } from "../../assets/icons/notifications/bell1.svg";

export default function NotificationBell(props) {

	//Make a state to handle notifications, if there are notifications then the red dot will be shown otherwise not.

	const [isOpen, setIsOpen] = useState(false);

	const handleState = () => {
		setIsOpen(!isOpen);

		if (props.onClick) {
			props.onClick();
		}

	};

	useEffect(() => {
		setIsOpen(props?.isBellStatusOpen);
	}, [props?.isBellStatusOpen]);

	return (
		<div className="d-flex">
			<div className={`${props.className} pointer`} onClick={handleState}>
				{isOpen ? <Bell1 /> : <Bell />}
			</div>
			{props.hasUnreadNotification ? <div style={{ width: "5px", height: "5px", borderRadius: "50%", backgroundColor: "red", marginLeft: "-5px" }}></div> :
				<></>
			}
		</div>
	);
}
