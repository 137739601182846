import React, { useState } from "react";
import Modal from "./Modal";
import Input from "../sub-component/Input";
import baseURL from "../../utilities/BaseURL";
import AlertBox from "./AlertBox";
import { isEmailValid } from "../../utilities/Utilites";

/**
 * 
 * @param {Object} props 
 * @param {Boolean} props.contactUsModalStatus for modal status like either modal should open or not
 * @param {Function} props.onClose to close the modal from parent 
 * @param {Function} props.onDone to close the modal from parent and to do other operations
 * @returns 
 */

export default function ContactUsFrom(props) {

	const [details, setDetails] = useState({
		name: "",
		email: "",
		phone: "",
		message: ""
	});

	// eslint-disable-next-line no-unused-vars
	const [contactUsModalStatus, setContactUsModalStatus] = useState(props.contactUsModalStatus);
	const [isDataSubmitied, setIsDataSubmitied] = useState(false);
	const [alert, setAlert] = useState();
	const [processing, setProcessing] = useState(false);

	const handleUserInput = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		setDetails({ ...details, [`${name}`]: value });
	};

	const validateForm = () => {

		if (details.name.length < 3) {
			return { isValid: false, message: "Name is required" };
		}

		if (!isEmailValid(details.email)) {
			return { isValid: false, message: "Invalid email address" };
		}

		if (details.phone.length < 9) {
			return { isValid: false, message: "Invalid phone number" };
		}

		if (details.message.length < 10) {
			return { isValid: false, message: "Please enter a proper message" };
		}

		return { isValid: true, message: null };

	};

	const onSubmitData = async () => {

		const validation = validateForm();

		if (!validation.isValid) {
			setAlert(
				<AlertBox color="danger" title="Error" description={validation.message} />
			);
			return;
		}

		setProcessing(true);

		const response = await fetch(`${baseURL}/v1/contact/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
			},
			body: JSON.stringify(details)
		});

		setProcessing(false);

		const data = await response.json();

		if (!response.ok) {
			setAlert(
				<AlertBox color="danger" title="Error" description={data.error ? data.error?.message : "Something went wrong. Try again"} />
			);
			return;

		}
		
		setIsDataSubmitied(true);

	};

	const onDone = () => {
		setIsDataSubmitied(false);
		props.onDone();
	};

	return (
		<>
			{
				contactUsModalStatus &&
				<Modal title={isDataSubmitied ? "Custom Plan" : "Let’s Talk"}
					confirmBtnTitle={isDataSubmitied ? "Done" : "Send"}
					confirmBtnBusyStatus={processing}
					onConfirm={isDataSubmitied ? onDone : onSubmitData}
					onClose={() => { setIsDataSubmitied(false); props.onClose(); }}
				>
					{
						!isDataSubmitied
							?
							<form className="ls-contact-us-container" onSubmit={onSubmitData}>
								<span className="contact-us-title">To get information about our Enterprise offerings, please contact us below:</span>
								<div className="contact-us-form">
									<Input
										placeholder="Name"
										value={details.name}
										type={"text"}
										onChange={(e) => handleUserInput(e)}
										name={"name"}
										className={"b4 "}
									/>
									<Input
										placeholder="Email"
										value={details.email}
										type={"email"}
										onChange={(e) => handleUserInput(e)}
										name={"email"}
										className={"b4 "} />
									<Input
										placeholder="Phone Number"
										value={details.phone}
										type={"text"}
										onChange={(e) => handleUserInput(e)}
										name={"phone"}
										className={"b4 "} />
									<Input
										textArea
										placeholder="Type your message here..."
										value={details.message}
										type={"text"}
										onChange={(e) => handleUserInput(e)}
										name={"message"}
										className={"b4 contact-us-text-area"} />
									{
										alert
									}
								</div>


							</form>
							:
							<div className="successfully-contacted-text-container">
								<span className="hoki-color s2">Your message has been succesfully sent!</span>
								<span className="s2" style={{ fontWeight: "normal" }}>You will receieve a response from us as soon as possible.</span>
							</div>
					}


				</Modal>
			}
		</>
	);
}
