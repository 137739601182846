import React from "react";

export default function ProgressBar(props) {

	const completed = (props.completed || (props.current / props.total) * 100);
	if(parseInt(props.templateId)===2){
		if (props.percentageRight) {
			return (
				<div style={{ display: "flex", alignItems: "center" }}>
					<div className="progress-container" style={{ backgroundColor: "#CED5DB", height: "6px", width: "100%", ...props.style }}>
						<div style={{ width: `${completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>
					</div>
					<div style={{ marginLeft: "15px" }}>
						<span className="cs-description">{completed.toFixed(2).replace(/[.,]00$/, "")}%</span>
					</div>
				</div>
			);
		}
		else if (props.percentageUp) {
			return (
				<div style={{ display: "flex", alignItems: "center" }}>
	
					<div style={{ position: "absolute", margin: "-30px 0 0 0" }}>
						<p className="cs-p2 hoki-color">{`$${props.current || 0} / $${props.total || 0}`}</p>
					</div>
					<div className="progress-container" style={{ backgroundColor: "#CED5DB", height: "6px", width: "100%", ...props.style }}>
						<div style={{ width: `${completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>
					</div>
					<div style={{ marginLeft: "-30px", marginTop: "-30px" }}>
						<p className="cs-p2 ">{completed.toFixed(1).replace(/[.,]0$/, "")}%</p>
					</div>
	
				</div>
			);
		}
		
		else {
			return (
				<div className="progress-container" style={{ backgroundColor: "#CED5DB", height: "6px", width: "100%", ...props.style }}>
					<div style={{ width: `${completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>
				</div>
			);
		}
	}
	else{
		if (props.percentageRight) {
			return (
				<div style={{ display: "flex", alignItems: "center" }} className="w-100">
					<div className="progress-container" style={{ backgroundColor: "#CED5DB", height: "6px", ...props.style }}>
						<div style={{ width: `${completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>
					</div>
					<div style={{ marginLeft: "15px" }}>
						<span className="cs-description">{completed.toFixed(2).replace(/[.,]00$/, "")}%</span>
					</div>
				</div>
			);
		}
		else if (props.percentageUp) {
			return (
				<div style={{ display: "flex", alignItems: "center" }}>

					<div style={{ position: "absolute", margin: "-30px 0 0 0" }}>
						<p className="cs-p2 hoki-color">{`$${props.current || 0} / $${props.total || 0}`}</p>
					</div>
					<div className="progress-container" style={{ backgroundColor: "#CED5DB", height: "6px", width: "220px", ...props.style }}>
						<div style={{ width: `${completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>
					</div>
					<div style={{ marginLeft: "-30px", marginTop: "-30px" }}>
						<p className="cs-p2 ">{completed.toFixed(1).replace(/[.,]0$/, "")}%</p>
					</div>

				</div>
			);
		}
		
		else {
			return (
				<div className="progress-container" style={{ backgroundColor: "#CED5DB", height: "6px", width: "220px", ...props.style }}>
					<div style={{ width: `${completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>
				</div>
			);
		}
	}

	
}