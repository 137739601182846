import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

import AuthMessageCard from "../../components/auth/AuthMessageCard";
import StripeCard from "../../components/subscription/StripeCard";
import SegmentToggle from "../../components/sub-component/SegmentToggle";
import SubscriptionPlanCard from "../../components/subscription/SubscrptionPlanCard";
import Button from "../../components/sub-component/Button";
import { Packages } from "../../utilities/Packages";
import SubscriptionPackageModal from "../../components/subscription/SubscriptionPackageModal";
import ContactUsFrom from "../../components/common/ContactUsForm";
import { openURL, removeAllCookies, SubscriptionStatus } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";
import AlertBox from "../../components/common/AlertBox";
import StepProgressBar from "../../components/sub-component/StepProgressBar";

export default function Subscription() {
	const auth = useAuth();

	const [selectedPackage, setSelectedPackage] = useState(
		localStorage.getItem("selected-package")
			?
			JSON.parse(localStorage.getItem("selected-package")).packageId === 1 ? Packages.starter : JSON.parse(localStorage.getItem("selected-package")).packageId === 4 ? Packages.basic : Packages.professional
			:
			Packages.basic
	);

	const segmentOptions = [
		{ key: 1, value: "Monthly", package_key: "monthly" },
		{ key: 2, value: "Yearly", package_key: "yearly" }
	];

	const [selectedPackgeInterval, setSelectedPackgeInterval] = useState(
		localStorage.getItem("package-interval")
			?
			JSON.parse(localStorage.getItem("package-interval")).packageInterval.key === 1 ? segmentOptions[0] : segmentOptions[1]
			:
			segmentOptions[0]
	);
	const [isPlanSelected, setIsPlanSelected] = useState(false);
	const [isModalActive, setIsModalActive] = useState(false);
	const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
	const [couponCode, setCouponCode] = useState({
		isOpen: false,
		code: null,
		isApplying: false,
		isApplied: false,
		couponDetails: null
	});

	const [alert, setAlert] = useState();

	const [processing, setProcessing] = useState(false);
	
	const [subscriptionProration, setSubscriptionProration] = useState({
		package_id: "",
		package_name: "",
		package_price: "",
		package_interval: "",
		total_payment: 0,
		payment_due_today: 0,
	});

	useEffect(async () => {

		var query = `pricing_id=${selectedPackage[selectedPackgeInterval.package_key].pricing_id}`;

		console.log("query inside use========>",selectedPackage,selectedPackgeInterval);

		if (couponCode.code) {
			query = `${query}&coupon=${couponCode.code}`;
		}

		setProcessing(true);

		const { result, error } = await openURL(`/v1/stripe_customers/invoice_proration?${query}`, "GET", auth);

		setProcessing(false);

		if (error) {
			setAlert(
				<AlertBox title="Error" description={error.message} color="danger" />
			);
		} else if (result) {

			console.log("result in query+++++++",result.data);

			var totalPayment = result.data.lines.data[0].price.unit_amount;

			if (result.data.discount?.coupon.amount_off) {
				totalPayment = totalPayment - result.data.discount.coupon.amount_off;
			
			} else if (result.data.discount?.coupon.percent_off) {
				totalPayment = totalPayment - (totalPayment * result.data.discount.coupon.percent_off / 100);
			}

			var amountDue = result.data.subscription.status === SubscriptionStatus.status.TRIALING ?  0 : result.data.amount_due / 100;

			const proration = {
				package_id: result.data.lines.data[0].price.metadata.package_id,
				package_name: result.data.lines.data[0].price.metadata.package_name,
				package_price: result.data.lines.data[0].price.unit_amount,
				package_interval: result.data.lines.data[0].price.metadata.package_payment_interval,
				total_payment: totalPayment / 100,
				payment_due_today: amountDue,
			};

			console.log("package",proration);

			setSubscriptionProration(proration);

		}


	}, [selectedPackage, selectedPackgeInterval, couponCode]);

	useEffect(()=>{
		console.log("selectedpackage",selectedPackage);
	},[selectedPackage]);

	return (

		<div>

			<Container fluid={true} className="p-0">
			
				<Row>
					<Col className="pl-0 d-lg-flex d-none ">
						<AuthMessageCard paragraph={
							["Giving is not just about making a ",
								<span style={{ color: "#0054DC" }} key="0">donation</span>,
								", its about making an ",
								<span style={{ color: "#0054DC" }} key="1">impact</span>,
								"."]}
						/>
					</Col>
					<Col className="p-0" >
						<div className="d-flex flex-column justify-content-start h-100 subscription-container p-2" style={{maxWidth:"98vw"}}>
							
							{
								isPlanSelected ? (
									<>
										<div className="stepProgressbarContainer"  >
											<StepProgressBar step={3}/>
										</div>
										<h3 className="h2 mb-4">Payment Details</h3>
										<StripeCard
											pricingId={selectedPackage[selectedPackgeInterval.package_key].pricing_id}
											packageId={selectedPackage.id}
											coupon={couponCode.code}
										/>
										<div className="d-flex align-self-end">
											<Button
												isHollow={true}
												id="payment-info-change-plan-button"
												style={{ width: "100%", marginTop: "20px" }}
												title="Change Plan"
												fontStyle="roboto-b3"
												onClick={() => setIsPlanSelected(false)}
											/>
										</div>
									</>
								) : (
									<>
										<div className="stepProgressbarContainer" style={{maxWidth:"90vw"}}>
											<StepProgressBar step={2}/>
										</div>
										<h3 className="h2">Confirm Plan</h3>
										<div style={{ width: "230px" }}>
											<SegmentToggle
												disabled={processing}
												options={segmentOptions}
												selected={selectedPackgeInterval}
												id="subscription-plan-toggler"
												onSwitch={(option => {
													if (localStorage.getItem("package-interval")) {
														localStorage.removeItem("package-interval");
													}
													setSelectedPackgeInterval(option);
												})}
											/>
											<p className="b4 text-right mt-2" style={{ color: "#0054DC" }}>Save 25% Annually</p>
										</div>
										<div className="subscription-detail-card">
											<SubscriptionPlanCard
												
												processing={processing}
												packageId={subscriptionProration?.package_id}
												packageName={subscriptionProration?.package_name}
												packagePrice={subscriptionProration?.package_price / 100}
												totalAmount={subscriptionProration.total_payment}
												amountDue={subscriptionProration?.payment_due_today}
												packageInterval={subscriptionProration?.package_interval}
												// nextPaymentAt={
												// 	subscriptionProration?.subscription.status === SubscriptionStatus.status.TRIALING ? (
												// 		subscriptionProration?.subscription.trial_end
												// 	) : (
												// 		() => {
												// 			const date = new Date();
												// 			const nextMonth = new Date(date.setMonth(date.getMonth() + 1));
												// 			parseInt((nextMonth.getTime() / 1000).toFixed(0));
												// 		}
												// 	)
												// }
												// selectedPackage={selectedPackage}
												onChangePlanClick={() => setIsModalActive(true)}
												// couponCode={couponCode}
												onCouponApplied={(coupon) => { setCouponCode(coupon); }}
											/>
										</div>

										<div style={{ width: "100%", marginTop: "36px" }}>
											<Button title="Confirm" id="subscription-plan-confirmation-button" style={{ width: "100%", height: "40px" }}
												onClick={() => setIsPlanSelected(true)}
											/>
										</div>
										{alert}
									</>
								)
							}
						</div>

					</Col>
				</Row>
			</Container>

			{
				isModalActive && (
					<SubscriptionPackageModal
						allowCustomPackage
						onClose={() => setIsModalActive(false)}
						confirmedPackage={selectedPackage}
						onConfirm={(pkg) => {
							if (pkg.id === 3) {
								setIsModalActive(false);
								setIsContactUsModalOpen(true);
							}
							else {
								console.log("pakages if not 3---------",pkg);
								setSelectedPackage(pkg);
								setIsModalActive(false);
								if (localStorage.getItem("selected-package")) {
									localStorage.removeItem("selected-package");
								}
							}
						}}
					/>
				)
			}
			{
				isContactUsModalOpen &&
				<ContactUsFrom contactUsModalStatus={isContactUsModalOpen} onClose={() => { setIsContactUsModalOpen(false); }} onDone={() => { setIsContactUsModalOpen(false); removeAllCookies(); window.location.reload(); }} />
			}

		</div>

	);

}