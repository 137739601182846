import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Button from "../components/sub-components/Button";
import { ReactComponent as AddCampaignDefault } from "../assets/icons/basic/default-image.svg";


export default function Gallery(props) {

	const [property, setProperty] = useState({
		limit: 8,
		total: (props.galleryImages && props.galleryImages.length) || 0
	});

	const getPureImageURL = (image) => {
		// &#x2F;
		const img = image?.replaceAll("&#x2F;", "/");
		return img;
	};
	const renderImages = (file, index) => {
		if(parseInt(props.templateId)===1){
			if (index + 1 <= property.limit) {
				return (
					<Col className="imageGallerycontainerTemplate1 " key={index} lg={3} xs={6}>
						<img className="imageGallery" alt="Not Found" src={getPureImageURL(file.image)} />
					</Col>
				);
			}
		}
		else if(parseInt(props.templateId)===2){
			if (index + 1 <= property.limit) {
				return (
					<Col className="imageGallerycontainerTemplate2" key={index} lg={3} xs={6}>
						<img className={`imageGallery image${index}`} alt="Not Found" src={getPureImageURL(file.image)} />
					</Col>
				);
			}
		}

		
	};

	const increaseLimit = () => {
		setProperty({ ...property, limit: property.limit + 4 });
	};

	const decreseLimit = () => {
		if (property.limit !== 8) {
			setProperty({ ...property, limit: property.limit - 4 });
		}

	};

	return (

		<Fragment>
			{
				(props.galleryImages && props.galleryImages.length > 0)
					?
					<div className="gallery" id="gallery">
						<div className="heading1 heading-box" >
							<span className="cs-hero3" style={{ color: `${props.primaryColor}` }}>{props.galleryTitle}</span>
						</div>
						<Row className="no-gutters gallery-images-container">
							{props.galleryImages &&
								props.galleryImages.map((image, index) => {
									return renderImages(image, index);
								})}
						</Row>
						<Container className="show-buttons">
							<Row>
								<Col lg="6" xs="5">
									{
										props.galleryImages.length > 8 &&
										<Button whiteButton title="Show More" style={{ width: "200px" }} onClick={() => { increaseLimit(); }} />
									}
								</Col>
								<Col lg="6" xs="5">
									{
										props.galleryImages.length > 8 &&
										<Button whiteButton title="Show Less" style={{ width: "200px" }} onClick={() => { decreseLimit(); }} />
									}
								</Col>
							</Row>
						</Container>

					</div>
					:
					<Fragment>
						{
							props.isInEditingInProcess &&
							<div className="gallery" id="gallery">
								<div className="heading1 heading-box" >
									<span className="cs-hero3" style={{ color: `${props.primaryColor}` }}>{props.galleryTitle}</span>
								</div>
								<Row className="no-gutters gallery-images-container">
									{
										Array.from({ length: 8 }).map((_, index) => {
											return (
												<Col className="imageGallerycontainer" key={index} lg={3} xs={6}>
													{/* <img className="imageGallery default-image" alt="Not Found"
														style={{ mask: `url(${AddCampaignDefault}) no-repeat 50% 50%` }}
														src={AddCampaignDefault}
													/> */}
													<AddCampaignDefault fill={props.primaryColor} width="324px" height="324px" />
												</Col>
											);
										})
									}
								</Row>

							</div>
						}
					</Fragment>
			}

		</Fragment>
	);
}