/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Button from "../sub-component/Button";
import AlertBox from "../common/AlertBox";
import { convertToLongDate, openURL } from "../../utilities/Utilites";
import { useAuth } from "./../../context/AuthContext";
import CampaignCard from "../dashboard/mid-page/compaign/CampaignCard";
import Input from "../sub-component/Input";
import Select from "../sub-component/Select";
import { GoalForm } from "../../utilities/AppConstant";

export default function Goals(props) {

	const auth = useAuth();
	const [impacts, setImpacts] = useState([]);
	const [alert, setAlert] = useState();
	const [goals, setGoals] = useState([]);
	const [deleteGoalId, setDeleteGoalId] = useState(undefined);
	const [pastGoals, setPastGoals] = useState([]);
	const timer = useRef();


	const [editGoalId, setEditGoalId] = useState();
	const [modalStatuses, setModalStatuses] = useState({
		showCreateImpactModal: false,
		showUpdateImpactModal: false
	});

	const [goal, setGoal] = useState({
		impact_id: null,
		target: null,
		start_date: null,
		end_date: null,
	});

	useEffect(() => {
		console.log("goal", {
			...goal,
			target: Number(goal.target),
			start_date:new Date(goal.start_date).toLocaleDateString("en-US"),
			end_date: new Date(goal.end_date).toLocaleDateString("en-US")
		});
	}, [goal]);

	const skipStep = () => {
		if (props.onClick) {
			props.onClick();
		} else {
			history.push("/dashboard/campaign");
		}
	};

	async function getImpacts() {

		const { result } = await openURL("/v1/impact/", "GET", auth);

		if (result) {

			setImpacts(result.message);
		}

	}

	async function getGoals() {

		const { result } = await openURL("/v1/goal/?with_status=true", "GET", auth);
		var existingGoals = [];
		// if(existingGoals == null) existingGoals = [];
		if (result) {
			result.data.forEach((goal)=>{
				existingGoals.push(goal.impact.factor);
			});
			if (props.shouldNext) {
				localStorage.setItem("goal", JSON.stringify(existingGoals));
			}

			setGoals(result.data);
		}

	}

	async function getPastGoals() {

		const { result } = await openURL("/v1/goal/?with_status=true&past_goals=true", "GET", auth);
		console.log(result.data, "result");
		if (result) {
			setPastGoals(result.data);
		}

	}

	function validateForm() {

		if (!goal.impact_id) {
			return { isValid: false, message: GoalForm.IMPACT_ID };

		} else if (!goal.target || isNaN(goal.target) || goal.target <= 0) {
			return { isValid: false, message: GoalForm.TARGET };

		} else if (!goal.start_date) {
			return { isValid: false, message: GoalForm.START_DATE };

		} else if (!goal.end_date) {
			return { isValid: false, message: GoalForm.END_DATE };

		} else if (new Date(goal.end_date) < new Date(goal.start_date)) {
			return { isValid: false, message: GoalForm.START_DATE_GREATER };

		} else {
			return { isValid: true, message: "" };
		}


	}

	async function submitForm() {
		const validation = validateForm();

		if (!validation.isValid) {
			handleAlert("danger", "Incomplete data", validation.message);
			return;
		}

		goal.target = Number(goal.target);

		goal.start_date = new Date(goal.start_date).toLocaleDateString("en-US");
		goal.end_date = new Date(goal.end_date).toLocaleDateString("en-US");

		const { result, error } = await openURL("/v1/goal/", "POST", auth, goal);

		if (error) {

			handleAlert("danger", "Warning", error.message);
			return;
		}

		handleAlert("success", "Success", "Goal added successfully");

		setGoal({
			impact_id: "",
			target: "",
			start_date: null,
			end_date: null,
		});

		setTimeout(() => {
			setModalStatuses({
				...modalStatuses,
				showCreateImpactModal: false
			});
		}, 1000);

		refreshLists();
	}

	async function updateGoal() {
		goal.target = Number(goal.target);
		goal.start_date = new Date(goal.start_date).toLocaleDateString("en-US");
		goal.end_date = new Date(goal.end_date).toLocaleDateString("en-US");

		const { result, error } = await openURL(`/v1/goal/${editGoalId}`, "PATCH", auth, goal);

		if (error) {
			handleAlert("danger", "Warning", error.message);
			return;
		}

		setEditGoalId(null);
		setGoal({
			impact_id: "",
			target: "",
			start_date: null,
			end_date: null,
		});

		handleAlert("success", "Success", "Goal updated successfully");

		setTimeout(() => {
			setModalStatuses({
				...modalStatuses,
				showUpdateImpactModal: false
			});
		}, 1000);

		refreshLists();
	}

	async function deleteGoal(id) {
		const { error } = await openURL(`/v1/goal/${id}`, "DELETE", auth);

		if (error) {
			handleAlert("danger", "Warning", error.message);
			return;
		}

		setDeleteGoalId(id);

		handleAlert("success", "Success", "Goal deleted successfully");

		setTimeout(() => {
			refreshLists();
		}, 1000);
	}

	function editGoal(goal) {
		// setImpacts([...impacts, goal.impact]);
		setEditGoalId(goal.id);
		setGoal({ impact_id: goal.impact_id, target: Number(goal.target), start_date: new Date(goal.start_date), end_date: new Date(goal.end_date) });
	}

	function handleAlert(color, title, message) {

		clearTimeout(timer.current);

		setAlert(
			<AlertBox color={color} title={title} description={message} />
		);

		timer.current = setTimeout(() => {
			setAlert(null);
		}, 1000);

	}

	const isMobile = useMediaQuery({
		query: "(max-device-width: 767px)"
	});




	function refreshLists() {
		getImpacts();
		getGoals();
		getPastGoals();
	}

	useEffect(() => {
		refreshLists();
	}, []);


	const ModalComp = (type) => {
		return (

			<Modal isOpen={true} toggle={() => {
				type === "create" ?
					setModalStatuses({ ...modalStatuses, showCreateImpactModal: false }) :
					setModalStatuses({ ...modalStatuses, showUpdateImpactModal: false });
			}} >
				<ModalHeader toggle={() => {
					type === "create" ?
						setModalStatuses({ ...modalStatuses, showCreateImpactModal: false }) :
						setModalStatuses({ ...modalStatuses, showUpdateImpactModal: false });
				}}>
					<div className="w-100 d-flex justify-content-center">
						{
							type === "create"
								?
								<span className="h2" style={{ fontWeight: "700" }} >Create New Impact Objective</span>
								:
								<span className="h2" style={{ fontWeight: "700" }}>Update Impact Objective</span>
						}
					</div>
				</ModalHeader>
				<ModalBody>
					<div>
						<div className="w-100">
							<div style={{ height: "80px" }}>
								<Select
									// varient="customizeSelect"
									selectionWithKey
									style={{ marginTop: "15px" }}
									title="Select Impact Statement"
									options={impacts ? (
										impacts.map(impact => (impact.factor))
									) : (
										[]
									)}
									selected={impacts?.filter((impact) => { return (impact?.id === goal?.impact_id); })[0]?.factor}
									placeholder={"Select Impact Statement"}
									onSelect={(state) => { setGoal({ ...goal, "impact_id": impacts[state.id].id }); }}
									id="select-impact-statement"
								/>
							</div>
							<div className="w-100 d-flex justify-content-between flex-column flex-md-row" style={{ marginTop: "20px" }}>
								<div className="form-date-picker d-flex flex-column align-items-start mb-4 mb-md-0" >
									<span className="b3" style={{ marginBottom: "10px" }}>Start Date</span>
									<div style={{ position: "relative", width: "100%" }}>
										<DatePicker
											selected={goal.start_date ? new Date(goal.start_date) : goal.start_date}
											onChange={date => setGoal({ ...goal, start_date: date})}
											placeholderText="MM-DD-YYYY"
											minDate={new Date()}
											className="date-picker-table w-100 calender-icon"
											dateFormat="MM-dd-yyyy"
											id="impact-start-date-picker"
										/>
									</div>
								</div>
								<div className="form-date-picker d-flex flex-column align-items-start" >
									<span className="b3" style={{ marginBottom: "10px" }}>End Date</span>
									<div style={{ position: "relative", width: "100%" }}>
										<DatePicker
											disabled={!goal.start_date}
											selected={goal.end_date ? new Date(goal.end_date) : goal.end_date}
											onChange={date => setGoal({ ...goal, end_date: date })}
											placeholderText="MM-DD-YYYY"
											minDate={goal.start_date || new Date()}
											className="date-picker-table w-100 calender-icon"
											dateFormat="MM-dd-yyyy"
											id="impact-end-date-picker"
										/>
									</div>

								</div>
							</div>
							<div className="goal-input">
								<Input
									style={{ marginTop: "20px", width: "100%" }}
									label="Goal"
									type="number"
									name="target"
									onChange={(event) => {
										if (Number(event.target.value) > Number(event.target.max)) {
											return;
										}
										setGoal({ ...goal, target: +event.target.value });
									}}
									value={goal.target}
									max="10000000"
									min="0"
									placeholder="eg. 100"
									onKeyPress={(e) => isNaN(e.key) && e.preventDefault()}
									id="goal-input"
								/>
							</div>

						</div>

					</div>
				</ModalBody>
				<ModalFooter className="d-flex flex-column justify-content-center " style={{ border: "none" }}>
					<Button
						title={type === "create" ? "Add Impact Objective" : "Update Impact Objective"}
						className="b2"
						onClick={() => { type === "create" ? submitForm() : updateGoal(); }}
						id="create-or-update-impact-modal"
						style={{ width: "max-content", minHeight: "30px", borderRadius: "28px", backgroundColor: "#0054DC", padding: "10px 100px", maxWidth: "100%" }}
					/>
					{	(modalStatuses.showCreateImpactModal || modalStatuses.showUpdateImpactModal) &&
							alert
					}
					
					<span></span>
				</ModalFooter>
			</Modal>
			
		);
	};

	function getTotalImpact(goal) {
		if (!goal) {
			return {};
		}
		const factorAmount = goal.charity_impact.factor_amount;

		const campaignImpacts = goal.impact.campaigns.map((campaign) => {
			const donations = campaign.donations.reduce((total, donation) => {
				if (
					Date.parse(donation.date) >= Date.parse(goal.start_date) &&
					Date.parse(donation.date) <= Date.parse(goal.end_date)
				) {
					return total + Number(donation.amount);
				} else {
					return total;
				}
			}, 0);

			return donations / factorAmount;
		});

		const totalImpact = campaignImpacts.reduce((total, campaignImpact) => total + campaignImpact, 0);

		return {
			// ImpactObjective: goal.impact.name,
			impact_factor: goal.impact.factor,
			objective_period: `${convertToLongDate(goal.start_date.split("T")[0])} To ${convertToLongDate(goal.end_date.split("T")[0])}`,
			achievedTarget: ((totalImpact / goal.target) * 100).toFixed(1).replace(/[.,]0$/, ""),
			achieved: totalImpact,
			total: goal.target,
			suffix: goal.impact.suffix,
		};

	}

	const history = useHistory();

	return (

		<div className="onbording-impact-objective-form d-flex flex-column align-items-center">

			{
				modalStatuses.showCreateImpactModal &&
				ModalComp("create")
			}
			{
				modalStatuses.showUpdateImpactModal &&
				ModalComp("update")
			}

			<div className="w-100">
				{
					props.isLabel &&
					<span style={{ textAlign: "center" }} className="s1">Impact Objectives</span>
				}

				{
					props.hasHeaderLine
						?
						<Fragment>
							{props.hasHeaderLine}
						</Fragment>
						:
						<p className="b3" style={{ marginTop: "20px", fontSize: "121dp", textAlign: "left" }}>
							Setting up your impact objectives before the donation campaign will help us understand your goals and we will run a campaign according to your defined objectives.
						</p>
				}

				<div className={`${!props.hasHeaderLine && "mt-5"} form-table`}>
					<div className="w-100 d-flex flex-column" id="active-objectives-container">
						<div className="w-100 d-flex justify-content-between" style={{ marginBottom: "20px" }}>
							<span className="cloud-burst-color" style={{ fontWeight: "700" }}>Active Objectives</span>
							{(auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14) && 
							<Button
								style={{ padding: "5px 28px" }}
								className="b4"
								title="Create New Impact Objective"
								onClick={() => setModalStatuses({ ...modalStatuses, showCreateImpactModal: true })} />}
							
						</div>

						{
							goals.length > 0
								?
								goals.map((goal, index) => {
									const refinedGoal = getTotalImpact(goal);
									// console.log(goal,"Goal")
									return (
										<Fragment key={index}>
											<CampaignCard
												style={{ marginTop: "20px" }}
												title={goal.impact.factor}
												subTitle={refinedGoal.objective_period}
												goal={`${refinedGoal.total} ${refinedGoal.suffix}`}
												showProgressBar
												suffix={refinedGoal.suffix}
												completed={refinedGoal.achieved}
												target={`${refinedGoal.total}`}
												completedInPercentage={refinedGoal.achieved / refinedGoal.total * 100}
												isViewEnable
												isEditEnable = {auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14}
												onView={goal ? () => history.push(`impact_and_objective/id=${goal.id}`) : () => history.push(`goals/list`)}
												deleteButtonLable={"Delete Goal"}
												onEdit={() => { setModalStatuses({ ...modalStatuses, showUpdateImpactModal: true }); editGoal(goal); }}
												isDeleteEnable = {auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14}
												onDelete={() => { deleteGoal(goal.id); }}
												key={index}
											/>
											{
												deleteGoalId === goal.id &&
												alert
											}									
										</Fragment>

									);
								})
								:
								<span className="w-100 text-center">
									No Active Objectives
								</span>
						}
						
					</div>


					{
						props.hasHeaderLine &&
						<div className="w-100 d-flex flex-column" id="past-objectives-container">
							<div className="w-100 d-flex justify-content-between" style={{ marginBottom: "20px", marginTop: "40px" }}>
								<span className="cloud-burst-color" style={{ fontWeight: "700" }}>Past Objectives</span>
							</div>

							{
								pastGoals.length
									?
									pastGoals.map((goal, index) => {
										const refinedGoal = getTotalImpact(goal);
										return (
											<CampaignCard
												style={{ marginTop: "20px" }}
												title={goal.impact.factor}
												subTitle={refinedGoal.objective_period}
												goal={`${refinedGoal.total} ${refinedGoal.suffix}`}
												showProgressBar
												suffix={refinedGoal.suffix}
												completed={refinedGoal.achieved}
												target={`${refinedGoal.total}`}
												completedInPercentage={refinedGoal.achieved / refinedGoal.total}
												isViewEnable
												onView={() => history.push(`/dashboard/goals/impact_and_objective/id=${goal.id}`)}
												key={index}
											/>
										);
									})
									:
									<span className="w-100 text-center">
										No Past Objectives
									</span>
							}
						</div>
					}

					<div style={{ marginTop: "50px" }} className=" d-flex justify-content-center">
						{
							props.shouldNext
							&&
							<Fragment>
								{
									goals.length > 0 ? (
										<Button onClick={props.onClick} style={{ marginLeft: "10px" }} title={"Next"} leftArrow id="new-user-create-impact-next-button" />
									) : (
										<Button onClick={() => skipStep()} style={{ marginRight: "10px" }} isHollow title={"Skip"} id="new-user-create-impact-skip-button" />
									)
								}
							</Fragment>
						}
					</div>

				</div>
			</div>
		</div>
	);
}
