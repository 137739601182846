import React, { useState, useEffect, useRef, useMemo } from "react";
// import { useAuth } from "../../../builder/context/AuthContext";
import SearchAndSelectCharity from "../../../components/search-and-select-charity/SearchAndSelectCharity";
import { getUnauthorizedURL } from "../../../utilities/Utilites";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";

export default function MapWithCharities() {
	const articleRef = useRef();
	const [charities, setCharities] = useState([]);
	const [isCharitiesLoading, setIsCharitiesLoading] = useState(true);

	const getCharities = async () => {

		const { result } = await getUnauthorizedURL(`/v1/charities/`, { id: null, tenant_id: null });
		if (result) {
			setCharities(result.data);
		}
	};

	useEffect(() => {
		getCharities();
	}, []);

	useMemo(() => {
		if (charities.length > 1) {
			setIsCharitiesLoading(false);
		}
	}, [charities]);

	return (
		<Navbar>

			<Helmet>
				<title>Charities We Work with - Altrunic</title>
				<meta name="description" content="Navigate through millions of hand-picked charities to select your most suitable nearest charity with its impact information and star rating. Start your first giving campaign now!" />
			</Helmet>

			<div className="d-flex flex-column">
				<div className="charities-page-container">
					<div className="cpc-heading">
						<span className="ls-h4" >Charities We Work With</span>
						<span className="ls-b0-5 hoki-color" style={{ margin: "25px 0px" }}>Navigate through millions of hand picked charities to select your most suitable nearest charity with its impact information and star rating</span>
					</div>
					<div className="charities-page-maps-container">
						{
							isCharitiesLoading ?

								<div className="w-100 d-flex justify-content-center align-items-center" style={{ height: "95px" }}>
									<span className="busy-indicator-blue " style={{ height: "20px", width: "20px" }}></span>
								</div>
								:
								<>
									{
										charities?.length > 0 &&
										<SearchAndSelectCharity
											loaderStatus={isCharitiesLoading}
											charities={charities}
											selectedCharity={null}
											getSelectedCharites={() => { }}
											ref={articleRef}
										/>

									}
								</>

						}
					</div>
				</div>
				<Footer />

			</div >
		</Navbar>
	);
}
