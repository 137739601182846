import React from "react";
import { ReactComponent as AddCampaignDefault } from "../assets/icons/Visual Builder/vb-add-campaign.svg";
import { ReactComponent as AddButton } from "../assets/icons/Visual Builder/circular-add.svg";
import { useHistory } from "react-router";

export default function VBAddCampaign(props) {

	const history = useHistory();

	return (
		<div className="cs-campaign-box-container" style={{ width: "368px" }}>
			<div className="campaign-card-vertical-image" style={{ height: "218px" }}>
				<AddCampaignDefault style={{ fill: `${props.color}` }} width="368px" height="218px" />
			</div>
			<div className="campaign-vertical-card-details-container d-flex flex-column align-items-center justify-content-start" style={{ marginTop: "-1px", height: "432px", border: `1px solid ${props.color}` }}>
				<span className="heading2" style={{ marginTop: "30px" }}>Add Campaign</span>
				<AddButton
					onClick={() => { history.push("dashboard/campaigns/create"); }}
					style={{ fill: `${props.color}`, alignSelf: "center", marginTop: "30%" }} />
			</div>
		</div>
	);
}