import React from "react";
import ImageButton from "../../ImageButton";

import SettlementIcon from "../../../../assets/icons/Genral/Settlement.svg";
import FlagIcon from "../../../../assets/icons/impact-flag.svg";
import { useHistory } from "react-router";
import { useSettlementContext } from "../../../../context/SettlementContext";
import { useAuth } from "../../../../context/AuthContext";

export default function FinishedCampaignCard(props) {

	const auth = useAuth();
	const settlement = useSettlementContext();
	const history = useHistory();

	if (props.campaignName) {

		return (
			<div className={"d-flex flex-column justify-content-between pointer finished-campaign-card " + props.className}
				style={props.hasBorder ? { borderTop: `1px solid ${props.cardColor}`, ...props.style } : { ...props.style }} onClick={props.onClick}>
				<div onClick={() => history.push(`campaigns/id=${props.campaignId}`)} >
					<div>
						<p className="b3 mb-0">{props.campaignName || "Campaign Name"}</p>
					</div>
					<div className="mt-2">
						<p className="roboto-b3 navy-blue-color">{props.charityName || "Campaign Name"}</p>
					</div>
					<div className="roboto-b3 d-flex flex-column" style={{ color: "#5E7488" }}>
						<span>
							Start Date: {props.startDate || "Unavailable"}
						</span>
						<span className="pt-2">
							End Date: {props.endDate || "Unavailable"}
						</span>
					</div>
				</div>
				<div className="w-100 d-flex" style={{ marginTop: "10px" }}>
					<img width="20px" height="20px" style={{ marginTop: "3px" }} src={FlagIcon} />
					<span className="b3" style={{ marginLeft: "10px", fontSize: "0.9rem" }}>{`$${props.donations || 0} donations received by ${props.participants || 0} participants`}</span>
				</div>
				{/* <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100 square-button-container"> */}
				<ImageButton
					style={{
						marginTop: "10px",
						backgroundColor: (props.settlementAmount <= 0 || props.isAmountSettled) ? "#CE8DFF" : "#7ED0FF",
						outline: "none",
						cursor: (props.settlementAmount <= 0 || props.isAmountSettled) && "not-allowed"
					}}
					//disabled={(props.settlementAmount <= 0 || props.isAmountSettled)}
				
					disabled={(props.settlementAmount <= 0 || auth.userProfile.role_assignment.id === 15 || auth.userProfile.role_assignment.id === 14 || props.isAmountSettled) }
					className="align-self-center"
					squareButton
					title={"$" + Math.round(isNaN(props.settlementAmount) ? 0 : props.settlementAmount)}
					description={(props.settlementAmount <= 0 || props.isAmountSettled) ? "Amount settled" : "Settlement Amount"}
					image={SettlementIcon}
					onClick={() => {
						// console.log(props.settlementAmount, props.campaignId, "FLAG FLAG");
						settlement.setSettlementDetails({
							settlementAmount: props.settlementAmount,
							campaignId: Number(props.campaignId)
						});
						settlement.setIsSettlingAmount(true);
					}}
				/>

				{/* </div> */}

			</div>
		);
	} else {
		return <>No completed campaigns</>;
	}

}