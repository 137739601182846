import React, { createContext, useContext, useEffect, useState } from "react";
import DonationCardDetails from "../components/DonationCardDetails";
import DonationLoginCard from "../components/DonationLoginCard";
import DonationTypeCard from "../components/DonationTypeCard";
import GuestLoginCard from "../components/GuestLoginCard";
import DonationDoneCard from "../components/DonationDoneCard";
// import baseURL from "../utilities/BaseURL";
import { useAuth } from "./AuthContext";
import DonationSignupCard from "../components/DonationSignupCard";
// import { getUnauthorizedURL } from "../utilities/Utilites";
import baseURL from "../utilities/BaseURL";

const DonationContext = createContext();

export function useDonation() {
	return useContext(DonationContext);
}


export default function DonationProvider({ children, primaryColor, tenantId }) {

	const auth = useAuth();
	const [donationDetails, setDonationDetails] = useState({
		donation_amount: null,
		campaign_id: null
	});

	const [canGuestDonate, setCanGuestDonate] = useState(false);
	const [donatingAs, setDonatingAs] = useState(null);
	const [guestToken, setGuestToken] = useState(null);
	const [donationSuccess, setDonationSuccess] = useState();
	const [isSigningUp, setIsSigningUp] = useState(false);

	const handleDonatingAs = async (type = "Employee") => {

		setDonatingAs(type);

	};

	const clearDonation = () => {
		setDonationDetails({
			donation_amount: null,
			campaign_id: null
		});
		setDonatingAs(null);
	};

	useEffect(() => {
		(async () => {

			const response = await fetch(`${baseURL}/v1/charity_site_guest/?tenant_id=${tenantId}`);

			if (response.ok) {

				const result = await response.json();
				setCanGuestDonate(result.data.can_guest_donate);

			}

		})();
	}, [tenantId]);

	return (
		<DonationContext.Provider value={{ setDonationDetails, donationDetails, handleDonatingAs, clearDonation, setGuestToken, donatingAs, setDonationSuccess, setIsSigningUp }}>
			{
				donationDetails.donation_amount && (
					<div className="donation-modal-wrap">
						{
							donationSuccess ? (
								<DonationDoneCard />
							) : ((auth && auth.access_token) || guestToken) ? (
								<DonationCardDetails primaryColor={primaryColor} />
							) : (
								(donatingAs || !canGuestDonate) ? (
									donatingAs === "Guest" ? (
										<GuestLoginCard primaryColor={primaryColor} tenantId={tenantId} />
									) : (
										<>
											{
												isSigningUp ? (
													<DonationSignupCard primaryColor={primaryColor} />
												) : (
													<DonationLoginCard primaryColor={primaryColor} />
												)
											}
										</>
									)
								) : (
									<DonationTypeCard primaryColor={primaryColor} />
								)
							)
						}
					</div>
				)
			}
			{
				children
			}
		</ DonationContext.Provider>
	);

}