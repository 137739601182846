import React from "react";
import PriceCardContainer from "../../../components/landing-page/PriceCardContainer";

export default function Section6() {



	return (
		<div className="section6">
			<div className="card-section">
				{/* {}
				<div className="card-section-header">
					<span className="s1">Altrunic is free forever</span>
					<span className="b2 hoki-color" style={{ marginTop: "15px" }}>We believe that doing social good is not just for large enterprises. Our core offering is free for anyone and will always stay like that.</span>
				</div> */}
				<PriceCardContainer isOnLandingSite />
			</div>
		</div>
	);
}