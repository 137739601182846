import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Section6 from "../Section6";
import brand_logos from "../../../../assets/icons/landing page 2/Logos.svg";
import check from "../../../../assets/icons/basic/check_icon.svg";
import arrow_up from "../../../../assets/icons/basic/arrow_up.svg";
import arrow_down from "../../../../assets/icons/basic/arrow_down.svg";
import { Helmet } from "react-helmet";


export default function PricingPage() {

	const impactFeatures = [
		{
			name: "Unlimited Campaigns", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "Impact Objectives", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "Unlimited Donors", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "Checkout Giving", subtitle: "(Coming soon)", basic: check, starter: check, professional: check, enterprise: check
		}
		,];

	const customization = [
		{
			name: "Branded Website", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "Remove Altrunic Branding", basic: null, starter: null, professional: check, enterprise: check
		},
		{
			name: "Multiple Websites", basic: null, starter: null, professional: null, enterprise: check
		}
		,];

	const support = [
		{
			name: "Basic Support", basic: check, starter: check, professional: check, enterprise: check
		},
		{
			name: "Dedicated Impact Consulting", basic: null, starter: null, professional: null, enterprise: check
		}
		,];

	const administration = [
		{
			name: "User Management", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "Reporting", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "Company Profile", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "Team Creation", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "More than 20 Employees", basic: null, starter: check, professional: check, enterprise: check
		}
		,];

	const otherFeatures = [
		{
			name: "Leaderboards", subtitle: "(Coming soon)", basic: check, starter: check, professional: check, enterprise: check
		}
		, {
			name: "Personalized Recommendations", basic: null, starter: null, professional: check, enterprise: check
		}
		,];

	const faq_list = [
		{ id: 1, question: "Q1. Will a Starter Plan be suitable for my startup business?", answer: "Altrunic is committed to serve society by helping small businesses to make efforts for Social Giving. We are providing necessary features in our basic plan to make a difference." },
		{ id: 2, question: "Q2. How many campaigns can I run in one Plan?", answer: "There is no limit to run donation campaigns in any plan, because we want you to take part in Giving Back as much as you can and ultimately, it will help your business grow." },
		{ id: 3, question: "Q3. How many websites can I make in a Starter Plan?", answer: "Starter Plan is limited for only one charity website and the website will be hosted by “altrunic.com” subdomain. If you want to remove Altrunic branding, you can always upgrade your plan anytime." },
		{ id: 4, question: "Q4. What do you mean by Measurable Impact?", answer: "Altrunic is the platform which provides the organizations with the Measurable social impact that means the donation campaigns effect will be translated into individual level entities so that donors should be transparent of their donations." },
		{ id: 5, question: "Q5. What are your payment methods for Donations?", answer: "A donor will have multiple options to donate which includes his Stripe account, Paypal and credit/debit cards. We are working on adding other payment methods too like Google Pay, Apple Pay." },
		{ id: 6, question: "Q6. How can I be sure my donations deliver successfully?", answer: "That’s where Altrunic stands upon the other platforms, you can see the effect of every campaign or even Impact objectives on Admin Dashboard which shows you the progress of the campaigns as well the impact being generated." },
		{ id: 7, question: "Q7. We are a large company, which plan suits us?", answer: "We recommend going for an Enterprise plan for large businesses as you can avail multiple charity websites as well as dedicated impact. However, you are always welcome to test by starting a 30 days Free Trial." },
		{ id: 8, question: "Q8. How can I make Customizations on my charity website?", answer: "Altrunic provides “Visual Builder” feature on every plan for the users to customize Charity websites as best as they can. We also have a default template ready for the users who aren’t familiar with drag and drop features." },
		{ id: 9, question: "Q9. What is a Donation Matching feature?", answer: "Donation Matching is an advanced feature for organizations to take part in donor’s donation campaigns. By enabling this feature, for every donation percentage of amount will be contributed by the company itself." },
		{ id: 10, question: "Q10. Do you have a support team in case of any help?", answer: "Altrunic believes in customer satisfaction to their best, that’s where our Product success department comes in, you can raise a support ticket anytime, anywhere. Our customer success team will get back to you ASAP. Besides, you can visit our Help Centre to have answers to many questions." }
	];

	const [selectedFaq, setSelectedFaq] = useState(0);


	function handleFaqChange(index) {
		if (selectedFaq) {
			if (selectedFaq.id === index + 1) {
				setSelectedFaq(0);
			}
			else {
				setSelectedFaq(faq_list[index]);
			}
		}
		else {
			setSelectedFaq(faq_list[index]);
		}
	}


	return (
		<Navbar>

			<Helmet>
				<title>Find the right pricing package for your business with Altrunic</title>
				<meta name="description" content="Whether you’re a startup or a large enterprise, we’ve got you covered with the best pricing plans" />
			</Helmet>

			<div className="d-flex flex-column">
				<div className="d-flex flex-column align-items-center" style={{ textAlign: "center", paddingTop: "75px" }}>
					<span className="ls-h4 nero-color pricing-page-title-mobile" style={{ width: "680px", paddingBottom: "25px" }}>Choose the Plan that’s Right for You</span>
					<span className="ls-b0-5 hoki-color pricing-page-descripition-mobile" style={{ width: "680px" }}>Whether you’re a startup or a large enterprise, we’ve got you covered</span>
					<div>
						<Section6 />
					</div>

					<div className="brand-logos pricing-brands-mobile d-flex align-items-center flex-column" style={{ paddingTop: "50px", width: "100vw", borderRadius: "110px 110px 0 0", backgroundColor: "#F4F9FD" }}>
						<span className="ls-h4 logos-text">You are not alone. Altrunic is Trusted by</span>
						<img src={brand_logos} style={{ width: "70%", paddingTop: "30px", paddingBottom: "50px" }} />
					</div>

				</div>

				<div className="pricing-block">
					<span className="ls-h4 white-color">Full Plan Comparison</span>

					<div className="prices-heading">
						<div className="" style={{ width: "50%", textAlign: "left" }}>
							<span className="ls-h3 white-color feature-heading">Features</span>
						</div>

						<div className="d-flex justify-content-around algin-items-center" style={{ width: "50%" }}>
							<div className="h-100 d-flex flex-column justify-content-center align-items-center " style={{ width: "20%" }}>
								<span className="ls-h6	 white-color col-heading" >Basic</span>
								<span className="ls-b0 white-color col-price">$99/mo</span>
							</div>
							<div className="h-100 d-flex flex-column justify-content-center align-items-center " style={{ width: "20%" }}>
								<span className="ls-h6	 white-color col-heading" >Starter</span>
								<span className="ls-b0 white-color col-price">$249/mo</span>
							</div>
							<div className="h-100 d-flex flex-column justify-content-center align-items-center" style={{ width: "20%" }}>
								<span className="ls-h6 white-color col-heading" >Professional</span>
								<span className="ls-b0 white-color col-price">$399/mo</span>
							</div>
							<div className="h-100 d-flex flex-column justify-content-center align-items-center" style={{ width: "20%" }}>
								<span className="ls-h6 white-color col-heading" >Enterprise</span>
								<span className="ls-b0 white-color col-price">Custom</span>
							</div>
						</div>

					</div>

					<div className="d-flex flex-column w-100" style={{ textAlign: "left", marginTop: "50px" }}>
						<span className="ls-h4 white-color feature-title" style={{ paddingBottom: "25px", paddingLeft: "10px" }}>Impact Features</span>

						{
							impactFeatures.map((item, index) => {
								return (
									<div className="d-flex flex-row w-100" key={index}>
										<div className="name-row">
											<span className="ls-s0 white-color name-row-title" style={{ fontWeight: "normal" }}>{item.name}<span className="ls-b1 white-color ml-2">{item?.subtitle}</span></span>
										</div>
										<div className="d-flex data-col flex-row  justify-content-around" style={{ width: "50%" }}>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.basic} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.starter} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.professional} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.enterprise} />
											</div>
										</div>
									</div>
								);
							})
						}


					</div>

					<div className="d-flex flex-column w-100" style={{ textAlign: "left", marginTop: "50px" }}>
						<span className="ls-h4 white-color feature-title" style={{ paddingBottom: "25px", paddingLeft: "10px" }}>Customization</span>

						{
							customization.map((item, index) => {
								return (
									<div className="d-flex flex-row w-100" key={index}>
										<div className="name-row">
											<span className="ls-s0 white-color name-row-title" style={{ fontWeight: "normal" }}>{item.name}</span>
										</div>
										<div className="d-flex data-col flex-row  justify-content-around" style={{ width: "50%" }}>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.basic} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.starter} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.professional} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.enterprise} />
											</div>
										</div>
									</div>
								);
							})
						}


					</div>

					<div className="d-flex flex-column w-100" style={{ textAlign: "left", marginTop: "50px" }}>
						<span className="ls-h4 white-color feature-title" style={{ paddingBottom: "25px", paddingLeft: "10px" }}>Support</span>

						{
							support.map((item, index) => {
								return (
									<div className="d-flex flex-row w-100" key={index}>
										<div className="name-row">
											<span className="ls-s0 white-color name-row-title" style={{ fontWeight: "normal" }}>{item.name}</span>
										</div>
										<div className="d-flex data-col flex-row  justify-content-around" style={{ width: "50%" }}>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.basic} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.starter} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.professional} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.enterprise} />
											</div>
										</div>
									</div>
								);
							})
						}


					</div>

					<div className="d-flex flex-column w-100" style={{ textAlign: "left", marginTop: "50px" }}>
						<span className="ls-h4 white-color feature-title" style={{ paddingBottom: "25px", paddingLeft: "10px" }}>Administration</span>

						{
							administration.map((item, index) => {
								return (
									<div className="d-flex flex-row w-100" key={index}>
										<div className="name-row">
											<span className="ls-s0 white-color name-row-title" style={{ fontWeight: "normal" }}>{item.name}</span>
										</div>
										<div className="d-flex data-col flex-row  justify-content-around" style={{ width: "50%" }}>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.basic} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.starter} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.professional} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.enterprise} />
											</div>
										</div>
									</div>
								);
							})
						}


					</div>

					<div className="d-flex flex-column w-100" style={{ textAlign: "left", marginTop: "50px" }}>
						<span className="ls-h4 white-color feature-title" style={{ paddingBottom: "25px", paddingLeft: "10px" }}>Other Features</span>

						{
							otherFeatures.map((item, index) => {
								return (
									<div className="d-flex flex-row w-100" style={{ height: "max-content" }} key={index}>
										<div className="name-row">
											<span className="ls-s0 white-color name-row-title" style={{ fontWeight: "normal" }}>{item.name}<span className="ls-b1 white-color ml-2">{item?.subtitle}</span></span>
										</div>
										<div className="d-flex data-col flex-row  justify-content-around" style={{ width: "50%" }}>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.basic} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.starter} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.professional} />
											</div>
											<div className="d-flex justify-content-center" style={{ width: "20%" }}>
												<img src={item.enterprise} />
											</div>
										</div>
									</div>
								);
							})
						}


					</div>


				</div>
				<span className="ls-h4" style={{ padding: "70px 0px", textAlign: "center" }}>All the Answers You are Looking For</span>

				<div className="faq">
					{
						faq_list.map((faq, index) => {
							if (faq.id === selectedFaq.id)
								return (
									<div className="question-selected" key={index} onClick={() => handleFaqChange(index)}>
										<div className="d-flex justify-content-between w-100">
											<div className=" white-color question-text-mobile ls-s0" style={{ fontWeight: "normal" }}>{faq.question}</div>
											<img src={arrow_up} />
										</div>
										<div className="ls-b1 white-color description-mobile answer-text-mobile" style={{ paddingTop: "30px", paddingLeft: "30px", paddingRight: "75px", lineHeight: "26px", fontWeight: "normal" }}>{faq.answer}</div>
									</div>
								);
							else return (
								<div className="question" key={index} onClick={() => handleFaqChange(index)}>
									<div className="ls-s0 licorice-color question-text-mobile" style={{ fontWeight: "normal" }}>{faq.question}</div>
									<img src={arrow_down} />
								</div>
							);
						})

					}
				</div>

				<div>
					<Footer />
				</div>
			</div>
		</Navbar>
	);
}