import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import cross from "../assets/icons/basic/cross.svg";
import { useSettlementContext } from "../context/SettlementContext";


export default function SettlementCard() {

	const settlement = useSettlementContext();
	const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_PKEY);

	return (
		<div id="popup" className="sattlement-card">
			<img src={cross} className="cross-sign" alt="close" onClick={() => settlement.setIsSettlingAmount(false)}
				style={{height: "20px", width: "20px", border: "1px solid", padding: "3px", borderRadius: "2.5px"}}
			/>
			<span className="s3" style={{ borderBottom: "1px solid #CED5DB", width: "90%", padding: "0 0 10px 0", marginBottom: "10px" }}>Amount Settlement</span>

			<Elements stripe={stripePromise}>
				<CheckoutForm />
			</Elements>

		</div>
	);
}