import React, { useState, Fragment, useEffect } from "react";
import SuggestedCharitiesCard from "../../../components/dashboard/right-side-menu/SuggestedCharitiesCard";
import Line from "../../../assets/icons/Genral/Line.png";
import { ReactComponent as ActiveStatus } from "../../../assets/icons/Genral/Ellipse.svg";
// import SwitchButton from "../../../components/sub-component/SwitchButton";
// import icon from "../../../assets/icons/notifications/icon.svg";
import Input from "../../../components/sub-component/Input";
import Button from "../../../components/sub-component/Button";
import AlertBox from "../../../components/common/AlertBox";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";


import { getUnauthorizedURL, convertToLongDate, openURL } from "../../../utilities/Utilites";
import { useAuth } from "../../../context/AuthContext";
import { useSettlementContext } from "../../../context/SettlementContext";
import SwitchButton from "../../../components/sub-component/SwitchButton";



export default function CampaignRight(props) {

	const auth = useAuth();
	const history = useHistory();
	const settlement = useSettlementContext();

	const [isCampaignFeatured, setIsCampaignFeatured] = useState();

	const [campaignDetails, setCampaignDetails] = useState(
		{
			campaignName: "",//"We will raise $20,000 for orphans",
			isCampaignEnded: false,
			target: "20000",
			minDonation: 20,
			moneyRaised: 18.25,
			matchingRatio: 25,
			settlementAmmount: 9362,
			startDate: "",
			endDate: ""
		}
	);

	const [isEditingStatus, setIsEditingStatus] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [alert, setAlert] = useState(null);
	const [isEndingCampaign, setIsEndingCampaign] = useState(false);
	// const [endCampaignModel,setIsEndingCampaignModel] = useState(isEndingCampaign)

	// var currentDate = `${month}  ${date}, ${year}`;

	// const [isShown, setIsShown] = useState(false);

	const [charities, setCharities] = useState([
		{
			title: "",
			// title: location.state.campaignName,
			description: "",
			details: "",
			charity_impacts: [
				{ impact_statement: "" }
			]
		}
	]);

	const endCampaign = async () => {
		// setCampaignDetails({ ...campaignDetails, isCampaignEnded: true });
		const body = {
			is_amount_settled: (campaignDetails.settlementAmmount === 0),
			is_ended: true,
			end_date: new Date().toISOString().split("T")[0]
		};

		setProcessing(true);
		const { result, error } = await openURL("/v1/campaign/" + props.campaignId, "PATCH", auth, body);
		setProcessing(false);

		if (error) {
			console.log(error);
		} else if (result) {
			setCampaignDetails({ ...campaignDetails, isCampaignEnded: true });
			setIsEditingStatus(false);
			window.location.reload();
		}
	};




	const updateCampOnClick = () => {
	
		if (isEditingStatus) {
			updateCampaign();
		}
		else{
			setIsEditingStatus(!isEditingStatus);
		}
		
	};



	const setEndDate = (date) => {

		setCampaignDetails({ ...campaignDetails, endDate: new Date(date + " GMT").toISOString().split("T")[0] });

	};

	/**
	 * This function is used to get Impacts
	 */
	const updateCampaign = async () => {

		const body = {
			name: campaignDetails.campaignName,
			end_date: campaignDetails.endDate.split("T")[0],
			target: Number(campaignDetails.target),
			min_donate: Number(campaignDetails.minDonation),
			is_featured: campaignDetails.isFeatured
		};
		if(body.target < 100 || body.target > 10000000 || !body.target){
			
			setAlert(
				<AlertBox color="danger" title="Error" description="Target Amount should be greater than 100 and less than 10,000,000" />
			);
			setIsEditingStatus(true);
		}
		else if(!body.min_donate){
			setAlert(
				<AlertBox color="danger" title="Error" description="Please enter valid minimum donation value" />
			);
			setIsEditingStatus(true);
		}
		else if(body.target < body.min_donate ){
			setAlert(
				<AlertBox color="danger" title="Error" description="Minimum donation cann't be less than target amount" />
			);
			setIsEditingStatus(true);
		}
		else{
			setProcessing(true);
			const { result, error } = await openURL("/v1/campaign/" + props.campaignId, "PATCH", auth, body);

			setIsCampaignFeatured(campaignDetails.isFeatured);

			setProcessing(false);

			if (error) {
				setIsEditingStatus(!isEditingStatus);
				setAlert(
					<AlertBox color="danger" title="Error" description={error.message} />
				);
			} else if (result) {
				setIsEditingStatus(!isEditingStatus);
				setAlert(
					<AlertBox color="success" title="Success" description="Campaign updated" />
				);
				setTimeout(() => {
					setAlert(null);
				}, 3000);
			}
		}
		


	};

	const getCampaign = async () => {

		const { result, error } = await getUnauthorizedURL(`/v1/campaign/${props.campaignId}`, { id: auth.user_id, tenant_id: auth.tenant_id });

		if (error) {
			history.push("/dashboard/");
		} else if (result) {

			var totalDonation = 0;

			console.log(result, "====================");

			result.message.donations.map((donation) => {
				totalDonation = totalDonation + Number(donation.amount);
			});

			setCampaignDetails(

				{
					...campaignDetails,
					campaignName: result.message.name,
					isCampaignEnded: result.message.is_ended,
					target: result.message.target,
					minDonation: Number(result.message.min_donate).toFixed(0),
					moneyRaised: totalDonation,
					isContibuting: result.message.match_contribution,
					isAmountSettled: result.message.is_amount_settled,
					matchingRatio: result.message.matching_ratio,
					settlementAmmount: (result.message.matching_ratio * totalDonation) / 100,
					startDate: result.message.start_date.split("T")[0],
					endDate: result.message.end_date,
					isFeatured: result.message.is_featured

					// startDate:convertToLongDate(result.message.start_date.split("T")[0]) ,
					// endDate: convertToLongDate(result.message.end_date.split("T")[0])

				});

			setIsCampaignFeatured(result.message.is_featured);



			setCharities([{
				title: result.message.charity.name,
				logo_url: result.message.charity.logo_url,
				charity_url: result.message.charity.charity_url,
				description: result.message.impact.sdg,
				details: result.message.charity.description,
				charity_impacts: result.message.charity.charity_impacts
			}]);

		}

	};

	// useEffect(() => {
	// 	console.log("Campaign Detail:",campaignDetails);
	// }, [campaignDetails]);

	useEffect(() => {
		if (props.campaignId) {
			getCampaign();
		}

	}, []);




	return (
		<div className="w-100">
			<div className="w-100 d-flex justify-content-between">
				<span className="s3">Settings</span>
				{
					(auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14) && (
						!campaignDetails.isCampaignEnded &&
						<Button
							className="b5"
							id="edit-campaign-button"
							title={isEditingStatus ? "Confirm Changes" : "Edit Campaign"}
							style={{ width: "150px", height: "30px", borderRadius: "15px", backgroundColor: "#007AFF", padding: "10px 15px" }}
							onClick={updateCampOnClick}
							busy={processing}
						/>

					)

				}


			</div>
			{
				alert
			}
			<div style={{ marginTop: "20px" }}>
				<SuggestedCharitiesCard
					charities={charities}
				/>
			</div>
			<div style={{ height: "0px" }}>
				<img src={Line} alt="">
				</img>
			</div>
			<div style={{ marginTop: "25px" }} className="d-flex flex-column align-items-start">
				<span className="b4 nero-color" style={{ fontWeight: "500" }}>Campaign Name</span>
			</div>
			<div style={{ height: "30px", width: "100%", padding: "0px 10px 0px 0px", marginTop: "0",marginBottom:"1.7rem" }}>
				{
					isEditingStatus ? (
						<div style={{ width: "100%", padding: "0", marginTop: "0px" }}>
							<Input inputStyle={{ height: "30px" }} value={campaignDetails.campaignName}
								type="text"
								id="edit-campaign-name-input"
								onChange={(e) => {
									setCampaignDetails({ ...campaignDetails, campaignName: e.target.value });
								}}
							/>
						</div>
					) : (
						<span className="b4">{campaignDetails.campaignName}</span>
					)
				}
			</div>
			<div style={{ marginTop: "10px" }} className="d-flex flex-column align-items-start">
				<span className="b4 nero-color" style={{ fontWeight: "500" }} > Campaign Status</span>
			</div>
			<div className="d-flex align-items-start" style={{ padding: "10px 0px 15px 0px", borderBottom: "1.5px solid #CBE3FF" }}>
				<div style={{ width: "6px", height: "6px", marginRight: "5px" }} >
					<ActiveStatus fill={`${campaignDetails.isCampaignEnded ? "#0054DC" : "#00D69A"}`} />
				</div>
				<div >
					{campaignDetails.isCampaignEnded &&
						campaignDetails.isCampaignEnded
						?
						<span className="b4" style={{ color: "#0054DC" }}>Completed</span>
						:
						<span className="b4" style={{ color: "#00D69A" }}>Active</span>
					}

				</div>
			</div>

			<div style={{ paddingTop: "15px" }} className="d-flex flex-column align-items-start">
				<span className="b4 nero-color" style={{ fontWeight: "500" }}>Target ($)</span>
			</div>
			{
				isEditingStatus
					?
					<div style={{ width: "100%", padding: "0px 10px 0px 0px", marginTop: "0px" }}>
						<Input inputStyle={{ height: "30px" }} value={campaignDetails.target}
							type="number"
							max="10000000"
							min="0"
							id="edit-campaign-target-input"
							onChange={(e) => {
								if (Number(event.target.value) > Number(event.target.max)) {
									return;
								}
								setCampaignDetails({ ...campaignDetails, target: e.target.value });
							}}
							onKeyPress={(e) => isNaN(e.key) && e.preventDefault()}
						/>
					</div>
					:
					<div style={{ height: "30px", width: "100%", padding: "0px 10px 0px 0px", marginTop: "10px" }}>
						<span className="b4">${campaignDetails.target}</span>
					</div>
			}



			<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-start">
				<span className="b4 nero-color" style={{ fontWeight: "500" }}>Minimum Donation ($)</span>
			</div>
			<div style={{ height: "30px", width: "100%", padding: "0px 10px 0px 0px", marginTop: "5px",marginBottom:"1.5rem" }}>
				{
					isEditingStatus ? (
						<div style={{ width: "100%", padding: "0px 10px 0px 0px", marginTop: "0px" }}>
							<Input inputStyle={{ height: "30px" }} value={campaignDetails.minDonation}
								type="number"
								max="9999"
								id="edit-campaign-minimum-donation-input"
								min="0"
								onChange={(e) => {
									if (Number(e.target.value) > Number(e.target.max)) {
										return;
									}
									setCampaignDetails({ ...campaignDetails, minDonation: e.target.value });
								}}
								onKeyPress={(e) => isNaN(e.key) && e.preventDefault()}
							/>
						</div>
					) : (
						<span className="b4">${campaignDetails.minDonation}</span>
					)
				}
			</div>

			<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-start">
				<span className="b4 nero-color" style={{ fontWeight: "500" }}>Featured</span>
			</div>
			<div style={{ height: "auto", width: "100%", padding: "0px 10px 0px 0px", marginTop: "10px" }}>
				{
					isEditingStatus ? (
						<>
							<SwitchButton
								value={campaignDetails.isFeatured}
								id="edit-campaign-featured-switch-button"
								onChange={(state) => {
									setCampaignDetails({ ...campaignDetails, isFeatured: state.value });
								}}
							/>
							{
								!isCampaignFeatured && (
									<ul className="b4" style={{ color: "red", marginLeft: "-20px" }}>
										<li>Featuring this campaign will remove feature status of already featured campaign</li>
									</ul>
								)
							}
						</>
					) : (
						<SwitchButton
							value={campaignDetails.isFeatured}
							isLocked
							lock_icon="heart-fill-icon"
						/>
					)
				}

			</div>

			<div style={{ paddingTop: "15px" }} className="d-flex flex-column align-items-start">
				<span className="b4 nero-color" style={{ fontWeight: "500" }}>Money Raised</span>
			</div>
			<div className="d-flex align-items-start" style={{ padding: "10px 0px 15px 0px", borderBottom: "1.5px solid #CBE3FF" }}>
				<span className="b4">${campaignDetails.moneyRaised}</span>
			</div>

			{
				campaignDetails.isContibuting && (

					<Fragment>
						<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-start">
							<span className="b4 nero-color" style={{ fontWeight: "500" }} > Matching</span>
						</div>
						<div className="d-flex" style={{ height: "30px", width: "100%", padding: "0px 10px 0px 0px", marginTop: "10px" }}>
							<div style={{ width: "6px", height: "6px", marginRight: "5px" }} >
								<ActiveStatus fill={"#00D69A"} />
							</div>
							<div >
								<span className="b4" style={{ color: "#00D69A", marginRight: "5px" }}>On</span>
								<span className="b4"> @{campaignDetails.matchingRatio}%</span>
							</div>
						</div>

						<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-start">
							<span className="b4 nero-color" style={{ fontWeight: "500" }}>Settlement Amount</span>
						</div>
						<div className="w-100 d-flex justify-content-between align-items-center" style={{ padding: "10px 0px 15px 0px", borderBottom: "1.5px solid #CBE3FF" }}>
							<div
								className="d-flex align-items-start">
								<span className="b4">${campaignDetails.settlementAmmount.toFixed(2)}</span>
							</div>

							{
								(campaignDetails.isCampaignEnded && !campaignDetails.isAmountSettled) ? (
									<Button
										isHollow
										className="b4"
										title="Settle Amount"
										style={{ width: "max-content", height: "23px", borderRadius: "10px", padding: "10px 15px" }}
										onClick={() => {
											settlement.setSettlementDetails({
												settlementAmount: campaignDetails.settlementAmmount,
												campaignId: Number(props.campaignId)
											});
											settlement.setIsSettlingAmount(true);
										}}
									/>
								) : (
									<div className="d-flex"
										style={{
											border: "none", borderRadius: "15px", height: "25px",width:"132px",textAlign:"center",
											background: campaignDetails.isAmountSettled ? "#55d79b" : "#5E7488"
										}}>
										<span className="align-self-center lock-icon"></span>
										<span className="align-self-end" style={{ color: "#ffffff"}}>
											{
												campaignDetails.isAmountSettled ? (
													"Amount settled"
												) : (
													"Settle Amount"
												)
											}
										</span>
									</div>
								)

							}

						</div>
					</Fragment>
				)

			}


			<Fragment>
				<div style={{ padding: "10px 0px 15px 0px", borderBottom: "1.5px solid #CBE3FF" }} >

					<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-start">
						<span className="b4 nero-color" style={{ fontWeight: "500" }} > Start Date</span>
					</div>
					<div className="d-flex" style={{ height: "30px", width: "100%", padding: "0px 10px 0px 0px", marginTop: "10px" }}>
						<span className="b4">{convertToLongDate(campaignDetails.startDate)}</span>
					</div>

					<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-start">
						<span className="b4 nero-color" style={{ fontWeight: "500" }}>End Date</span>
					</div>


					<div className="d-flex align-items-start" style={
						!isEditingStatus
							?
							{ padding: "10px 0px 15px 0px", borderBottom: "1.5px solid #CBE3FF" }
							:
							{ height: "max-content", width: "100%", padding: "0px 10px 0px 0px", marginTop: "10px" }
					}>
						{
							isEditingStatus
								?
								<div style={{ position: "relative", width: "max-content" }}>
									<DatePicker
										id="picker2"
										selected={new Date(campaignDetails.endDate)}
										onChange={date => setEndDate(date)}
										placeholderText="Select end date"
										minDate={new Date()}
										// maxDate={minCampaignEndDate}
										className="date-picker"
										wrapperClassName="date-picker-container"
										dateFormat="yyyy-MM-dd"
									/>
									{/* <CalenderIcon style={{ position: "absolute", right: "8px", top: "8px" }} /> */}
								</div>
								:
								<span className="b4">{convertToLongDate(campaignDetails.endDate)}</span>
						}

					</div>
				</div>
				{
					(!campaignDetails.isCampaignEnded && isEditingStatus) &&
					<div className="d-flex flex-column align-items-start" style={{ padding: "10px 0px 15px 0px", borderBottom: "1.5px solid #CBE3FF", position: "relative" }}>

						{
							isEndingCampaign && (
								<div style={{ position: "absolute", top: -100 }}>
									<AlertBox
										type="confirmation"
										id="confirmation-to-end-campaign"
										statements={["Confirmation", "You won't be able to resume this campaign"]}
										processing={processing}
										action={(confirm) => {
											confirm ? (
												endCampaign()
											) : (
												setIsEndingCampaign(false)
											);
										}}
										confirmButtonStyle={{ background: "#fa4747" }}
									/>
								</div>
							)
						}

						<Button

							className="b5"
							id="end-campaign-immediately-button"
							title="End Campaign"
							style={{
								width: "60%",
								height: "30px",
								borderRadius: "15px",
								padding: "10px 15px",
								background: "#fa4747",
							}}
							onClick={() => setIsEndingCampaign(true)}
						/>


					</div>
				}


			</Fragment>

			{/* {
				(auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14) && (
					<Button
						className="b4"
						title="View in Site Builder"
						style={{ width: "max-content", height: "23px", borderRadius: "10px", backgroundColor: "#007AFF", padding: "10px 15px", marginTop: "15px" }}
						onClick={() => history.push("/visualbuilder")} />
				)
			} */}


			{/* <div style={{ marginTop: "8px" }} className="d-flex flex-column align-items-start">
				<span className="b4">Pause</span>
			</div>
			<div style={{ marginTop: "5px" }}>
				<SwitchButton />
			</div>
			<Button
				className="b4"
				title="Early End"
				style={{ width: "83px", height: "23px", borderRadius: "10px", backgroundColor: "#007AFF", padding: "5px 15px" }} />

			<div style={{ marginTop: "25px" }} className="d-flex flex-column align-items-start">
				<span className="b4">End Date</span>
			</div>
			<div style={{ height: "30px", width: "100%", backgroundColor: "white", borderRadius: "5px", padding: "0px 10px", boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)", marginTop: "5px" }}>
				<span className="b4">{currentDate}</span>
			</div> */}

		</div>
	);
}