import React, { useContext, useState } from "react";
import { VisualBuilderContext } from "../../../context/VisualBuilderContext";

import { ReactComponent as UpBtn } from "../../../assets/icons/Visual Builder/up-arrow.svg";
import { ReactComponent as DownBtn } from "../../../assets/icons/Visual Builder/down-arrow.svg";
import { ReactComponent as DeleteBtn } from "../../../assets/icons/Visual Builder/delete-button.svg";


import Navbar from "../../../builder/components/sub-components/Navbar";
import Footer from "../../../builder/components/sub-components/Footer";
import HeroHeader from "../../../builder/components/HeroHeader";
import FeatureComponent from "../../../builder/components/FeatureComponent";

import AboutUs from "../../../builder/components/AboutUs";
import ImpactAndObjectives from "../../../builder/components/ImpactAndObjectives";
import OnGoingCampaigns from "../../../builder/components/OnGoingCampaigns";
import CompletedCampaigns from "../../../builder/components/CompletedCampaigns";
import OurVolunteers from "../../../builder/components/OurVolunteers";
import TestimonyComponent from "../../../builder/components/TestimonyComponent";
import CampaignBox from "../../../builder/components/CampaignBox";
// import GalleryComponent from "../../../builder/components/GalleryComponent";
import Gallery from "../../../builder/components/Gallery";
import Spacer from "../../../builder/components/Spacer";

import { useAuth } from "../../../context/AuthContext";

export default function EditorMidComp() {

	const { editorDataValues, setEditorDataValues } = useContext(VisualBuilderContext);
	const auth = useAuth();
	const [alert, setAlert] = useState(false);

	console.log("editor mid values: ",editorDataValues);

	const getComponent = (name) => {
		let component = null;
		switch (name) {
			case "Navbar":
				component = Navbar;
				break;
			case "HeroHeader":
				component = HeroHeader;
				break;
			case "CampaignDetailsAndProgressBox":
				component = CampaignBox;
				break;
			case "FeatureComponent":
				component = FeatureComponent;
				break;
			case "Gallery":
				component = Gallery;
				break;
			case "AboutUs":
				component = AboutUs;
				break;
			case "OnGoingCampaigns":
				component = OnGoingCampaigns;
				break;
			case "TestimonyComponent":
				component = TestimonyComponent;
				break;
			case "ImpactAndObjectives":
				component = ImpactAndObjectives;
				break;
			case "CompletedCampaigns":
				component = CompletedCampaigns;
				break;
			case "OurVolunteers":
				component = OurVolunteers;
				break;
			case "Footer":
				component = Footer;
				break;
			case "Spacer":
				component = Spacer;
				break;
		}
		return component;
	};

	const deleteSelectedComponent = (index) => {
		
		var tempVlaue = editorDataValues.selectedPage;
		tempVlaue.splice(index, 1);
		setEditorDataValues({ ...editorDataValues, selectedPage: tempVlaue });
		if (editorDataValues.selectedPage.length > 0) {
			if (index === editorDataValues.selectedPage.length) {
				setEditorDataValues({ ...editorDataValues, selectedComponentIndex: (index - 1), dataHasChanged: true });
			}
		}
		else {
			setEditorDataValues({ ...editorDataValues, selectedComponentIndex: undefined, dataHasChanged: true });
		}
		// globalChangeOccured();
	};

	const moveSelectedComponentUp = (index) => {
		var tempVlaue = editorDataValues.selectedPage;
		const currentIndex = tempVlaue[index];
		const previousIndex = tempVlaue[index - 1];
		tempVlaue[index - 1] = currentIndex;
		tempVlaue[index] = previousIndex;
		setEditorDataValues({ ...editorDataValues, selectedPage: tempVlaue, dataHasChanged: true });
		setEditorDataValues({ ...editorDataValues, selectedComponentIndex: (index - 1), dataHasChanged: true });
	};

	const moveSelectedComponentDown = (index) => {
		var tempVlaue = editorDataValues.selectedPage;
		const currentIndex = tempVlaue[index];
		const nextIndex = tempVlaue[index + 1];
		tempVlaue[index + 1] = currentIndex;
		tempVlaue[index] = nextIndex;
		setEditorDataValues({ ...editorDataValues, selectedPage: tempVlaue, dataHasChanged: true });
		setEditorDataValues({ ...editorDataValues, selectedComponentIndex: (index + 1), dataHasChanged: true });
	};

	// eslint-disable-next-line no-unused-vars
	const globalChangeOccured = () => {
		var tempData = editorDataValues.dat;
		if (editorDataValues.selectedPageIndex === 0) {
			tempData = { ...tempData, home: editorDataValues.selectedPage };
		}
		setEditorDataValues({ ...editorDataValues, data: tempData });
	};

	return (
		<div className="mid-section-container" id="mid-section-container">
			<div className="vb-container-header d-flex align-items-center justify-content-between">
				<span>{editorDataValues.selectedPageIndex === 0 ? "Home Page" : editorDataValues.data.campaigns[editorDataValues.selectedPageIndex - 1].campaign_name}</span>
				{alert ? <span className="alert alert-danger">You can view your published site after 5 mins</span> : null}
				<span className="roboto-b3 navy-blue-color pointer ml-2"
					onClick={() => {
						if (editorDataValues.selectedPageIndex === 0) {
							window.open(`https://${editorDataValues.data?.site_url}`, "_blank");
							setAlert(true);
							setTimeout(() => {
								setAlert(false);
							}, 10000); // Hides the alert after 10 seconds (10000 milliseconds)
						}
						else {
							window.open(`https://${editorDataValues.data?.site_url}/campaign/${editorDataValues.data.campaigns[editorDataValues.selectedPageIndex - 1].campaign_id}`, "_blank");
							setAlert(true);
							setTimeout(() => {
								setAlert(false);
							}, 10000); // Hides the alert after 10 seconds (10000 milliseconds)
						}
					}}
				>View Published Site ↗</span>
			</div>
			<div className="vb-container-view">
				<div className="vb-component-container">
					<div className="web-component-view">
						{
							React.createElement(getComponent("Navbar"), { logo: editorDataValues.data.logo, primaryColor: `#${editorDataValues.data.colors[0].hashCode}`, ...editorDataValues.data.header.props })
						}
						{
							editorDataValues.selectedPage.length > 0 && editorDataValues.selectedPage.map((component, index) => {
								var props = component.props;
								return (
									<div
										className="position-relative d-flex" key={index}>
										{
											(index === editorDataValues.selectedComponentIndex) &&
											< div className="selected-comp-side-options">
												<UpBtn
													className={(index > 1 && !component.is_component_fixed) ? "SCSButton" : "SCSButtonDisable"}
													id="visual-builder-up-button"
													onClick={!component.is_component_fixed ? (index > 1 ? () => moveSelectedComponentUp(index) : () => { }) : () => { }} />
												<DeleteBtn
													className={!component.is_component_fixed ? "SCSButton" : "SCSButtonDisable"}
													id="visual-builder-delete-button"
													onClick={!component.is_component_fixed ? () => (deleteSelectedComponent(index)) : () => { }} />
												<DownBtn
													className={(index + 1 < editorDataValues.selectedPage.length && !component.is_component_fixed) ? "SCSButton" : "SCSButtonDisable"}
													id="visual-builder-down-button"
													onClick={(index + 1 < editorDataValues.selectedPage.length && !component.is_component_fixed) ? () => moveSelectedComponentDown(index) : () => { }} />
											</div>
										}
										<div className="pointer w-100" onClick={() => setEditorDataValues({ ...editorDataValues, selectedComponentIndex: index })}>

											{
												(component.component_name === "OnGoingCampaigns" || component.component_name === "CompletedCampaigns")
													?
													React.createElement(getComponent(component.component_name), {templateId: editorDataValues.data.templateId, tenantId: auth.tenant_id, primaryColor: `#${editorDataValues.data.colors[0].hashCode}`, isInEditingInProcess: true, campaigns: editorDataValues.data.campaigns, ...props })
													:
													<>
														{
															(component.component_name === "CampaignDetailsAndProgressBox")
																?
																React.createElement(getComponent(component.component_name), { campaignId: editorDataValues.data.campaigns[editorDataValues.selectedPageIndex - 1].campaign_id,templateId: editorDataValues.data.templateId,	 tenantId: auth.tenant_id, primaryColor: `#${editorDataValues.data.colors[0].hashCode}`, isInEditingInProcess: true, ...props })
																:
																React.createElement(getComponent(component.component_name), {templateId: editorDataValues.data.templateId, tenantId: auth.tenant_id, primaryColor: `#${editorDataValues.data.colors[0].hashCode}`, isInEditingInProcess: true, ...props })
														}
													</>

											}
										</div>
									</div>
								);
							})
						}
						<div className="w-100" style={{ position: "absolute", bottom: "0" }}>
							{
								React.createElement(getComponent("Footer"), { logo: editorDataValues.data.logo, primaryColor: `#${editorDataValues.data.colors[0].hashCode}`, headerLink: editorDataValues.data.header.props.headerLinks, ...editorDataValues.data.footer.props })
							}
						</div>
					</div>
				</div>
			</div >
		</div >
	);
}