/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "./../components/sub-components/Button";
import { useAuth } from "../context/AuthContext";
import { openURL } from "../utilities/Utilites";
import stripeLogo from "./../assets/icons/stripe.png";
import { useDonation } from "../context/DonationContext";

export default function CheckoutForm(props) {

	const auth = useAuth();
	console.log("auth inside checkout form",auth);
	const donation = useDonation();

	const [isReadyForPayment, setIsReadyForPayment] = useState(false);
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState(false);
	const [showHonorSection, setShowHonorSection] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	const cardStyle = {
		style: {
			base: {
				padding: "5px",
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#32325d"
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		},
		hidePostalCode: true,
	};
	function handleInput(event) {
		donation.setDonationDetails(prev => ({ ...prev, [event.target.name]: event.target.value }));
	}
	const handleChange = async (event) => {
		setIsReadyForPayment(true);
		setError(event.error ? event.error.message : "");
	};
	const handleSubmit = async ev => {
		ev.preventDefault();

		if (!donation.donationDetails.first_name.trim()) {
			setError("First Name is required");
			return;
		}

		if (!donation.donationDetails.last_name.trim()) {
			setError("Last Name is required");
			return;
		}

		if (!isReadyForPayment) {
			setError("Enter card details to proceed");
			return;
		}

		if(auth.userProfile !== undefined && !auth.userProfile.is_active) {
			setError("Please activate your account first");
			return;
		}

		setProcessing(true);

		const { result, error } = await openURL("/v1/donations/", "POST", auth, {
			amount: donation.donationDetails.donation_amount,
			campaign_id: donation.donationDetails.campaign_id,
			doner_name: `${donation.donationDetails.first_name} ${donation.donationDetails.last_name}`,
			doner_type: donation.donatingAs || "Employee",
			donor_address: donation.donationDetails.donor_address,
			donor_email: donation.donationDetails.donor_email,
			donor_city: donation.donationDetails.donor_city,
			donor_state: donation.donationDetails.donor_state,
			donor_pincode: donation.donationDetails.donor_pincode,
			dedicatee_name: donation.donationDetails.dedicatee_name,
			dedicatee_recipient_email: donation.donationDetails.dedicatee_recipient_email,
		});

		if (error) {
			setError(`Payment failed ${error.message}`);
			setProcessing(false);
			return;
		}

		if (!result) {
			return;
		}

		const payload = await stripe.confirmCardPayment(result.data.client_secret, {
			payment_method: {
				card: elements.getElement(CardElement)
			}
		});
		if (payload.error) {
			setError(`Payment failed ${payload.error.message}`);
			setProcessing(false);
		} else {
			setError(null);
			setProcessing(false);
			setSucceeded(true);
			donation.setDonationSuccess(true);
		}
	};

	useEffect(() => {
		if (auth.userProfile) {
			donation.setDonationDetails(prev => ({ ...prev, first_name: auth.userProfile.first_name, last_name: auth.userProfile.last_name}));
		}
	}, [auth]);

	return (
		<div className="w-100 px-4" style={{ maxHeight: "600px", overflow: "scroll", overflowX: "hidden"}}>
			<form id="payment-form" onSubmit={handleSubmit} className="text-left">
				<div className="d-flex w-100 justify-content-between">
					<div className="w-50 mr-2">
						<label htmlFor="first_name" className="d-block" style={{ fontSize: "0.9rem", marginBottom: "0"}}>First Name<span style={{ color: "red" }}>*</span></label>
						<input
							className="w-100"
							placeholder="First Name"
							style={{ border: "1px solid #d0d0d0", borderRadius: "5px", padding: "5px" }}
							type="text"
							name="first_name"
							value={donation.donationDetails.first_name}
							id="first_name"
							onChange={handleInput}
						/>
					</div>
					<div className="w-50 ml-2">
						<label htmlFor="last_name" className="d-block" style={{ fontSize: "0.9rem", marginBottom: "0"}}>Last Name<span style={{ color: "red" }}>*</span></label>
						<input
							className="w-100"
							placeholder="Last Name"
							style={{ border: "1px solid #d0d0d0", borderRadius: "5px", padding: "5px" }}
							type="text"
							value={donation.donationDetails.last_name}
							id="last_name"
							name="last_name"
							onChange={handleInput}
						/>
					</div>			
				</div>
				<div className="login-fields mt-3" onKeyPress={(event) => (event.key === "Enter") && handleSubmit()}>
					<label htmlFor="donor_address" className="d-block" style={{ fontSize: "0.9rem", marginBottom: "0"}}>Address:</label>
					<input
						className="b3 w-100"
						type="text"
						id="donor_address"
						placeholder="Address"
						style={{ border: "1px solid #e1e1e1", padding: "8px", borderRadius: "8px" }}
						name="donor_address"
						onChange={handleInput}
					/>
				</div>
				<div className="login-fields mt-3" onKeyPress={(event) => (event.key === "Enter") && handleSubmit()}>
					<label htmlFor="donor_city" className="d-block" style={{ fontSize: "0.9rem", marginBottom: "0"}}>City:</label>
					<input
						className="b3 w-100"
						type="text"
						id="donor_city"
						placeholder="City"
						style={{ border: "1px solid #e1e1e1", padding: "8px", borderRadius: "8px" }}
						name="donor_city"
						value={donation.donationDetails.donor_city}
						onChange={handleInput}
					/>
				</div>
				<div className="d-flex w-100 justify-content-between mt-3">
					<div className="w-50 mr-2">
						<label htmlFor="donor_state" className="d-block" style={{ fontSize: "0.9rem", marginBottom: "0"}}>State:</label>
						<input
							className="w-100"
							placeholder="State"
							style={{ border: "1px solid #d0d0d0", borderRadius: "5px", padding: "5px" }}
							type="text"
							name="donor_state"
							id="donor_state"
							value={donation.donationDetails.donor_state}
							onChange={handleInput}
						/>
					</div>

					<div className="w-50 ml-2">
						<label htmlFor="donor_pincode" className="d-block" style={{ fontSize: "0.9rem", marginBottom: "0"}}>Postal Code:</label>
						<input
							className="w-100"
							placeholder="90000"
							style={{ border: "1px solid #d0d0d0", borderRadius: "5px", padding: "5px" }}
							type="text"
							id="donor_pincode"
							name="donor_pincode"
							value={donation.donationDetails.donor_pincode}
							onChange={handleInput}
						/>
					</div>			
				</div>
				<div className="w-100 mt-4">
					<div className="border rounded px-4 py-3">
						<p className="mb-0 text-primary" style={{ textDecoration: "underline"}} onClick={() => setShowHonorSection(prev => !prev)}><span className="font-weight-bold">+</span> Donate in honor or memory of a loved one</p>
						<div className={`mt-4 ${showHonorSection ? "h-100": "d-none"}`}>
							<div className="w-100 mt-3">
								<label htmlFor="dedicatee_name" className="d-block" style={{ fontSize: "0.9rem", marginBottom: "0"}}>Dedicatee&#x27;s name:</label>
								<input
									className="w-100"
									type="text"
									style={{ border: "1px solid #e1e1e1", padding: "8px", borderRadius: "8px" }}
									name="dedicatee_name"
									id="dedicatee_name"
									onChange={handleInput}
								/>
							</div>
							<div className="w-100 mt-3">
								<label htmlFor="dedicatee_recipient_email" className="d-block" style={{ fontSize: "0.9rem", marginBottom: "0"}}>Recipient email:</label>
								<input
									className="w-100"
									type="text"
									style={{ border: "1px solid #e1e1e1", padding: "8px", borderRadius: "8px" }}
									name="dedicatee_recipient_email"
									id="dedicatee_recipient_email"
									onChange={handleInput}
								/>
								<p className="mt-1" style={{fontSize: "0.75rem", lineHeight: "0.8rem"}}>Optional: Notify someone about the gift. 
									The email will identify you as the donor, but doesn&#x27;t specify the amount of your gift.</p>
							</div>
						</div>
					</div>
				</div>
				<h6 className="mt-2 text-left">Donation Amount:</h6>
				<input
					disabled={true}
					className="w-100"
					placeholder="Amount"
					style={{ border: "1px solid #d0d0d0", borderRadius: "5px", padding: "5px" }}
					type="text"
					value={donation.donationDetails.donation_amount}
				/>
				

				<CardElement className="card-element" id="card-element" options={cardStyle} onChange={handleChange} />

				<div className="d-flex flex-row justify-content-center" style={{ width: "100%", marginTop: "20px" }}>
					{/* <Button charityLogin title="Back" className="d-flex back-button" /> */}
					<div style={{ width: "220px", height: "35px" }}>
						<Button
							busy={processing}
							disabled={succeeded || processing}
							type="submit"
							title="Proceed to Checkout"
							className="d-flex"
							style={{ backgroundColor: props.primaryColor, height: "100%", width: "100%" }}
						/>
					</div>


				</div>
				<div className="w-100 d-flex justify-content-center">
					<img src={stripeLogo} alt="stripe" className="mt-4" />	
				</div>

				{error && (
					<div className="card-error text-center" role="alert" style={{ marginTop: "10px", color: "#fa755a" }}>
						{error}
					</div>
				)}
				{/* Show a success message upon completion */}
				{succeeded && (
					<div className="card-success" role="alert">
						<span>Payment succeeded.</span>
					</div>
				)}
			</form>
		</div>
	);
}