import React, { useState } from "react";
import { useHistory } from "react-router";
import AlertBox from "../../../../components/common/AlertBox";
import Modal from "../../../../components/common/Modal";
import Topbar from "../../../../components/SetupWizad/Topbar";
import Button from "../../../../components/sub-component/Button";
import StripeCard from "../../../../components/subscription/StripeCard";
import { useAuth } from "../../../../context/AuthContext";
import { openURL } from "../../../../utilities/Utilites";

export default function AccountSuspended() {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const auth = useAuth();
	const history = useHistory();
	const [alert, setAlert] = useState(null);
	const [retryBtnStatus, setRetryBtnStatus] = useState(false);


	const retryPayment = async () => {
		setRetryBtnStatus(true);
		const { result, error } = await openURL(`/v1/subscriptions/retry`, "POST", auth, {
			subscription_id: auth.subscription.id
		});

		if (error) {
			setAlert(
				<AlertBox color="danger" title="There was a problem with the payment. Update your payment method, or retry your payment." description={error.message} />
			);
		} else if (result) {

			setAlert(
				<AlertBox color="success" title="Success" description={"Subscription Payed"} />
			);
			history.push("/dashboard");
			// window.location.reload();
		}
		setRetryBtnStatus(false);


	};

	return (
		<div style={{ height: "100vh", display: "flex", flexFlow: "column" }}>
			<Topbar />
			<div className="setup-wizard">
				<div className="setup-wizard-form" >
					<span className="s1">Account Suspended</span>
					<div style={{ marginTop: "88px", maxWidth: "777px", width: "100%", textAlign: "center" }}>
						<p className="b2 hoki-color">Your account has been suspended due to non-payments. Please retry payment or update payment method. </p>
						<div className="d-flex" style={{ marginTop: "70px", justifyContent: "space-evenly" }}>
							{
								auth.userProfile.role_assignment.id !== 14 && auth.userProfile.role_assignment.id !== 15 && (
									<>
										<Button
											busy={retryBtnStatus}
											title={"Retry Payment"}
											style={{ height: "40px", margin: "auto", width: "270px" }}
											onClick={retryPayment}
										/>
										<Button
											title={"Update Payment Method"}
											style={{ width: "max-content", height: "40px", margin: "auto", padding: "10px 48px" }}
											onClick={() => setIsModalOpen(true)}
										/>
									</>
								)
							}
						</div>
						<div className="mt-4">
							{alert}
						</div>
					</div>
				</div>
			</div>

			{
				isModalOpen &&
				<Modal title="Update Payment Method"
					onClose={() => { setIsModalOpen(false); }}>
					<div className="w-100 mt-5  d-flex justify-content-center" style={{ marginBottom: "45px" }}>
						<div style={{ width: "550px", boxShadow: "6px 15px 30px rgba(0, 0, 0, 0.15)", background: "white", borderRadius: "25px", padding: "20px" }}>
							<StripeCard stripCardCURD onCardUpdate={() => { setIsModalOpen(false); }} />
						</div>
					</div>
				</Modal>
			}
		</div>
	);
}
