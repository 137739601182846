import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthMessageCard from "../../components/auth/AuthMessageCard";
import AlertBox from "../../components/common/AlertBox";
import Button from "../../components/sub-component/Button";
import Input from "../../components/sub-component/Input";
// import { useAuth } from "../../context/AuthContext";
import baseURL from "../../utilities/BaseURL";
import altrunc_logo from "../../assets/icons/logo.png";
import { useHistory } from "react-router";
import StepProgressBar from "../../components/sub-component/StepProgressBar";

import { isEmailPublic, isEmailValid, isPasswordValid, setCookie } from "../../utilities/Utilites";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import {Color , SignUP, Title } from "../../utilities/AppConstant";
export default function Signup() {

	// const auth = useAuth();

	const history = useHistory();
	const [isBusy, setIsBusy] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [user, setUser] = useState({
		first_name: null,
		last_name: null,
		email: null,
		password: null,
		confirm_password: null
	});

	const [errors, setErrors] = useState({
		first_name: false,
		last_name: false,
		email: false,
		password: false,
		confirm_password: false
	});

	const [alert, setAlert] = useState();

	function handleInput(event) {

		const { value } = event.target;
		setUser({ ...user, [event.target.name]: value.trim() });

	}

	function onFocus(event) {
		if (event.target.name === "password") {
			setAlert(
				<AlertBox color="info" title="Password requirments" description={[
					<ul key="1">
						<li key="2">Password should contain atleast 1 special charater</li>
						<li key="4">Password should contain minimum 10 charaters</li>
					</ul>
				]} />
			);
		}
	}

	function onBlur(event) {

		console.log(event.target.name);

		if (event.target.name === "email") {

			if (!isEmailValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}

			if (isEmailPublic(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}

		}

		if (event.target.name === "password") {
			setAlert(null);
			if (!isPasswordValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}
		}

		if (!user[event.target.name]) {
			setErrors({ ...errors, [event.target.name]: true });
			return;
		}
		setErrors({ ...errors, [event.target.name]: false });
	}

	function validateForm() {

		if (!user.first_name || user.first_name.trim() === "") {
			return { isValid: false, message: SignUP.FIRST_NAME };
		}

		if (!user.last_name || user.last_name.trim() === "") {
			return { isValid: false, message: SignUP.LAST_NAME};
		}

		if (!user.email || user.email.trim() === "" || isEmailPublic(user.email)) {
			return {
				isValid: false,
				message: [
					<span key="1">
						{SignUP.EMAIL_KEY1}
					</span>
				]
			};
		}
		if (!user.email.includes("@")) {
			return {
				isValid: false,
				message: [
					<span key="2">
						{SignUP.EMAIL_KEY2}
					</span>
				]
			};
		}

		if (errors.password || !user.password) {
			return {
				isValid: false, title: "Weak password", message: [
					<ul key="1">
						<li key="2">{SignUP.PASSWORD_KEY_1_2}</li>
						<li key="4">{SignUP.PASSWORD_KEY_1_4}</li>
					</ul>
				]
			};
		}

		if (user.password !== user.confirm_password) {
			return { isValid: false, message: SignUP.PASSWORD_CONFIRM };
		}

		return { isValid: true, message: "" };

	}

	function handleSubmit(event) {

		event.preventDefault();

		const validation = validateForm();

		if (!validation.isValid) {
			setAlert(
				<AlertBox color={Color.ERROR} title={Title.TITLE_ERROR} description={validation.message} />
			);
			return;
		}

		setIsBusy(true);

		fetch(`${baseURL}/v1/auth/register/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user)
		}).then(async (res) => {

			const json = await res.json();

			if (res.ok) {
				return json;
			} else {
				return Promise.reject(json);
			}

		}).then((response) => {
			// auth.authLogin(response);
			setCookie("token", response.refresh_token, 7);
			window.location.reload();
			// setTimeout(() => window.location.reload(), 500);

		}).catch(err => {
			if (err.message === "tenant domain already exists") {
				setAlert(
					<AlertBox color={Color.ERROR} title={Title.TITLE_ERROR} description={[<span key={1}>Tenant with <b>@{user.email.split("@")[1]}</b> domain aleady exists</span>]} />
				);
			} else {
				setAlert(
					<AlertBox color={Color.ERROR}  title={Title.TITLE_ERROR} description={err.message} />
				);
			}
			setIsBusy(false);
		});

	}

	return (

		<div className="d-flex flex-lg-row flex-column justify-content-between">

			<div className="d-lg-flex message-card-mobile" style={{ width: "100%", height: "auto", position: "relative", }}>
				<AuthMessageCard paragraph={
					["Giving is not just about making a ",
						<span style={{ color: "#0054DC" }} key="0">donation</span>,
						", its about making an ",
						<span style={{ color: "#0054DC" }} key="1">impact</span>,
						"."]}
				/>
			</div>

			<div className="d-flex flex-column justify-content-center auth-form signup-form">

				{/* {alert} */}

				<div className="d-flex flex-column align-self-center" style={{ height: "auto", width: "100%", borderBottom: "2px solid #CBE3FF", paddingBottom: "40px" }}>

					<div className="message-card-logo-mobile">

						<img src={altrunc_logo} />

					</div>
					<div className="stepProgressbarContainer" >
						<StepProgressBar step={1}/>
					</div>
					<span className="s2" style={{ textAlign: "left" }}>Sign up.</span>

					<form onSubmit={handleSubmit} action="#">
						<div style={{ width: "100%", marginTop: "56px" }}>
							<Input
								placeholder="First Name"
								label=""
								name="first_name"
								onChange={handleInput}
								onBlur={onBlur}
								className={errors.first_name ? "input-underline-error" : "input-underline"}
								underLine
								id="first_name"
							/>
						</div>

						<div style={{ width: "100%", marginTop: "30px" }}>
							<Input
								placeholder="Last Name"
								label=""
								name="last_name"
								onChange={handleInput}
								onBlur={onBlur}
								className={errors.last_name ? "input-underline-error" : "input-underline"}
								underLine
								id="last_name"
							/>
						</div>

						<div style={{ width: "100%", marginTop: "30px" }}>
							<Input
								placeholder="Email"
								label=""
								name="email"
								underLine
								onChange={handleInput}
								className={errors.email ? "input-underline-error" : "input-underline"}
								onBlur={onBlur}
								id="email"
							/>
						</div>

						<div style={{ width: "100%", marginTop: "30px", position: "relative" }}>
							<Input
								placeholder="Password"
								label=""
								name="password"
								type={showPassword ? "text" : "password"}
								onChange={handleInput}
								onBlur={onBlur}
								onFocus={onFocus}
								className={errors.password ? "input-underline-error" : "input-underline"}
								underLine
								id="password"
							/>
							{showPassword ?
								<FaRegEye style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
								:
								<FaRegEyeSlash style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
							}
						</div>

						<div style={{ width: "100%", marginTop: "30px", position: "relative" }}>
							<Input
								placeholder="Confirm Password"
								name="confirm_password"
								type={showConfirmPassword ? "text" : "password"}
								label=""
								underLine
								onChange={handleInput}
								className={errors.confirm_password ? "input-underline-error" : "input-underline"}
								onBlur={onBlur}
								id="confirm_password"
							/>
							{showConfirmPassword ?
								<FaRegEye style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowConfirmPassword(prev => !prev)} size={20} />
								:
								<FaRegEyeSlash style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowConfirmPassword(prev => !prev)} size={20} />
							}
						</div>

						<div style={{ marginTop: "15px" }}>
							<span>{"By continuing, you’re agreeing to our Altrunic's "}</span>
							<a className="pointer" onClick={() => { history.push("/terms_and_condition"); }} style={{ color: "#0054DC" }} >Terms of Service </a>
							<span>and </span>
							<a className="pointer" onClick={() => { history.push("/privacy_policy"); }} style={{ color: "#0054DC" }} >Privacy Policy </a>
							{/* <span>and </span>
							<span className="pointer" onClick={() => { }} style={{ color: "#0054DC" }}>Cookie Policy.</span> */}
						</div>

						<div style={{ width: "100%", marginTop: "41px" }}>
							<Button title="Sign up" style={{ width: "100%", height: "40px" }} onClick={handleSubmit} busy={isBusy} />
						</div>
					</form>

				</div>
				<span className="b3" style={{ margin: "15px 0", textAlign: "center" }}>Already a user?</span>
				<div className="d-flex justify-content-center" >
					<Link to="/login" >
						<Button title="Login " whiteButton />
					</Link>
				</div>

				<div style={{ position: "relative" }}>
					<div style={{ position: "absolute", width: "100%" }}>
						{alert}
					</div>
				</div>

			</div>

		</div>
	);

}