import React from "react";
import { numberFormatter } from "../../../../utilities/Utilites";
import ProgressBar from "../../ProgressBar";

/**
 * 
 * @param {Object} props  Props object
 * @param {string} props.cardColor rgba color for card
 * @param {number} props.current Amount raised
 * @param {number} props.total Amount to be raied
 */
export default function ObjectiveStatusCard(props) {

	return (

		<div className="d-flex flex-column objective-card" style={{ backgroundColor: `${props.cardColor}` }}>

			<div className="d-flex justify-content-between">
				<div style={{ width: "auto" }}>
					<span style={{ fontSize: "10px", color: "#ffffff" }}>$ {numberFormatter(props.current) || "0"}</span>
				</div>
				<div style={{ width: "auto" }}>
					<span style={{ fontSize: "10px", color: "#ffffff" }}>$ {numberFormatter(props.total) || "0"}</span>
				</div>
			</div>

			<div style={{ height: "5px" }}>
				<ProgressBar barColor="#d311d6" completed={(props.current / props.total) * 100} />
			</div>

			<span className="h6" style={{ color: "#fff", margin: "10px 0 0 0", textAlign: "left" }}>Objective</span>
			<span className="b6" style={{ color: "#2A3440", marginTop: "0", textAlign: "left", fontSize: "10px" }}>Progress</span>

		</div>

	);

}