import React, { Fragment, useState, useEffect } from "react";
import { Packages } from "../../utilities/Packages";
import SubscriptionPackageModal from "./SubscriptionPackageModal";
import SegmentToggle from "../sub-component/SegmentToggle";
import SubscriptionPlanCard from "./SubscrptionPlanCard";
import Modal from "../common/Modal";
import { openURL, SubscriptionStatus } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";
import AlertBox from "../common/AlertBox";
import ContactUsFrom from "../common/ContactUsForm";

/**
 * 
 * @param {Object} props 
 * @param {boolean} props.isOnChangePlanModalOpen show status for change plan modal
 * @param {Function} props.onCloseModal function to close all modals from parent comp
 * @returns 
 */

export default function SubscriptionPlanChangeFlow(props) {

	const [onChangePlan, setOnChangePlan] = useState({
		isOnChangePlanModalOpen: props.isOnChangePlanModalOpen,
		isOnConfirmPlanModalOpen: false,
		isOnConfirmedPlanModalOpen: false,
		isOnUpdateCardModalOpen: false
	});

	const [planChanged, setPlanChanged] = useState({
		current_plan: "",
		destination_plan: "",
		plan_operation: ""
	});	

	const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
	const [btnBusyStatus, setBtnBusyStatus] = useState({
		confirmingPackage: false
	});

	const [selectedPackage, setSelectedPackage] = useState();

	const segmentOptions = [
		{ key: 1, value: "Monthly", package_key: "monthly" },
		{ key: 2, value: "Yearly", package_key: "yearly" }
	];

	const [selectedPackgeInterval, setSelectedPackgeInterval] = useState(segmentOptions[0]);
	const auth = useAuth();
	const [alert, setAlert] = useState();

	const [paymentError, setPaymentError] = useState(false);

	useEffect(() => {
		setOnChangePlan({ ...onChangePlan, isOnChangePlanModalOpen: props.isOnChangePlanModalOpen });
	}, [props.isOnChangePlanModalOpen]);

	useEffect(() => {

		// console.log("props",props)
		if (props.selectedUserPlan.id === Packages.basic.id) {
			setSelectedPackage(Packages.basic);
		}else if(props.selectedUserPlan.id === Packages.starter.id){
			setSelectedPackage(Packages.starter);
		}
		else {
			setSelectedPackage(Packages.professional);
		}
	}, [props.selectedUserPlan]);

	const onCloseModal = () => {

		if(onChangePlan.isOnConfirmedPlanModalOpen){
	
			window.location.reload();

		}

		if(onChangePlan.isOnChangePlanModalOpen){
			if (props.selectedUserPlan.id === Packages.starter.id) {
				setSelectedPackage(Packages.starter);
			}
			else if(props.selectedUserPlan.id === Packages.basic.id){
				setSelectedPackage(Packages.basic);
			}else if(props.selectedUserPlan.id === Packages.professional.id){
				setSelectedPackage(Packages.professional);
			}
		
		}

		if(onChangePlan.isOnConfirmPlanModalOpen){
			if (props.selectedUserPlan.id === Packages.starter.id) {
				setSelectedPackage(Packages.starter);
			}

			else if(props.selectedUserPlan.id === Packages.basic.id){
				setSelectedPackage(Packages.basic);
			}else if(props.selectedUserPlan.id === Packages.professional.id){
				setSelectedPackage(Packages.professional);
			}
			

			else {
				setSelectedPackage(Packages.professional);
			}

		}
		
		setOnChangePlan({
			isOnChangePlanModalOpen: false,
			isOnConfirmPlanModalOpen: false,
			isOnConfirmedPlanModalOpen: false,
			isOnUpdateCardModalOpen: false
		});
		
		props.onCloseModal();
	};

	const onConfirmSubScriptionChange = async (event) => {

		event.preventDefault();
		setBtnBusyStatus({ ...btnBusyStatus, confirmingPackage: true });
		var tempPricingId = null;
		if (selectedPackgeInterval.package_key === "monthly") {
			tempPricingId = selectedPackage.monthly.pricing_id;
		}
		else {
			tempPricingId = selectedPackage.yearly.pricing_id;
		}
		const { result, error } = await openURL(`/v1/subscriptions/${auth.subscription.id}`, "PATCH", auth, {
			package_id: selectedPackage.id,
			pricing_id: tempPricingId,
			coupon: couponCode.code
		});

		if (error) {
			
			setAlert(
				<AlertBox color="danger" title="There was a problem with the payment. Update your payment method, or retry your payment." description={error.message} />
			);
			setPaymentError(true);
		} else if (result) {
			
			setAlert(
				<AlertBox color="success" title="Success" description={"Subscription Updated"} />
			);
			setOnChangePlan({ ...onChangePlan, isOnChangePlanModalOpen: false, isOnConfirmPlanModalOpen: false, isOnConfirmedPlanModalOpen: true });
		}
		setBtnBusyStatus({ ...btnBusyStatus, confirmingPackage: false });
	};

	const [couponCode, setCouponCode] = useState({
		isOpen: false,
		code: null,
		isApplying: false,
		isApplied: false,
		couponDetails: null
	});

	const [processing, setProcessing] = useState(false);

	const [subscriptionProration, setSubscriptionProration] = useState({
		package_id: "",
		package_name: "",
		package_price: "",
		package_interval: "",
		total_payment: 0,
		payment_due_today: 0,
	});

	useEffect(async () => {

		if (!selectedPackage) {
			return;
		}

		var query = `pricing_id=${selectedPackage[selectedPackgeInterval.package_key].pricing_id}`;

		if (couponCode.code) {
			query = `${query}&coupon=${couponCode.code}`;
		}

		setProcessing(true);

		const { result, error } = await openURL(`/v1/stripe_customers/invoice_proration?${query}`, "GET", auth);

		setProcessing(false);

		if (error) {
			setAlert(
				<AlertBox title="Error" description={error.message} color="danger" />
			);
		} else if (result) {
			const linesTotalCount = result.data.lines.total_count;
			let totalPayment = result.data.lines.data[linesTotalCount - 1].price.unit_amount;
			const amountDue = result.data.subscription.status === SubscriptionStatus.status.TRIALING ? 0 : result.data.amount_due / 100;

			if (result.data.discount?.coupon.amount_off) {
				totalPayment = totalPayment - result.data.discount.coupon.amount_off;

			} else if (result.data.discount?.coupon.percent_off) {
				totalPayment = totalPayment - (totalPayment * result.data.discount.coupon.percent_off / 100);
			}

			const proration = {
				package_id: result.data.lines.data[linesTotalCount - 1].price.metadata.package_id,
				package_name: result.data.lines.data[linesTotalCount - 1].price.metadata.package_name,
				package_price: result.data.lines.data[linesTotalCount - 1].price.unit_amount,
				package_interval: result.data.lines.data[linesTotalCount - 1].price.metadata.package_payment_interval,
				total_payment: totalPayment / 100,
				payment_due_today: amountDue,
			};

			setSubscriptionProration(proration);
		}
	}, [selectedPackage, selectedPackgeInterval, couponCode]);

	const getPlanOperation = (currentUserPlanId, selectedPackageId) => {
		if(currentUserPlanId === 1) {
			if(selectedPackageId === 2 || selectedPackageId === 3) {
				return "Upgraded";
			}
			else if(selectedPackageId === 4) {
				return "Downgraded";
			}
		}
		else if(currentUserPlanId === 2) {
			if(selectedPackageId === 1 || selectedPackageId === 4) {
				return "Downgraded";
			}
			else if(selectedPackageId === 3) {
				return "Upgraded";
			}
		}
		else if(currentUserPlanId === 3) {
			if(selectedPackageId === 1 || selectedPackageId === 2 || selectedPackageId === 4) {
				return "Downgraded";
			}
		}
		else if(currentUserPlanId === 4) {
			if(selectedPackageId === 1 || selectedPackageId === 2 || selectedPackageId === 3) {
				return "Upgraded";
			}
		}
		else return "";
	};

	useEffect(() => {
		const currentUserPlan = props.selectedUserPlan;
		const planOperation = getPlanOperation(currentUserPlan?.id, selectedPackage?.id);

		setPlanChanged(prev => ({ 
			...prev, 
			current_plan: currentUserPlan.package_name, 
			destination_plan: selectedPackage?.name,
			plan_operation: planOperation
		}));
	}, [selectedPackage]);

	return (
		<Fragment>
			{console.log(selectedPackage,"selected Package")}
			{
				onChangePlan.isOnChangePlanModalOpen &&
				<SubscriptionPackageModal
					onClose={onCloseModal}
					confirmedPackage={selectedPackage}
					onConfirm={(pkg) => {
						if (pkg.id === 3) {
							setIsContactUsModalOpen(true);
						}
						else{
							localStorage.setItem("packageId",pkg.id);
							setSelectedPackage(pkg);
							setOnChangePlan({ ...onChangePlan, isOnChangePlanModalOpen: false, isOnConfirmPlanModalOpen: true });
						}
					}}
					allowCustomPackage={true}
				/>
			}
			{
				isContactUsModalOpen &&
				<ContactUsFrom contactUsModalStatus={isContactUsModalOpen} onClose={() => { setIsContactUsModalOpen(false); }} onDone={() => { setIsContactUsModalOpen(false); window.location.reload(); }} />
			}

			{
				onChangePlan.isOnConfirmPlanModalOpen &&
				<Modal title="Confirm Plan" confirmBtnTitle={`${paymentError ? "Retry Payment" : "Confirm"}`} confirmBtnBusyStatus={btnBusyStatus.confirmingPackage}
					onClose={() => { onCloseModal(); }}
					onConfirm={(event) => {
						onConfirmSubScriptionChange(event);
					}}
					isFuntionTwoShow={paymentError}
					onOtherConfirmTitle={paymentError ? "Update Payment Method" : null}
					onOtherConfirmMethod={() => {
						setOnChangePlan({ ...onChangePlan, isOnConfirmPlanModalOpen: false, isOnUpdateCardModalOpen: true });
					}}
				>
					<div className="w-100 d-flex justify-content-center">
						<div style={{ maxWidth: "500px", width: "500px" }}>
							<div className="w-100 d-flex flex-column align-items-center">
								<div style={{ width: "230px" }}>
									<SegmentToggle
										disabled={processing}
										options={segmentOptions}
										selected={selectedPackgeInterval}
										onSwitch={(option => setSelectedPackgeInterval(option))}
									/>
									<p className="b4 text-right align-self-end mt-1" style={{ color: "#0054DC" }}>Save 25% Annually</p>
								</div>

							</div>
							<div className="subscription-detail-card">
								<SubscriptionPlanCard
									processing={processing}
									packageId={subscriptionProration?.package_id}
									packageName={subscriptionProration?.package_name}
									packagePrice={subscriptionProration?.package_price / 100}
									totalAmount={subscriptionProration.total_payment}
									amountDue={subscriptionProration?.payment_due_today}
									packageInterval={subscriptionProration?.package_interval}
									// packageInterval={selectedPackgeInterval.package_key}
									selectedPackage={selectedPackage}
									onChangePlanClick={() => { setOnChangePlan({ ...onChangePlan, isOnChangePlanModalOpen: true, isOnConfirmPlanModalOpen: false }); }}
									// couponCode={couponCode}
									onCouponApplied={(coupon) => { setCouponCode(coupon); }}

								/>
							</div>
							{alert}
						</div>
					</div>

				</Modal>
			}

			{/* {
				onChangePlan.isOnUpdateCardModalOpen &&
				<Modal title="Update Payment Method"
					onClose={() => { onCloseModal(); }}>
					<div className="w-100 mt-5  d-flex justify-content-center" style={{ marginBottom: "45px" }}>
						<div style={{ width: "550px", boxShadow: "6px 15px 30px rgba(0, 0, 0, 0.15)", background: "white", borderRadius: "25px", padding: "20px" }}>
							<StripeCard stripCardCURD onCardUpdate={() => { setOnChangePlan({ ...onChangePlan, isOnConfirmPlanModalOpen: true, isOnUpdateCardModalOpen: false }); }} />
						</div>
					</div>
				</Modal>

			} */}
			{
				onChangePlan.isOnConfirmedPlanModalOpen &&
				<Modal title="Plan Changed" confirmBtnTitle={`${selectedPackage?.id === 1 ? "Configure Hosted Domain" : "Customize Domain"}`}
					onClose={onCloseModal}
					onConfirm={() => {
						setOnChangePlan({ ...onChangePlan, isOnChangePlanModalOpen: false, isOnConfirmPlanModalOpen: false, isOnConfirmedPlanModalOpen: false });
						props.onPlanChanged({ selectedPackage: selectedPackage });
						window.location.reload();
					}}>
					<div className="w-100 d-flex justify-content-center">
						<div className="d-flex flex-column align-items-center" style={{ maxWidth: "800px", width: "800px" }}>
							{
								<Fragment>
									<p className="s2 hoki-color" style={{ marginTop: "20px" }}>
										Your plan has been changed successfully to:&nbsp;
										<font color="#0054DC">{planChanged.destination_plan} Plan</font>
									</p>
									<p className="s2 hoki-color" style={{ marginTop: "20px", lineHeight: "1.2" }}>
										<span style={{ fontSize: "1rem" }}>
											({planChanged.plan_operation === "Upgraded" && "Congratulations! "}Your plan has been successfully {planChanged.plan_operation} from {planChanged.current_plan} to {planChanged.destination_plan})
										</span>
									</p>
									{planChanged.destination_plan === "Professional" ? (
										<p className="b2 hoki-color" style={{ marginTop: "20px" }}>
											You need to configure a hosted domain where your impact site will be available.
										</p>
									) : (
										<p className="b2 hoki-color" style={{ marginTop: "20px" }}>
											You can now customize your Impact site domain!
										</p>
									)}
								</Fragment>
							}
						</div>
					</div>

				</Modal>
			}

		</Fragment >
	);
}
