import React, { useState, useEffect } from "react";
import Input from "../sub-component/Input";
import Button from "../sub-component/Button";
import Select from "../sub-component/Select";
import {
	isNumberValid,
	isTextValid,
	isUrlValid,
	isZipcodeValid,
	openURL,
	USA_STATES,
} from "../../utilities/Utilites";
import { useAuth } from "./../../context/AuthContext";
import AlertBox from "../common/AlertBox";
import {Color,ErrorMessages, Title} from "../../utilities/AppConstant";

export default function EnterpriseSetup(props) {
	const auth = useAuth();

	const stateOption = USA_STATES;

	const [company, setCompany] = useState({
		tenant_name: "",
		street_address_1: "",
		street_address_2: "",
		city: "",
		zip_code: "",
		state: "Alabama",
		country: "United States",
		phone: "",
		web_url: "",
		organization: "",
		sector: "", 
	});

	const [organization, setOrganization] = useState([]);
	const [filteredSectors, setFilteredSectors] = useState([]);


	// const [companyId, setCompanyId] = useState();
	const [alert, setAlert] = useState();
	const [processing, setProcessing] = useState(false);

	const handleInput = (event) => {
		setCompany({ ...company, [event.target.name]: event.target.value });
	};


	console.log("company", company);
	const validate = () => {
		if (!isTextValid(company.tenant_name)) {
			return { isValid: false, message: ErrorMessages.INVALID_COMPANY_NAME };
		}

		if (!company.street_address_1) { 
			return { isValid: false, message: ErrorMessages.MISSING_STREET_ADDRESS };
		}

		// if (!isTextValid(company.city)) {
		// 	return { isValid: false, message: "City is required" };
		// }

		if (!isZipcodeValid(company.zip_code)) {
			return { isValid: false, message: ErrorMessages.INVALID_ZIP_CODE};
		}

		if (!company.state) {
			return { isValid: false, message: ErrorMessages.STATE_NOT_SELECTED };
		}

		if (!isNumberValid(company.phone)) {
			return { isValid: false, message: ErrorMessages.MISSING_PHONE };
		}

		if (!isTextValid(company.country)) {
			return { isValid: false, message: ErrorMessages.MISSING_COUNTRY};
		}

		if (!isUrlValid(company.web_url)) {
			return { isValid: false, message: ErrorMessages.MISSING_WEB_URL };
		}

		setAlert(null);
		return { isValid: true };

	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		const validation = validate();

		if (!validation.isValid) {
			setAlert(
				<AlertBox color={Color.ERROR} title={Title.TITLE_ERROR} description={validation.message} />
			);
			return;
		}

		setProcessing(true);

		const { result, error } = await openURL("/v1/tenant_profile/" + auth.tenant_id, "PATCH", auth, company);
		if (error) {
			setAlert(
				<AlertBox
					color={Color.ERROR}
					title={Title.TITLE_ERROR}
					description="internal Server Problem!"
				/>
			);
		} else if (result) {
			setAlert(
				<AlertBox
					color={Color.SUCCESS}
					title={Title.TITLE_SUCCESS}
					description="Record updated successfully"
				/>
			);

			if (props.shouldCreate && props.onClick) {
				props.onClick();
			}
		}

		setProcessing(false);
	};

 



	const handleTypeChange = (event) => {
		console.log("event", event);
		const selectedType = event;

		setCompany({ ...company, organization: selectedType });

		// Find the selected type from data
		const selectedData = organization.find(
			(item) => item.type === selectedType
		);

		console.log("new data", selectedData);
		if (selectedData) {
			setFilteredSectors(selectedData.sectors);
			console.log("filter", filteredSectors);
		} else {
			setFilteredSectors([]);
		}
	};

	const handleSectorChange = (event) => {
		setCompany({ ...company, sector: event });
	};

	useEffect(() => {
		const getCompanyData = async () => {
			const { result } = await openURL(`/v1/tenant_profile/${auth.tenant_id}`, "GET", auth, null);
			console.log("company in setup", result.data);

			if (result) {
				setCompany(prev => ({
					...prev,
					city: result.data.city ?? prev.city,
					country: result.data.country ?? prev.country,
					organization: result.data.organization ?? prev.organization, 
					phone: result.data.phone ?? prev.phone,
					sector: result.data.sector ?? prev.sector,
					state: result.data.state ?? prev.state,
					street_address_1: result.data.street_address_1 ?? prev.street_address_1,
					street_address_2: result.data.street_address_2 ?? prev.street_address_2,
					tenant_name: result.data.tenant_name ?? prev.tenant_name,
					web_url: result.data.web_url ?? prev.web_url,
					zip_code: result.data.zip_code ?? prev.zip_code
				}));
				const resultOrganization = await openURL(`/v1/organization/`, "GET", auth, null);
				setOrganization(resultOrganization.result.data);

				// Find the selected type from data
				const selectedData = resultOrganization?.result?.data?.find(
					(item) => item.type === result?.data.organization
				);

				if (selectedData) {
					setFilteredSectors(selectedData.sectors);
					console.log("filter", filteredSectors);
				} else {
					setCompany(prev => ({ ...prev, 
						organization: resultOrganization?.result?.data[0].type,
						sector: resultOrganization?.result?.data[0]?.sectors[0]?.sector
					}));
					setFilteredSectors(resultOrganization?.result?.data?.[0]?.sectors);
				}
			}
		};
		getCompanyData();
	}, []);

	return (
		<div className="form-responsiveness">
			<form
				className="d-flex flex-column setup-form w-100"
				onSubmit={handleSubmitForm}
			>
				{props.isPageLabeled && (
					<div style={{ textAlign: "center" }}>
						<span style={{ textAlign: "center" }} className="s1">
              Company Setup
						</span>
						{props.isPageLabeled && (
							<p
								className="b3"
								style={{
									textAlign: "left",
									marginTop: "20px",
									fontSize: "121dp",
								}}
							>
								{props.label}
							</p>
						)}
					</div>
				)}
				<Input
					value={company.tenant_name || ""}
					type="text"
					id="setup-company-company-name"
					style={{ marginTop: "35px" }}
					label="Company Name"
					name="tenant_name"
					onChange={handleInput}
					placeholder="Company ABC"
				/>
       
				<div
					className="w-100 d-flex justify-content-between"
					style={{ marginBottom: "35px" }}
				>
					<div style={{ width: "48%" }}>
						<Select
							varient="customizeSelect"
							style={{ marginTop: "15px", marginBottom: "15px" }}
							title="Sector Type"
							id="setup-company-type"
							options={organization?.map((org) => org.type)}
							selected={company && company?.organization}
							placeholder={"Select"}
							onSelect={handleTypeChange}
						/>
					</div>
					<div style={{ width: "48%" }}>
						<Select
							varient="customizeSelect"
							style={{ marginTop: "15px", marginBottom: "15px" }}
							title="Sub-Sector"
							id="setup-company-sector"
							options={filteredSectors?.map((org) => org.sector)}
							selected={company && company?.sector}
							placeholder={"Select"}
							onSelect={handleSectorChange}
						/>
					</div>
				</div>
				<Input
					value={company.street_address_1 || ""}
					type="text"
					id="setup-company-street-address-1"
					style={{ marginTop: "15px" }}
					label="Street Address"
					name="street_address_1"
					onChange={handleInput}
				/>

				<Input
					value={company.street_address_2 || ""}
					type="text"
					id="setup-company-street-address-2"
					style={{ marginTop: "0px" }}
					name="street_address_2"
					onChange={handleInput}
				/>

				<div className="w-100 d-flex justify-content-between">
					<div style={{ width: "48%" }}>
						<Input
							value={company.city || ""}
							style={{ marginTop: "15px" }}
							label="City"
							id="setup-company-city"
							name="city"
							onChange={handleInput}
							placeholder="City"
						/>
					</div>
					<div style={{ width: "48%" }}>
						<Input
							style={{ marginTop: "15px" }}
							label="Country"
							id="setup-company-country"
							value={company.country || "United States"}
							readonly={true}
							name="country"
							// onChange={handleInput}
							disabled={true}
						/>

					</div>
				</div>

				<div className="w-100 d-flex justify-content-between">
					<div style={{ width: "48%" }}>
						<Select
							varient="customizeSelect"
							style={{ marginTop: "15px" }}
							title="State / Province"
							id="setup-company-state"
							options={stateOption}
							selected={company.state}
							placeholder={company.state || "California"}
							onSelect={(state) => setCompany({ ...company, state: state })}
						/>
					</div>
					<div style={{ width: "48%" }}>
						<Input
							value={company.zip_code || ""}
							style={{ marginTop: "8px" }}
							label="Zip Code"
							id="setup-company-zip-code"
							name="zip_code"
							onChange={handleInput}
							minLength="5"
							maxLength="5"
							placeholder="90001"
						/>
					</div>
				</div>

				<Input
					id="phone-number"
					style={{ marginTop: "15px" }}
					label="Phone"
					type="text"
					placeholder="+01-631-856-0330"
					name="phone"
					value={company.phone || ""}
					onChange={(phone) => setCompany({ ...company, phone: phone })}
					phoneNumber
				/>

				<Input
					id="url"
					type="url"
					style={{ marginTop: "15px" }}
					label="Website URL"
					placeholder="https://charitoz.altrunic.com"
					name="web_url"
					onChange={handleInput}
					value={company.web_url || ""}
				/>
				{alert}

				{props.shouldCreate && (
					<div
						style={{ marginTop: "50px", width: "115px", height: "42px" }}
						className="align-self-center"
					>
						<Button
							onClick={handleSubmitForm}
							leftArrow
							busy={processing}
							type="submit"
							id="company-profile-form-submit-button"
							style={{ width: "100%", height: "100%" }}
						/>
					</div>
				)}
				{props.shouldUpdate && (
					<div
						style={{ marginTop: "30px", width: "115px", height: "42px" }}
						className="align-self-center"
					>
						<Button
							className="b2"
							onClick={handleSubmitForm}
							busy={processing}
							title="Update"
							id="company-profile-form-update-button"
							type="submit"
							style={{ width: "100%", height: "100%" }}
						/>
					</div>
				)}
			</form>
		</div>
	);
}
