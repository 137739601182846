import React, { useState, Fragment } from "react";
import { Row} from "react-bootstrap";
import MemberCard from "./sub-components/MemberCard";
import CarouselComponent from "./sub-components/CarouselComponents";
import memberImage1 from "../assets/images/Image.png";
import memberImage2 from "../assets/images/Image2.png";
import memberImage3 from "../assets/images/Image3.png";

export default function OurVolunteers(props) {

	// eslint-disable-next-line no-unused-vars
	const [members, setMembers] = useState([
		{ volenteerImage: memberImage1, volenteerName: null, volenteerDesignation: null },
		{ volenteerImage: memberImage2, volenteerName: null, volenteerDesignation: null },
		{ volenteerImage: memberImage3, volenteerName: null, volenteerDesignation: null }
	]);
	if(parseInt(props.templateId)===1){
		return (
			<div id="our-volunteers-template-1">
				{
					props.isInEditingInProcess
						?
						<div className="our-volunteers-component-container-template-1" style={{ background: `${props.primaryColor}` }}>
							<span className="cs-hero3 white-color">{props.volunteersHeaderTitle}</span>
							<div className="volunteers-cards-container">
								{
									props.volunteersDetails && props.volunteersDetails.map((member, index) => {
										return (
											<div key={index} className="volunteers-card-container">
												<MemberCard
													templateId={parseInt(props.templateId)}
													// defaultMemberCard
													color={props.primaryColor}
													cardImage={member.inputDetails[0].value} cardTitle={member.inputDetails[1].value} cardSubTitle={member.inputDetails[2].value} />
											</div>
										);
									})
								}
								{
									Array.from({ length: 3 - props.volunteersDetails.length }).map((_, index) => {
										return (
											<div key={index} className="volunteers-card-container">
												<MemberCard
													templateId={parseInt(props.templateId)}
													defaultMemberCard
													color={props.primaryColor} />
											</div>
										);
									})
								}
							</div>
						</div>
						:
	
						<Fragment>
							{
								props.volunteersDetails?.length > 0
								&&
								<div className="our-volunteers-component-container-template-1" style={{ background: `${props.primaryColor}` }}>
									<span className="cs-hero3 white-color">{props.volunteersHeaderTitle || "Title Not Found"}</span>
									<Row className="volunteers-cards-container">
										{/* {
											props.volunteersDetails && props.volunteersDetails.map((member, index) => {
												return (
													<Col lg="4" md="6" sm="12" key={index} className="volunteers-card-container">
														<MemberCard
															templateId={parseInt(props.templateId)}
															// defaultMemberCard
															color={props.primaryColor}
															cardImage={member.inputDetails[0].value} cardTitle={member.inputDetails[1].value} cardSubTitle={member.inputDetails[2].value} />
													</Col>
												);
											})
										} */}
										{ props.volunteersDetails && <CarouselComponent
											isVolunteer
											templateId={parseInt(props.templateId)}
											primaryColor={props.primaryColor}
											data={props.volunteersDetails}
										/>}
									</Row>
								</div>
							}
						</Fragment>
	
				}
			</div>
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<div id="our-volunteers-template-2">
				{
					props.isInEditingInProcess
						?
						<div className="our-volunteers-component-container-template-2">
							<span>{props.volunteersHeaderTitle || "Title Not Found"}</span>
							<span className="cs-hero3" style={{color:props.primaryColor}}>Our organizers are here to help you</span>
							<div className="volunteers-cards-container">
								{
									props.volunteersDetails && props.volunteersDetails.map((member, index) => {
										return (
											<div key={index} className="volunteers-card-container">
												<MemberCard
													// defaultMemberCard
													templateId={parseInt(props.templateId)}
													color={props.primaryColor}
													cardImage={member.inputDetails[0].value} cardTitle={member.inputDetails[1].value} cardSubTitle={member.inputDetails[2].value} />
											</div>
										);
									})
								}
								{
									Array.from({ length: 3 - props.volunteersDetails.length }).map((_, index) => {
										return (
											<div key={index} className="volunteers-card-container">
												<MemberCard
													templateId={parseInt(props.templateId)}
													defaultMemberCard
													color={props.primaryColor} />
											</div>
										);
									})
								}
							</div>
						</div>
						:
	
						<Fragment>
							{
								props.volunteersDetails?.length > 0
								&&
								<div className="our-volunteers-component-container-template-2" >
									<span>{props.volunteersHeaderTitle || "Title Not Found"}</span>
									<span className="cs-hero3" style={{color:props.primaryColor}}>Our organizers are here to help you</span>
									<Row className="volunteers-cards-container">
										{/* {
											props.volunteersDetails && props.volunteersDetails.map((member, index) => {
												return (
													<Col lg="4" md="6" sm="12" key={index} className="volunteers-card-container">
														<MemberCard
															// defaultMemberCard
															templateId={parseInt(props.templateId)}
															color={props.primaryColor}
															cardImage={member.inputDetails[0].value} cardTitle={member.inputDetails[1].value} cardSubTitle={member.inputDetails[2].value} />
													</Col>
												);
											})
										} */}
										{ props.volunteersDetails && <CarouselComponent
											isVolunteer
											templateId={parseInt(props.templateId)}
											primaryColor={props.primaryColor}
											data={props.volunteersDetails}
										/>}
									</Row>
								</div>
							}
						</Fragment>
	
				}
			</div>
		);
	}
	else{
		return(
			<>
				{/* returning template id is {parseInt(props.templateId)} for volunteers */}
			</>
		);
	}
}
