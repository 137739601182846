import React from "react";
import CreateCampaignForm from "../../../../components/SetupWizad/CreateCampaignForm";
export default function CreateCampaign(props) {
	return (
		<div className="d-flex justify-content-center">
			<div className="form-handling w-100">
				<CreateCampaignForm
					shouldSkip={props.shouldSkip}
					shouldUpdate={props.shouldUpdate}
					shouldDelete={props.shouldDelete}
					submitActionTitle={props.label || "Create"} />
			</div>
		</div>
	);
}
