import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import CampaignProgressCard from "../../../components/dashboard/mid-page/dashboard-main/CampaignProgressCard";
import ObjectiveCard from "../../../components/dashboard/mid-page/dashboard-main/ObjectiveCard";
import { getUnauthorizedURL, openURL } from "../../../utilities/Utilites";
import { useAuth } from "../../../context/AuthContext";
import FinishedCampaignsContainer from "../../../components/dashboard/mid-page/dashboard-main/FinishedCampaignsContainer";

export default function Dashboard() {
	const auth = useAuth();
	const history = useHistory();
	const [onGoingCampaigns, setOnGoingCampaigns] = useState([]);
	const [completedCampaigns, setCompletedCampaigns] = useState([]);
	const [objectiveStatuses, setObjectiveStatuses] = useState([]);

	const getOnGoingCampaign = async () => {

		const { result } = await getUnauthorizedURL("/v1/campaign/?parameter=ON_GOING", { id: auth.user_id, tenant_id: auth.tenant_id });

		if (result) {
			setOnGoingCampaigns(result.message);
		}

	};

	const calculateUser = ({ donations }) => {
		let amount = 0;
		const donationSeries = [];

		donations.map((donation) => {
			const date = new Date(donation.date);

			var newA = donationSeries.filter((item) => {
				return item.x === date.toDateString();
			});

			if (newA[0]) {
				newA[0].y += Number(donation.amount);
			}
			else {
				donationSeries.push({ x: date.toDateString(), y: Number(donation.amount) });
			}

			amount = amount + Number(donation.amount);
		});

		return { particpents: donations.length, amountDonate: amount, dataSeries: donationSeries };
	};

	const geCompletedCampaign = async () => {

		const { result } = await getUnauthorizedURL("/v1/campaign/?parameter=COMPLETED", { id: auth.user_id, tenant_id: auth.tenant_id });

		if (result) {
			setCompletedCampaigns(result.message);
		}

	};


	const getGoal = async () => {

		const { result } = await openURL("/v1/goal/?with_status=true", "GET", auth, null);

		if (result) {

			const goalStatus = result.data.map(goal => {
				const factorAmount = goal.charity_impact.factor_amount;

				const campaignImpacts = goal.impact.campaigns.map(campaign => {
					const donations = campaign.donations.reduce((total, donation) => {
						if (
							Date.parse(donation.date) >= Date.parse(goal.start_date) && 
							Date.parse(donation.date) <= Date.parse(goal.end_date)
						) {
							return total + Number(donation.amount);
						} else {
							return total;
						}
					}, 0);

					return donations / factorAmount;
				});

				// console.log(campaignImpacts, "IMPACT STATUS");

				const totalImpact = campaignImpacts.reduce((total, campaignImpact) => total + campaignImpact, 0);

				return {
					id: goal.id,
					text: goal.impact.factor,
					percentage: (totalImpact / goal.target) * 100,
					color: "#CE8DFF"
				};

			});

			setObjectiveStatuses(goalStatus);

			// result.data.map((goal) => {
			// 	var totalDonation = 0;
			// 	var percentage = 0;
			// 	goal.impact.campaigns.map((campaign) => {
			// 		campaign.donations.map((donate) => {
			// 			if (donate.date >= goal.start_date && donate.date <= goal.end_date) {
			// 				totalDonation = totalDonation + Number(donate.amount);
			// 			}
			// 		});
			// 	});
			// 	percentage = ((totalDonation / Number(goal.target)) * 100).toFixed(1);
			// 	objectiveArray.push({ id: goal.id, text: goal.impact.factor, percentage: percentage, color: "#CE8DFF" });
			// });
			// setObjectiveStatuses(objectiveArray);
		}

	};


	useEffect(() => {
		getGoal();
		getOnGoingCampaign();
		geCompletedCampaign();
	}, []);



	return (

		<div>

			<div className="dashboard-card-inverse" style={{ marginTop: "25px" }} id="dashboard-card-inverse">
				<span className="s3 white-color" style={{ color: "white" }}>Objective Status</span>
				<div className="d-flex justify-content-center">
					<div
						className="d-flex justify-content-center dashboard-card-inner-wrapper"
						style={{ marginTop: "15px", position: "relative" }}>
						<ObjectiveCard
							// onClick={() => history.push(`goals`)}
							objectiveStatuses={objectiveStatuses}
						/>
					</div>
				</div>
			</div>

			<div className="dashboard-card" style={{ marginTop: "25px" }} id="onGoing-Campigns-container">
				<span className="s3 nero-color" >Ongoing Campaigns</span>
				<div className="d-flex flex-column align-items-center">
					{
						onGoingCampaigns.length > 0
							?
							onGoingCampaigns.map((onGoingCampaign, index) => {
								const { particpents, amountDonate, dataSeries } = calculateUser(onGoingCampaign);

								return (
									<CampaignProgressCard
										onClick={() => history.push(`campaigns/id=${onGoingCampaign.id}`)}
										key={index}
										cardColor={(index % 2 === 0) ? "#CE8DFF" : "#7ED0FF"}
										hasBorder={index === 0 ? false : true}
										campaignName={onGoingCampaign.name}
										charityName={onGoingCampaign.charity.name}
										participants={particpents}
										currentObjective={amountDonate}
										totalObjective={onGoingCampaign.target}
										dataSeries={dataSeries}
										enableStatusCards={true}
										style={{ marginTop: "20px", width: "100%" }}
									/>
								);
							})
							:
							<CampaignProgressCard
								noData
								cardColor="#CE8DFF"
								style={{ marginTop: "20px", width: "100%" }} />
					}

				</div>
			</div>

			<div className="dashboard-card" style={{ marginTop: "25px", backgroundColor: "transparent", boxShadow: "none", paddingBottom: "0px" }} id="completed-compaigns-contianer-dashboard">
				<FinishedCampaignsContainer completedCampaigns={completedCampaigns} />
			</div>
		</div>
	);
}