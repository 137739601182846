import React from "react";
import { Fragment } from "react";
import Button from "../sub-component/Button";
import ImagePicker from "../sub-component/ImagePicker";
import Input from "../sub-component/Input";

import uploadIcon from "../../assets/icons/basic/share-arrow.svg";

export default function MultiInput(props) {


	const setCurrentData = (e, outerIndex, index) => {
		const currentData = props.value;
		currentData[outerIndex].inputDetails[index] = { ...currentData[outerIndex].inputDetails[index], value: e.target.value };
		props.getMultiInputData({ data: currentData, dataFor: props.dataFor });
	};

	const setCurrentFile = (data) => {
		const currentData = props.value;
		const outerIndex = parseInt(data.imageFor.substr(0, data.imageFor.indexOf("+")));
		const index = parseInt(data.imageFor.substr((data.imageFor.indexOf("+") + 1), data.imageFor.length));
		currentData[outerIndex].inputDetails[index] = { ...currentData[outerIndex].inputDetails[index], value: data.file };
		props.getMultiInputData({ data: currentData, dataFor: props.dataFor });
	};

	const setAddInput = (e, index) => {
		document.getElementById(`addInput${index}`).value = e.target.value;
	};
	const getFile = (data) => {
		document.getElementById(`${data.imageFor}`).classList.remove("imagePickerAdjust");
		document.getElementById(`${data.imageFor}`).src = data.file;
	};
	const addMultiInputData = () => {
		const previousValues = props.value;
		const currentValue = [];
		props.inputTypes.map((input, index) => {
			let tempObj = {};
			if (input === "input") {
				tempObj = { inputType: input, value: document.getElementById(`addInput${index}`).value };
			}
			else if (input === "textareaInput") {
				tempObj = { inputType: input, value: document.getElementById(`addInput${index}`).value };
			}
			else if (input === "image") {
				tempObj = { inputType: input, value: document.getElementById(`addInput${index}`).src };
			}
			currentValue.push(tempObj);
		});
		previousValues.push({ inputDetails: currentValue });
		props.getMultiInputData({ data: previousValues, dataFor: props.dataFor });

		props.inputTypes.map((input, index) => {
			if (input === "input") {
				document.getElementById(`addInput${index}`).value = null;
			}
			else if (input === "textareaInput") {
				document.getElementById(`addInput${index}`).value = null;
			}
			else if (input === "image") {
				document.getElementById(`addInput${index}`).src = uploadIcon;
				document.getElementById(`addInput${index}`).classList.add("imagePickerAdjust");
			}
		});
	};


	return (
		<div>
			{
				console.log(props, "labels")
			}
			{
				props.value && props.value.map((value, outerIndex) => {
					console.log(value, "input");
					return (
						<div key={outerIndex} style={{ padding: "15px 0px", borderBottom: "2px solid #CBE3FF" }}>


							{
								value.inputDetails.map((input, index) => {

									return (
										<div key={index} style={{ marginTop: "15px" }}>

											{
												input.inputType === "input" &&
												<Input
													label={props?.labels?.length > 0 && props.labels[index]}
													id={`MICurrentInput${index}`}
													value={input.value}
													onChange={(e) => setCurrentData(e, outerIndex, index)} />
											}
											{
												input.inputType === "textareaInput" &&
												<Input
													textArea
													label={props?.labels?.length > 0 && props.labels[index]}
													id={`MICurrentInput${index}`}
													value={input.value}
													onChange={(e) => setCurrentData(e, outerIndex, index)}
												/>
											}
											{
												input.inputType === "image" &&
												<ImagePicker
													label={props?.labels?.length > 0 && props.labels[index]}
													height={76}
													width={76}
													image={input.value}
													getFile={setCurrentFile}
													imageFor={`${outerIndex}+${index}`}
												/>
											}
										</div>
									);
								})
							}
						</div>
					);
				})
			}
			<Fragment>
				{
					(props.length && props.length > props.value.length) &&
					<Fragment>
						{
							props.inputTypes && props.inputTypes.map((inputType, index) => {
								return (
									<div key={index} style={{ marginTop: "15px" }}>
										{
											inputType === "input" &&
											<Input
												// value="123"
												label={props?.labels?.length > 0 && props.labels[index]}
												id={`addInput${index}`}
												name={`addInput${index}`}
												onChange={(e) => setAddInput(e, index)}
											/>
										}
										{
											inputType === "textareaInput" &&
											<Input
												textArea
												label={props?.labels?.length > 0 && props.labels[index]}
												id={`addInput${index}`}
												name={`addInput${index}`}
												onChange={(e) => setAddInput(e, index)}
											/>
										}
										{
											inputType === "image" &&
											<ImagePicker
												label={props?.labels?.length > 0 && props.labels[index]}
												height={76}
												width={76}
												getFile={getFile}
												imageFor={`addInput${index}`}
												id={`addInput${index}`}
											/>
										}
									</div>
								);
							})
						}
						< div className="w-100 d-flex justify-content-start">
							<Button visualBuilderButton="blue" title="Add" style={{ marginTop: "15px" }} onClick={() => addMultiInputData()} />
						</div>
					</Fragment>
				}
			</Fragment>

		</div >
	);
}
