import React, { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import articleImage from "../../assets/images/dashboard/article.png";

//Dashboard Menu
import DashbordMenu from "../layouts/dashboard-menu-wrapper/DashbordMenu";

//mobile and laptop screen header
import MidLayerHeader from "../../components/dashboard/mid-page/MidLayerHeader";
import { ReactComponent as MobileMenu } from "../../assets/icons/basic/menu.svg";
import { ReactComponent as MobileLogo } from "../../assets/icons/Genral/mobileLogo.svg";
import { ReactComponent as MoblieRightSideIcon } from "../../assets/icons/Genral/Suggestion.svg";

// pages
import Dashboard from "./Dashboard/Dashboard";
import CampaignDetails from "./Dashboard/Campaigns/CampaignDetails";
import GoalsDetails from "./Dashboard/Goals/GoalsDetails";
import Goals from "./Dashboard/Goals";
import Campaign from "./Dashboard/Campaigns/Campaign";
import CreateCampaign from "./Dashboard/Campaigns/CreateCampaign";
import Audit from "./Dashboard/Audit/Audit";
import CompanySettings from "./Dashboard/Settings/CompanySettings";
// import UsersSettings from "./Dashboard/Settings/UsersSettings";
import EmployeesSettings from "./Dashboard/Settings/EmployeesSettings";
import BillingSettings from "./Dashboard/Settings/BillingSettings";
import CharitySettings from "./Dashboard/Settings/CharitySettings";

//right panels
// import DashboardMain from "../layouts/dashboard-right-panels/DashboardRight";
import OnGoingCampaignPanel from "../layouts/dashboard-right-panels/OnGoingCampaign";
import CompletedCampaignPanel from "../layouts/dashboard-right-panels/CompletedCampaign";
// import VisualBuilder from "./visual-builder/VisualBuilder";

//right panel items
import SuggestedCampaignsCard from "../../components/dashboard/right-side-menu/SuggestedCampaignsCard";
// import SuggestedCharitiesCard from "../../components/dashboard/right-side-menu/SuggestedCharitiesCard";
// import SuggestedRewardCard from "../../components/dashboard/right-side-menu/SuggestedReward";
// import EmployeeSetupForm from "../../components/SetupWizad/EmployeeSetupForm";

import CampaignSettings from "../../views/layouts/dashboard-right-panels/OnGoingCampaign";
import ImpactAndObjectivesDeatils from "./Dashboard/Goals/ImpactAndObjectivesDeatils";
import UserSettings from "./Dashboard/Settings/UsersSettings";
import { useAuth } from "../../context/AuthContext";

import ErrorPage from "./ErrorPage";
import SubscriptionWebHooks from "./Dashboard/subscription-webhooks/SubscriptionWebHooks";
import DomainSetup from "../../components/SetupWizad/DomainSetup";

const suggestedArticles = {
	onDashboardPage: [{ link: "https://help.altrunic.com/en/articles/5477428-understanding-the-dashboard-screen", headerTitle: "Suggested Help Article", title: "Understanding the Dashboard Screen", image: articleImage, description: "This screen will serve as a Reporting screen where you can analyze the progress of your donation campaigns as well as their corresponding impact objectives. Learn more in this article." }],
	onImpactObjectivesPage: [{ link: "https://help.altrunic.com/en/articles/5477385-editing-and-setting-up-impact-goals", headerTitle: "Suggested Help Article", title: "Editing and setting up Impact goals", image: articleImage, description: "This screen will give you all the information on the Impact objectives you created and can also add new ones. Learn more here in this article." }],
	onCampaignPage: [{ link: "https://help.altrunic.com/en/articles/5477503-setting-up-the-campaign", headerTitle: "Suggested Help Article", title: "Setting up your donation campaign", image: articleImage, description: "Creating a campaign may seem like a big task, but with this guide, you'll have your first campaign up and running with no complexity!" }],
	onAuditPage: [{ link: "https://help.altrunic.com/en/articles/5667615-auditing-the-donation-activities-by-volunteers", headerTitle: "Suggested Help Article", title: "Auditing the donation activities by volunteers", image: articleImage, description: "You can audit the activities of the donors who donated to your donation campaigns including company employees or a guest from outside the organization." }],

	onCompanySettingsPage: [{ link: "https://help.altrunic.com/en/articles/5477354-entering-the-company-information-company-setup", headerTitle: "Suggested Help Article", title: "Entering the Company information", image: articleImage, description: "Please provide your company basic details i.e Name, address, phone and website url to help us complete your company profile." }],
	onUserSettingsPage: [{ link: "https://help.altrunic.com/en/articles/5477389-inviting-the-team-members", headerTitle: "Suggested Help Article", title: "Inviting the team members", image: articleImage, description: "Share your company's social activities with your colleagues so that maximum effect can be achieved.Article link:" }],
	onImpactSiteSettingsPage: [{ link: "https://help.altrunic.com/en/articles/5477371-entering-the-charity-site-url-charity-site", headerTitle: "Suggested Help Article", title: "Configuring & Entering the Charity Site URL", image: articleImage, description: "You can learn more about the configuration of your impact site setting here about three of different subscription plans." }],
	onBillingSettingsPage: [{ link: "https://help.altrunic.com/en/articles/5480716-billing-screen", headerTitle: "Suggested Help Article", title: "Understanding of Billing and Transaction information", image: articleImage, description: "You can see all the transactions on a single screen anytime. You can check the status of each transaction whether it failed or was processed successfully." }],

};



export default function DashboardPageContainer() {

	const { userProfile } = useAuth();
	// const settlement = useSettlementContext();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
	const [campaignId, setCampaignId] = useState(null);
	const [selectedPage, setSelectedPage] = useState({
		pageIndex: 0,
		pageLabel: "Dashboard",
		selectedRightPanel: 0,
	});
	const match = useRouteMatch();

	const rightPanels = [
		{ panel: <SuggestedCampaignsCard campaigns={suggestedArticles.onDashboardPage} btnTitle="Go to Article" /> }, //will display on dashboard page
		{ panel: <SuggestedCampaignsCard campaigns={suggestedArticles.onImpactObjectivesPage} btnTitle="Go to Article" /> },//will display on impact objective page
		{ panel: <SuggestedCampaignsCard campaigns={suggestedArticles.onCampaignPage} btnTitle="Go to Article" /> },//will display on campaigns page
		{ panel: <SuggestedCampaignsCard campaigns={suggestedArticles.onAuditPage} btnTitle="Go to Article" /> },//will display on audit page
		{ panel: null },
		{ panel: <CampaignSettings campaignId={campaignId} /> },
		{ panel: <OnGoingCampaignPanel campaignId={campaignId} /> },
		{ panel: <CompletedCampaignPanel /> },
		{ panel: null },
		{
			panel: [
				<SuggestedCampaignsCard key={0} campaigns={suggestedArticles.onCompanySettingsPage} btnTitle="Go to Article" />,
				<SuggestedCampaignsCard key={1} campaigns={suggestedArticles.onUserSettingsPage} btnTitle="Go to Article" />,
				<SuggestedCampaignsCard key={2} campaigns={suggestedArticles.onImpactSiteSettingsPage} btnTitle="Go to Article" />,
				<SuggestedCampaignsCard key={3} campaigns={suggestedArticles.onBillingSettingsPage} btnTitle="Go to Article" />,
			],
		},
	];

	const SetMenuState = () => {
		if (!isMenuOpen) {
			if (isRightMenuOpen) {
				document
					.getElementById("suggestions-menu")
					.classList.remove("menu-slide-left");
				setIsRightMenuOpen(!isRightMenuOpen);
			}
			document.getElementById("mobile-menu").classList.add("menu-slide-right");
			setIsMenuOpen(!isMenuOpen);
		} else {
			document
				.getElementById("mobile-menu")
				.classList.remove("menu-slide-right");
			setIsMenuOpen(!isMenuOpen);
		}
	};


	const getCampaignId = (id) => {
		// console.log("Container Campaign ID:", id);
		setCampaignId(id);
	};

	const SetSuggentionsMenuState = () => {
		if (!isRightMenuOpen) {
			if (isMenuOpen) {
				document
					.getElementById("mobile-menu")
					.classList.remove("menu-slide-right");
				setIsMenuOpen(!isMenuOpen);
			}
			document
				.getElementById("suggestions-menu")
				.classList.add("menu-slide-left");
			setIsRightMenuOpen(!isRightMenuOpen);
		} else {
			document
				.getElementById("suggestions-menu")
				.classList.remove("menu-slide-left");
			setIsRightMenuOpen(!isRightMenuOpen);
		}
	};

	const getPageNo = (pageNo, label, rightPanelIndex, hasSubMenu) => {
		console.log(pageNo, label, rightPanelIndex, hasSubMenu, "right Index");
		if (hasSubMenu) {
			//
		} else {
			setSelectedPage(
				{
					...selectedPage,
					pageIndex: pageNo,
					pageLabel: label,
					selectedRightPanel: rightPanelIndex,
				});
		}
	};


	return (
		<div style={{overflowX:"hidden"}} >
			<SubscriptionWebHooks />
			<div className="dashboard-wrapper" >

				<div
					id="mobile-menu"
					className="menu d-flex flex-column justify-content-between">
					<DashbordMenu getSelectedItem={getPageNo} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
				</div>
				{/*Mobile Responsive Menu*/}
				<div
					className="w-100 on-mobile justify-content-between align-items-center"
					style={{ padding: "15px" }}>
					<MobileMenu onClick={() => SetMenuState()} />
					<MobileLogo />
					<MoblieRightSideIcon onClick={() => SetSuggentionsMenuState()} />
				</div>

				{/* Dashboard Page Routes */}
				<div className="dashboard-page-wrapper ">
					<div className="dashboard-mid-wrapper ">
						<div className="mid-inner-wrapper ">
							<MidLayerHeader label={selectedPage.pageLabel} />
							{
								userProfile && (
									<Switch>
										<Route Route exact path={`${match.path}/`}>
											<Dashboard />
										</Route>

										<Route exact path={`${match.path}/goals/list`}>
											<Goals hasHeaderLine={false} />
										</Route>
										<Route exact path={`${match.path}/campaigns`}>
											<Campaign />
										</Route>
										{
											userProfile.role_assignment.id === 12 && (
												<Route exact path={`${match.path}/audit`}>
													<Audit />
												</Route>

											)
										}

										<Route exact path={`${match.path}/campaigns/id=:id`}>
											<CampaignDetails getSelectedItem={getPageNo} getCampaignId={getCampaignId} />
										</Route>

										<Route exact path={`${match.path}/goals`}>
											<GoalsDetails />
										</Route>
										<Route path={`${match.path}/goals/impact_and_objective/id=:id`}>
											<ImpactAndObjectivesDeatils getSelectedItem={getPageNo} />
										</Route>
										{
											(userProfile.role_assignment.id === 12 || userProfile.role_assignment.id === 14) && (
												<Route path={`${match.path}/campaigns/create`}>
													<CreateCampaign />
												</Route>
											)
										}
										{
											(userProfile.role_assignment.id === 12 || userProfile.role_assignment.id === 14) && (
												<Route path={`${match.path}/campaigns/update/id=:id`}>
													<CreateCampaign shouldUpdate shouldDelete label="Update" />
												</Route>
											)
										}
										{
											userProfile.role_assignment.id === 12 && (
												<>
													<Route path={`${match.path}/settings/company`}>
														<CompanySettings />
													</Route>
													<Route path={`${match.path}/settings/users`}>
														<UserSettings />
														{/* <UsersSettings /> */}
													</Route>
													<Route path={`${match.path}/settings/employees`}>
														<EmployeesSettings />
													</Route>
													<Route path={`${match.path}/settings/charity`}>
														<CharitySettings />
													</Route>
													<Route path={`${match.path}/settings/billing`}>
														<BillingSettings />
													</Route>
													<Route path={`${match.path}/settings/domain-setup`}>
														<DomainSetup />
													</Route>

												</>
											)
										}
										<Route path="/error" component={ErrorPage} />
										<Redirect to="/error" />
									</Switch>
								)}
						</div>
					</div>
					{
						((!window.location.pathname.includes("/dashboard/campaigns/create")) && (!window.location.pathname.includes("/dashboard/campaigns/update"))) && <>
							<div id="suggestions-menu" className="suggestion-menu">
								<div className="dashboard-right-wrapper">
									{selectedPage.selectedRightPanel !== null &&
								Array.isArray(rightPanels[selectedPage.selectedRightPanel].panel)
										?
										<>
											{
												rightPanels[selectedPage.selectedRightPanel].panel[selectedPage.pageIndex]
											}
										</>
										: rightPanels[selectedPage.selectedRightPanel].panel}

									{/* {
							selectedPage.selectedRightPanel !== null &&
							rightPanels[7].panel
						} */}
								</div>
							</div>
						</> 
					}
					
				</div>
			</div >
		</div>
	);
}
