import React, { useState } from "react";
import { Link } from "react-router-dom";
// import logo from "../../../assets/icons/visual-builder/logo_visualBuilder.png";
// import logo_mobile from "../../../assets/icons/landing page 2/logo_mobile.svg";
import logo from "../../../assets/icons/logo/Base Large BETA-min.png";
import logo_mobile from "../../../assets/icons/landing page 2/LOGO_BETA.svg";
import Button from "../../../components/sub-component/Button";

import { ReactComponent as MenuIcon } from "../../../assets/icons/landing page 2/Menu.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/basic/cross.svg";
// import ProfileButton from "../sub-components/Button";
// import { useAuth } from "../../../context/AuthContext";

export default function Navbar(props) {

	// const auth = useAuth();

	const [openMobileMenu, setOpenMobileMenu] = useState(false);

	const openMobileMenuHandle = () => {
		if (openMobileMenu) {
			setOpenMobileMenu(false);
			document.getElementById("lp-navbar-menu-id").classList.remove("lp-navbar-mobile-menu-active");
		}
		else {
			document.getElementById("lp-navbar-menu-id").classList.add("lp-navbar-mobile-menu-active");
			setOpenMobileMenu(true);
		}

	};


	return (

		<div style={{ overflow: `${openMobileMenu ? "hidden" : "scroll"}`, height: `${openMobileMenu ? "100vh" : "max-content"}` }}>
			<div className="lp-navbar-mobile">
				<div id="lp-navbar-menu-id" className="lp-navbar-mobile-menu">
					<div className="d-flex justify-content-end align-items-center w-100 py-3 px-4" style={{ marginRight: "20px" }}>
						<CrossIcon style={{ width: "17px", height: "16px", fill: "white" }} onClick={() => openMobileMenuHandle()} />
					</div>
					<div className="lp-navbar-menu-items" style={{ borderTop: `1px solid ${props.primaryColor}` }}>
						<a
							className=""
							href={`/`} target="_blank" rel="noopener noreferrer"
							style={{ marginTop: "15px", marginBottom: "70px", width: "90%", height: "100%" }}
						>
							<div className="d-flex flex-column  justify-content-between" style={{ width: "100%", height: "100%" }}>
								<div className="d-flex flex-column-reverse align-items-center justify-content-start">
									<Link className="ls-b0-5 white-color nav-item-mobile" to="/about" style={{ cursor: "pointer", paddingTop: "7px", height: "40px", marginBottom: "20px", width: "100%" }}>About Us</Link>
									<Link to="/pricing" className="ls-b0-5 white-color nav-item-mobile" style={{ cursor: "pointer", paddingTop: "7px", height: "40px", marginBottom: "20px", width: "100%" }}>Pricing</Link>
									<Link to="/features" className="ls-b0-5 white-color nav-item-mobile" style={{ cursor: "pointer", paddingTop: "7px", height: "40px", marginBottom: "20px", width: "100%" }}>Features</Link>
									{/* <Link to="/blogs" className="ls-b1 cloud-burst-color" style={{ cursor: "pointer" }}>Blogs</Link> */}
									<Link to="/" className="ls-b0-5 white-color nav-item-mobile" style={{ cursor: "pointer", paddingTop: "7px", height: "40px", marginBottom: "20px", width: "100%" }}>Home</Link>
								</div>
								<div className="d-flex flex-column-reverse align-items-center justify-content-start" style={{ width: "100%" }}>
									<Link to="/signup"><div className="get-started-button" style={{ marginTop: "15px" }}>
										<span className="ls-b1 navy-blue-color">Start Free Trial</span>
										{/* <span className="navy-blue-color">It’s free forever!</span> */}
									</div>
									</Link>
									<Link to="/login" className="ls-b0-5 white-color nav-item-mobile" style={{ cursor: "pointer", paddingTop: "7px", height: "40px", marginBottom: "20px", width: "100%" }}>Login</Link>
								</div>
							</div>
						</a >

					</div>
				</div>
			</div>
			<div className="d-flex justify-content-end justify-self-center mobile-direction">
				<div className="lp-navbar">
					<div className="d-flex justify-self-center" style={{ width: "148px", height: "50px" }}>
						<Link to="/"><img className="mt-1" src={logo} style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }} /></Link>
					</div>
					<div className="nav-buttons-container">
						<div className="nav-buttons">
							<Link to="/features" className="ls-b1 cloud-burst-color" style={{ cursor: "pointer", marginRight: "50px" }}>Features</Link>
							<Link to="/pricing" className="ls-b1 cloud-burst-color" style={{ cursor: "pointer", marginRight: "50px" }}>Pricing</Link>
							<Link to="/about" className="ls-b1 cloud-burst-color" style={{ cursor: "pointer", marginRight: "50px" }}>About Us</Link>
							<div className="nav-drop-down-container" style={{ marginRight: "50px" }}>
								<div className="nav-drop-down">
									<span className="ls-b1 cloud-burst-color nav-drop-down-icon">Resources</span>
									<div className="nav-drop-down-box">
										<span className="b5 hoki-color" style={{ marginTop: "12px" }} onClick={() => { window.open("https://help.altrunic.com/en/", "_blank"); }}>Help Center</span>
										<span className="b5 hoki-color" style={{ marginTop: "12px" }} onClick={() => { window.open("https://join.slack.com/t/altrunic/shared_invite/zt-wpe1bd02-4ylI05yuRhiMggxxOjHNfQ", "_blank"); }}>Community</span>
									</div>
								</div>
							</div>
							{/* <Link to="/blogs" className="ls-b1 cloud-burst-color" style={{ cursor: "pointer", marginRight: "50px" }}>Blogs</Link> */}
							<Link to="/charities" className="ls-b1 cloud-burst-color" style={{ cursor: "pointer" }}>Charities</Link>
						</div>

						<div className="d-flex align-items-center">
							<Link to="/signup"><Button title="Start Free Trial" className="ls-b0-5" btnContainerClass="navbar-signup-btn" style={{ width: "max-content", padding: "8.5px 36px", height: "45px", marginRight: "40px" }} /></Link>
							<Link className="ls-b0-5 licorice-color navbar-content-text" to="/login" style={{ cursor: "pointer" }}>Sign In</Link>
						</div>
					</div>
				</div>

				<div className="lp-navbar-mobile">
					{
						openMobileMenu
							?
							<div className="d-flex justify-content-between align-items-center" style={{ width: "100vw", paddingLeft: "5%" }}>
								<div>
									<Link to="/"><img className="mobile-logo" src={logo_mobile} /></Link>
								</div>
							</div>
							:
							<div className="d-flex justify-content-between align-items-center" style={{ paddingRight: "5%", paddingLeft: "5%", width: "100vw" }}>
								<Link to="/"><img className="mobile-logo" src={logo_mobile} /></Link>
								<MenuIcon fill={"black"} onClick={() => openMobileMenuHandle()} />
							</div>
					}
				</div>
			</div >
			{props.children}
		</div>
	);
}