import React, { useState } from "react";
import AuthMessageCard from "../../components/auth/AuthMessageCard";
import AlertBox from "../../components/common/AlertBox";
import Button from "../../components/sub-component/Button";
import Input from "../../components/sub-component/Input";
import { useAuth } from "../../context/AuthContext";
import { isEmailValid, openURL } from "../../utilities/Utilites";
import altrunc_logo from "../../assets/icons/logo.png";


export default function ForgotPassword() {

	const auth = useAuth();
	const [email, setEmail] = useState();
	const [alert, setAlert] = useState(null);
	const [processing, setProcessing] = useState(false);

	function handleInput(event) {
		setEmail(event.target.value);
	}

	function validateForm() {

		if (!isEmailValid(email)) {
			return { isValid: false, message: "Please enter a valid email" };
		}

		return { isValid: true, message: "" };

	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		const validation = validateForm();

		if (!validation.isValid) {
			setAlert(
				<AlertBox color="danger" title="Invalid Email" description={validation.message} />
			);
			return;
		}

		setProcessing(true);

		const { result, error } = await openURL("/v1/auth/resetpassword/request", "POST", auth, { email: email });

		setProcessing(false);

		if (error) {
			setAlert(
				<AlertBox color="danger" title="Invalid Email" description={error.message} />
			);
		} else if (result) {
			setAlert(
				<AlertBox color="success" title="Success" description={result.message} />
			);
		}

	};

	return (

		<div className="d-flex flex-lg-row flex-column">
			<div className="d-sm-flex message-card-mobile" style={{ width: "100%", height: "auto", position: "relative", padding: "20px 0" }}>
				<AuthMessageCard paragraph={
					["Every action we take ",
						<span style={{ color: "#0054DC" }} key="0">impacts</span>,
						" the lives of others around us.",
					]}
				/>
			</div>

			<form style={{ height: "100vh", width: "100%", padding: "0 5.75%", }} className="d-flex flex-column justify-content-center" onSubmit={handleSubmit}>

				<div className="d-flex flex-column align-self-center" style={{ height: "auto", width: "100%", paddingBottom: "40px" }}>

					<div className="message-card-logo-mobile">

						<img src={altrunc_logo} />

					</div>

					<span className="s2" style={{ textAlign: "left" }}>Forgot Password.</span>

					<div style={{ width: "100%", marginTop: "56px" }}>
						<Input placeholder="Email" label="" underLine onChange={handleInput} />
					</div>

					<div style={{ width: "100%", marginTop: "41px" }}>
						<Button title="Continue" style={{ width: "100%" }} onClick={handleSubmit} busy={processing} />
					</div>

				</div>

				<div style={{ position: "relative", width: "100%" }}>
					<div style={{ position: "absolute", width: "100%" }}>
						{alert}
					</div>
				</div>


			</form>

		</div>
	);

}