import React from "react";
import img1 from "../../../../assets/images/Features Page/Feature 1A-min.png";
import img2 from "../../../../assets/images/Features Page/Homepage - Donation 1-min.png";
import img3 from "../../../../assets/images/Features Page/Charity Homepage 1-min.png";
import img4 from "../../../../assets/images/Features Page/Charity Homepage 2-min.png";
import bg1 from "../../../../assets/icons/Features Page/bg1.svg";
import bg2 from "../../../../assets/icons/Features Page/bg2.svg";
// import { Fragment } from "react";


export default function MainFeatures(props) {

	const mainFeaturesData = [{
		heading: "Impact Objectives",
		title: "Redefining Corporate Social Responsibility",
		descriptionP1: "Translate your corporate social efforts into measurable impacts provided by every non-profit charity that makes our platform transparent where you know exactly where your each single dollar reflects on Well-Being of the society.",
		descriptionP2: null,
		image: img1,
		tag_text_top: "Lorem Ipsum",
		tag_text_bot: "Dolor"
	},
	{
		heading: "Impact Websites",
		title: "Branded Impact Web Experience",
		descriptionP1: "Altrunic facilitates every company providing a hosted impact website with branded experience for your customers and employees to project the social image, your company wants to present.",
		descriptionP2: null,
		image: img3,
		tag_text_top: "Lorem Ipsum",
		tag_text_bot: "Dolor"
	},
	{
		heading: "Campaigns",
		title: "Making It Easy for Companies to Give Back",
		descriptionP1: "We have made easy for every company to start philanthropy efforts and make a positive impact on their communities by using an advanced Campaigns module where they can select a charity by navigating on Charity Map.",
		descriptionP2: null,
		image: img2,
		tag_text_top: "Lorem Ipsum",
		tag_text_bot: "Dolor"
	},
	{
		heading: "Advanced Giving",
		title: "Advanced Giving for Every Business",
		descriptionP1: "Altunic's Advanced Giving enables every business to participate with their employees and customers in philanthropy efforts by donation matching & checkout giving.",
		descriptionP2: null,
		image: img4,
		tag_text_top: "Lorem Ipsum",
		tag_text_bot: "Dolor"
	}];

	const blueRight = (index) => {
		return (
			<div className="main-feature-mobile-right d-flex flex-row justify-content-center w-100" style={{ paddingBottom: "100px", height: "fit-content" }}>
				<div className="d-flex flex-column justify-content-center image-mobile">
					{/* <div className="image-tag d-flex align-self-end navy-blue-bg s3 white-color">{index.tag_text_top}</div> */}
					<img src={index.image} style={{ width: "100%", height: "100%", filter: "drop-shadow(2px 8px 15px rgba(0, 0, 0, 0.1))" }} />
					{/* <div className="image-tag lavender-blue-bg s3">{index.tag_text_bot}</div> */}
				</div>
				<div className="blue-box" style={{ backgroundImage: `url(${bg1})` }}>
					<span className="title-box ls-s0 white-color" style={{ lineHeight: "30px" }}>{index.heading}</span>
					<span className="title-box ls-h6 white-color" style={{ lineHeight: "30px", fontStyle: "italic", fontWeight: "600" }}>{index.title}</span>
					<span className="description-box ls-b1 white-color" style={{ lineHeight: "25px", marginTop: "16px" }}>{index.descriptionP1}</span>
					<span className="description-box ls-b1 white-color" style={{ lineHeight: "25px" }}>{index.descriptionP2}</span>
				</div>
			</div>
		);
	};

	const blueLeft = (index) => {
		return (
			<div className="d-flex flex-row justify-content-center w-100 main-feature-mobile-left" style={{ paddingBottom: "100px", height: "fit-content" }}>
				<div className="blue-box" style={{ backgroundImage: `url(${bg2})` }}>
					<span className="title-box ls-s0 white-color" style={{ lineHeight: "30px" }}>{index.heading}</span>
					<span className="title-box ls-h6 white-color" style={{ lineHeight: "30px", fontStyle: "italic", fontWeight: "600" }}>{index.title}</span>
					<span className="description-box ls-b1 white-color" style={{ lineHeight: "25px", marginTop: "16px" }}>{index.descriptionP1}</span>
					<span className="description-box ls-b1 white-color" style={{ lineHeight: "25px" }}>{index.descriptionP2}</span>
				</div>
				<div className="d-flex flex-column justify-content-center image-mobile">
					{/* <div className="image-tag d-flex align-self-end navy-blue-bg s3 white-color">{index.tag_text_top}</div> */}
					<img src={index.image} style={{ width: "100%", height: "100", filter: "drop-shadow(2px 8px 15px rgba(0, 0, 0, 0.1))" }} />
					{/* <div className="image-tag lavender-blue-bg s3">{index.tag_text_bot}</div> */}
				</div>
			</div>
		);
	};

	return (
		<div className="main-features landing-site-container">

			{
				mainFeaturesData.map((data, index) => {
					return (

						<div key={index} ref={index === 0 ? props.featureFirstBoxRef : index === 1 ? props.featureSecondBoxRef : index === 2 ? props.featureThirdBoxRef : props.featureFourthBoxRef}>
							{
								index % 2 === 0
									?
									blueRight(data)
									:
									blueLeft(data)
							}
						</div>
					);
				})
			}

		</div>
	);
}