import React from "react";
import Navbar from "../Navbar";
import { Col, Container, Row } from "reactstrap";
import storyImage from "./../../../../assets/images/landing/aboutus/Rectangle 320-min (2).png";
import ourmission from "./../../../../assets/images/landing/aboutus/Rectangle 322-min (2).png";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

export default function AboutUs() {

	return (
		<Navbar>

			<Helmet>
				<title>About Altrunic - Get to know about the story and values behind us</title>
				<meta name="description" content="Altrunic helps businesses create measurable social impact with the help of their employees and customers. It also facilitates every business to make an impact website with a bundle of customizing elements. Create your business’s impact website now!" />
			</Helmet>

			<div className="d-flex flex-column">
				<span className="ls-h4 text-center" style={{ marginTop: "70px", marginBottom: "30px" }}>About Us</span>

				<div className="about-wrapper">
					<Container>

						<Row className="story-margin">
							<Col>

								<div className="d-flex align-items-center story-wrapper about-us-content-wrapper">

									<div className="story-text-wrapper-left ">
										<span className="ls-h4 mb-5">Our Story</span>
										<span className="about-us-content ls-b0-5 hoki-color">
											America is a pretty polarized place right now. We&apos;re inundated with negative news and it&apos;s taking a toll on our view of the world. We felt like we needed to do something to help make a difference. <br /><br />

											We didn’t have to look very far: it was right in front of us. Our entrepreneurs are motivated by a passion for their customers and desire to make their communities better. Across industries and across the world, there are some striking similarities that stand out: They share two things that are essential to success: a love for customers, and a desire to improve the community around them. <br /><br />

											Altrunic was born to help bring good to the world. We want to celebrate the small businesses who make a difference in their communities. These people are making the world a better place and we want to uplift them. We believe that with greater visibility, more good will happen, and we can all work together and build a sustainable kindness movement driven at the community level.
										</span>

										<div className="story-image-wrapper-right">
											<img src={storyImage} alt="story image" />
										</div>

									</div>
								</div>

							</Col>
						</Row>

						<Row className="story-margin">
							<Col>

								<div className="d-flex align-items-center story-wrapper our-mission-wrapper about-us-content-wrapper">

									<div className="story-text-wrapper-right">
										<span className="ls-h4 mb-5">Our Mission</span>
										<span className="about-us-content ls-b0-5  hoki-color">
											The idea of doing good at the expense of one&apos;s business still persists. But, we know there&apos;s a reality that your business must be profitable to give back.  We believe doing good will make your business stand out. We&apos;ve built a platform that makes it easy to grow and impact society. We believe the good you do can differentiate your business in an authentic way that&apos;s best for the bottom line as well as the community. That&apos;s why we&apos;ve built a platform to make it easy and engaging for you to give back.
										</span>

										<div className="story-image-wrapper-left">
											<img src={ourmission} alt="story image" />
										</div>

									</div>
								</div>

							</Col>
						</Row>

					</Container>
				</div>

				<div className="value-wrapper">

					<Container>

						<Row>
							<Col>

								<div className="value-text-wrapper">
									<div className="d-flex justify-content-center">
										<span className="text-white ls-h4" style={{ fontWeight: "600", margin: "auto" }}>Our Values</span>
									</div>
									<div className="value-text-box">
										<span className="text-white ls-h4 text-left" style={{ fontWeight: "600" }}>Sustainability</span>
										<p className="text-white ls-b0-5 text-left" style={{ lineHeight: "25px", marginTop: "10px" }}>{"We want to build a sustainable world. That's why we're creating a streamlined system that lets people record and report their sustainability-focused initiatives.. Communities and stakeholders can then get engaged with this new system in an engaging way through the charity website."}</p>
									</div>

									<div className="value-text-box">
										<span className="text-white ls-h4 text-left" style={{ fontWeight: "600" }}>Transparency</span>
										<p className="text-white ls-b0-5 text-left" style={{ lineHeight: "25px", marginTop: "10px" }}>{"As donors see the impact of their giving, they are more likely to want to give more. We want to make giving easier, and we've developed state-of-the-art software for generating social impact and strengthening company culture. It lets you run a charitable campaign with the best charities around the world and tools to measure impact."}</p>
									</div>

									<div className="value-text-box">
										<span className="text-white ls-h4 text-left" style={{ fontWeight: "600" }}>Efficiency</span>
										<p className="text-white ls-b0-5 text-left" style={{ lineHeight: "25px", marginTop: "10px" }}>{"Charities are so important to our society, but they're limited by their resources. They're using old technology and outdated software that's not efficient. We want to change that. Altrunic is using technology-- one of the most important concepts of this century-- to make charities more efficient. "}</p>
									</div>

									<div className="value-text-box">
										<span className="text-white ls-h4 text-left" style={{ fontWeight: "600" }}>Kindness</span>
										<p className="text-white ls-b0-5 text-left" style={{ lineHeight: "25px", marginTop: "10px" }}>{"Kindness is an important human trait. Every day, people around the world donate their time and money to those in need. We want to promote this kindness by showcasing the whole flow and impact of a donation. It will show how powerful kindness can be, and empower more people to do the same."}</p>
									</div>

								</div>

							</Col>
						</Row>

					</Container>
				</div>


				<div>
					<Footer />
				</div>

			</div>
		</Navbar>
	);

}
