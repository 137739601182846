import React, { useState } from "react";
import Campaignimage from "../assets/images/CampaignImage.png";
import ProgressBar from "../components/sub-components/ProgressBar";
import Button from "../components/sub-components/Button";
import Campaigncardimage from "../assets/images/CampaignImage1.png";
import { ReactComponent as HeartIcon } from "../assets/icons/basic/icon.svg";
import { ReactComponent as InfinityIcon } from "../assets/icons/various/infinity.svg";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShareBtn from "./sub-components/ShareBtn";
import { useDonation } from "../context/DonationContext";
import { useAuth } from "../context/AuthContext";
// import ProfilePage from "../views/pages/Charity-Site/ProfilePage";
// import DonationLoginCard from "./DonationLoginCard";
// import DonationCardDetails from "./DonationCardDetails";


/**
 * 
 * @param {object} props 
 * @param {Array} props.Donation 
 * @returns 
 */
export default function CampaignCard(props) {

	const auth = useAuth();
	const donation = useDonation();

	var prices = [{ id: 1, value: Number(props.minDonate) }, { id: 2, value: props.minDonate + 20 }, { id: 3, value: props.minDonate + 50 }, { id: 4, value: null }];
	// var prices = [{ id: 1, value: 10 }, { id: 3, value: 50 }, { id: 4, value: 100 }, { id: 5, value: null }];

	const [selectedPrice, setSelectedPrice] = useState(prices[0]);
	

	function getTotalDonations() {

		if (props.donations) {
			return props.donations.reduce((total, donation) => Number(donation.amount) + total, 0);
		} else {
			return 0;
		}

	}

	function handlePriceChange(index) {
		setSelectedPrice(prices[index]);
	}

	function handleDonationNow() {

		if (auth.is_editing) {
			console.log(donation);
			return;

		}

		if (selectedPrice.value < props.minDonate) {
			alert(`Cannot donate less than ${props.minDonate}`);
			return;
		}

		donation.setDonationDetails(prev =>({
			...prev, 
			donation_amount: isNaN(selectedPrice.value) ? (props.minDonate) : (selectedPrice.value), 
			campaign_id: props.campaignId,
			donor_email: auth?.userProfile?.email
		}));

	}

	function handleKeyPress(event) {

		if (isNaN(event.key)) {
			event.preventDefault();
		}

		if (event.target.value > 9999) {
			event.preventDefault();
		}

	}

	if(parseInt(props.templateId)===1){
		return (
			<div className="w-100" id="campaign-card">
				{
					props.isFeatureCampaignCard ? (
						<div>
							<Row className="campaign-card m-0" style={{ flexWrap: "nowrap" }}>
								<Col xs="6" className="p-0 d-flex" style={{ position: "relative" }}>
									<Link to={auth.is_editing ? "#" : props.to} style={{ textDecoration: "none", width: "100%", height: "100%", }}>
										<img
											className="pointer"
											src={props.heroHeaderImage || Campaignimage}
											alt=""
											style={{ objectFit: "cover" }}
										/>
									</Link>
									<div className="share-btn-container" >
										<ShareBtn
											componentId={props.componentId}
											title={props.campaignCardTitle}
											description={`${props.campaignCardTitle} Description`}
											image={"https://www.steadylearner.com/static/images/brand/prop-passer.png"}
											color={props.primaryColor} />
									</div>
								</Col>
								<Col xs="6" className="campaign-card-details d-flex flex-column align-items-start" style={{ border: `1px solid ${props.primaryColor || "#4267B2"}` }}>
									<Link to={auth.is_editing ? "#" : props.to} style={{ textDecoration: "none" }} className="d-flex flex-column align-items-start">
										<div className="campaign-card-title">
											<p className="heading1">{props.campaignCardTitle || "Campaign Title Not Found"}</p>
										</div>
										<span className="cs-p1 campaign-card-impact"> <span className="cs-b2">Impact: </span> {props.campaignCardImpact || "Campaign Impact Not Found"}</span>
										<span className="cs-p1 campaign-card-matching"><span className="cs-b2">Matching:</span> {props.campaignCardMatching || "We will participate as much as you donate"} </span>
										<div className="campaign-card-description">
											<p className="cs-p2 mb-0" style={{ color: "#364254" }}>
												{props.campaignCardDescription || "Campaign Discriptio Not Found"}
											</p>
										</div>
										{/* <div className="campaign-card-price"> */}
										<span className="heading2"><span style={{ color: `${props.primaryColor || "#4267B2"}` }}>{`$${props.target - getTotalDonations()}`}</span> to go</span>
										{/* </div> */}
										<div className="campaign-card-propgressbar">
											<ProgressBar
												percentageRight
												barColor={`${props.primaryColor || "#4267B2"}`}
												current={getTotalDonations()}
												total={props.target}
											/>
										</div>
									</Link>
									<div className="campaign-donation-section-container" style={{ width: "100%" }}>
										<div className="donation-price-section" >
											{
												prices.slice(0, prices.length - 1).map((price, index) => {
													if (price.id === selectedPrice.id)
														return (
															<div key={index} onClick={() => handlePriceChange(index)} className="price-button b5 white-color" style={{ backgroundColor: `${props.primaryColor}`, borderColor: `${props.primaryColor}` }}>${price.value}</div>
														);
													else
														return (
															<div key={index} onClick={() => handlePriceChange(index)} className="price-button b5 hoki-color" style={{ borderColor: `${props.primaryColor}` }}>${price.value}</div>
														);
												})
											}
	
											<input
												type="number"
												className="custom-price-button b5 hoki-color"
												style={{ borderColor: `${props.primaryColor}` }}
												placeholder="Custom"
												onChange={(event) => {
													setSelectedPrice({ ...selectedPrice, value: event.target.value });
												}}
												onKeyPress={handleKeyPress}
												onClick={() => handlePriceChange(prices.length - 1)}
											/>
	
										</div>
										<div className="campaign-card-button">
											<Button onClick={handleDonationNow} color={props.primaryColor} title="Donate Now" />
										</div>
									</div>
								</Col>
							</Row >
						</div>
					) : (
						<div className={`${props.isActive ? "campaign-card-vertical" : "completed-campaign-card-vertical"}`} style={{ }}>
							<div className="campaign-card-vertical-image" style={{ position: "relative" }}>
								<Link to={auth.is_editing ? "#" : props.to} style={{ textDecoration: "none", width: "100%", height: "100%", }}>
									<img
										className="pointer"
										src={props.heroHeaderImage || Campaigncardimage}
										alt=""
										style={{ width: "100%", height: "100%", objectFit: "cover" }}
									/>
								</Link>
								<div className="share-btn-container" style={{ right: "10px", bottom: "10px" }} >
									<ShareBtn
										componentId={props.componentId}
										title={props.campaignCardTitle}
										description={`${props.campaignCardTitle} Description`}
										image={"https://www.webtech.co.jp/blog/wp-content/uploads/2015/01/1200x630.png"}
										color={props.primaryColor} />
								</div>
							</div>
							<div className="campaign-vertical-card-details-container" >
							
								<div className="campaign-vertical-card-details" style={{ border: `1px solid ${props.primaryColor || "#4267B2"}` }}>
									<Link to={auth.is_editing ? "#" : props.to} style={{ textDecoration: "none" }}>
										<div className="campaign-vertical-card-title">
											<p className="heading2-t2">{props.campaignCardTitle || "Campaign Title Not Found"}</p>
										</div>
										<div className="campaign-vertical-card-impact">
											<HeartIcon style={{ fill: `${props.primaryColor || "#4267B2"}`, marginRight: "8px" }} width="17px" height="16px" />
											<div className="campaign-vertical-card-impact-details">
												<p className="cs-p2-t2"> <span className="cs-b3-t2">Impact: </span> {props.campaignCardImpact || "Campaign Impact Not Found"}</p>
											</div>
										</div>
										{
											props.isActive && (
												<div className="campaign-vertical-card-matching">
													<div style={{ marginTop: "0px", marginRight: "8px", width: "14px", height: "13px" }}>
														<InfinityIcon style={{ fill: `${props.primaryColor || "#4267B2"}` }} width="17px" height="16px" />
													</div>
													<div>
														<p className="cs-p2-t2 mb-0 text-left"><span className="cs-b3-t2 ">Matching:</span> {props.campaignCardMatching || "Campaign Matching Not Found"}</p>
													</div>
												</div>
											)
										}
										<div className="campaign-vertical-card-description">
											<p className="cs-p2-t2 mb-md-0 mb-2 text-left" style={{ color: "#364254",minHeight:"100px" }}>
	
												{
													props.isActive ? (
														props.campaignCardDescription ||
														"Campaign Discriptio Not Found "
													) : (
														<div>
	
															<p style={{ textAlign: "left" }}>
																{/* {props.charityDescription} */}
																{(props.charityDescription && props.charityDescription.length) > 280 ? (
																	`${props.charityDescription.substring(0, 280)}...`
																) : (
																	props.charityDescription
																)}
															</p>
															<p style={{ textAlign: "left" }}>
																{props.campaignEndDate || "2020"}
															</p>
														</div>
													)
												}
											</p>
										</div>
	
									</Link>
									{
										props.isActive && (
											<>
												<div className="campaign-vertical-card-price mt-5 mt-md-2 ">
													<span className=""><span className="">{`$${props.target - getTotalDonations()}`}</span > to go</span>
												</div>
												<div className="campaign-vertical-card-progress  mt-0">
													<ProgressBar
														percentageRight
														barColor={`${props.primaryColor || "#4267B2"}`}
														current={getTotalDonations()}
														total={props.target}
													/>
												</div>
	
	
												<div className="d-flex align-items-center justify-content-end flex-column">
													<div className="donation-price-section-template-2 pb-2 pb-md-2 pt-2 pt-md-0 row">
														{
															prices.slice(0, prices.length - 1).map((price, index) => {
																if (price.id === selectedPrice.id)
																	return (
																		<div key={index} onClick={() => handlePriceChange(index)} className="price-button b5 white-color mt-2" style={{ backgroundColor: `${props.primaryColor}`, borderColor: `${props.primaryColor}` }}>${price.value}</div>
																	);
																else
																	return (
																		<div key={index} onClick={() => handlePriceChange(index)} className="price-button b5 hoki-color mt-2 " style={{ borderColor: `${props.primaryColor}` }}>${price.value}</div>
																	);
															})
														}
	
														<input
															type="number"
															className="custom-price-button b5 hoki-color mt-2"
															style={{ borderColor: `${props.primaryColor}` }}
															placeholder="Custom"
															onChange={(event) => {
																setSelectedPrice({ ...selectedPrice, value: event.target.value });
															}}
															onClick={() => handlePriceChange(prices.length - 1)}
															onKeyPress={handleKeyPress}
														/>
	
													</div>
													<div className="campaign-vertical-card-button w-100 mb-4 mb-md-0">
														<Button onClick={handleDonationNow} campaignCardButton color={props.primaryColor} title="Donate Now" style={{ width: "100%",padding:"2px 30px",height:"42px",borderRadius:"24px" }} />
													</div>
												</div>
	
											</>
										)
									}
	
								</div>
								{/* <div
									className="campaign-vertical-card-bar"
									style={{ backgroundColor: `${props.primaryColor || "#4267B2"}` }}></div> */}
							</div>
						</div>)
				}
			</div>
	
		);
	}
	else if(parseInt(props.templateId)===2){
		
		return (
			<div className="w-100" id="campaign-card">
				{
					props.isFeatureCampaignCard ? (
						<div className="w-100 col-11 offset-1">
							<div className=" w-100 d-flex campaign-card-template-2 " style={{ flexWrap: "nowrap",border: `1px solid ${props.primaryColor || "#4267B2"}`}}>
								<div className="p-0 d-flex imageContainer" >
									<Link to={auth.is_editing ? "#" : props.to} style={{ textDecoration: "none", width: "100%", height: "100%", }}>
										<img
											className="pointer"
											src={props.heroHeaderImage || Campaignimage}
											alt=""
											style={{ objectFit: "cover" }}
										/>
									</Link>
									<div className="share-btn-container-template-2 " >
										<ShareBtn
											componentId={props.componentId}
											title={props.campaignCardTitle}
											description={`${props.campaignCardTitle} Description`}
											image={"https://www.steadylearner.com/static/images/brand/prop-passer.png"}
											color={props.primaryColor} />
									</div>
								</div>
								<div className="campaign-card-details d-flex flex-column align-items-start justify-content-space-between col-7 offset-5" >
									<Link to={auth.is_editing ? "#" : props.to} style={{ textDecoration: "none" }} className="d-flex flex-column align-items-start w-100">
										<div className="campaign-card-title">
											<p className="heading1" style={{color:props.primaryColor}}>{props.campaignCardTitle || "Campaign Title Not Found"}</p>
										</div>
										<div className="d-flex pb-0 mt-2">

											<HeartIcon style={{ fill: `${props.primaryColor || "#4267B2"}`, marginRight: "8px",marginTop:"0.2rem" }} width="17px" height="13px" />
											<div className="campaign-vertical-card-impact-details pb-0">
												<p className="cs-p2 mb-0"> <span className="cs-b3">Impact: </span> {props.campaignCardImpact || "Campaign Impact Not Found"}</p>
											</div>
										</div>
										{
											props.isActive && (
												<div className="campaign-vertical-card-matching d-flex mt-2">
													<div style={{ marginTop: "0px", marginRight: "8px", width: "14px", height: "13px" }}>
														<InfinityIcon style={{ fill: `${props.primaryColor || "#4267B2"}` }} width="17px" height="16px" />
													</div>
													<div>
														<p className="cs-p2 mb-0"><span className="cs-b3">Matching:</span> {props.campaignCardMatching || "Campaign Matching Not Found"}</p>
													</div>
												</div>
											)
										}
										<div className="campaign-card-description mt-2 mb-2">
											<p className="cs-p2 mb-0" style={{ color: "#364254" }}>
												{props.campaignCardDescription || "Campaign Discriptio Not Found"}
											</p>
										</div>
										{/* <div className="campaign-card-price"> */}
										<span className="heading2 mt-2"><span style={{ color: `${props.primaryColor || "#4267B2"}` }}>{`$${props.target - getTotalDonations()}`}</span> to go</span>
										{/* </div> */}
										<div className="campaign-card-propgressbar  w-100 ">
											<ProgressBar
												percentageRight
												barColor={`${props.primaryColor || "#4267B2"}`}
												current={getTotalDonations()}
												style={{width:"100%"}}
												total={props.target}
											/>
										</div>
									</Link>
									<div className="campaign-donation-section-container flex-column" style={{ width: "100%" }}>
										<div className="donation-price-section-template-2" >
											{
												prices.slice(0, prices.length - 1).map((price, index) => {
													if (price.id === selectedPrice.id)
														return (
															<div key={index} onClick={() => handlePriceChange(index)} className="price-button b5 white-color" style={{ backgroundColor: `${props.primaryColor}`, borderColor: `${props.primaryColor}` }}>${price.value}</div>
														);
													else
														return (
															<div key={index} onClick={() => handlePriceChange(index)} className="price-button b5 hoki-color" style={{ borderColor: `${props.primaryColor}` }}>${price.value}</div>
														);
												})
											}
	
											<input
												type="number"
												className="custom-price-button b5 hoki-color"
												style={{ borderColor: `${props.primaryColor}` }}
												placeholder="Custom"
												onChange={(event) => {
													setSelectedPrice({ ...selectedPrice, value: event.target.value });
												}}
												onKeyPress={handleKeyPress}
												onClick={() => handlePriceChange(prices.length - 1)}
											/>
	
										</div>
										<div className="w-100 ">
											<Button onClick={handleDonationNow} color={props.primaryColor} title="Donate Now" style={{ width: "100%" }} />
										</div>
									</div>
								</div>
							</div >
						</div>
					) : (
						<div className={`${props.isActive ? "campaign-card-vertical2 row" : "completed-campaign-card-vertical2 "}`}  style={{border:`1px ${props.primaryColor} solid`,borderRadius:"24px",width:"100%",maxWidth:"426px"}}>
							<div className="campaign-card-vertical-image col-12" style={{ position: "relative"}}>
								<Link to={auth.is_editing ? "#" : props.to} style={{ textDecoration: "none",height:"100%"}}>
									<div className="imageContainer h-100">
										<img
											className="pointer"
											src={props.heroHeaderImage || Campaigncardimage}
											alt=""
											style={{ width: "100%", height: "100%",objectFit:"cover",borderRadius:"24px",margin:`${props.isActive?"-25px 0 0 0":"15px 0 5px 0"}` }}
										/>
									</div>
									
								</Link>
								<div className="share-btn-container2 position-absolute w-100" style={{ right: "25px", bottom: `${props.isActive?"35px":"15px"}` }} >
									<ShareBtn
										componentId={props.componentId}
										title={props.campaignCardTitle}
										style={{height:"44px",width:"107px",padding:"0"}}
										description={`${props.campaignCardTitle} Description`}
										image={"https://www.webtech.co.jp/blog/wp-content/uploads/2015/01/1200x630.png"}
										color={props.primaryColor} />
								</div>
							</div>
							
							<div className={`col-12  text-left ${props.isActive?"hideScrollBar":"completedCampaignDescriptionBox"}`} style={{width:"fit-content"}} >
	
								<div className="campaign-vertical-card-details pl-1" >
									<Link to={auth.is_editing ? "#" : props.to} style={{ textDecoration: "none" }}>
										<div className="campaign-vertical-card-title">
											<p className="heading2-t2 " style={{color:props.primaryColor}}>{props.campaignCardTitle || "Campaign Title Not Found"}</p>
										</div>
										<div className="d-flex pb-0 mt-2">

											<HeartIcon style={{ fill: `${props.primaryColor || "#4267B2"}`, marginRight: "8px",marginTop:"0.2rem" }} width="17px" height="13px" />
											<div className="campaign-vertical-card-impact-details pb-0">
												<p className="cs-p2-t2 mb-0"> <span className="cs-b3-t2">Impact: </span> {props.campaignCardImpact || "Campaign Impact Not Found"}</p>
											</div>
										</div>
										{
											props.isActive && (
												<div className="campaign-vertical-card-matching d-flex mt-2">
													<div style={{ marginTop: "0px", marginRight: "8px", width: "14px", height: "13px" }}>
														<InfinityIcon style={{ fill: `${props.primaryColor || "#4267B2"}` }} width="17px" height="16px" />
													</div>
													<div>
														<p className="cs-p2-t2 mb-0"><span className="cs-b3-t2">Matching:</span> {props.campaignCardMatching || "Campaign Matching Not Found"}</p>
													</div>
												</div>
											)
										}
									</Link>
								</div>
							
								<div className="campaign-vertical-card-description col-12 mt-2 pl-1">
									<p className="cs-p2-t2 mb-0 " style={{ color: "#364254" }}>
										{
											props.isActive ? (
												
												props.campaignCardDescription ||
												"Campaign Discriptio Not Found "
											) : (
												<div>

													<p style={{ textAlign: "left" }}>
														{/* {props.charityDescription} */}
														{(props.charityDescription && props.charityDescription.length) > 280 ? (
															`${props.charityDescription.substring(0, 280)}...`
														) : (
															props.charityDescription
														)}
													</p>
													
												</div>
											)
										}
									</p>
								</div>
							</div>
							{props.isActive ?(<></>):<>
								<p className="cs-b3-t2 mt-1 col-12 ml-1 text-left" style={{ color: "#364254" }}>					
									{props.campaignEndDate || "2020"}
								</p>
							</>}
							
							{
								props.isActive && (
									<div className="mt-auto col-12 text-left">
										<div className="campaign-vertical-card-price  ">
											<span className=""><span className="">{`$${props.target - getTotalDonations()}`}</span > to go</span>
										</div>
										<div className="campaign-vertical-card-progress  ">
											<ProgressBar
												percentageRight
												templateId={2}
												barColor={`${props.primaryColor || "#4267B2"}`}
												current={getTotalDonations()}
												total={props.target}
											/>
										</div>


										<div className="d-flex align-items-center justify-content-end flex-column">
											<div className="donation-price-section-template-2 row">
												{
													prices.slice(0, prices.length - 1).map((price, index) => {
														if (price.id === selectedPrice.id)
															return (
																<div key={index} onClick={() => handlePriceChange(index)} className="price-button b5 white-color mt-2" style={{ backgroundColor: `${props.primaryColor}`, borderColor: `${props.primaryColor}` }}>${price.value}</div>
															);
														else
															return (
																<div key={index} onClick={() => handlePriceChange(index)} className="price-button b5 hoki-color mt-2 " style={{ borderColor: `${props.primaryColor}` }}>${price.value}</div>
															);
													})
												}

												<input
													type="number"
													className="custom-price-button b5 hoki-color mt-2"
													style={{ borderColor: `${props.primaryColor}` }}
													placeholder="Custom"
													onChange={(event) => {
														setSelectedPrice({ ...selectedPrice, value: event.target.value });
													}}
													onClick={() => handlePriceChange(prices.length - 1)}
													onKeyPress={handleKeyPress}
												/>

											</div>
											<div className="campaign-vertical-card-button w-100 mb-3">
												<Button onClick={handleDonationNow} campaignCardButton color={props.primaryColor} title="Donate Now" style={{ width: "100%",padding:"2px 30px",height:"42px",borderRadius:"24px" }} />
											</div>
										</div>

									</div>
								)
							}

								
							
							
						</div>)
				}
			</div>
	
		);
	}
}