import React, { useState } from "react";
import { Fragment } from "react";

import { ReactComponent as ArrowDown } from "../../../assets/icons/arrows/chevron-bottom.svg";


/**
 * Select Component
 * @param {object} props Form Select Component
 * @param {Function} updateSelections Update Selected States which Send back the selected option To The Parent(or where you call it)
 * @param {String} varient should be either 'multi-select' or null (for single selection)
 * @param {Array} options array of string (options)
 * @param {Array} selected array of selected option. Pass it an array in which you want to save options
*/

export default function Dropdown(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(props.selected);

	const optionSelected = (option) => {
		var dummyArr = [option];
		setSelected(dummyArr);
		document.getElementById(`${props.id}` || "dropdownId").innerHTML = `${option}`;
		setIsOpen(!isOpen);

	};



	return (
		<div className={"d-flex flex-column align-items-start position-relative pointer " + `${props.className}`}
			style={{ ...props.style, }}
			tabIndex="0"
			onClick={props.disable ? () => { } : () => setIsOpen(!isOpen)}
			onBlur={() => { setIsOpen(false); }}
		>
			<span className="b3">{props.title}</span>
			<div className="custom-select-div" style={{ height: "100%", marginTop: "0px" }}
			>
				<div>
					<span
						id={props.id || "dropdownId"}
						style={{maxWidth: "120px"}}
						className=" row-cell b4 d-inline-block text-truncate"
					>{selected || props.placeholder || ""}</span>
				</div>

				<ArrowDown
					width="16"
					height="16"

					style={{ cursor: "pointer" }} />
			</div>

			{
				isOpen &&

				<div
					className="w-100 custom-select-option-container"
					style={{ position: "absolute", marginTop: "1.5em", }}>
					{
						props.options && props.options.map((option, key) => {
							return (
								<Fragment key={key}>

									<div className="custom-select-option" onClick={props.disable ? () => { } : () => {
										optionSelected(option.option);
										if (props.onSelect) {
											props.onSelect(option);
										}
									}}>
										<span className="row-cell b4" style={{ padding: "5px 0" }}>
											{option.option}
										</span>
									</div>
								</Fragment>
							);
						})
					}
				</div>

			}

		</div >
	);
}
