import React, { useEffect, useState } from "react";
import Button from "../sub-component/Button";

export default function PriceCardSubscriptionComponent(props) {

	const [isSelected, setIsSelected] = useState(props.isSelected || false);
	useEffect(() => {
		setIsSelected(props.isSelected);
	}, [props.isSelected]);

	return (
		<div className={`${props?.isOnLandingSite ? "package-card-onlanding-page" : "package-card"} ${(isSelected ? "selected" : "unselected")}`} onClick={props.onCardClick}>
			<div className={`${props?.isOnLandingSite ? "package-card-header-onlanding-page" : "package-card-header"}`} style={props.headerStyle}>
				<span className={` ${props?.isOnLandingSite ? "ls-s0" : "s3"} ${(isSelected || props.isOnHeaderBlue) ? "white-color" : "navy-blue-color"}`} >{props.package}</span>
				<span className={`${props?.isOnLandingSite ? "ls-h4" : "s1"} mt-1 ${(isSelected || props.isOnHeaderBlue) ? "white-color" : "navy-blue-color"}`} style={{ fontWeight: "bold" }}>{props.price}<span className={`${(isSelected || props.isOnHeaderBlue) ? "b3 white-color" : "b3 navy-blue-color"}`}>/mo</span></span>
				{props.titleless && <span className={`${props?.isOnLandingSite ? "ls-h4" : "s1"} mt-1 ${(isSelected || props.isOnHeaderBlue) ? "white-color" : "navy-blue-color"}`} style={{ fontWeight: "bold" }}>Upto 20 Employees</span>}
				{props.titlegreater  && <span className={`${props?.isOnLandingSite ? "ls-h4" : "s1"} mt-1 ${(isSelected || props.isOnHeaderBlue) ? "white-color" : "navy-blue-color"}`} style={{ fontWeight: "bold" }}>20+ Employees</span>}
				<span className={`b5 mt-1 ${(isSelected || props.isOnHeaderBlue) ? "white-color" : "navy-blue-color"}`}>{props.yearlyTotalPrice || ""}</span>
			</div>
			<div className={`${props?.isOnLandingSite ? "package-card-details-container-onlanding-page pl-1 pr-1" : "package-card-details-container pl-1 pr-1"}`}>
				<div className={`text-center package-card-details ${isSelected && "white-color"}`} style={{ lineHeight: "40px" }}>
					{props.description}
				</div>

				{
					props.isCurrentConfirmCard &&
					<div className="selected-package-card">
						<div className="d-flex justify-content-center align-items-center h-100">
							<span>Current Plan</span>
							<span className="tick-icon" style={{ background: "#00D69A" }} />
						</div>
					</div>
				}
				{
					props.shouldBtnShow &&
					<div className="d-flex justify-content-center" style={{ paddingTop: "30px" }}>
						<Button title={props.btnTitle} onClick={props.onClick} className={`${props?.isOnLandingSite ? "ls-b0-5" : ""}`} />
					</div>
				}
			</div>
		</div>
	);
}