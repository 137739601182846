import React, { useState, useEffect } from "react";
import Select from "../sub-component/Select";
import ReactStars from "react-stars";
// import ReactRoundedImage from "react-rounded-image";
import RoundImage from "../../assets/icons/Genral/Unicef.svg";
import { getCookie, getOriginFromLink } from "../../utilities/Utilites";
import Button from "../sub-component/Button";

export default function SelectedCharity(props) {
	const { charity } = props;
	const [impactFectors, setImpactFectors] = useState([]);

	useEffect(() => {
		const tempCharityArr = [];
		if (charity?.charity_impacts?.length > 0) {
			charity?.charity_impacts?.map((impact) => {
				tempCharityArr.push(impact?.impact_statement);
			});
		}
		setImpactFectors(tempCharityArr);
	}, [charity]);

	const token = getCookie("token");

	const getImpactSelection = (impact) => {
		// console.log(charity.charity_impacts[impact.id], "dhoka");
		if (charity?.charity_impacts?.length > 0) {
			props?.getSelectedImpact(charity?.charity_impacts[impact?.id]);
		}
	};
	return (
		<>
			<div className="SSC-selected-charity-box-container hideScrollBar" style={{maxWidth:"90vw",maxHeight:"400px"}}>
				<div className="SSCSC-image">
					<img src={`//logo.clearbit.com/${getOriginFromLink(charity?.charity_url)}` || RoundImage} />
				</div>
				<div className="d-flex flex-column flex-md-row w-100">
					<div className="SSCSC-details" style={{maxWidth:"80%"}}>
						<span className="s3 navy-blue-color" style={{ fontWeight: "700" }}>{charity.name || "Charity Title"}</span>
						{
							impactFectors.length > 1
								?
								<>
									<span className="b4 mischka-selectedImpact.impact_statementcolor" style={{ marginTop: "10px" }}>Select Impact</span>
									<Select
										placeholder="Select SDG"
										options={impactFectors}
										selectionWithKey
										selected={charity.charity_impacts.length > 0 ? charity.charity_impacts[0]?.impact_statement : "No Impact"}
										onSelect={getImpactSelection}
									/>
								</>
								:
								<div className="d-flex mt-2">
									<span className="navy-blue-color" style={{ width: "50px" }}>Impact: </span>
									<span className="map-selected ml-2">{impactFectors[0]}</span>
								</div>
						}

						<div className="SSCSC-details-description-container" style={{ marginTop: "10px" }}>
							<span >{charity.description || "description"}</span>
						</div>

					</div>
					<div className="SSCSC-rating-card">
						<span className="b4 licorice-color">Charity Rating</span>
						<ReactStars
							className="SSCSC-rating-card-stars"
							count={5}
							value={Number(charity.score)/20}
							size={32}
							edit={false}
							half={true}
							color1={"#C4C4C4"}
							color2={"#0054DC"} />
						<div className="SSCSC-rating-card-rating-container">
							<span className="b3">{Number(charity.score) || "N/A"}</span>
							<span className="b4" style={{ color: "#5E7488" }}>Out of 100</span>
						</div>
						<div className="SSCSC-rating-card-side-details-container">
							<span className="b4 hoki-color">{charity.sdg}</span>
							{/* <span className="b4 hoki-color" style={{ marginTop: "10px" }}>Location: {charity.state || "not available"}</span> */}
							<div className="text d-flex" style={{ marginTop: "10px" }}>
								<span className="b4 hoki-color" >Website:</span>
								<span className="b4 hoki-color text-truncate pointer ml-1"
									onClick={charity?.website?.length > 0 ? () => { window.open(charity.website, "_blank"); } : () => { }}
									style={{ textDecoration: "underline" }}>
									{charity.website ? charity.website.replace("http://", "").replace("https://", "").replace("/", "") : "not available"}
								</span>
							</div>

							<span className="b4 hoki-color" style={{ marginTop: "10px" }}>EIN: {charity.ein || "**-******"}</span>
					
						</div>
					
					</div> 
				</div>
			

			</div>
			<div className="d-flex justify-content-center">
				{ token &&
				<Button
					style={{  height: "100%",paddingLeft:"10%",paddingRight:"10%" }}
					onClick={()=>{
						props.onCharitySelect(charity);
					}}		
					id="set-selected-campaign-button"
					// onClick={params.id ? null : () => setCampaignData()}
					title="Select Charity"
				/>
				}
			</div>
		</>
		
	);
}

