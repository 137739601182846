import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import baseURL from "../utilities/BaseURL";
import { getCookie } from "../utilities/Utilites";

const AuthContext = createContext({
	is_editing: true,
});

export function useAuth() {
	return useContext(AuthContext);
}

class AuthStatus {

	static get pending() {
		return "PENDING";
	}

	static get unauthorized() {
		return "UNAUTHORIZED";
	}

	static get authorized() {
		return "AUTHORIZED";
	}

}

const finalPaths = ["/", "/login", "/signup", "/forgotpassword", "/resetpassword"];

export default function AuthProvider({ children }) {

	const [authStatus, setAuthStatus] = useState(AuthStatus.pending);
	const [auth, setAuth] = useState(null);
	const [userProfile, setUserProfile] = useState();
	const history = useHistory();

	function handleAuth(data, withProfile = true) {
		setAuth(data);
		if (withProfile) {
			getUserProfile(data.user_id, data.access_token);
		}
	}

	async function getUserProfile(userId, access_token) {

		if (!userId || !access_token) {
			return;
		}

		const user_profile = await fetch(`${baseURL}/v1/user_profile/${userId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${access_token}`,
			}
		});

		if (!user_profile.ok) {
			return false;
		}

		const profile_data = await user_profile.json();
		setUserProfile(profile_data.data);

		return profile_data.data;

	}

	async function refreshToken() {

		try {

			const response = await fetch(`${baseURL}/v1/auth/refresh`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
				},
				body: JSON.stringify({ refresh_token: getCookie("token") })
				// credentials: "include",
			});

			if (!response.ok) {
				return { authentication: false };
			}

			const json = await response.json();

			return { authentication: true, json };

		} catch (err) {
			return { authentication: false };
		}

	}

	async function authRefresh() {

		// try {

		setAuthStatus(AuthStatus.pending);

		const data = await refreshToken();

		if (!data.authentication) {
			setAuthStatus(AuthStatus.unauthorized);
			return;
		}

		setAuth(data.json);

		await getUserProfile(data.json.user_id, data.json.access_token);


		if (finalPaths.includes(history.location.pathname.toLowerCase())) {
			history.push("/");
		}

		setAuthStatus(AuthStatus.authorized);


	}

	useEffect(() => {
		authRefresh();
	}, []);

	return (
		<AuthContext.Provider value={{ ...auth, authRefresh: authRefresh, handleAuth: handleAuth, userProfile, is_editing: false }} >
			{
				authStatus !== AuthStatus.pending ?
					children
					:
					<></>
			}
		</ AuthContext.Provider>
	);

}