import React, { useState } from "react";
import { useParams } from "react-router";
import AuthMessageCard from "../../components/auth/AuthMessageCard";
import AlertBox from "../../components/common/AlertBox";
import Button from "../../components/sub-component/Button";
import Input from "../../components/sub-component/Input";
import { useAuth } from "../../context/AuthContext";
import { isPasswordValid, openURL } from "../../utilities/Utilites";
import altrunc_logo from "../../assets/icons/logo.png";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";


export default function ResetPassword() {

	const auth = useAuth();
	const { password_token } = useParams();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [password, setPassword] = useState({
		password: null,
		confirm_password: null,
	});

	const [alert, setAlert] = useState();
	const [processing, setProcessing] = useState();

	function handleInput(event) {
		setPassword({ ...password, [event.target.name]: event.target.value });
	}

	function validateForm() {

		if (!isPasswordValid(password.password)) {
			return {
				isValid: false, title: "Weak password", message: [
					<ul key="1">
						<li key="2">Password should contain atleast 1 special charater</li>
						<li key="4">Password should contain minimum 10 charaters</li>
					</ul>
				]
			};
		}

		if (password.password !== password.confirm_password) {
			return { isValid: false, title: "Error", message: "Password did not match" };
		}

		return { isValid: true, message: "" };

	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		const validation = validateForm();

		if (!validation.isValid) {
			setAlert(
				<AlertBox color="danger" title={validation.title} description={validation.message} />
			);
			return;
		}
		const bodyData = { reset_token: password_token, password: password.password };

		setProcessing(true);

		const { result, error } = await openURL("/v1/auth/resetpassword", "PATCH", auth, bodyData);

		setProcessing(false);

		if (error) {
			setAlert(
				<AlertBox color="danger" title="Error" description={error.message} />
			);

		} else if (result) {
			setAlert(
				<AlertBox color="success" title="Success" description={result.message} />
			);

			setTimeout(() => {
				window.location.replace("/login");
			}, 1000);

		}

	};

	return (

		<div className="d-flex flex-lg-row flex-column">

			<div className="d-sm-flex message-card-mobile" style={{ width: "100%", height: "auto", position: "relative", padding: "20px 0" }}>
				<AuthMessageCard paragraph={
					["Making a ",
						<span style={{ color: "#0054DC" }} key="0">donation</span>,
						" is the ultimate sign of solidarity.",
					]}
				/>
			</div>

			<div style={{ height: "100vh", width: "100%", padding: "0 5.75%", }} className="d-flex flex-column justify-content-center">



				<div className="message-card-logo-mobile">

					<img src={altrunc_logo} />

				</div>

				<div className="d-flex flex-column align-self-center" style={{ height: "auto", width: "100%", paddingBottom: "40px" }}>
					<span className="s2" style={{ textAlign: "left" }}>Set Password.</span>

					<div style={{ width: "100%", marginTop: "56px", position: "relative" }}>
						<Input placeholder="New Password" label="" underLine onChange={handleInput} name="password" type={showPassword? "text": "password"} />
						{showPassword ? 
							<FaRegEye style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
							:
							<FaRegEyeSlash style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
						}
					</div>

					<div style={{ width: "100%", marginTop: "56px", position: "relative" }}>
						<Input placeholder="Confirm Password" label="" underLine onChange={handleInput} name="confirm_password" type={showConfirmPassword ? "text": "password"} />
						{showConfirmPassword ? 
							<FaRegEye style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowConfirmPassword(prev => !prev)} size={20} />
							:
							<FaRegEyeSlash style={{ position: "absolute", right: "8px", top: "8px" }} onClick={() => setShowConfirmPassword(prev => !prev)} size={20} />
						}
					</div>

					<div style={{ width: "100%", marginTop: "41px" }}>
						<Button title="Continue" style={{ width: "100%" }} onClick={handleSubmit} busy={processing} />
					</div>

				</div>

				<div style={{ position: "relative" }}>
					<div style={{ position: "absolute", width: "100%" }}>
						{alert}
					</div>
				</div>

			</div>

		</div>
	);

}