/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "./sub-component/Button";
import { useAuth } from "../context/AuthContext";
import { openURL } from "../utilities/Utilites";
import stripeLogo from "./../assets/icons/stripe.png";
import { useSettlementContext } from "../context/SettlementContext";
import Input from "./sub-component/Input";

export default function CheckoutForm(props) {

	const auth = useAuth();
	const settlement = useSettlementContext();
	
	const [donerName, setDonerName] = useState(auth.userProfile && (`${auth.userProfile.first_name} ${auth.userProfile.last_name}`));
	const [isReadyForPayment, setIsReadyForPayment] = useState(false);

	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState(null);
	const [disabled, setDisabled] = useState(true);
	// const [clientSecret, setClientSecret] = useState("");
	const stripe = useStripe();
	const elements = useElements();


	const cardStyle = {
		style: {
			base: {
				padding: "5px",
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "14px",
				"::placeholder": {
					color: "#32325d"
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		},
		hidePostalCode: true,
	};
	const handleChange = async (event) => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setIsReadyForPayment(true);
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};
	const handleSubmit = async ev => {
		ev.preventDefault();

		if(!donerName || donerName === "") {
			setError("Enter your name");
			return;
		}

		if (!isReadyForPayment) {
			setError("Enter card details to proceed");
			return;
		}

		setProcessing(true);

		const { result, error } = await openURL("/v1/donations/", "POST", auth, {
			amount: settlement.settlementDetails.settlementAmount.toFixed(2),
			campaign_id: settlement.settlementDetails.campaignId,
			doner_name: donerName,
			doner_type: "Company",
		});

		if (error) {
			setError(`Payment failed ${error.message}`);
			setProcessing(false);
			return;
		}

		if (!result) {
			return;
		}

		const payload = await stripe.confirmCardPayment(result.data.client_secret, {
			payment_method: {
				card: elements.getElement(CardElement)
			}
		});
		if (payload.error) {
			setError(`Payment failed ${payload.error.message}`);
			setProcessing(false);
		} else {
			setError(null);
			setProcessing(false);
			setSucceeded(true);
			settlement.setSettlementDone(true);
		}

		await openURL(`/v1/campaign/amountsettled/${settlement.settlementDetails.campaignId}`, "PATCH", auth);
	};

	return (
		<div style={{ width: "100%", padding: "0 20px 15px" }}>
			<p className="b4 text-left">
				Please enter card details and make payment to settle the company’s contribution amount.
				This payment will show up as company donation in the audit page.
			</p>

			<div className="d-flex justify-content-between">
				{console.log(settlement.settlementDetails)}
				<Input label="Name" value={donerName} onChange={(e) => setDonerName(e.target.value)}/>
				<Input label="Settlement Amount" disabled={true} value={settlement.settlementDetails.settlementAmount}/>
			</div>

			<form id="payment-form" onSubmit={handleSubmit}>
				<CardElement className="card-element" id="card-element" options={cardStyle} onChange={handleChange} />

				<div className="d-flex flex-row justify-content-center" style={{ width: "100%", marginTop: "20px" }}>
					{/* <Button charityLogin title="Back" className="d-flex back-button" /> */}
					<div style={{ width: "250px", height: "35px" }}>
						<Button
							busy={processing}
							disabled={succeeded || processing}
							type="submit"
							title="Make Payment"
							className="d-flex b3"
							style={{ backgroundColor: "#0054DC", height: "100%", width: "100%" }}
						/>
					</div>


				</div>

				{/* Show any error that happens when processing the payment */}
				{error && (
					<div className="card-error" role="alert" style={{ marginTop: "10px", color: "#fa755a" }}>
						{error}
					</div>
				)}
				{/* Show a success message upon completion */}
				{succeeded && (
					<div className="card-success" role="alert" style={{ marginTop: "10px", color: "#229b24" }}>
						<span>Payment succeeded.</span>
					</div>
				)}


			</form>
			<img src={stripeLogo} alt="stripe" style={{ marginTop: "10px" }} />
		</div>
	);
}