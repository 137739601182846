import React, { useEffect, useState } from "react";
import ReactRoundedImage from "react-rounded-image";
import RoundImage from "../../../assets/icons/Genral/Unicef.svg";
import { getOriginFromLink } from "../../../utilities/Utilites";

export default function CharityInfoBox(props) {

	// const tempDetail = "UNICEF works in over 190 countries and territories to save children’s lives, to defend their rights, and to help them fulfil their potential, from early childhood through adolescence. And we never give up. UNICEF works for and with children and young people across 21 countries and territories in Europe and...";
	const [charity, setCharity] = useState(props.charity);
	const [isSelected, setIsSelected] = useState(props.isSelected);


	useEffect(() => {
		setCharity(props.charity);
		setIsSelected(props.isSelected);
	}, [props]);

	const { charity_url, name } = charity;
	return (
		<div className="charity-info-box-wrapper w-100">
			<div className="charity-info-box-container">
				<div className="charity-info-header-details-container">
					<ReactRoundedImage
						className="react-round-image"
						image={`//logo.clearbit.com/${getOriginFromLink(charity_url)}` || RoundImage}//charity_url
						imageWidth="57"
						imageHeight="57"
						roundedSize="0"
					/>
					<div className="charity-info-span-details">
						<span className="s4 navy-blue-color charity-info-span-details-header" style={{ fontWeight: "700" }}>{name || "Title"}</span>
						{/* <span className="roboto-b3 hoki-color" style={{ marginTop: "2px" }}>{address || "Location"} - {props.location || "Humanitarian aid organization"} </span> */}
						{
							!props.selectedCharity
								?
								<span className="roboto-b3 navy-blue-color mt-1 charity-info-span-details-description">Impact: <span className="roboto-b3">{charity?.charity_impacts[0]?.impact_statement || "20,000 children supported"}</span></span>
								:
								<span className="roboto-b3 navy-blue-color mt-1 charity-info-span-details-description">Impact: <span className="roboto-b3">{charity?.selectedImpact?.impact_statement || "20,000 children supported"}</span></span>
						}

					</div>
				</div>

				<div
					className="d-flex justify-content-center align-items-center pointer"
					style={{ backgroundColor: `${isSelected ? "#FC4848" : "#0054DC"}`, width: "100%", height: "27px", borderRadius: "28px", marginTop: "10px" }}
					onClick={() => {
						props.getCharityId(isSelected ? null : charity);
						if (!isSelected) {
							setIsSelected(true);
						}
						else {
							setIsSelected(false);
						}
					}}>
					<span className="b4 white-color">{isSelected ? "Unselect" : "Select"}</span>
				</div>
				<div className="map-info-box-bottom-pointer"></div>
			</div>
		</div>
	);
}
