/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import Input from "../sub-component/Input";
import Button from "../sub-component/Button";
import { getBucketUrl, isCharitySiteUrlValid, isCustomSiteUrlValid, openURL } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";
import AlertBox from "../common/AlertBox";
import SwitchButton from "../sub-component/SwitchButton";
import HelpIconComponent from "../common/HelpIconComponent";
import { Packages } from "./../../utilities/Packages";
import { ReactComponent as EditIcon } from "../../assets/icons/basic/pencil-edit.svg";
import Modal from "../common/Modal";
import { ReactComponent as ExternalLinkIcon } from "../../assets/icons/basic/external-link.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/basic/copy.svg";
import SubscriptionPlanChangeFlow from "../subscription/SubscriptionPlanChangeFlow";
import DomainSetup from "./DomainSetup";

export default function CharitySiteSetup(props) {
	const location = useLocation();
	const auth = useAuth();

	const [charityUrl, setCharityUrl] = useState("");
	const [charityId, setCharityId] = useState();
	const [isUpdate, setIsUpdate] = useState(false);
	const [alert, setAlert] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [charityAlert, setCharityAlert] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [bucketURL, setBucketURL] = useState(null);

	const [isAllowGuestDonation, setIsAllowGuestDonation] = useState(false);

	const [isCharitySiteNameConfirmed, setIsCharitySiteNameConfirmed] = useState({
		isSaveBtnAvailable: true,
		isAlerBoxHide: true,
		isConfirmed: false,
		isCharityNameEmpty: true
	});

	const [configureDNSModal, setConfigureDNSModal] = useState({
		isOpen: false,
		isTextCopied: false
	});

	const [isDomainSetupModal,setIsDomainSetupModal] =useState(false);
	
	const handleDomainSetupClick = () => {
		setIsDomainSetupModal(true);
		setConfigureDNSModal({...configureDNSModal,isOpen:false});
	};

	const handleInput = (event) => {

		console.log("event=======",event.target.value.toLowerCase());
		
		if (event.target.value.includes("altrunic")) {
			//alert('You are trying to type "altrunic" which is wrong.');
			setAlert(<AlertBox color="danger" title="Warning" description="You keyword contains altrunic which is wrong. Please type something else"/>);
			setTimeout(() => {
				setAlert(null); 
			  }, 3000);
			return;
		  }

		if (!isCharitySiteNameConfirmed.isConfirmed) {
			if (event.target.value !== "") {
				setIsCharitySiteNameConfirmed({ ...isCharitySiteNameConfirmed, isCharityNameEmpty: false });
			}
			else {
				setIsCharitySiteNameConfirmed({ ...isCharitySiteNameConfirmed, isCharityNameEmpty: true });
			}
			setCharityUrl(event.target.value.toLowerCase());
		}

	};

	const allowGuestDonations = (status) => {
		setIsAllowGuestDonation(status.value);
	};
	const validate = () => {

		if (!charityUrl) {
			return { isValid: false, message: "Charity site url is required!" };
		}

		if (auth.subscription.subscription_package.package_name === Packages.starter.name) {

			if (!isCharitySiteUrlValid(charityUrl)) {
				return { isValid: false, message: "Charity site url cannot contain any special character" };
			}

		}else if(auth.subscription.subscription_package.package_name === Packages.basic.name) {
			if (!isCharitySiteUrlValid(charityUrl)) {
				return { isValid: false, message: "Charity site url cannot contain any special character in basic plan" };
			}
		}else {
			if (!isCustomSiteUrlValid(charityUrl)) {
				return { isValid: false, message: "Invalid impact site URL" };
			}
		}

		return { isValid: true, message: "" };
	};

	const handleSubmitForm = async (event) => {

		if (event) {
			event.preventDefault();
		}

		const validation = validate();

		if (!validation.isValid) {
			setAlert(
				<AlertBox color="danger" title="Error" description={validation.message} />
			);
			return;
		}

		setIsBusy(true);

		const body = {
			can_guest_donate: isAllowGuestDonation
		};
		localStorage.getItem("packageId")== 2 ? body.site_url= `www.${charityUrl}` : body.site_url= `${charityUrl}`;

		if (!isUpdate) {
			const { result, error } = await openURL("/v1/charity_site/", "POST", auth, body);
			if (error) {
				if (error.message.includes("duplicate key value violates unique constraint")) {
					setAlert(
						<AlertBox color="danger" title="Error" description={"Your selected site name is not available. Please try another name!"} />
					);
				}
				else {
					setAlert(
						<AlertBox color="danger" title="Error" description={error.message} />
					);
				}
			} else if (result) {
				setAlert(
					<AlertBox color="success" title="Success" description={result.message} />
				);
				setIsCharitySiteNameConfirmed({ ...isCharitySiteNameConfirmed, isConfirmed: true, isAlerBoxHide: true });
				if (auth.subscription.subscription_package.package_name === Packages.professional.name) {
					setConfigureDNSModal({ ...configureDNSModal, isOpen: true });
				}

				getCharityData();
			}

		} else if (isUpdate) {

			const { result, error } = await openURL("/v1/charity_site/" + charityId, "PATCH", auth, body);

			if (error) {
				if (error.message.includes("duplicate key value violates unique constraint")) {
					setAlert(
						<AlertBox color="danger" title="Error" description={"Charitsite URL must be unique."} />
					);
				}
				else {
					setAlert(
						<AlertBox color="danger" title="Error" description={error.message} />
					);
				}

			} else if (result) {
				setAlert(
					<AlertBox color="success" title="Success" description={"Impact site updated"} />
				);
				getCharityData();
				if (props.onClick) {
					props.onClick();
				}
			}
		}

		setIsBusy(false);

	};

	const getCharityData = async () => {

		const { result } = await openURL("/v1/charity_site/", "GET", auth, null);

		console.log("result------->",result);

		if (result && result.data.length > 0) {

			setIsUpdate(true);
			setCharityId(result.data[0].id);
			setIsAllowGuestDonation(result.data[0].can_guest_donate);

			if (!result.data[0].site_url) {
				setIsCharitySiteNameConfirmed({ ...isCharitySiteNameConfirmed, isCharityNameEmpty: false, isConfirmed: false });
				return;
			}

			setCharityUrl(result.data[0].site_url.replace(".altrunic.com", "").replace(".altrunic.org", ""));
			if (result.data[0].site_url.replace(".altrunic.com", "").replace(".altrunic.org", "") !== "") {
				setIsCharitySiteNameConfirmed({ ...isCharitySiteNameConfirmed, isCharityNameEmpty: false, isCharitySiteNameConfirmed: true, isAlerBoxHide: true, isConfirmed: true });
				setBucketURL(getBucketUrl(result.data[0].site_url));
			}


		}
	};

	useEffect(() => {
		console.log("location",location);
		getCharityData();
	}, []);

	const saveCharityNameComp = () => {
		return (
			<Fragment>

				{
					<div className="d-flex flex-column align-items-start" style={{ padding: "10px 0px 15px 0px", position: "relative" }}>

						{
							!isCharitySiteNameConfirmed.isAlerBoxHide && (
								<div className="confirm-url-alert-box">
									<AlertBox
										processing={isBusy}
										type="confirmation"
										id="hosted-domain-confirmation-alertbox-button"
										statements={["You will not be able to change this domain without contacting our support.", "Do you want to continue?"]}
										// processing={processing}
										action={(confirm) => {
											if (confirm) {
												auth.setHasImpactSiteUrl(true);
												handleSubmitForm();
												setIsCharitySiteNameConfirmed({ ...isCharitySiteNameConfirmed, isAlerBoxHide: true });
											} else {
												setIsCharitySiteNameConfirmed({ ...isCharitySiteNameConfirmed, isAlerBoxHide: true });
											}
										}}
										confirmButtonStyle={{ background: "#0054DC" }}
									/>
								</div>
							)
						}

						{console.log(isCharitySiteNameConfirmed.isConfirmed, "CONSDSD")}

						<Button

							className="b5"
							title="Save"
							id="hosted-domain-save-button"
							style={{
								width: "64px",
								height: "30px",
								borderRadius: "15px",
								padding: "10px 15px",
								background: `${(isCharitySiteNameConfirmed.isConfirmed || isCharitySiteNameConfirmed.isCharityNameEmpty) ? "#5E7488" : "#0054DC"}`,
								cursor: `${(isCharitySiteNameConfirmed.isConfirmed || isCharitySiteNameConfirmed.isCharityNameEmpty) ? "not-allowed" : "pointer"}`,
								marginLeft: "10px"
							}}
							onClick={
								(isCharitySiteNameConfirmed.isConfirmed || isCharitySiteNameConfirmed.isCharityNameEmpty)
									?
									(event) => { event.preventDefault();
									}
									:
									(event) => { event.preventDefault(); setIsCharitySiteNameConfirmed({ ...isCharitySiteNameConfirmed, isAlerBoxHide: false }); }
							}
						/>


					</div>
				}

			</Fragment>
		);
	};

	const starterPackageComp = () => {

		const [isOnChangePlanModalOpen, setIsOnChangePlanModalOpen] = useState(false);

		const onCloseModal = () => {
			setIsOnChangePlanModalOpen(false);
		};

		const [plan, setPlan] = useState(auth.subscription.subscription_package);

		const onPlanChanged = (planChanged) => {
			console.log(planChanged);
			setPlan(planChanged.selectedPackage);
		};

		return (
			<Fragment>
				<div style={{ textAlign: "center", width: "100%" }}>
					{
						props.isLabel &&
						<span style={{ textAlign: "center" }} className="s1">Impact Site</span>
					}

					<p className="b3" style={{ marginTop: "20px", fontSize: "121dp", textAlign: "left" }}>
						You are currently on the&nbsp;
						<font color="#0054DC">{auth.subscription.subscription_package.package_name} plan </font>which comes with impact site on a hosted domain.
					</p>
				</div>

				<div className="d-flex align-items-end " style={{ marginTop: "25px", width: "100%", position: "relative" }}>
					<EditIcon style={{ fill: "#DADADA", position: "absolute", bottom: "11px", left: "7px" }} />
					<Input label="Hosted Domain" placeholder="yourcampaign" name="site_url" id="hosted-domain-input" onChange={handleInput} disabled={isCharitySiteNameConfirmed.isConfirmed} value={charityUrl}
						style={{ width: "221px" }} inputStyle={{ textAlign: "end", padding: "0 10px 0 33px", color: "#0054DC" }} />
					<div style={{ height: "40px", marginLeft: "7px" }} className="d-flex align-items-center">
						<div className="d-flex align-items-center">
							<span className="b2" style={{ color: "#0054DC", fontWeight: "bolder" }}> {window._env_.REACT_APP_CHARITY_DOMAIN && window._env_.REACT_APP_CHARITY_DOMAIN==="altrunic.org" ? ".altrunic.org" : ".altrunic.com"}</span> 						
						</div>

						{saveCharityNameComp()}
					</div>
				</div>

				<p className="b3" style={{ marginTop: "25px", marginBottom: "30px", fontSize: "121dp", textAlign: "left" }}>
					Want to customise impact site domain?
					<font color="#0054DC" className="pointer" onClick={() => { setIsOnChangePlanModalOpen(true); }}> Upgrade to Professional Plan </font>
				</p>

				<SubscriptionPlanChangeFlow
					selectedUserPlan={plan}
					isOnChangePlanModalOpen={isOnChangePlanModalOpen}
					onCloseModal={onCloseModal}
					onPlanChanged={onPlanChanged} />

			</Fragment>
		);
	};

	const professionalPackageComp = () => {

		return (
			<Fragment>
				<div style={{ textAlign: "center", width: "100%" }}>
					{
						props.isLabel &&
						<span style={{ textAlign: "center" }} className="s1">Impact Site</span>
					}

					<p className="b3" style={{ marginTop: "20px", fontSize: "121dp", textAlign: "left" }}>
						You are currently on the
						<font color="#0054DC"> Professional plan </font>. You can customise the domain of your impact site.
					</p>

				</div>

				<div className="d-flex align-items-end " style={{ marginTop: "25px", width: "100%", position: "relative" }}>
					<EditIcon style={{ fill: "#DADADA", position: "absolute", bottom: "11px", left: "7px" }} />
					<Input label="Custom Domain" placeholder="yourcampaign.com" name="site_url" onChange={handleInput} disabled={isCharitySiteNameConfirmed.isConfirmed} value={charityUrl}
						style={{ width: "221px" }} inputStyle={{ textAlign: "end", padding: "0 10px 0 33px", color: "#0054DC" }} />
					<div style={{ height: "40px", marginLeft: "7px" }} className="d-flex align-items-center">
						{saveCharityNameComp()}
					</div>
				</div>

				{
					bucketURL && (
						<div className="w-100 d-flex align-items-end">
							<div className="d-flex align-items-end " style={{ marginTop: "25px", width: "100%", maxWidth: "456px", position: "relative" }}>
								<div
									className="pointer"
									style={{ fill: "#DADADA", position: "absolute", bottom: "11px", right: "13px" }}
									onClick={() => { navigator.clipboard.writeText(bucketURL); }}>
									<CopyIcon fill="#0054DC" className="mr-1" />
									<span className="navy-blue-color b3">COPY</span>
								</div>


								<Input
									label="S3 Bucket URL"
									placeholder="AWS S3-Bucket URL"
									name="s3-bucket-url"
									disabled={true}
									value={bucketURL}
									// value={`${charityUrl}.s3-website-us-east-1.amazonaws.com`}
									style={{ width: "100%" }} inputStyle={{ padding: "0 90px 0 10px", color: "#2A3440" }}
								/>


							</div>
							<HelpIconComponent
								style={{ marginLeft: "23px", marginBottom: "18px" }}
								plainInfo={["You will need this link when confirguring your custom Domain."]}
							/>
						</div>
					)
				}

				{location.pathname.includes("dashboard") && <div style={{ margin: "19px 0 40px 0" }}>
					{
						location.pathname.includes("dashboard") ? (
							<Link to={{pathname: "/dashboard/settings/domain-setup",state: { isSetupPage: false,isDashboardPage:true }}}>
								<span className="b3 navy-blue-color pointer"><ExternalLinkIcon fill="#0054DC" className="mb-1" /> View detailed instructions here</span>
							</Link>
						) : (
							<span className="b3 navy-blue-color pointer"><ExternalLinkIcon fill="#0054DC" className="mb-1" /> View detailed instructions here</span>
						)
					}
				</div>}

				

				{location.pathname.includes("setup") && <div style={{ margin: "19px 0 40px 0" }}>
					{
						location.pathname.includes("setup") ? (
							<Link to={{pathname: "/setup/domain-setup",state: { isSetupPage: true,isDashboardPage:false }}}>
								<span className="b3 navy-blue-color pointer"><ExternalLinkIcon fill="#0054DC" className="mb-1" /> View detailed instructions here</span>
							</Link>
						) : (
							<span className="b3 navy-blue-color pointer"><ExternalLinkIcon fill="#0054DC" className="mb-1" /> View detailed instructions here</span>
						)
					}
				</div>}

			</Fragment>

		);
	};

	return (
		<div className="form-responsiveness p-2 " style={{maxWidth:"98vw"}}>

			<form className="d-flex flex-column align-items-start"  onSubmit={handleSubmitForm}>

				{
					auth?.subscription?.subscription_package?.package_name === Packages?.starter?.name && starterPackageComp()
				}
				{
					auth?.subscription?.subscription_package?.package_name === Packages?.basic?.name && starterPackageComp()
				}
				{
					auth?.subscription?.subscription_package?.package_name === Packages?.professional?.name && professionalPackageComp()
				}
				<div className="w-100">
					{charityAlert}
				</div>

				<div className="w-100" style={{ paddingTop: "25px", borderTop: "2.5px solid #CBE3FF" }}>
					<span className="b3">Allow Domain Login</span>
					<div className="d-flex" style={{ marginTop: "10px" }}>
						<SwitchButton isLocked value={true} style={{ marginRight: "7px" }} lockColor={"#5E7488"} />
						<span className="b3">
							Everyone with an email account on your company domain will be able to login and donate on your charity site.
						</span>
					</div>
				</div>

				<div className="w-100" style={{ marginTop: "40px" }}>
					<span className="b3">Allow Guest Donations</span>
					<div className="d-flex align-items-start" style={{ marginTop: "10px" }}>
						<SwitchButton value={isAllowGuestDonation} onChange={allowGuestDonations} style={{ marginRight: "7px" }} id="allow-guest-donations-switch-button"/>
						<HelpIconComponent
							style={{ marginLeft: "23px", marginTop: "5px" }}
							plainInfo={["Turning this setting on will allow guest users to donate on your campaigns without needing to be signed-up."]}
						/>
					</div>
				</div>

				<div className="w-100">
					{alert}
				</div>

				<div style={{ marginTop: "50px", width: "115px", height: "42px" }} className="align-self-center" id="new-user-create-impact-site-button">
					{
						(props.shouldCreate) &&
						<Button title="Next"  leftArrow busy={isBusy}
							style={{
								width: "100%", height: "100%",
								background: `${(!isCharitySiteNameConfirmed.isConfirmed) ? "#5E7488" : "#0054DC"}`,
								cursor: `${(!isCharitySiteNameConfirmed.isConfirmed) ? "not-allowed" : "pointer"}`,

							}}
							onClick={
								(isCharitySiteNameConfirmed.isConfirmed)
									?
									handleSubmitForm
									:
									() => { event.preventDefault(); }
							} />
					}
					{props.shouldUpdate &&
						<Button title="Update" onClick={handleSubmitForm} busy={isBusy} style={{ width: "100%", height: "100%" }} />
					}
				</div>

			</form>

			{
				configureDNSModal.isOpen &&
				<Modal title="Configure DNS" confirmBtnTitle="Done"
					onClose={() => { setConfigureDNSModal({ ...configureDNSModal, isOpen: false }); }}
					onConfirm={() => { setConfigureDNSModal({ ...configureDNSModal, isOpen: false }); }}
				>
					<div className="w-100 d-flex flex-column align-items-center mt-4">
						<div style={{ maxWidth: "684px", width: "100%", textAlign: "center" }}>
							<span className="b2 hoki-color">
								To finish setting up your custom domain, you need to add a DNS record with your nameserver provider.
							</span>
							<div className="w-100 d-flex" style={{ marginTop: "60px", justifyContent: "space-between" }}>
								<div className="d-flex flex-column" style={{ textAlign: "center", width: "85px" }}>
									<span className="b3 hoki-color" style={{ fontWeight: "700" }}>TYPE</span>
									<span className="b2 hoki-color" style={{ marginTop: "30px" }}>CNAME</span>
								</div>
								<div className="d-flex flex-column" style={{ textAlign: "center", width: "185px" }}>
									<span className="b3 hoki-color" style={{ fontWeight: "700" }}>NAME</span>
									<span className="b2 hoki-color" style={{ marginTop: "30px" }}>{charityUrl}</span>
								</div>
								<div className="d-flex flex-column" style={{ textAlign: "center", width: "296px", position: "relative" }}>
									<span className="b3 hoki-color" style={{ fontWeight: "700" }}>TYPE</span>
									<span className="b2 hoki-color" style={{ marginTop: "30px" }}>{bucketURL}</span>
									<div
										className="d-flex flex-column align-items-center justify-content-start"
										style={{bottom: "-23px", right: "-75px", height: "57px",marginTop:"10px" }}>
										<Button isHollow title="COPY" fontStyle="b4" leftIcon={<CopyIcon fill="#0054DC" className="mr-1" />}
											style={{ padding: "5px 10px" }}
											onClick={() => {
												setConfigureDNSModal({ ...configureDNSModal, isTextCopied: true });
												navigator.clipboard.writeText(bucketURL);
											}}
										/>
										{
											configureDNSModal.isTextCopied &&
											<span className="b4 mt-2" style={{ color: "green" }}>COPIED</span>
										}

									</div>
								</div>

							</div>
							{location.pathname.includes("setup") && 
							<div style={{ marginTop: "45px" }}>
								<a className="b3 navy-blue-color pointer" onClick={handleDomainSetupClick}><ExternalLinkIcon fill="#0054DC" className="mb-1" /> View detailed instructions here</a>
							</div>}
						</div>

					</div>
				</Modal>
				
			}
			{isDomainSetupModal && 
				<Modal confirmBtnTitle="Close"
					onClose={() => { setConfigureDNSModal({ ...configureDNSModal, isOpen: true });
						setIsDomainSetupModal(false);}}
					onConfirm={() => { setConfigureDNSModal({ ...configureDNSModal, isOpen: true });
						setIsDomainSetupModal(false);}}
					height={"800px"}
				>
					<DomainSetup isSetupPage={false} isModal isDashboardPage={false} />
				</Modal>
			}
		</div>
	);
}
