import React from "react";
import AboutUsImage from "../assets/images/AboutUs.png";
import Button from "../components/sub-components/Button";

export default function AboutUs(props) {
	if(parseInt(props.templateId)===1){
		return (

			<div className="about-us-container-template-1" id="about-us-container-template-1">
				<div className="about-us-image">
					<img
						src={props.aboutUsImage || AboutUsImage}
						alt=""
						style={{ width: "100%", height: "100%", objectFit: "cover" }}
					/>
				</div>
				<div className="about-us-box" style={{ backgroundColor: `${props.primaryColor || "#4267B2"}` }}>
					<div className="">
						<div>
							<span className="heading2-t2 text-colour">About Us</span>
						</div>
						<div className="title-box" >
							<span className="cs-hero3 text-colour ">{props.aboutUsTitle}</span>
						</div>
						<div className="subtitle-box">
							<span className="cs-label text-colour">{props.aboutUsSubtitle}</span>
						</div>
						<div className="text-box">
							<p className="text-colour cs-p2-t2" style={{ whiteSpace: "pre-wrap" }}>{props.aboutUsDescription}</p>
						</div>
						<div className="btn-spacing w-100 justify-content-center justify-content-md-start" style={{ display: "flex", marginTop: "10px" }}>
							<Button whiteButton title="Learn More" />
						</div>
					</div>
				</div>
			</div>
		);
	}
	else if(parseInt(props.templateId)==2){
		return (
			<div className="about-us-container-template-2" id="about-us-container-template-2">
				<div className="about-us-box col-12 col-md-6 " >
					<div className="">
						<div>
							<span className="heading2">About Us</span>
						</div>
						<div className="title-box" >
							<span className="cs-hero3 " style={{ color: `${props.primaryColor || "#4267B2"}` }}>{props.aboutUsTitle}</span>
						</div>
						<div className="subtitle-box">
							<span className="cs-label">{props.aboutUsSubtitle}</span>
						</div>
						<div className="text-box">
							<p className="cs-p1-t2" style={{ whiteSpace: "pre-wrap" }}>{props.aboutUsDescription}</p>
						</div>
						<div className="btn-spacing justify-content-center justify-content-md-start w-100" style={{ display: "flex", marginTop: "10px" }}>
							<Button color={props.primaryColor} title="Learn More" style={{height:"42px"}} />
						</div>
					</div>
				</div>
				<div className="about-us-image col-12 col-md-6">
					<img
						src={props.aboutUsImage || AboutUsImage}
						alt=""
						style={{ width: "100%", height: "100%", objectFit: "cover" }}
					/>
				</div>
			</div>
		);
	}
	else{
		return(
			<>
			
			</>
		);
		
	}
	
}