import React from "react";
import Button from "../../components/sub-component/Button";
// import { Link } from "react-router-dom";

export default function AccountActivated() {
	return (

		<div className="d-flex flex-column justify-content-center">
			<h3 className="h2">Account Activated!</h3>

			<p className="b2 hoki-color mt-4 mb-4">
				Your account has been succesfully activated. Please procced to login.
			</p>


			<Button title="Login" style={{ height: "40px", width: "100%" }} onClick={() => window.location.replace("/subscription")}/>

		</div>
	);
}