import React, { useState } from "react";
import Button from "./sub-components/Button";
import ProgressBar from "./sub-components/ProgressBar";

import { ReactComponent as HeartIcon } from "./../assets/icons/basic/icon.svg";
import { ReactComponent as MatchingIcon } from "./../assets/icons/various/infinity.svg";
// import { ReactComponent as MatchingIcon2 } from "../assets/icons/various/M2.svg";
import crossArrow from "./../assets/icons/basic/arrow-left right.svg";
import { useDonation } from "../context/DonationContext";
import { useAuth } from "../context/AuthContext";
// import infoIcon from "./../assets/icons/notifications/info.svg";


/**
 * 
 * @param {object} props props object
 * @param {Array} props.donations array of donations
 * @returns 
 */

export default function CampaignProgressCard(props) {
	const auth = useAuth();
	const donation = useDonation();

	const prices = [
		{ id: 1, value: Number(props.campaignDetails.min_donate) },
		{ id: 3, value: Number(props.campaignDetails.min_donate) + 20 },
		{ id: 4, value: Number(props.campaignDetails.min_donate) + 50  },
		{ id: 5, value: null }
	];
	
	const [selectedPrice, setSelectedPrice] = useState(prices[0]);
	

	function handlePriceChange(index) {
		setSelectedPrice(prices[index]);
	}

	function handleDonationNow() {

		if (auth.is_editing) {
			return;
		}

		if (selectedPrice.value < props.minDonate) {
			alert(`Cannot donate less than ${props.minDonate}`);
			return;
		}

		donation.setDonationDetails(prev => ({
			...prev, 
			donation_amount: isNaN(selectedPrice.value) ? (props.minDonate) : (selectedPrice.value), 
			campaign_id: props.campaignDetails.id,
			donor_email: auth?.userProfile?.email
		}));
	}

	function handleKeyPress(event) {

		if (isNaN(event.key)) {
			event.preventDefault();
		}

		if (event.target.value > 9999) {
			event.preventDefault();
		}

	}
	
	function findDays(start, end ) {
		const today = new Date().getDate();
		const todayMonth = new Date().getMonth() + 1;
		const todayYear = new Date().getFullYear();
		const startDay = new Date(start).getDate();
		const startMonth = new Date(start).getMonth() + 1;
		const startYear = new Date(start).getFullYear();
		const endDay = new Date(end).getDate();
		const endMonth = new Date(end).getMonth() + 1;
		const endYear = new Date(end).getFullYear();
		const endDate = Date.parse(end);
		let startDate = Date.parse(start)
		let diffTime = Math.abs(endDate - startDate);

		// common case
		if ((startYear < todayYear || ((startMonth < todayMonth) && (startYear === todayYear)) || (((startDay <= today) && (startMonth === todayMonth) && (startYear === todayYear)))) && (endYear > todayYear || ((endMonth > todayMonth) && (endYear === todayYear)) || (((endDay > today) && (endMonth === todayMonth) && (endYear === todayYear))))) {
			startDate = Date.parse(new Date())
			diffTime = Math.abs(endDate - startDate);
			return `${Math.ceil(diffTime / (1000 * 60 * 60 * 24))} days left`
		}

		// past case
		else if (((startDay < today) && (startMonth <= todayMonth) && (startYear <= todayYear)) && ((endDay < today) && (endMonth <= todayMonth) && (endYear <= todayYear))) {
			return "Campaign Over"
		}

		// today case 
		else if (((endDay === today) && (endMonth === todayMonth) && (endYear === todayYear))) {
			return "Last day"
		}

		// future case
		else if ((startYear > todayYear || ((startMonth > todayMonth) && (startYear === todayYear)) || (((startDay > today) && (startMonth === todayMonth) && (startYear === todayYear)))) && (endYear > todayYear || ((endMonth > todayMonth) && (endYear === todayYear)) || (((endDay > today) && (endMonth === todayMonth) && (endYear === todayYear))))) {
			let todayDate = Date.parse(new Date())
			startDate = Date.parse(start)
			diffTime = Math.abs(startDate - todayDate);
			return `Starts in ${Math.ceil(diffTime / (1000 * 60 * 60 * 24))} days`
		}
		return "N/A"
	}

	if(parseInt(props.templateId)===1){
		return (
			<div className="w-100 d-flex flex-column" id="campaign-progress-card">
				<div className="h-100 d-flex flex-column campaign-progress-card" style={{ border: `2px solid ${props.primaryColor}`, borderRadius: "10px", padding: "20px 30px" }}>
	
					<div className="d-flex flex-row justify-content-between">
						<div>
							<span className="cs-b1">Campaign Progress</span>
						</div>
						<div>
							
							<Button
								text
								style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "max-content", padding: "0px 5px", backgroundColor: `${props.primaryColor}` }}
								title={
                                    (() => {
                                        if (props.campaignDetails) {
											return findDays(props.campaignDetails.start_date, props.campaignDetails.end_date)
                                        }
                                    })()
                                }
								
							/>
						</div>
					</div>
					
	
					<div style={{ padding: "40px 10px 15px 0px", borderBottom: "2px solid #CED5DB" }}>
						<ProgressBar
							barColor={props.primaryColor}
							// completed="40"
							percentageUp
							style={{ width: "100%" }}
							total={props.campaignDetails && props.campaignDetails.target}
							current={
								(() => {
									if (props.campaignDetails.donations) {
										const donations = props.campaignDetails.donations.reduce((r, c) => r + Number(c.amount), 0);
										return donations;
									}
								})()
							}
						/>
					</div>
	
					<div className="h-100 d-flex flex-row" style={{ marginTop: "10px" }}>
						<span className="h-100 d-flex flex-column justify-content-between" style={{ borderRight: "2px solid #CED5DB", width: "50%" }}>
	
							<span className="d-flex flex-row justify-content-center align-items-center align-self-center" style={{ width: "250px" }}>
								<span className="ml-0" >
									<HeartIcon fill={props.primaryColor} width="22px" height="22px" />
								</span>
								<span className="ml-2 cs-b1" >Impact Factor</span>
							</span>
	
							<div className="d-flex justify-content-center align-items-center">
								<span className="cs-p1">
									<span className="mr-2">
										${props.campaignDetails ? Number(props.campaignDetails.charity?.charity_impacts[0].factor_amount).toFixed(0) : 0}
									</span>
									<img src={crossArrow} alt="Impact Icon" height="22" />
									<span className="ml-2">{props.campaignDetails ? props.campaignDetails.impact.sdg : "Factor"}</span>
								</span>
							</div>
	
						</span>
	
						<span className="d-flex flex-column justify-content-between" style={{ width: "50%" }}>
	
							<span className="d-flex flex-row justify-content-center align-items-center align-self-center" style={{ width: "250px" }}>
								<span className="ml-2" >
									<MatchingIcon fill={props.primaryColor} width="22px" height="22px" />
								</span>
								<span className="ml-2 cs-b1" >Matching</span>
								<span className="ml-2" >
									{/* <img src={CrossArrow} alt="Impact Icon" height="22" /> */}
								</span>
							</span>
							<div className="w-100 h-100 d-flex justify-content-center align-items-end">
								<span className="cs-p1">{props.campaignDetails && props.campaignDetails.match_contribution ? "On" : "Off"}</span>
							</div>
						</span>
	
					</div>
	
				</div>
				{console.log("props.campaignDetails", props.campaignDetails, !props.campaignDetails.is_ended,  props.campaignDetails.end_date < new Date())}
				{
					(!props.campaignDetails.is_ended && props.campaignDetails.end_date < new Date()) && (
						
						<div className="campaign-donation-section-container d-flex justify-content-between align-items-center flex-wrap mt-2" style={{ width: "100%" }}>
							<div className="donation-price-section col-12 col-lg-7 justify-content-between justify-content-lg-start pl-0 pr-0">
								{
									prices.slice(0, prices.length - 1).map((price, index) => {
										if (price.id === selectedPrice.id)
											return (
												<div key={index} onClick={() => handlePriceChange(index)} className="price-button-t2 b5 white-color" style={{ backgroundColor: `${props.primaryColor}`, borderColor: `${props.primaryColor}` }}>${price.value}</div>
											);
										else
											return (
												<div key={index} onClick={() => handlePriceChange(index)} className="price-button-t2 b5 hoki-color" style={{ borderColor: `${props.primaryColor}` }}>${price.value}</div>
											);
									})
								}
	
								<input
									type="number"
									className="custom-price-button-t2 b5 hoki-color"
									style={{ borderColor: `${props.primaryColor}` }}
									placeholder="Custom"
									onChange={(event) => {
										setSelectedPrice({ ...selectedPrice, value: event.target.value });
									}}
									onKeyPress={handleKeyPress}
									onClick={() => handlePriceChange(prices.length - 1)}
								/>
	
							</div>
							<div className="donation-card-button col-12 col-lg-4 align-self-center justify-content-end d-flex pl-0 pr-0">
								<Button onClick={handleDonationNow} color={props.primaryColor} title="Donate Now" />
							</div>
						</div>
					)
	
				}
	
			</div>
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<div className="w-100 d-flex flex-column pl-md-5 pl-0" id="campaign-progress-card">
				<div className="h-100 d-flex flex-column campaign-progress-card ml-0 ml-md-5" style={{ border: `2px solid ${props.primaryColor}`, borderRadius: "24px", padding: "20px 30px" }}>
	
					<div className="d-flex flex-row justify-content-between text-left">
						<div>
							<span className="cs-s3 " style={{color:props.primaryColor}} >Campaign Progress</span>
						</div>
						<div>
	
							<Button
								normalButton
								style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "max-content", padding: "0px 5px" }}
								title={
									(() => {
										if (props.campaignDetails) {
											return findDays(props.campaignDetails.start_date, props.campaignDetails.end_date)
                                        }
									})()
								}
							/>
						</div>
					</div>
	
					
	
					<div className="h-100 d-flex flex-column w-100 " style={{ marginTop: "20px" }}>
						
	
						<span className="d-flex flex-md-row flex-column justify-content-start align-items-center w-100 borderBottom">
							<div className="d-flex col-12 col-md-5 pl-0 borderRight"> 
								<span className="ml-0" >
									<HeartIcon fill={props.primaryColor} width="22px" height="22px" />
								</span>
								<span className="ml-2 cs-p0 text-left " >Impact Factor</span>
							</div>
							<div className="d-flex justify-content-start align-items-start ml-4 col-12 col-md-8 pl-md-0 mt-2 mb-2 mt-md-0 mb-md-0">
								<span className="cs-p1">
									<span className="mr-2">
										${props.campaignDetails ? Number(props.campaignDetails.charity?.charity_impacts[0].factor_amount).toFixed(0) : 0}
									</span>
									<img src={crossArrow} alt="Impact Icon" height="22" />
									<span className="ml-2">{props.campaignDetails ? props.campaignDetails.impact.sdg : "Factor"}</span>
								</span>
							</div>
						</span>
						<span className="d-flex flex-column flex-md-row justify-content-start align-items-strech" >
							<div className="d-flex align-items-end col-12 col-md-5 pl-0 borderRight" >
								<span className="ml-0 " >
									<MatchingIcon fill={props.primaryColor} width="22px" height="22px" />
								</span>
								<span className="ml-2 cs-p0 mt-2" >Matching</span>
								<span className="ml-2" >
									{/* <img src={CrossArrow} alt="Impact Icon" height="22" /> */}
								</span>
							</div>
							<div className="w-100 h-100 d-flex justify-content-start align-items-center ml-4 mt-md-auto mt-2 col-12 col-md-8 pl-md-0 pl-1">
								<span className="cs-p1">{props.campaignDetails && props.campaignDetails.match_contribution ? "On" : "Off"}</span>
							</div>
								
						</span>
							
	
						
	
						<span className="d-flex flex-column justify-content-between" style={{ width: "50%" }}>
	
							
						
							
						</span>
	
					</div>
					<div style={{ padding: "40px 10px 15px 0px",marginTop:"10px"}}>
						<ProgressBar
							barColor={props.primaryColor}
							// completed="40"
							percentageUp
							style={{ width: "100%" }}
							total={props.campaignDetails && props.campaignDetails.target}
							current={
								(() => {
									if (props.campaignDetails.donations) {
										const donations = props.campaignDetails.donations.reduce((r, c) => r + Number(c.amount), 0);
										return donations;
									}
								})()
							}
						/>
					</div>
					{
						(!props.campaignDetails.is_ended && props.campaignDetails.end_date < new Date()) && (
							
							<div className="campaign-donation-section-container d-flex justify-content-between align-items-center flex-wrap mt-2 " style={{ width: "100%" }}>
								<div className="donation-price-section col-12 col-lg-9 pl-0 pr-0 justify-content-md-start">
									{
										prices.slice(0, prices.length - 1).map((price, index) => {
											if (price.id === selectedPrice.id)
												return (
													<div key={index} onClick={() => handlePriceChange(index)} className="price-button-t2 b5 white-color mr-2" style={{ backgroundColor: `${props.primaryColor}`, borderColor: `${props.primaryColor}` }}>${price.value}</div>
												);
											else
												return (
													<div key={index} onClick={() => handlePriceChange(index)} className="price-button-t2 b5 hoki-color mr-2" style={{ borderColor: `${props.primaryColor}` }}>${price.value}</div>
												);
										})
									}
		
									<input
										type="number"
										className="custom-price-button-t2 b5 hoki-color"
										style={{ borderColor: `${props.primaryColor}` }}
										placeholder="Custom"
										onChange={(event) => {
											setSelectedPrice({ ...selectedPrice, value: event.target.value });
										}}
										onKeyPress={handleKeyPress}
										onClick={() => handlePriceChange(prices.length - 1)}
									/>
		
								</div>
								<div className="donation-card-button mt-2 mt-md-0 col-12 col-lg-3 pl-0 pr-0">
									<Button normalButton onClick={handleDonationNow} color={props.primaryColor} style={{color:"white",width:"100%"}} title="Donate Now" />
								</div>
							</div>
						)
		
					}
				</div>
	
				
	
			</div>
		);
	}
	else{
		return(
			<>
			</>
		);
		
	}
}