import React from "react";


/**
 * 
 * @param {Object} props Props object
 * @param {number} props.completed completed percentage
 * @param {boolean} props.isPercentageLabeled to show the percentage
 */
export default function ProgressBar(props) {

	if (props.type == "DonationProgressBar") {
		return (
			<>
				<div className="d-flex justify-content-between">
					<span className="b3 mischka-color">{Number(props.completed).toFixed(2)}% Completed</span>
					<span className="b3 mischka-color">{props.achieved && Number(props.achieved).toFixed(0)}/{props.total && props.total} {props.suffix && props.suffix}</span>
				</div>

				<div className="d-flex align-items-center mt-2" style={{ width: `${props.width}` }}>
					<div className="progress-container w-100" style={{ height: `${props.height}`, background: "#F4F9FD", ...props.style }}>

						<div style={{ width: `${props.completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>

					</div>
				</div>

			</>
		);

	}
	else {
		return (
			<>
				{props.isPercentageLabeled ?
					<div className="d-flex align-items-center" style={{ width: `${props.width}` }}>
						<div className="progress-container w-100" style={{ height: `${props.height}`, background: "#F4F9FD", ...props.style }}>

							<div style={{ width: `${props.completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>

						</div>
						<div style={{ marginLeft: "10px" }}>
							<span className="roboto-b3">{props.completed}%</span>
						</div>
					</div>
					:
					<div className="progress-container" style={{ background: "#FFFFFF", ...props.style }}>

						<div style={{ width: `${props.completed || 0}%`, backgroundColor: `${props.barColor}` }} className="progress-c-bar"></div>

					</div>

				}
			</>
		);
	}

}