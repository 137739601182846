import React, { useState, useEffect, useRef } from "react";
import Button from "../sub-component/Button";
import AlertBox from "../common/AlertBox";
import { isEmailValid, openURL } from "../../utilities/Utilites";
import DropDown from "../sub-component/DropDown";

import { useAuth } from "./../../context/AuthContext";


export default function EmployeeSetupForm(props) {

	const auth = useAuth();
	const [alert, setAlert] = useState();
	const timer = useRef();

	const [roles, setRoles] = useState();
	const [users, setUsers] = useState([]);
	const [editingUser, setEditingUser] = useState([]);
	const [employeeUser,setEmployeeUser] = useState([]);

	const [newUser, setNewUser] = useState({
		email: null,
		role_id: null,
	});

	const [editUserId, setEditUserId] = useState(null);

	const [processingRowId, setProcessingRowId] = useState();
	const [processing, setProcessing] = useState(false);

	async function getRoles() {

		const { result } = await openURL("/v1/roles/", "GET", auth);

		if (result) {
			setRoles(result.data);
		}
	}

	async function getUsers() {

		const { result } = await openURL("/v1/user/?managers=true", "GET", auth);

		const response_user = await openURL("/v1/user/?employees=true", "GET", auth);


		console.log("repsonse",response_user.result.data);
		if(response_user){
			setEmployeeUser(response_user.result.data);
		}

		if (result) {
			setUsers(result.data);
		}

	}

	function validateForm() {

		if (!newUser.email || !isEmailValid(newUser.email)) {
			return { isValid: false, message: "Please enter a valid email" };

		} else if (!newUser.role_id) {
			return { isValid: false, message: "Please select a role" };

		} else {
			return { isValid: true, message: "" };
		}


	}

	async function addUser() {

		const validation = validateForm();

		if (!validation.isValid) {
			handleAlert("danger", "Incomplete data", validation.message);
			return;
		}

		setProcessing(true);

		const { error } = await openURL("/v1/user/", "POST", auth, newUser);

		setProcessing(false);

		if (error) {

			handleAlert("danger", "Error", error.message[0].toUpperCase()+error.message.slice(1,error.message.length));
			return;
		}

		handleAlert("success", "Success", "User added successfully");

		setNewUser({
			email: "",
			role_id: null,
		});

		getUsers();


	}

	async function updateUser() {


		setProcessingRowId(editUserId);

		const { error } = await openURL(`/v1/user/${editUserId}`, "PUT", auth, editingUser);

		setProcessingRowId(null);

		if (error) {
			handleAlert("danger", "Error", error.message);
			return;

		}

		setEditUserId(null);
		setEditingUser(null);

		handleAlert("success", "Success", "User updated successfully");

		getUsers();

	}

	async function deleteUser(id) {

		setProcessingRowId(id);

		const { error } = await openURL(`/v1/user/${id}`, "DELETE", auth);

		setProcessingRowId(null);

		if (error) {

			handleAlert("danger", "Error", error.message);
			return;

		}

		handleAlert("success", "Success", "User deleted successfully");

		getUsers();

	}

	function editUser(user) {
		setEditUserId(user.id);
		setEditingUser({ email: user.email, role_id: user.role_assignment.id });
	}

	function handleAlert(color, title, message) {

		clearTimeout(timer.current);

		setAlert(
			<AlertBox color={color} title={title} description={message} />
		);

		timer.current = setTimeout(() => {
			setAlert(null);
		}, 3000);

	}


	function getFormRow() {
		return (
			<div className="d-flex flex-row justify-content-around table-row">

				<div className="b4 " style={{ width: "33%", textAlign: "center", padding: "12px 10px 5px" }}>
					<input
						type="email"
						id="create-new-user-invite-team-input"
						placeholder="youremail@example.com"
						style={{
							width: "100%",
							border: "none",
							borderBottom: "1px solid #a1a1a1",
							textAlign: "center",
							padding: "5px 0",
						}}
						value={newUser.email}
						onChange={(event) => setNewUser({ ...newUser, "email": event.target.value })}
					/>
				</div>

				<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0 5px 0" }}>
					<DropDown
						options={
							roles ? (
								roles.map(role => ({ key: role.id, value: role.role_name }))
							) : (
								[]
							)
						}
						selectedKey={newUser.role_id}
						maxCharacter="36"
						id="create-user-invite-team-dropdown"
						onSelect={(object) => {
							setNewUser({ ...newUser, "role_id": object.key });
						}}
						key={Math.ceil(Math.random() * 100)}
					/>
				</div>


				<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0 5px 0" }}>
					{
						processing ? (
							<span className="busy-indicator-blue"></span>
						) : (
							<span className="plus-icon" id="plus-icon" onClick={addUser} />
						)

					}

				</div>
			</div>
		);
	}

	function getEditFormRow() {

		return (
			<div className="d-flex flex-row justify-content-around table-row" >

				<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 20px 5px" }}>
					<input
						type="email"
						id="update-existing-user-invite-team-input"
						placeholder="youremail@example.com"
						style={{
							width: "100%",
							border: "none",
							borderBottom: "1px solid #a1a1a1",
							textAlign: "center",
							padding: "5px 0",
						}}
						value={editingUser.email}
						onChange={(event) => setEditingUser({ ...editingUser, "email": event.target.value })}
					/>
				</div>

				<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0 5px 0" }}>
					<DropDown
						options={
							roles ? (
								roles.map(role => ({ key: role.id, value: role.role_name }))
							) : (
								[]
							)
						}
						selectedKey={editingUser.role_id}
						id="update-existing-user-dropdown"
						maxCharacter="36"
						onSelect={(object) => {
							setEditingUser({ ...editingUser, "role_id": object.key });
						}}
					/>
				</div>


				<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0 5px 0" }}>
					{
						processingRowId ? (
							<span className="busy-indicator-blue"></span>
						) : (
							<span className="tick-icon" id="tick-icon" onClick={updateUser} />
						)

					}

				</div>
			</div>
		);
	}

	useEffect(() => {

		getRoles();
		getUsers();

	}, []);



	return (
		<div className="d-flex flex-column form-responsiveness pl-2 pr-2" style={{maxWidth:"99vw"}}>
			{
				props.label &&
				<span className="s1">{props.label || "Invite Team"}</span>
			}

			{
				props.description &&
				<p className="b3" style={{ marginTop: "20px", fontSize: "121dp" }}>
					{
						props.description &&
						"Your Social impact can’t be successful without your team. Altrunic got you covered with the feature to share your activities with your colleagues so that maximum effect can be achieved."
					}

				</p>
			}

			<div className="mt-1 pl-2 pr-4 pt-0" style={{ width: "100%",maxWidth:"99vw",overflowX:"auto",paddingBottom:"100px" }}>
				
				<div className="" style={{ marginTop: "15px",minWidth:"575px"}} >

					<div className="d-flex flex-row justify-content-around table-header pt-3 pb-3" >
						<div className="b4" style={{ width: "33%", textAlign: "center" }}>Email</div>
						<div className="b4" style={{ width: "33%", textAlign: "center" }}>Role</div>
						<div className="b4" style={{ width: "33%", textAlign: "center" }}>Action</div>
					</div>

					<div className="table-body pl-2 pr-2" >


						{
							users.map((user, index) => {

								if (user.id === editUserId) {
									return getEditFormRow();
								}

								return (
									<div className="d-flex flex-row justify-content-around table-row" key={index}>
										<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0" }}>
											{user.email}
										</div>
										<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0" }}>
											{user.role_assignment.role_name}
										</div>

										<div className="b4" style={{ width: "33%", textAlign: "center", padding: "12px 0" }}>
											{
												user.id !== auth.user_id && (
													processingRowId === user.id ? (
														<span className="busy-indicator-blue"></span>
													) : (
														<span>
															<div className="edit-icon" onClick={() => editUser(user)} />
															<div className="delete-icon" onClick={() => { deleteUser(user.id); }} />
														</span>
													)
												)

											}
										</div>
									</div>
								);
							})
						}

						{
							(users.length + employeeUser.length) >= 20 && localStorage.getItem("packageId") == 4 ? "" : getFormRow()
						}


					</div>
				</div>

				
				{alert}

			</div>

			{
				props.shouldNext
				&&
				<div style={{marginTop:"-50px"}} className="align-self-center">
					<Button onClick={props.onClick} title="Finish" id="create-user-invite-team-finish-button" />
				</div>
			}



		</div >
	);

}