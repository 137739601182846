import React, { useState, useEffect } from "react";
import Circle from "../../assets/icons/notifications/circle.svg";
import { openURL } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";

export default function NotificationBox(props) {

	const auth = useAuth();
	const [notifications, setNotifications] = useState([]);
	const [processing, setProcessing] = useState(false);

	const getNotifications = async () => {

		setProcessing(true);

		const { result } = await openURL("/v1/notifications/", "GET", auth, null);

		setProcessing(false);

		if (result) {

			var hasUnreadNotification = false;
			var notf = [];
			result.map((data) => {

				if (data.status === "UNREAD") {
					hasUnreadNotification = true;
				}

				notf.push(
					{
						isRead: (data.status == "UNREAD") ? false : true,
						notification: data.message_body,
						date: new Date(data.createdAt).toLocaleString(),
						objectID: data._id
					}
				);

			});

			setNotifications(notf);
			props.setHasUnreadNotification(hasUnreadNotification);
		}
	};

	const markNotifcationAsRead = async () => {

		const { result } = await openURL("/v1/notifications/mark", "POST", auth, null);

		if (result) {
			const readNotif = notifications.map((notif) => ({ ...notif, isRead: true }));
			props.setHasUnreadNotification(false);
			setNotifications(readNotif);
		}

	};

	useEffect(() => {
		getNotifications();
	}, []);




	return (
		<div
			ref={props?.reference} tabIndex={props?.tabIndex}
			className="notification-box"
			onBlur={props?.onBlur}
			style={{ top: "40px", overflow: "auto", scrollbarWidth: "none", ...props?.style }}>

			<div className="w-100">
				<div className="d-flex justify-content-between pt-3 px-3 pb-2">
					<div>
						<span className="roboto-s2">Notifications</span>
					</div>
					<div>
						<span className="roboto-b3 pointer" style={{ color: "#007AFF" }} onClick={markNotifcationAsRead}>Mark all as read</span>
					</div>
				</div>
				<div>
					{
						processing ? (
							<div
								className="busy-indicator-blue d-flex align-self-center"
								style={{
									borderWidth: "3px",
									height: "25px",
									width: "25px",
									borderRadius: "100%",
									margin: "30px auto"
								}}
							/>
						) : (
							notifications.map((notifcation, index) => {
								if (notifcation) {
									const noti=notifcation.notification;
									const restNotification=noti.slice(1,noti.length);
									return (
										<div style={{ marginLeft: "20px" }} key={index}>
											<div className="d-flex">
												{!notifcation.isRead ?
													<div style={{ marginLeft: "-9px" }}>
														<img src={Circle} style={{ width: "4px", height: "4px" }} />
													</div>
													: <div style={{ marginLeft: "-5px" }}></div>}
												<div style={{ marginLeft: "4px" }}>
													<span className="b4">{notifcation.notification[0].toUpperCase()+restNotification}</span>
												</div>

											</div>
											<div style={{ marginTop: "-5px" }}>
												<span className="roboto-b3" style={{ color: "#A4AEB8" }}>{notifcation.date}</span>
											</div>
										</div>
									);
								}

							})
						)
					}

				</div>
			</div>

		</div>

	);
}