import React, { Fragment } from "react";
import ReactRoundedImage from "react-rounded-image";

import { ReactComponent as User } from "../../assets/icons/basic/user1.svg";

/**
 * Image Button
 * @param {object} props Button with image, Default after the title
 * @param {String} props.title Button title
 * @param {String} props.image Button image
 * @param {boolean} props.roundedButton Rounded Button
 * @param {boolean} props.squareButton Square Button
 * @param {boolean} props.imageLeft Button on left side of the button
 * @param {boolean} props.imageRight Button on right side of the button
 */
const defaultImage = "https://icons.iconarchive.com/icons/mahm0udwally/all-flat/256/Photos-icon.png";

export default function ImageButton(props) {


	return (
		<React.Fragment>
			{
				props.roundedButton &&
				<div className={`image-button ${props.className}`}>
					{
						props.imageLeft &&
						<ReactRoundedImage
							image={props.image || defaultImage}
							roundedSize="0" imageWidth="22" imageHeight="22" />
					}
					<span className="button-text" style={{ margin: "0 2.5px 0 2.5px" }}>
						{props.title || "john doe"}
					</span>
					{
						props.imageRight &&
						<Fragment>
							{
								props.forDefaultUser ?
									<User style={{ fill: "red" }} />
									:
									<ReactRoundedImage
										image={props.image || defaultImage}
										roundedSize="0" imageWidth="22" imageHeight="22" />
							}

						</Fragment>

					}
				</div>

			}

			{
				props.squareButton &&
				<button className={`d-flex flex-row square-button ${props.className}`} style={props.style} disabled={props.disabled} onClick={props.onClick}>
					<span style={{ marginRight: "10px" }}>
						<img src={props.image || defaultImage} style={{ height: "30px", width: "30px", objectFit: "contain" }} />
					</span>
					<span className="d-flex flex-column justify-content-center" >
						<span className="b3" style={{ color: "#FFF", textAlign: "left" }}>
							{props.title || "Title"}
						</span>
						<span className="b4" style={{ color: "#FFF", textAlign: "left" }}>
							{props.description || "Settlement"}
						</span>
					</span>
				</button>
			}
		</React.Fragment >
	);

}