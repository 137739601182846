import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import baseURL from "../../utilities/BaseURL";
import AccountActivated from "./AccountActivated";
import LinkExpired from "./LinkExpired";
import AuthMessageCard from "../../components/auth/AuthMessageCard";

export default function UserActivation() {

	const params = useParams();

	const [activationProcess, setActivationProcess] = useState(ActivationState.state.PENDING);

	useEffect(() => {

		(async () => {

			const response = await fetch(`${baseURL}/v1/auth/activate`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json"
				},
				body: JSON.stringify({ activation_token: params.activation_token })
			});

			if (!response.ok) {
				setActivationProcess(ActivationState.state.FAILED);
			} else {
				setActivationProcess(ActivationState.state.SUCCESS);
			}

		})();

	}, []);

	return (
		<div className="d-flex flex-lg-row flex-column justify-content-between">

			<div className="d-sm-flex message-card-mobile" style={{ width: "100%", height: "auto", position: "relative", }}>
				<AuthMessageCard paragraph={
					["Giving is not just about making a ",
						<span style={{ color: "#0054DC" }} key="0">donation</span>,
						", its about making an ",
						<span style={{ color: "#0054DC" }} key="1">impact</span>,
						"."]}
				/>
			</div>

			<div className="auth-form d-flex justify-content-center" >

				{
					activationProcess === ActivationState.state.FAILED ? (
						<LinkExpired />
					) : activationProcess === ActivationState.state.SUCCESS ? (
						<AccountActivated />
					) : (
						<div className="busy-indicator-blue " style={{
							display: "block",
							height: "50px",
							width: "50px",
							borderRadius: "25px",
							borderWidth: "5px",
							margin: "auto",
						}}></div>
					)
				}

			</div>
		</div>

	);

}

class ActivationState {

	static get state() {
		return {
			PENDING: "pending",
			SUCCESS: "success",
			FAILED: "failed"
		};
	}

}