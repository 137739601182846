/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect, Fragment } from "react";
import {
	GoogleMap,
	Marker,
	InfoWindow,
} from "react-google-maps";
// import { getDistance } from 'geolib';
// import { LoadScript } from "@react-google-maps/api";
// import * as parkData from "./mapData.json";
// import mapStyles from "./mapStyles";
import mapMarker from "../../../assets/icons/maps/Location Pin.png";
// import mapMarker2 from "../../../assets/icons/maps/mapmarker2.svg";

import CharityInfoBox from "./CharityInfoBox";

export default function Map(props) {

	const [hoveredCharity, setHoveredCharity] = useState(props.hoveredCharity);
	const [selectedCharity, setSelectedCharity] = useState(props?.selectedCharity || null);
	console.log(selectedCharity);
	const [mapRef, setMapRef] = useState(null);
	const [center, setCenter] = useState(props.center);
	const [charities, setCharities] = useState(props.charities);

	const mapInfoBoxComp = useMemo(() => mapInfoBox(props.getSelectedCharityFromMap, charities.filter(item => { return item.id === hoveredCharity?.id; }), selectedCharity), [hoveredCharity, selectedCharity]);

	function mapInfoBox(getSelectedCharityFromMap, hoveredCharity, selectedCharity) {
		const getCharityId = (charity) => {
			getSelectedCharityFromMap(charity);
		};
		return (
			<>
				{
					hoveredCharity.length === 1
						?
						<InfoWindow
							position={{
								lng: hoveredCharity[0].longitude,
								lat: hoveredCharity[0].latitude
							}}
						// onMouseOut={() => {
						// 	setHoveredCharity(null);
						// }}
						>

							<CharityInfoBox charity={hoveredCharity[0]} getCharityId={getCharityId} isSelected={props.selectedCharity ? (props.selectedCharity.id === hoveredCharity[0].id ? true : false) : false} />
						</InfoWindow>

						:
						<>
							{
								selectedCharity &&
								<InfoWindow
									position={{
										lng: selectedCharity.longitude,
										lat: selectedCharity.latitude
									}}
									options={{
										alignBottom: true,
										pane: "mapPane",

										boxStyle: {
											width: "300px"
										},
										closeBoxURL: ``,
										enableEventPropagation: true
									}}
								>

									<CharityInfoBox selectedCharity charity={selectedCharity} isSelected getCharityId={getCharityId} />
								</InfoWindow>
							}

						</>
				}
			</>
		);

	}
	useMemo(() => setHoveredCharity(props.hoveredCharity), [props.hoveredCharity]);
	useMemo(() => setSelectedCharity(props?.selectedCharity), [props?.selectedCharity]);

	function googleMapsMarkers(charitiesMarkers) {
		// console.log(charitiesMarkers, "use memo comp");
		return (
			<Fragment>
				{charitiesMarkers.map(park => {
					return (
						<Marker
							key={park.id}
							position={{
								lng: park.longitude,
								lat: park.latitude
							}}
							onClick={() => {
								setHoveredCharity(park);
							}}

							// onClick={() => { props?.getCharityId(park.id); }}
							icon={
								// (park.id === props?.hoveredCharity || park.id === props?.selectedCharity?.id)
								// 	?
								// {
								// 	url: `${mapMarker2}`,
								// 	scaledSize: new window.google.maps.Size(25, 25)
								// }
								// :
								{
									url: `${mapMarker}`,
									scaledSize: new window.google.maps.Size(25, 30.4)
								}
							}
						>

						</Marker>

					);
				})}

			</Fragment>
		);
	}
	const charityMarkers = useMemo(() => googleMapsMarkers(charities), charities);


	useEffect(() => {
		setCharities(props.charities);
	}, [props.charities]);

	return (
		<GoogleMap
			defaultZoom={10}
			defaultCenter={center}
			id="form-map"
			ref={map => setMapRef(map)}
			// onCenterChanged={() => }
			options={
				{
					minZoom: 4,
					maxZoom: 18,
				}
			}

			onDragEnd={() => { props?.getCurrentCenter(mapRef.getCenter().toJSON()); setHoveredCharity(null); setSelectedCharity(null); }}
			onZoomChanged={() => { setSelectedCharity(null); setHoveredCharity(null); }}>

			{
				charities.length > 0 &&
				<Fragment>
					{charityMarkers}

					{mapInfoBoxComp}
				</Fragment>
			}




		</GoogleMap >
	);
}
