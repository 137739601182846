import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import AddRow from "./Row";
import { ReactComponent as Delete } from "../../../assets/icons/basic/trash.svg";
import { ReactComponent as Edit } from "../../../assets/icons/basic/edit.svg";

/**
 * Tabel
 * @param {object} props Tabel
 * @param {boolean} props.isEditable if true you can use CRUD oprations on table
 * @param {array} props.headers an array of strings which can be collaction of column(label) for header 
 * @param {array} props.rows an 2D array of items
 * @param {boolean} props.alignText it uses the flex property like start, center and end to align items(text) 
 * @param {Function} props.updateTable a callback functions which retuens all updated data
 * @param {Function} props.updatedRow a callback functions which retuens all updated row parameters are (row , actionId) where actionId 0 is add 1 is edit 2 is delete
 * @param {Array} props.activeSelectInput pass an array of objects in which each object has a Col no. to tell where we want to use selection and the data we can use multiple select with multiple objects in the array 
 */

export default function CustomTabel(props) {

	const [rows, setRows] = useState([]);
	// const [editable, setEditable] = useState(false);
	const [activeForEdit, setActiveForEdit] = useState(null);
	const [headers, setHeaders] = useState(props.headers);
	// const [tableWidth, setTableWidth] = useState(null);

	useEffect(() => {
		if (props.isEditable === true && headers[headers.length - 1] !== "Action") {
			setHeaders([...headers, "Action"]);
		}
	}, [props.isEditable]);

	useEffect(() => {
		if (props.rows) {
			setRows(props.rows);
		}
		else {
			setRows(rows);
		}
	}, [props.rows, rows]);

	// const checkRow = (action, rowBeingAdded, index) => {
	const checkRow = (action, rowBeingAdded) => {

		var updatedRow = rows;


		if (action === 0) {
			updatedRow.push(rowBeingAdded);
			props.updatedRow(rowBeingAdded, 0);
		}
		if (action === 3) {

			const tempIndex = updatedRow.indexOf(rowBeingAdded);
			if (tempIndex > -1) {
				updatedRow.splice(tempIndex, 1);
				props.updatedRow(rowBeingAdded, 2);

			}
		}
		setRows([updatedRow]);
		props.updateTable(rows);
	};

	const editRow = (rowId) => {
		if (props.isEditable) {
			if (activeForEdit === null) {
				setActiveForEdit(rowId);

			}
			else if (rowId === activeForEdit) {
				props.updatedRow(rows[rowId], 1);
				setActiveForEdit(null);
			}
		}
	};

	// useEffect(() => {
	// 	if (props.columnsWidth) {
	// 		let tempVar = 0;
	// 		props.columnsWidth.map((item) => {
	// 			return (
	// 				tempVar += parseInt
	// 			);
	// 		});
	// 		setTableWidth(tempVar);
	// 		console.log(tempVar);
	// 	}
	// }, [tableWidth]);

	return (
		<div className="d-flex flex-column align-items-start custom-table">
			<span className="b3">{props.title}</span>
			{
				!props.isEditable

					?
					<div style={{ marginTop: "10px" }} className="custom-table-header">


						{

							headers.map((header, key) => {
								return (
									<div key={key}
										className={
											"row-cell d-flex " +
											`${`justify-content-${props.alignText ? props.alignText : `${key === 0 ? "center " : key === headers.length - 1 ? "center " : "center "} `}`}`

										}

										style={props.columnsWidth ?
											(props.columnsWidth.find(item => item.col === key + 1) ? { width: `${props.columnsWidth[key].width}` } : { width: `${100 / (headers.length)}%` })
											:
											{ width: `${100 / (headers.length)}%` }}
									>
										<span className={`b4 nero-color ${!props.alignText && `${key === 0 && " row-margin-lefts "} ${key === headers.length - 1 && " row-margin-rights "}`}`}> {header}</span>
									</div>
								);
							})

						}
					</div>
					:
					<div style={{ marginTop: "10px" }} className="custom-table-header w-100">
						<div style={{ width: "80%" }} className="d-flex justify-content-between align-items-center">

							{
								headers.map((header, key) => {
									return (
										<Fragment key={key}>
											{
												key != (headers.length - 1) &&

												<div
													className={
														"row-cell d-flex " +
														`${`justify-content-${props.alignText ? props.alignText : `${key === 0 ? "center " : key === headers.length - 1 ? "center " : "center "} `}`}`

													}

													style={
														props.columnsWidth ?
															(props.columnsWidth.find(item => item.col === key + 1) ? { width: `${props.columnsWidth[key].width}` } : { width: `${100 / (headers.length - 1)}%` })
															:
															{ width: `${100 / (headers.length - 1)}%` }
													}
												>
													<span className={`b4 nero-color ${!props.alignText && `${key === 0 && " row-margin-lefts "} ${key === headers.length - 1 && " row-margin-rights "}`}`}> {header}</span>
												</div>
											}
										</Fragment>
									);
								})
							}
						</div>
						<div
							className={"row-cell b4 d-flex" + ` justify-content-${props.alignText ? props.alignText : "center"}`}
							style={{ width: `${100 / (headers.length)}%`, paddingLeft: "20px" }}>
							<span className={`b4 nero-color `}> {headers[headers.length - 1]}</span>

						</div>
					</div>
			}

			<div
				style={{ marginTop: "10px" }}
				className="custom-table-body w-100">
				{
					props.rows && props.rows.map((row, index) => {
						return (
							<div key={index} className="outer-row">
								<div className="custom-table-row w-100">
									{
										activeForEdit !== index
											?
											<Fragment>
												<div
													className="d-flex"
													style={{ width: `${props.isEditable ? `${100 - (100 / (row.length))}` : "100"}%` }}>
													{
														row.map((entity, innerIndex) => {
															return (
																<Fragment key={innerIndex}>
																	{
																		innerIndex > 0 &&
																		<div
																			className={"d-flex align-items-center " + `${`justify-content-${props.alignText ? props.alignText : `${innerIndex === 1 ? "start " : "center "} `}`}`}
																			style={
																				props.columnsWidth ?

																					(props.columnsWidth.find(item => item.col === innerIndex) ? { width: `${props.columnsWidth[innerIndex - 1].width}` } : { width: `${(100 / (row.length - 1))}%` })
																					:
																					{ width: `${(100 / (row.length - 1))}%` }
																			}>
																			{
																				typeof entity === "string" || entity instanceof String
																					?
																					<span
																						style={{
																							textAlign: `${props.columnsTextAlignment
																								?
																								(props.columnsTextAlignment.find(item => item.col === innerIndex) ? `${props.columnsTextAlignment[innerIndex - 1].textAlign}` : "center")
																								:
																								"center"}`
																							, width: "100%"
																						}}
																						className={`row-cell b4 ${!props.alignText && `${innerIndex === 1 && ""}`}`}>
																						{entity}
																					</span>
																					:

																					<Fragment>
																						{
																							(entity.option !== undefined)
																								?
																								<span
																									style={{ textAlign: "center" }}
																									className={`row-cell b4 d-flex ${!props.alignText && `${innerIndex === 1 && " row-margin-lefts"}`}`}>
																									{entity.option}
																								</span>
																								:
																								<div
																									style={{ textAlign: "center" }}
																									className={`row-cell b4 d-flex  ${!props.alignText && `${innerIndex === 1 && " row-margin-lefts"}`}`}
																								>{entity}
																								</div>
																						}

																					</Fragment>

																			}

																		</div>

																	}
																</Fragment>
															);
														})
													}
												</div>
												{
													props.isEditable &&
													<div
														className={"row-cell b4 d-flex" + ` justify-content-${props.alignText ? props.alignText : "center"}`}
														style={{ width: `${100 / (row.length)}%`, paddingLeft: "20px" }}>
														{
															!props.allowDeleteOprationOnly &&
															< Edit className={`${!props.alignText && " table-icons pointer "}`} onClick={() => editRow(index)} width="16.67" height="16.67" />

														}
														{
															!props.hideDelete &&
															<Delete className={`${!props.alignText && "table-icons pointer "}`} style={{ marginLeft: "10px" }} onClick={() => checkRow(3, row)} width="16.67" height="16.67" />
														}

													</div>
												}
											</Fragment>
											:
											<AddRow
												onClick={() => editRow(index)}
												nonEditableColumns={props.nonEditableColumns}
												action={2} rowData={row}
												updateTable={checkRow}
												rowLength={headers.length}
												alignText={props.alignText}
												activeSelectInput={props.activeSelectInput && props.activeSelectInput}
												stepperInput={props.stepperInput && props.stepperInput}
												columnsWidth={props.columnsWidth}
											/>
									}
								</div>

							</div>
						);
					})
				}
				{
					(props.isEditable && !props.hideAddItemSection) &&
					<div className="w-100" style={{ padding: "0px 10px" }}>
						<AddRow
							updateTable={checkRow}
							alignText={props.alignText}
							rowLength={headers.length}
							activeSelectInput={props.activeSelectInput && props.activeSelectInput}
							stepperInput={props.stepperInput && props.stepperInput}
							nonEditableColumns={props.nonEditableColumns}
							columnsWidth={props.columnsWidth}
						/>
					</div>
				}

			</div>
		</div >
	);
}