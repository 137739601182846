import React, { useState, useEffect, useRef } from "react";
import { Fragment } from "react";

import { ReactComponent as ArrowDown } from "../../assets/icons/arrows/chevron-bottom.svg";
import Tag from "./Tag";


/**
 * Select Component
 * @param {object} props Form Select Component
 * @param {Function} updateSelections Update Selected States which Send back the selected option To The Parent(or where you call it)
 * @param {String} varient should be either 'multi-select' or null (for single selection)
 * @param {Array} options array of string (options)
 * @param {Array} selected array of selected option. Pass it an array in which you want to save options
*/

export default function Select(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(props.selected);

	const [optionBoxSize, setOptionBoxSize] = useState(0);
	const selectRef = useRef();

	useEffect(() => {
		// console.log(selected, "Selected Items");
		if (typeof props.updateSelections === "function") {
			props.updateSelections(selected);
		}
	}, [selected]);


	const optionSelected = (option) => {
		if (props.varient === "multi-select") {
			setSelected([...selected, option]);
		}
		else {
			var dummyArr = [option];
			setSelected(dummyArr);
		}
		setIsOpen(!isOpen);

	};


	const removeTag = (selectedTag) => {
		setSelected(selected.filter(item => item != selectedTag));
	};

	useEffect(() => {
		setSelected(props.selected);
	}, [props.selected]);

	useEffect(() => {
		if (isOpen) {
			selectRef?.current?.focus();
		}
		else{
			selectRef?.current?.blur();
		}
	}, [isOpen]);

	if (props.varient === "customizeSelect") {
		return (
			<div
				className="d-flex flex-column w-100 align-items-start position-relative pointer"
				style={{ ...props.style }}
				tabIndex="0"
				// onBlur={() => { setIsOpen(false); }}
				// onClick={props.disable ? () => { } : () => { selectRef?.current?.blur(); }}
			>
				<span className="b3">{props.title}</span>
				<select
					onClick={ () => {	setIsOpen(!isOpen);}}
					// onClick={props.disable ? () => { } : () => { selectRef?.current?.blur(); }}
					onChange={(e) => {					
						optionSelected(e.target.value);
						if (props.onSelect) {
							setOptionBoxSize(1); 
							setIsOpen(isOpen);
							props.onSelect(e.target.value);
						}					 
					}}
					id={props.id}
					ref={selectRef} size={optionBoxSize} style={{ zIndex: "9", top: "18px", marginTop: "10px", position: "absolute" }} className="form-select w-100 pointer" aria-label="Default select example" onFocus={() => { setOptionBoxSize(5); }}
					onBlur={()=> {setOptionBoxSize(1); }}
				>
					{
						props.options && props.options.map((option, key) => {
							return (
								<option key={key} selected={selected === option ? true : false} value={option}>{option}</option>
							);
						})
					}
				</select>
			</div>
		);
	}
	
	else {
		return (
			<div className="d-flex flex-column w-100 align-items-start position-relative pointer"
				style={{ ...props.style, }}
				tabIndex="0"
				onBlur={() => { setIsOpen(false); }}
				onClick={props.disable ? () => { } : () => setIsOpen(!isOpen)}
				id={props.id}
			>
				<span className="b3">{props.title}</span>
				<div className="custom-select-div"
				>
					{
						props.varient === "multi-select"
							?
							<div></div>
							:
							<div className="text-truncate">
								{
									selected
										?
										<span className="text-truncate">{selected || props.placeholder}</span>
										:
										<span style={{ color: "rgb(118, 118, 118)" }}>{props.placeholder}</span>
								}


							</div>
					}
					<ArrowDown

						style={{ cursor: "pointer" }} />
				</div>
				{
					props.varient === "multi-select" &&
					<div className="d-flex flex-wrap" style={{ marginTop: "10px" }}>
						{
							selected && selected.map((selection, index) => {
								return (
									<div key={index} style={{ marginLeft: "5px", marginBottom: "5px" }}>
										<Tag
											title={selection}
											onClick={() => {
												removeTag(selection);
											}}
										/>
									</div>
								);
							})
						}
					</div>
				}

				{
					isOpen &&

					<div
						className="w-100 custom-select-option-container"
						id="custom-select-option-container"
						style={{ position: "absolute", marginTop: "4.35em" }} >
						{/* <input /> */}
						{
							props?.enableResetOption &&
							<div className="custom-select-option" onClick={props.disable ? () => { } : () => {
								optionSelected(props?.placeholder);
								props.onSelect(null);
							}}>
								<span style={props.listStyle}>{props?.placeholder}</span>
							</div>

						}
						{
							props.options && props.options.map((option, key) => {
								return (
									<Fragment key={key}>
										{
											props.varient === "multi-select" ?
												!selected.includes(option) &&
												<div className="custom-select-option" onClick={() => {
													optionSelected(option);
													if (props.onSelect) {
														props.onSelect(option);
													}
												}}>
													<span>{option}</span>
												</div>
												:
												<div className="custom-select-option text-left" onClick={props.disable ? () => { } : () => {
													optionSelected(option);
													if (props?.selectionWithKey) {
														props.onSelect({ id: key, option: option });
													}
													else if (props.onSelect) {
														props.onSelect(option);
													}
												}}>
													<span style={props.listStyle}>{option}</span>
												</div>
										}
									</Fragment>
								);
							})
						}
					</div>

				}

			</div >
		);
	}
}
