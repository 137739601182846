import React from "react";
import ReactRoundedImage from "react-rounded-image";
import RoundImage from "../../assets/icons/Genral/Unicef.svg";
import { ReactComponent as ImpactIcon } from "../../assets/icons/basic/heart.svg";

export default function CharityCard(props) {
	return (
		<div className="suggested-charity-card ">
			<div>
				<div style={{ display: "flex", height: "70px" }}>
					<div className="rounded-image">
						<ReactRoundedImage
							image={props.image || RoundImage}
							imageWidth="70"
							imageHeight="70"
							roundedSize="0"
						/>
					</div>
					<div
						className="justify-content-between d-flex"
						style={{ flexDirection: "column", marginLeft: "20px", padding: "10px 0px" }}>
						<span className="b3 nero-color">{props.title || "Title Not Found"}</span>
						<span className="roboto-b3 hoki-color">{props.description || "Description Not Found"}</span>

						<div className="d-flex align-items-center">
							<ImpactIcon style={{ marginRight: "5px" }} />
							<span className="roboto-b3 cloud-burst-color">{props.impact}</span>
						</div>
					</div>
				</div>
				<div style={{ marginLeft: "20px", marginRight: "20px", marginTop: "15px", maxHeight: "97px", overflow: "scroll" }} className="hideScrollBar">
					<p className="roboto-b2" style={{ color: "#1B1D21" }}>{
						props.details ? (
							props.details.length > 240 ? `${props.details.substring(0, 240)}...` : props.details
						) : (
							"N/A"
						)
					}</p>
				</div>
			</div>
		</div>
	);
}
