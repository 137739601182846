import React, { useRef, useEffect } from "react";
import Navbar from "../Navbar";
import MainFeatures from "./MainFeatures";
import Footer from "../Footer";
import OtherFeatures from "./OtherFeatures";
// import grey_bg from "../../../../assets/images/Features Page/grey_bg.svg";
import blue_bg from "../../../../assets/images/Features Page/blue_bg.svg";
import laptop_mobile from "../../../../assets/images/Features Page/Both Laptop and Mobile-min.png";
import { Helmet } from "react-helmet";



export default function FeaturesPage() {

	const featureFirstBoxRef = useRef();
	const featureSecondBoxRef = useRef();
	const featureThirdBoxRef = useRef();
	const featureFourthBoxRef = useRef();

	useEffect(() => {
		if (localStorage.getItem("scrollTo") === "featureFirstBoxRef") {
			featureFirstBoxRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
		}
		else if (localStorage.getItem("scrollTo") === "featureSecondBoxRef") {
			featureSecondBoxRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
		}
		else if (localStorage.getItem("scrollTo") === "featureThirdBoxRef") {
			featureThirdBoxRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
		}
		else if (localStorage.getItem("scrollTo") === "featureFourthBoxRef") {
			featureFourthBoxRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
		}

	}, []);

	return (
		<Navbar>

			<Helmet>
				<title>All in one corporate social responsibility platform for you</title>
				<meta name="description" content="Altrunic has all the features you need to start or scale your social impact program including impact website, impact objectives, giving campaigns, donation matching, and much more. Set up your CSR program now!" />
			</Helmet>

			<div className="d-flex flex-column">
				{/* <div>
				<Navbar />
			</div> */}

				<div className="feature-heading-box">
					<span className="ls-h4 feature-heading-mobile" style={{ paddingBottom: "15px" }}>Our Features to Help You</span>
					<span className="ls-b0-5 hoki-color feature-subheading-mobile"> General Overview</span>
				</div>
				<div className="w-100">
					<MainFeatures featureFirstBoxRef={featureFirstBoxRef} featureSecondBoxRef={featureSecondBoxRef} featureThirdBoxRef={featureThirdBoxRef} featureFourthBoxRef={featureFourthBoxRef} />
				</div>

				<div className="other-features-title">
					<span className="ls-h4" style={{ paddingBottom: "20px" }}>And it doesn’t end here</span>
					<span className="ls-h4 other-features-subheading-mobile" style={{ paddingBottom: "50px" }}>Altrunic is a home of all Features to Give Back</span>
				</div>

				<div>
					<OtherFeatures />
				</div>

				<div className="grey-bg" style={{ background: `#F4F9FD` }}>
					<div className="blue-bg" style={{ backgroundImage: `url(${blue_bg})` }}>
						<div className="laptop-image-on-mobile laptop-image" style={{ width: "60%", height: "472px", maxWidth: "740px" }}>
							<img src={laptop_mobile} style={{ width: "100%", height: "100%" }} />
						</div>
						<div className="d-flex flex-column align-items-center laptop-text" style={{ width: "40%", paddingRight: "70px" }}>
							<div className="d-flex flex-column">
								<span className="ls-h4 white-color laptop-heading" style={{ lineHeight: "25px", textAlign: "left" }}>Your Social Impact Partner </span>
								<span className="ls-h4 white-color laptop-description" style={{ paddingTop: "15px", lineHeight: "25px" }}>Anywhere, Anytime</span>
								<span className="ls-b0-5 white-color laptop-description" style={{ paddingTop: "25px", lineHeight: "25px", maxWidth: "440px" }}>
									{"You can access Altrunic's advanced features not only on Desktop devices but also on your smartphones. You can control, edit & analyze your workspace reporting and campaigns anytime. "}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div>
					<Footer />
				</div>
			</div>
		</Navbar>
	);
}