/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ReactComponent as ShareBtnIcon } from "../../assets/icons/basic/share.svg";
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon
} from "react-share";
import { Helmet } from "react-helmet";
import { useAuth } from "../../context/AuthContext";

export default function ShareBtn(props) {

	const auth = useAuth();

	const [isFocused, setisFocused] = useState(false);

	// const settings = {
	// 	url: String(window.location),
	// 	title: "Steadylearner Website",
	// 	shareImage: "https://www.steadylearner.com/static/images/brand/prop-passer.png",
	// 	size: "30"
	// };

	const focusShare = () => {
		if (auth.is_editing) {
			console.log(document.getElementById(`campaign-${props.componentId}`));
			return;
		}
		if (isFocused) {
			document.getElementById(`campaign-${props.componentId}`).classList.remove("socialShareBtnActive");
			setisFocused(false);
		}
		else {
			document.getElementById(`campaign-${props.componentId}`).classList.add("socialShareBtnActive");
			setisFocused(true);
		}
	};
	return (
		<div className="d-flex flex-column align-items-end">

			{
				<div id={`campaign-${props.componentId}`} className="socialShareBtnContainer ">

					<FacebookShareButton quote={props.title} url={`${window.location.origin}`}>
						<FacebookIcon size={30} round={true} />
					</FacebookShareButton>

					<LinkedinShareButton title={props.title} windowWidth={750} windowHeight={600} url={`${window.location.origin}`}>
						<LinkedinIcon size={30} round={true} />
					</LinkedinShareButton>

					<TwitterShareButton title={props.title} url={`${window.location.origin}`}>
						<TwitterIcon size={30} round={true} />
					</TwitterShareButton>

				</div>
			}
			<div
				onClick={() => focusShare()}
				className="d-flex align-items-center justify-content-center socialShareBtn"
				style={{ backgroundColor: `${props.color}`,...props.style }}>
				<span className="cs-b1-t2 white-color">Share</span>
				<ShareBtnIcon fill="white" style={{ marginLeft: "7px" }} />
			</div>
		</div>
	);
}
