export class Packages {

	static get basic() {

		return {
			id: 4,
			name: "Basic",
			monthly: {
				price: 99,
				pricing_id: window._env_.REACT_APP_STRIPE_BASIC_MONTHLY_PRICING,
			},
			yearly: {
				price: 891,
				pricing_id: window._env_.REACT_APP_STRIPE_BASIC_YEARLY_PRICING,
			},

		};
	}

	static get starter() {
		return {
			id: 1,
			name: "Starter",
			monthly: {
				price: 249,
				pricing_id: window._env_.REACT_APP_STRIPE_STARTER_MONTHLY_PRICING,
			},
			yearly: {
				price: 2241,
				pricing_id: window._env_.REACT_APP_STRIPE_STARTER_YEARLY_PRICING,
			},
		};
	}

	static get professional() {

		return {
			id: 2,
			name: "Professional",
			monthly: {
				price: 399,
				pricing_id: window._env_.REACT_APP_STRIPE_PROFESSIONAL_MONTHLY_PRICING,
			},
			yearly: {
				price: 3591,
				pricing_id: window._env_.REACT_APP_STRIPE_PROFESSIONAL_YEARLY_PRICING,
			}

		};

	}

}