import React, { Fragment, useState } from "react";
import { ReactComponent as LeftArrow } from "../../../assets/icons/landing page 2/Left_Arrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/landing page 2/Right_Arrow.svg";
// import page_dot from "../../../assets/icons/landing page 2/Page_Dot.svg";
import siteBuilder from "../../../assets/images/landing/features/Features2A Image.png";
import donationMatching from "../../../assets/images/landing/features/Features2B Image-min.png";
import chechkoutGiving from "../../../assets/images/landing/features/New Advance Giving with shadow-min.png";
import charityInfo from "../../../assets/images/landing/features/Features2D Image-min.png";

export default function Section4() {

	const features = [
		{ id: 1, title: "Site builder", subtitle: "Highly customized Impact website can be designed within some clicks:", bullet1: "Pre-made default sections according to philanthropy needs of every business.", bullet2: "Ease to brand your impact website according to your requirements.", bullet3: "Customizable elements to make your design more interactive.", image: siteBuilder },
		{ id: 2, title: "Donation Matching", subtitle: "Donation matching is an advanced feature to facilitate businesses to:", bullet1: "Match every contribution of the donor amount  with the defined percentage.", bullet2: "Encourage employee & customers(guests) to join an organization in Giving.", bullet3: "Socially impact more for the well being of their organization as well as the Society.", image: donationMatching },
		{ id: 3, title: "Analytics", subtitle: "Analyze the impact of your donation campaigns with advanced analytical graphs and audit reports.", bullet1: "You can audit the report of the participants anytime in the platform with their donation amount and other vital information.", bullet2: "You can check the impact of your campaigns with Interactive analytical graphs and impacts through progress bars in Dashboard.", image: chechkoutGiving },
		{ id: 4, title: "Top Rated Charities", subtitle: "Altrunic is a platform of hand-picked top-rated charities where we:", bullet1: "Thoroughly inspect all nonprofits before they’re included in our database", bullet2: "Make sure your donation amount is properly delivered to the needy.", image: charityInfo }
	];

	const [selectedFeature, setSelectedFeature] = useState(0);

	function handlePreviousFeature() {
		if (selectedFeature === 0) {
			setSelectedFeature(features.length - 1);
		}
		else {
			setSelectedFeature(selectedFeature - 1);
		}
	}
	function handleNextFeature() {
		if (selectedFeature === features.length - 1) {
			setSelectedFeature(0);
		}
		else {
			setSelectedFeature(selectedFeature + 1);
		}
	}

	return (
		<Fragment>
			<div className="w-100 d-flex flex-column justify-content-center align-items-center">
				<div className="w-100 d-flex justify-content-center" style={{ marginTop: "114px" }}>
					<span className="ls-h4" style={{ textAlign: "center" }}>Features That Make a Difference</span>
				</div>
				<div className="section4">
					{/* <img src={left_arrow} onClick={handlePreviousFeature} style={{ cursor: "pointer", transition: "left 1s" }} /> */}
					<LeftArrow onClick={handlePreviousFeature} className="on-laptop pointer" />

					<div className="section4-left">
						<div className="d-flex flex-column text-box">
							<span className="ls-h4 nero-color">{features[selectedFeature].title}</span>
							<span className="ls-b0-5 hoki-color" style={{ paddingTop: "15px", fontWeight: "normal" }}>{features[selectedFeature].subtitle}</span>
							<ul style={{ paddingTop: "20px" }}>
								<li className="ls-b0-5 hoki-color">{features[selectedFeature].bullet1}</li>
								<li className="ls-b0-5 hoki-color" style={{ paddingTop: "20px" }}>{features[selectedFeature].bullet2}</li>
								{
									features[selectedFeature]?.bullet3 &&
									<li className="ls-b0-5 hoki-color" style={{ paddingTop: "20px" }}>{features[selectedFeature].bullet3}</li>
								}
							</ul>
						</div>

					</div>
					<div className="w-100 justify-content-between mobile-arrow on-mobile" style={{ padding: "0 10%" }}>
						<LeftArrow onClick={handlePreviousFeature} />
						<RightArrow onClick={handleNextFeature} />
					</div>
					<div className="section4-right">
						<img style={{ width: "90%", height: "90%" }} src={features[selectedFeature].image} />
						{/* <div className="d-flex align-self-center justify-content-between" style = {{paddingTop:"6.2vh",width:"14vw"}}>
					<img src={page_dot}/>
					<img src={page_dot} />
					<img src={page_dot} />
					<img src={page_dot} />
				</div> */}
					</div>
					{/* <img src={right_arrow} onClick={handleNextFeature} style={{ cursor: "pointer", animationDirection: "normal" }} /> */}

					<RightArrow onClick={handleNextFeature} className="on-laptop pointer" />
				</div>
			</div>

		</Fragment >
	);
}