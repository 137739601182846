/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import logo from "../../assets/icons/logo_sm.svg";
import Button from "../../components/sub-component/Button";
import image from "../../assets/images/man and cat.svg";
import { Link } from "react-router-dom";
import altrunc_logo from "../../assets/icons/logo.png";
// import Input from "../../components/sub-component/Input";
import AlertBox from "../../components/common/AlertBox";
import baseURL from "../../utilities/BaseURL";
// import { isEmailValid } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";
import AuthMessageCard from "../../components/auth/AuthMessageCard";

export default function UserInactive() {

	const auth = useAuth();

	// const [email, setEmail] = useState();
	const [alert, setAlert] = useState();
	const [processing, setProcessing] = useState(false);
	const [disabled, setDisabled] = useState(false);

	async function handleSubmit(e) {

		e.preventDefault();

		if (!auth.userProfile) {
			return;
		}

		setProcessing(true);

		const response = await fetch(`${baseURL}/v1/auth/activate/request`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
			},
			body: JSON.stringify({ email: auth.userProfile.email })
		});


		setProcessing(false);

		const json = await response.json();

		if (!response.ok) {
			setAlert(
				<AlertBox color="danger" title="Invalid Email" description={json.message} />
			);
			return;
		}

		setDisabled(true);

		setAlert(
			<AlertBox color="success" title="Success" description={json.message} />
		);


	}

	return (
		<div className="d-flex flex-lg-row flex-column justify-content-between">

			<div className="d-sm-flex message-card-mobile" style={{ width: "100%", height: "auto", position: "relative", }}>
				<AuthMessageCard paragraph={
					["Giving is not just about making a ",
						<span style={{ color: "#0054DC" }} key="0">donation</span>,
						", its about making an ",
						<span style={{ color: "#0054DC" }} key="1">impact</span>,
						"."]}
				/>
			</div>

			<div className="auth-form d-flex flex-column justify-content-center" >
				<span className="h1 navy-blue-color title-text" style={{ fontSize: "52px", fontWeight: "800" }}>Activation Pending</span>
				<span className="b2 description-text" style={{ marginTop: "60px" }}>Please check your email: <span style={{ color: "#0054DC" }}>{auth.userProfile && auth.userProfile.email}</span> and click the link in it to activate your account.</span><b />

				<div style={{ marginTop: "50px", width: "100%", maxWidth: "350px" }}>
					<form onSubmit={handleSubmit}>
						<Button title="Resend" style={{ width: "190px", height: "40px" }} busy={processing} type="submit" disabled={disabled} />

						<div style={{ position: "relative" }}>
							<div style={{ position: "absolute", width: "100%" }}>
								{alert}
							</div>
						</div>
					</form>

				</div>
			</div>
		</div>

	);
}