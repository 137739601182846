import React, { Fragment } from "react";
import ReactRoundedImage from "react-rounded-image";
import { ReactComponent as Commas } from "../../assets/icons/testimony_card_comma.svg";
import { ReactComponent as Add } from "../../assets/icons/basic/add-round-icon.svg";

export default function TestimonialCard(props) {

	// const [color, setColor] = useState(null);
	const getPureImageURL = (image) => {
		// &#x2F;
		const img = image.replaceAll("&#x2F;", "/");
		return img;
	};
	if(parseInt(props.templateId)===1){
		return (
			<Fragment>
				{
					props.defaultTestimonialCard
						?
						<div
							className="d-flex justify-content-center align-items-center"
							style={{ display: "flex", width: "390px", height: "221px", marginTop: "30px", backgroundColor: "white", borderRadius: "5px", position: "relative" }}>
	
							<div className="d-flex flex-column align-items-center">
								<Add fill={`${props.color}`} width="86px" height="86px" />
								<span className="cs-label" style={{ color: "#5E7488", marginTop: "23px" }}>ADD TESTIMONIAL</span>
							</div>
							<div style={{ position: "absolute", top: "-30px", left: "50px" }}>
								<Commas fill={`${props.color}`} />
							</div>
						</div>
						:
						<div style={{ display: "flex", width: "390px", height: "221px", marginTop: "30px", backgroundColor: "white", borderRadius: "5px", position: "relative" }}>
	
							<div style={{ padding: "44px 23px 0px 24px" }}>
								<ReactRoundedImage image={getPureImageURL(props.image)} roundedSize="0" imageWidth="75" imageHeight="75" />
							</div>
							<div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
								<div style={{ margin: "35px 24px 10px 0px", height: "83px", width: "93%", overflow: "auto" }} >
									<p className="cs-p2-t2 pr-1" style={{ color: "#364254", whiteSpace: "pre-wrap" }}>{props.description || "description"}</p>
								</div>
								<div style={{}}>
									<span style={{ color: `${props.color}` }} className="heading1">{props.name || "Name"}</span>
								</div>
								<div >
									<span className="cs-label" style={{ color: "#5E7488" }}>{props.profession || "Job Title"}</span>
								</div>
							</div>
							<div style={{ position: "absolute", top: "-30px", left: "50px" }}>
								<Commas fill={`${props.color}`} />
							</div>
						</div>
	
				}
			</Fragment >
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<Fragment>
				{
					props.defaultTestimonialCard
						?
						<div
							className="d-flex justify-content-center align-items-center"
							style={{ display: "flex", width: "390px", height: "221px", marginTop: "30px", backgroundColor: props.color, borderRadius: "5px", position: "relative" }}>
	
							<div className="d-flex flex-column align-items-center">
								<Add fill={`${props.color}`} width="86px" height="86px" />
								<span className="cs-label" style={{ color: "#5E7488", marginTop: "23px" }}>ADD TESTIMONIAL</span>
							</div>
							<div style={{ position: "absolute", top: "-30px", left: "50px" }}>
								<Commas fill={`${props.color}`} />
							</div>
						</div>
						:
						<div style={{ display: "flex", width: "390px", height: "221px", marginTop: "30px", backgroundColor:	props.color, borderRadius: "5px", position: "relative" }}>
	
							{/* <div style={{ padding: "0px 23px 0px 24px" }}>
								
							</div> */}
							<div style={{ display: "flex", flexDirection: "column", textAlign: "center",padding: "0px 23px 0px 24px" }} className="">
								<div style={{ margin: "40px 0px 10px 0px", height: "83px", width: "100%", overflowY: "auto",overflowX:"hidden" }} className="hideScroll">
									<p className="cs-p2-t2 white-color" style={{ whiteSpace: "pre-wrap" }}>{props.description || "description"}</p>
								</div>
								<div style={{}} className="text-center">
									<span  className="heading1 white-color">{props.name || "Name"}</span>
								</div>
								<div className="text-center white-color">
									<span className="cs-label white-color" style={{  }}>{props.profession || "Job Title"}</span>
								</div>
							</div>
							<div style={{ position: "absolute", top: "-40px", left: "calc(50% - 34.5px)" }} className="">
								<div style={{width:"75px",height:"75px",borderRadius:"24px" }}>
									<img src={getPureImageURL(props.image)} style={{width:"100%",height:"100%"}}/>
								</div>
								{/* <img src={getPureImageURL(props.image)} style={{width:"75px",height:"75px",borderRadius:"6px"}}/> */}
							</div>
						</div>
	
				}
			</Fragment >
		);
	}
	
}