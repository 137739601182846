import React, { useState, Fragment, useRef, useEffect } from "react";
import NotificationBell from "../../sub-component/NotificationBell";
// import SearchInput from "../../sub-component/SearchInput";
// import { ReactComponent as Line } from "../../../assets/icons/Genral/Line.svg";
import NotificationBox from "../../sub-component/NotificationBox";

export default function MidLayerHeader(props) {

	const [showResults, setShowResults] = useState(false);
	const notificationRef = useRef();

	const [hasUnreadNotification, setHasUnreadNotification] = useState(false);

	const onClick = () => {
		if (!showResults) {
			setShowResults(true);
		}
	};

	useEffect(() => {
		if (showResults) {
			notificationRef.current.focus();
		}
	}, [showResults]);

	return (
		<Fragment>
			<div className="mid-layer-header on-laptop">
				<div className="d-flex align-items-center" style={{ flexGrow: "1" }}>
					<span className="s1 nero-color">{props.label}</span>
					{/* <Line style={{marginLeft: "20px", marginRight: "20px"}}/>
					<SearchInput style={{flexGrow: "0.4"}}/> */}
				</div>
				<div className="mr-4" style={{ fontSize: "0.85rem"}}>
					{new Date().toDateString()}
				</div>
				<div className="d-flex align-items-center justify-content-end" style={{ flexGrow: "0", position: "relative" }}>
					<NotificationBell isBellStatusOpen={showResults} className="" onClick={onClick} hasUnreadNotification={hasUnreadNotification} />

					<NotificationBox
						setHasUnreadNotification={setHasUnreadNotification}
						reference={notificationRef}
						tabIndex={1}
						style={{ display: `${showResults ? "" : "none"}` }}
						onBlur={() => { setShowResults(false); }}
					/>
				</div>
			</div>
			<div className="mid-layer-header  on-mobile">
				<div className="d-flex align-items-center" style={{ width: "85%" }}>
					<span className="s3">{props.label}</span>
					{/* <Line style={{marginLeft: "10px", marginRight: "10px"}}/>
					<SearchInput style={{flexGrow: "0.4"}}/> */}
				</div>
				<div className="d-flex align-items-center justify-content-end" style={{ width: "10%" }}>
					<NotificationBell className="" />
				</div>
			</div>
		</Fragment>
	);
}
