/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";

export default function Status(props) {

	return (
		<div>
			{props.isSuccess &&
				<div className="d-flex justify-content-center align-items-center status" style={{ backgroundColor: "#00D69A", ...props.style }}>
					<span className="b4" style={{ color: "white", textTransform: "capitalize" }}>{props.status || "Success"}</span>
				</div>
			}

			{props.isFail &&
				<div className="d-flex justify-content-center align-items-center status" style={{ backgroundColor: "#FC4848", ...props.style }}>
					<span className="b4" style={{ color: "white", textTransform: "capitalize" }}>{props.status || "Fail"}</span>
				</div>
			}
		</div>
	);
}