import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";

import { Packages } from "../../utilities/Packages";
import Button from "../sub-component/Button";
import PriceCardSubscriptionComponent from "./PriceCardSubscriptionComponent";
import { openURL } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";

/**
 * @param {Object} props Props object of component
 * @param {Object} props.selected selected package object of component
 * @param {Function} props.onClose Modal close function
 * @param {Function} props.onConfirm Modal package confirm
 */
export default function SubscriptionPackageModal(props) {
	const auth = useAuth();	
	const currentPlan = auth.subscription?.subscription_package;

	const [packages] = useState([
		{
			id: 1,
			package: Packages.starter,
			description: <div className="package-description d-flex flex-column h-100" style={{ justifyContent: "space-evenly" }}>
				<span className="b2" style={{ fontWeight: "700" }}>Everything in Basic</span>
				<span className="b2" style={{ fontWeight: "700" }}>+</span>
				<span className="b2">More than 20</span>
				<span className="b2">Employees</span>
			</div>
		},
		{
			id: 2,
			package: Packages.professional,
			description: <div className="package-description d-flex flex-column h-100" style={{ justifyContent: "space-evenly" }}>
				<span className="b2" style={{ fontWeight: "700" }}>Everything in Starter</span>
				<span className="b2" style={{ fontWeight: "700" }}>+</span>
				<span className="b2">Custom Website URL</span>
				<span className="b2">Remove Altrunic Branding</span>
			</div>
		},
		{
			id: 3,
			package: {
				id: 3,
				name: "Enterprise",
				price: "Custom"
			},
			description: < div className="package-description d-flex flex-column h-100" style={{ justifyContent: "space-evenly" }}>
				<span className="b2" style={{ fontWeight: "700" }}>Everything in Professional</span>
				<span className="b2" style={{ fontWeight: "700" }}>+</span>
				<span className="b2">Multiple Websites</span>
				<span className="b2">Dedicated Impact Consulting</span>
			</div >
		},
		{
			id: 4,
			package: Packages.basic,
			description: <div className="package-description d-flex flex-column h-100 justify-content-between">
				<span className="b2">Upto 20 Employees</span>
				<span className="b2">Branded Website</span>
				<span className="b2">Impact Objectives</span>
				<span className="b2">Unlimited Campaigns</span>
				<span className="b2">Unlimited Donors</span>
				<span className="b2">Checkout Giving</span>
				<span className="b2">Donor Leaderboards</span>
			</div>
		}
	]);


	const [selectedPackage, setSelectedPackage] = useState(packages[0]);

	useEffect(() => {
		if (props?.confirmedPackage.id === Packages?.starter.id) {
			setSelectedPackage(packages[0]);
		}
		else if (props?.confirmedPackage.id === Packages?.professional.id) {
			setSelectedPackage(packages[1]);
		}else if (props?.confirmedPackage.id === Packages?.basic.id) {
			setSelectedPackage(packages[3]);
		}
	}, [props?.confirmedPackage]);

	const [employeeCheck,setEmployeeCheck] = useState(false);

	useEffect( async () => {
		const response_user = await openURL("/v1/user/?managers=true", "GET", auth);

		const response_employee_user = await openURL("/v1/user/?employees=true", "GET", auth);

		if(response_employee_user.error){
			return;
		}

		if (response_user.error) {
			return;
		}

		if((response_user.result.data.length + response_employee_user.result.data.length) > 20){
			setEmployeeCheck(true);
		} else {
			setEmployeeCheck(false);
		}
	},[]);

	return (
		<div className="subscription-package-modal-wrap">

			<div className="subscription-package-modal">
				<div className="subscription-modal-closebtn" onClick={props.onClose} />
				<div className="d-flex w-100 mb-3">
					<h3 className="h2 text-center w-100 mt-4">Change Plan</h3>
				</div>

				<div className="subscription-package-modal-content">
					<Row className="m-0 d-flex justify-content-between subscription-package-modal-content-card-container">
						{
							<Col key={packages[0].id-1} className="subscription-package-modal-content-card" >
								<PriceCardSubscriptionComponent
									titleless
									key={packages[3].id-1}
									onCardClick={() => !props?.allowCustomPackage ? (packages[3].id-1 !== 2 && setSelectedPackage(packages[packages[3].id-1])) : setSelectedPackage(packages[packages[3].id-1])}
									isSelected={packages[3].id === selectedPackage.id ? true : false}
									isCurrentConfirmCard={currentPlan?.id === packages[3].id}
									package={packages[3].package.name}
									price={packages[3].id-1 === 2 ? packages[3].package.price : <>`${packages[3].package.monthly.price}</>}
									description={packages[3].description}
						
								/>
								<PriceCardSubscriptionComponent
									titlegreater
									key={packages[0].id-1}
									onCardClick={() => !props?.allowCustomPackage ? (packages[0].id-1 !== 2 && setSelectedPackage(packages[packages[0].id-1])) : setSelectedPackage(packages[packages[0].id-1])}
									isSelected={packages[0].id === selectedPackage.id ? true : false}
									isCurrentConfirmCard={currentPlan?.id === packages[0].id}
									package={packages[0].package.name}
									price={packages[0].id-1 === 2 ? packages[0].package.price : <>`${packages[0].package.monthly.price}</>}
									description={packages[0].description} 
								/>
								<PriceCardSubscriptionComponent
									key={packages[1].id-1}
									onCardClick={() => { !props?.allowCustomPackage ? (packages[1].id-1 !== 2 && setSelectedPackage(packages[packages[1].id-1])) : setSelectedPackage(packages[packages[1].id-1]); }}
									isSelected={packages[1].id === selectedPackage.id ? true : false}
									isCurrentConfirmCard={currentPlan?.id === packages[1].id}
									package={packages[1].package.name}
									price={packages[1].id-1 === 2 ? packages[1].package.price : <>`${packages[1].package.monthly.price}</>}
									description={packages[1].description}
								/>
								<PriceCardSubscriptionComponent
									key={packages[2].id-1}
									onCardClick={() => { !props?.allowCustomPackage ? (packages[2].id-1 !== 2 && setSelectedPackage(packages[packages[2].id-1])) : setSelectedPackage(packages[packages[2].id-1]); }}
									isSelected={packages[2].id === selectedPackage.id ? true : false}
									isCurrentConfirmCard={currentPlan?.id === packages[2].id}
									package={packages[2].package.name}
									price={packages[2].id-1 === 2 ? packages[2].package.price : <>`${packages[2].package.monthly.price}</>}
									description={packages[2].description}
								/>
							</Col>	
						}
					</Row>
					{
						<Row className="mt-5">
							<Col>
								<Button
									id="subscription-confirm-plan-button"
									title="Confirm Plan"
									style={{
										width: "max-content",
										height: "40px",
										margin: "auto",
										backgroundColor: `${(selectedPackage.id === currentPlan?.id) || (employeeCheck === true && selectedPackage.id === 4) ? "grey" : "#0054DC"}`,
										cursor: `${(selectedPackage.id === currentPlan?.id) || (employeeCheck === true && selectedPackage.id === 4) ? "not-allowed" : "pointer"}`
									}}
									onClick={
										selectedPackage.id === currentPlan?.id || (employeeCheck === true && selectedPackage.id === 4)
											? null
											: () => props.onConfirm(selectedPackage.package)
									}
								/>
							</Col>
						</Row>
					}

				</div>

			</div>

		</div>
	);

}