import { useState, useEffect } from "react";

const useDebounce = (value, milliSeconds = 250) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, milliSeconds);

		return () => clearTimeout(handler);
	}, [value]);

	return debouncedValue;
};

export { useDebounce };
