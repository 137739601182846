/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
// import WorldMap from "../../../../components/dashboard/mid-page/compaign/WorldMap";
import CampaignProgressCard from "../../../../components/dashboard/mid-page/dashboard-main/CampaignProgressCard";
import Table from "../../../../components/sub-component/Table/Tabel";
import CampaignCard from "../../../../components/dashboard/mid-page/CampaignCard";
import { useHistory, useParams } from "react-router-dom";

import { getUnauthorizedURL, openURL } from "../../../../utilities/Utilites";
import { useAuth } from "../../../../context/AuthContext";
import Button from "../../../../components/sub-component/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import Input from "../../../../components/sub-component/Input";

import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

const participantsTableHeader = ["Rank", "Name", "Employee", "Donated"];

Array.prototype.clear = function () {
	this.splice(0, this.length);
};

export default function CampaignDetails(props) {
	const auth = useAuth();
	// const location = useLocation();
	const history = useHistory();

	const params = useParams();

	const [participants, setParticipants] = useState([]);
	
	const [campaign, setCampaign] = useState();
	const [donations, setDonations] = useState();
	const [charity, setCharity] = useState();
	
	const [emails, setEmails] = useState([]);
	const [IsInviteTopCampaignModalOpen, setIsInviteTopCampaignModalOpen] = useState(false);
	const [alert, setAlert] = useState("");
	
	const [charitySite, setCharitySite] = useState();

	// location.state && location.state.particpentDetail.forEach((data) => {
	// 	userIds.push(data[0]);
	// });

	const getCharity = async () => {

		if (!campaign) {
			return;
		}

		const { result } = await openURL(`/v1/charities/${campaign.charity_id}`, "GET", auth);

		if (result) {
			setCharity(result.data);
		}

	};

	const getCampaign = async () => {

		const { result, error } = await getUnauthorizedURL(`/v1/campaign/${params.id}`, { id: auth.user_id, tenant_id: auth.tenant_id });

		if (error) {
			history.push("/dashboard/");
		} else if (result) {

			setCampaign(result.message);
			setDonations(result.message.donations);

			const donations = result.message.donations;

			const row = donations.sort((a, b) => b.amount - a.amount).map((donation, index) => {
				// if (donation.doner_type === "Company") {
				return [
					"",
					(index + 1).toString(),
					donation.doner_name || "N/A",
					// <PostTextImage key={0} text={donation.doner_name} image={null} />,
					donation.doner_type === "Employee" || donation.doner_type === "Company" ? "Yes" : "No",
					donation.amount
					// userDonations.reduce((total, donation) => total + Number(donation), 0),
				];
				// }

			});

			setParticipants(row);

		}

	};

	const getCharitySite = async () => {

		const { result } = await openURL("/v1/charity_site/", "GET", auth, null);

		if (result && result.data.length > 0) {

			setCharitySite(result.data[0].site_url);

		}
	};


	const getGraphData = (totalDonations) => {

		if (!donations) {
			return [];
		}

		return totalDonations.map(donation => {

			return { x: new Date(donation.date.split("T")[0]).toDateString(), y: Number(donation.amount) };

		});

	};

	const sumTotalDonations = (totalDonations) => {

		if (!totalDonations || totalDonations.length <= 0) {
			return 0;
		}
		return totalDonations.reduce((total, donation) => total + Number(donation.amount), 0);

	};

	useEffect(() => {

		props.getCampaignId(params.id);

	}, [params.id]);

	useEffect(() => {

		getCampaign();
		getCharitySite();

	}, []);

	useEffect(() => {

		getCharity();

	}, [campaign]);

	useEffect(() => {
		props.getSelectedItem(2, "Campaign Details", 6, false);
	}, []);

	const sendInvitesToCampaign = async () => {
		let CSEmailString = "";
		emails.forEach((email) => {
			return (
				CSEmailString = CSEmailString ? `${CSEmailString},${email}` : `${email}`
			);
		});




		const requestBody = {
			recipients: CSEmailString,
			content: {
				tenant_name: auth.tenantProfile.tenant_name,
				campaign_name: campaign.name,
				impact_goal: campaign.impact.factor,
				campaign_url: `https://${charitySite}/campaign/${campaign.id}`,
			}
		};

		const { result } = await openURL(`/v1/notifications/send_mail`, "POST", auth, requestBody);
		console.log(result, result.status, "auth");
		if (result.status === "success") {
			setEmails([]);
			setAlert("emails sent successfully!");

			setTimeout(() => {
				setIsInviteTopCampaignModalOpen(false);
				setAlert("");
			}, 2000);

		}

	};

	return (
		<div>
			<CampaignCard
				name={campaign ? campaign.name : ""}
				participants={participants.length}
				currentObjective={sumTotalDonations(donations)}
				totalObjective={campaign && campaign.target}
				settlementAmount={campaign && ((sumTotalDonations(donations) * campaign.matching_ratio) / 100).toFixed(1).replace(/[.,]0$/, "")}
			/>
			{/* <img style={{ width: "100%", height: "29vh", marginTop: "25px", borderRadius: "25px" }} src={location.state?.image_url || CampaignImage} /> */}

			{/* <div className="dashboard-card map-card" style={{ marginTop: "25px" }}>
				<span className="s3">World Map</span>
				<div className="d-flex justify-content-center">
					<div
						className="d-flex justify-content-center dashboard-card-inner-wrapper"
						style={{ marginTop: "15px", height: "240px" }}>
						<WorldMap />
					</div>
				</div>
			</div> */}

			<div className="dashboard-card" style={{ marginTop: "25px" }}>
				<span className="s3 nero-color" >Campaign Progress</span>
				<div className="d-flex flex-column align-items-center">

					<CampaignProgressCard
						cardColor={"#CE8DFF"}
						campaignName={campaign && campaign.name}
						charityName={charity && charity.name}
						style={{ marginTop: "20px", width: "100%" }}
						dataSeries={getGraphData(donations || [])}
						currentObjective={sumTotalDonations(donations || [])}
						totalObjective={campaign && campaign.target}
						participants={participants.length}
						enableStatusCards={false}

					// factor={location.state?.factor || ""}
					/>

				</div>
			</div>

			<div style={{ marginTop: "25px", display: "flex", flexDirection: "column" }}>
				<div className="d-flex align-items-center justify-content-between">
					<span style={{ paddingLeft: "50px" }} className="s3 nero-color" >Participants</span>
					 {(auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14) && ( 
						<Button
							className="b5"
							title={"Invite to Campaign"}
							id="invite-to-campaign-button"
							style={{ width: "max-content", height: "30px", borderRadius: "15px", backgroundColor: "#007AFF", padding: "10px 15px" }}
							onClick={() => { setIsInviteTopCampaignModalOpen(true); }}
						/>
					)}	
				</div>

				<div className="d-flex flex-column align-items-center">
					<Table
						isEditable={false}
						headers={participantsTableHeader}
						rows={participants}
						style={{ marginTop: "15px" }}
						activeSelectInput={null}
					/>
				</div>
			</div>

			<Modal isOpen={IsInviteTopCampaignModalOpen} toggle={() => { setIsInviteTopCampaignModalOpen(!IsInviteTopCampaignModalOpen); }} >
				<ModalHeader toggle={() => { setIsInviteTopCampaignModalOpen(!IsInviteTopCampaignModalOpen); }}>
					<div className="w-100 d-flex justify-content-center">
						<span className="h2" style={{ fontWeight: "800" }}>Invite to Campaign</span>
					</div>
				</ModalHeader>
				<ModalBody>
					<div style={{ textAlign: "center" }}>
						<span className="b2 hoki-color" >To invite people to donate to this campaign, enter their emails below. If you have a mass email list set up, enter the corressponding email.</span>
						<div className="d-flex flex-column" id="invite-to-campaign-email-input" style={{ textAlign: "start" }}>
							<span className="b3 mt-4 mb-2">Email</span>
							<ReactMultiEmail
								placeholder="Email"
								emails={emails}
								
								onChange={(_emails) => {
									setEmails(_emails);
								}}
								validateEmail={email => {
									return isEmail(email); // return boolean
								}}
								getLabel={(email, index, removeEmail) => {
									return (
										<div data-tag key={index}>
											{email}
											<span data-tag-handle onClick={() => removeEmail(index)}>
												×
											</span>
										</div>
									);
								}}
							/>
						</div>
						{
							alert &&
							<div className="d-flex justify-content-start pt-2">
								<span style={{ color: "green" }}>{alert}</span>
							</div>
						}

					</div>
				</ModalBody>
				<ModalFooter className="d-flex justify-content-center" style={{ border: "none" }}>
					<Button
						title="Send Invitation"
						className="b2"
						id="invite-to-campaign-send-invitation-button"
						onClick={() => { sendInvitesToCampaign(); }}
						style={{ width: "max-content", minHeight: "30px", borderRadius: "28px", backgroundColor: "#0054DC", padding: "10px 100px" }}
					/>
				</ModalFooter>
			</Modal>
		</div>
	);
}
