/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
// import ImagePicker from "../sub-component/ImagePicker";
import Input from "../sub-component/Input";
// import Select from "../sub-component/Select";
import SwitchButton from "../sub-component/SwitchButton";
import Button from "../sub-component/Button";
import SearchAndSelectCharity from "../search-and-select-charity/SearchAndSelectCharity";
import { openURL, getUnauthorizedURL } from "../../utilities/Utilites";
import { useAuth } from "./../../context/AuthContext";
import AlertBox from "../common/AlertBox";
import { useHistory, useParams } from "react-router";
import ReactStars from "react-stars";
import { ReactComponent as CrossIcon } from "../../assets/icons/basic/circle-x.svg";
import HelpIconComponent from "../common/HelpIconComponent";
import { getOriginFromLink } from "../../utilities/Utilites";
import { Color,CampaignCreation } from "../../utilities/AppConstant";

export default function CreateCampaignForm(props) {
	const auth = useAuth();
	const history = useHistory();
	const params = useParams();
	const formWidth = useRef();
	const articleRef = useRef();
	const [isBusy, setIsBusy] = useState(false);
	const [lowWidth, setLowWidth] = useState(false);
	const [alert, setAlert] = useState();
	const [id, setId] = useState();
	const [campaignName, setCampaignName] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [target, setTarget] = useState("0");
	const [minDunation, setMinDunation] = useState();
	const [matchinRatio, setMatchigRatio] = useState(25);

	const [isMatchContribute, setIsMatchContribute] = useState(false);
	const [charities, setCharities] = useState([]);
	const [selectedCharity, setSelectedCharity] = useState();
	const [isMatchingRatioEnable, setMatchingRatioEnable] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [btn25, setbBtn25] = useState("activeBtn");
	const [btn50, setBtn50] = useState("inActiveBtn");
	const [btn100, setBtn100] = useState("inActiveBtn");
	const [isCampaignActive, setIsCampaignActive] = useState(false);

	const [isCharitiesLoading, setIsCharitiesLoading] = useState(true);

	const [charitiesLoader, setCharitiesLoader] = useState({
		isCharityLoading: true
	});

	const stateMaping = {
		campaignName: setCampaignName,
		startDate: setStartDate,
		endDate: setEndDate,
		target: setTarget,
		minDunation: setMinDunation,
		matchinRatio: setMatchigRatio
	};

	const [isToday, setIsToday] = useState(true);

	useEffect(() => {
		if (startDate) {
			const todayDate = new Date();
			const today = startDate.toDateString() === todayDate.toDateString();
			setIsToday(today);
			setIsCampaignActive(today);
		} else {
			// Handle the case when startDate is undefined
			setIsToday(false);
			setIsCampaignActive(false);
		}
	}, [startDate]);

	const handleChange = (event) => {

		if (Number(event.target.value) > Number(event.target.max)) {
			return;
		}

		stateMaping[event.target.name](event.target.value);
	};

	const getSelectedCharites = (selectedCharites) => {
		console.log("abcd", selectedCharites);
		if (selectedCharites !== undefined) {
			setSelectedCharity(selectedCharites);
		}
	};


	const matchContributeOnChange = (isTanentContribute) => {
		setIsMatchContribute(isTanentContribute.value);
		setMatchingRatioEnable(!isMatchingRatioEnable);
	};

	const getCharities = async () => {
		const { result } = await getUnauthorizedURL(`/v1/charities/`, { id: auth.userProfile.id, tenant_id: null });
		if (result) {
			const filteredResult = result.data.filter((item) => {
				return item.charity_impacts[0].impact != null;
			});
			setCharities(filteredResult);
		}
		setCharitiesLoader({ isCharityLoading: false });
	};

	const saveCampaign = async () => {

		// console.log(startDate.toISOString().split("T")[0], endDate.toISOString().split("T")[0], "DATES");


		if (!campaignName) {
			setAlert(
				<AlertBox color={Color.ERROR} title={CampaignCreation.CAMPAIGN_NAME} description={CampaignCreation.CAMPAIGN_NAME_DESCRIPTION} />
			);
			return;
		}

		if (!selectedCharity) {
			setAlert(
				<AlertBox color={Color.ERROR} title={CampaignCreation.CHARITY_TITLE} description={CampaignCreation.CHARITY_DESCRIPTION} />
			);
			return;
		}

		if (!target || target < 100 || target > 10000000) {
			setAlert(
				<AlertBox color={Color.ERROR} title={CampaignCreation.TARGET_TITLE} description={CampaignCreation.TARGET_DESCRIPTION} />
			);
			return;
		}

		if (!minDunation || minDunation < 1 || parseInt(minDunation) > parseInt(target)) {
			if (parseInt(minDunation) > parseInt(target)) {
				setAlert(
					<AlertBox color={Color.ERROR} title={CampaignCreation.MIN_DURATION_TITLE_ERROR} description={CampaignCreation.MIN_DURATION_DESCRIPTION_ERROR} />
				);
			}
			else {
				setAlert(
					<AlertBox color={Color.ERROR} title={CampaignCreation.MIN_DURATION_TITLE_REQUIRED} description={CampaignCreation.MIN_DURATION_DESCRIPTION_REQUIRED} />
				);
			}


			return;
		}

		if(isMatchContribute && (matchinRatio < 1 || matchinRatio > 100)) {
			setAlert(
				<AlertBox color={Color.ERROR} title={CampaignCreation.MATCH_CONTRIBUTE_TITLE} description={CampaignCreation.MATCH_CONTRIBUTE_DESCRIPTION} />
			);
			return;
		}
		if (!startDate) {
			setAlert(
				<AlertBox color={Color.ERROR} title={CampaignCreation.START_DATE_TITLE} description={CampaignCreation.START_DATE_DESCRIPTION} />
			);
			return;
		}

		if (!endDate) {
			setAlert(
				<AlertBox color={Color.ERROR} title={CampaignCreation.END_DATE_TITLE} description={CampaignCreation.END_DATE_DESCRIPTION} />
			);
			return;
		}

		if (endDate < startDate) {
			setAlert(
				<AlertBox color={Color.ERROR} title={CampaignCreation.INVALID_END_DATE_TITLE} description={CampaignCreation.INVALID_END_DATE_DESCRIPTION}/>
			);
			return;
		}


		setIsBusy(true);

		const body = {
			charity_id: selectedCharity.id,
			impact_id: selectedCharity.impact_id,
			is_active: isCampaignActive,
			matching_ratio: Number(matchinRatio),
			match_contribution: isMatchContribute,
			start_date: new Date(startDate).toLocaleDateString("en-US"),
			end_date: new Date(endDate).toLocaleDateString("en-US"),
			target: Number(target),
			min_donate: Number(minDunation),
			name: campaignName,
		};

		console.log("on setting", startDate, endDate);

		if ((!isUpdate)) { //!props.loadData) && 
			const { result, error } = await openURL("/v1/campaign/", "POST", auth, body);
			console.log("campaign resulttttt", result);
			if (error) {
				setAlert(
					<AlertBox color={Color.ERROR} title="Error" description={error.message} />
				);
			} else if (result) {
				if (props.onClick) {
					props.onClick();
				} else {
					history.push("/dashboard/campaigns");
				}
			}
		} else if (isUpdate) { //props.loadData) || 
			const { result, error } = await openURL("/v1/campaign/" + id, "PATCH", auth, body);
			if (error) {
				setAlert(
					<AlertBox color={Color.ERROR} title="Error" description={error.message} />
				);
			} else if (result) {
				if (props.onClick) {
					props.onClick();
				} else {
					history.push("/dashboard/campaigns");
				}
			}
		}



		setIsBusy(false);

	};

	const skipStep = () => {
		if (props.onClick) {
			props.onClick();
		} else {
			history.push("/dashboard/campaigns");
		}
	};

	const getLatestCampaign = async () => {
		const { result } = await getUnauthorizedURL(`/v1/campaign/?parameter=LATEST`, { id: auth.user_id, tenant_id: auth.tenant_id });

		if (result && result.message.length > 0) {
			console.log("Laest campaign:", result.message[0]);
			stateMaping["campaignName"](result.message[0].name);
			stateMaping["target"](result.message[0].target);
			stateMaping["startDate"](new Date(result.message[0].start_date));
			stateMaping["endDate"](new Date(result.message[0].end_date));
			stateMaping["minDunation"](result.message[0].min_donate);
			stateMaping["matchinRatio"](result.message[0].matching_ratio);
			const { Charity, Impact } = result.message[0];
			const charity_impacts = charities.filter(filteredCharity => { return filteredCharity.id === Charity.id; })[0].charity_impacts;
			setSelectedCharity({
				charity_impacts: charities.filter(filteredCharity => { return filteredCharity.id === Charity.id; })[0].charity_impacts,
				impact_id: Impact.id,
				selectedImpact: charity_impacts.filter(filteredImpact => { return filteredImpact.impact_id === Impact.id; })[0],
				website: Charity.charity_url,
				latitude: charities.filter(filteredCharity => { return filteredCharity.id === Charity.id; })[0].latitude,
				longitude: charities.filter(filteredCharity => { return filteredCharity.id === Charity.id; })[0].longitude,
				...Charity

			});
			setIsMatchContribute(result.message[0].match_contribution);
			setIsCampaignActive(result.message[0].is_active);
			setIsUpdate(true);
			setId(result.message[0].id);


		}
	};

	const changeCampaignStatus = (status) => {
		setIsCampaignActive(status.value);
	};

	const onClickBtn = async (event, id) => {
		if (id == 25) {
			setbBtn25("activeBtn");
			setBtn50("inActiveBtn");
			setBtn100("inActiveBtn");
			setMatchigRatio(25);
		} else if (id == 50) {
			setbBtn25("inActiveBtn");
			setBtn50("activeBtn");
			setBtn100("inActiveBtn");
			setMatchigRatio(50);
		}
		else if (id == 100) {
			setbBtn25("inActiveBtn");
			setBtn50("inActiveBtn");
			setBtn100("activeBtn");
			setMatchigRatio(100);
		}
		else {
			setbBtn25("inActiveBtn");
			setBtn50("inActiveBtn");
			setBtn100("inActiveBtn");
		}
	};


	const getCampaign = async () => {

		const { result, error } = await getUnauthorizedURL(`/v1/campaign/${params.id}`, { id: auth.user_id, tenant_id: auth.tenant_id });

		if (error) {
			history.push("/dashboard/");
		} else if (result) {

			setCampaignName(result.message.name);

			setStartDate(new Date(result.message.start_date));
			setEndDate(new Date(result.message.end_date));
			setTarget(result.message.target);
			setMinDunation(result.message.min_donate);
			setMatchigRatio(result.message.matching_ratio);
			setIsMatchContribute(result.message.match_contribution);

			setId(result.message.id);

			console.log(result.message, "get campaign");

			const { charity, impact } = result.message;
			const charity_impacts = charities.filter(filteredCharity => { return filteredCharity.id === charity.id; })[0].charity_impacts;
			setSelectedCharity(
				{
					charity_impacts: charities.filter(filteredCharity => { return filteredCharity.id === charity.id; })[0].charity_impacts,
					impact_id: impact.id,
					selectedImpact: charity_impacts.filter(filteredImpact => { return filteredImpact.impact_id === impact.id; })[0],
					website: charity.charity_url,
					latitude: charities.filter(filteredCharity => { return filteredCharity.id === charity.id; })[0].latitude,
					longitude: charities.filter(filteredCharity => { return filteredCharity.id === charity.id; })[0].longitude,
					...charity
				});



			onClickBtn(null, Number(result.message.matching_ratio));

			setIsUpdate(true);


		}

	};

	const deleteCampaign = async (id) => {

		const { result, error } = await openURL(`/v1/campaign/${id}`, "DELETE", auth);

		if (error) {
			setAlert(
				<AlertBox color={Color.ERROR} title="Error" description={error.message} />
			);
		} else if (result) {
			setAlert(
				<AlertBox color={Color.SUCCESS} title="Success" description="Campaign deleted successfully" />
			);

			history.push("/dashboard/");
		}

	};

	function handleKeyPress(event) {

		if (isNaN(event.key)) {
			event.preventDefault();
		}

	}

	// useEffect(async () => {
	// 	if (!selectedCharity) {
	// 		return;
	// 	}

	// 	const { result } = await openURL(`/v1/impact/${selectedCharity.impact_id}`, "GET", auth);

	// 	if (result) {
	// 		setImpact(result.message);
	// 	}


	// }, [selectedCharity]);

	useEffect(() => {
		getCharities();
	}, []);

	useEffect(() => {
		if (charities.length > 0) {
			if (params.id) {
				getCampaign();
			}

			if (props.loadData) {
				getLatestCampaign();
			}
		}
	}, [charities]);



	useEffect(() => {

		if (charities.length > 0) {
			setIsCharitiesLoading(false);
		}
		else {
			setIsCharitiesLoading(true);
		}
	}, [charities]);


	useEffect(() => {
		if (formWidth.current.offsetWidth <= 992) {
			setLowWidth(true);
		}
	}, []);

	return (
		<div className={`form-handling ${props.shouldSkip ? " " : "w-100"}`} ref={formWidth}>
			<div className="onbording-impact-objective-form d-flex flex-column setup-form w-100">
				{props.isLabel &&
					<div className="w-100 d-flex flex-column align-items-center justify-content-center">
						<span className="s1" style={{ textAlign: "center" }}>Campaign Setup</span>
						<p className="b3" style={{ marginTop: "20px", fontSize: "121dp" }}>
							Start your first Donation campaign by selecting your desired charity from a list of Hand-Picked top rated charities.

						</p></div>
				}

				{/* <div style={{ marginTop: "15px" }}>
					<ImagePicker label="Campaign Image" style={{ width: "100%", height: "218px" }} getFile={onImagePick} imageFor={campaign} />
				</div> */}
				<div style={{ borderBottom: "2px #CBE3FF solid" }} className="d-flex w-100">

					<div className="w-100">

						<Input
							style={{ paddingTop: "20px", paddingBottom: "24px" }}
							label="Campaign Name"
							name="campaignName"
							labelClass={`normal-bold`}
							boldLabel
							onChange={e => { handleChange(e); }}
							value={campaignName}
							placeholder="Enter campaign name"
							id="campaign-name-input"
						/>
					</div>
				</div>
				<div className="w-100" style={{ padding: "25px 0px", borderBottom: "2.5px solid #CBE3FF" }}>
					<div className="mb-2">
						<span className="normal-bold">Select Charity</span>

						{/* <button style={{border:"none" ,width: "25%",float:"right",padding:"2px",margin:"0px", fontSize:"15px",borderRadius:"25px",
							
							background:"#0054DC",
							color:"white"}} >Add Custom Charity</button> */}
					</div>
					{
						isCharitiesLoading ?

							<div className="w-100 d-flex justify-content-center align-items-center" style={{ height: "95px" }}>
								<span className="busy-indicator-blue " style={{ height: "20px", width: "20px" }}></span>
							</div>
							:
							<>
								{(charities.length > 0) &&
									<SearchAndSelectCharity
										loaderStatus={charitiesLoader.isCharityLoading}
										shouldApplyFilter={props.shouldSkip}
										charities={charities}
										selectedCharity={selectedCharity}
										getSelectedCharites={getSelectedCharites}
										ref={articleRef}
										isLowWidth={lowWidth}
									/>
								}
							</>
					}

					{/* {charities.length > 0 && <SearchAndSelectCharity
						charities={charities}

						statesSugestions={statesSugestions}
						// charityAreasSugestions={charityAreasSugestions}
						impactFactorsSugestions={impactFactorsSugestions}

						getSelectedCharites={getSelectedCharites}

						selectedCharity={selectedCharity}

						style={{ marginTop: "10px" }} />
					} */}
				</div>

				<div style={{ padding: "20px 0" }} className="d-flex flex-column " >
					{
						selectedCharity && <>
							<div className="selected-impact ">
								<span className="normal-bold">Selected Charity</span>
								<div
									className="pointer SSCBC-selected-charities-section-item  mt-2 d-flex" style={{ width: `${lowWidth ? "100%" : "47%"}` }}
								>
									<div className="d-flex align-items-start mr-2">
										<img src={`https://logo.clearbit.com/${getOriginFromLink(selectedCharity?.charity_url)}`} className="charity-logo-image" />

									</div>
									<div className=" d-flex flex-column w-100 ml-3">
										<div className="b4 d-flex w-100 justify-content-between" >
											<div className="b3 mb-1">
												{selectedCharity?.name}
											</div>
											<div className="mt-1 b4">
												<CrossIcon className="pointer" onClick={() => { setSelectedCharity(null); }} />
											</div>
										</div>
										<span className="b4 mb-1">{selectedCharity?.charity_impacts?.length > 0 && selectedCharity?.charity_impacts[0]?.impact?.sdg}</span>

										<ReactStars
											className="SSCSC-rating-card-stars"
											count={5}
											value={Number(selectedCharity.score) / 20}
											size={16}
											edit={false}
											half={true}
											color1={"#C4C4C4"}
											color2={"#0054DC"} />
										<span className="b4 hoki-color">Impact Factor</span>
										<span className="b4 SSCBC-charities-section-item pl-2">{selectedCharity?.charity_impacts[0]?.impact_statement}</span>
									</div>

								</div>
							</div>
						</>
					}
					<div className="w-100 d-flex justify-content-between flex-column flex-md-row">
						<Input
							style={{ marginTop: "15px", minWidth: "47%" }}
							labelClass={`normal-bold`}
							boldLabel
							label="Target ($)"
							type="number"
							name="target"
							onChange={e => { handleChange(e); }}
							value={target}
							max="10000000"
							min="0"
							placeholder="e.g 500"
							onKeyPress={handleKeyPress}
							id="campaign-target-input"
							description={[
								<ul key="1">
									<li key="2">Enter target amount between 100 - 10,000,000</li>
								</ul>
							]}
						/>


						<Input
							style={{ marginTop: "15px", minWidth: "47%" }}
							className="goal-input"
							labelClass={`normal-bold`}
							boldLabel
							name="minDunation"
							type="number"
							label="Minimum Donation (per donor)"
							onChange={e => { handleChange(e); }}
							value={minDunation}
							max="9999"
							min="0"
							id="campaign-minimum-donation-input"
							placeholder="e.g 20"
							onKeyPress={handleKeyPress}
							description={[
								<ul key="1">
									<li key="2">Enter minimum donation amount between 1 - 9999</li>
								</ul>
							]}
						/>
					</div>


					<span className="b2 cloud-burst-color" style={{ marginTop: "15px" }}>Impact:
						{
							(selectedCharity) && (
								<>
									<span className="b2 ml-2" style={{ color: "#0054DC" }}>{Math.round(target / selectedCharity.selectedImpact.factor_amount)} </span>
									<span className="b2 cloud-burst-color ml-1">{selectedCharity.selectedImpact.impact.factor}</span>
								</>
							)
						}
					</span>




					{/* <div style={{ width: "49%" }} className="d-flex flex-column">
						<span className="b3" style={{ textAlign: "left", marginTop: "15px" }}>Impact</span>
						<div style={{ height: "40px", marginTop: "10px" }} className="d-flex align-items-center">
							<span className="b4 align-middle" style={{ textAlign: "left" }}>
								{
									(impact && target) && `${target / impact.amount} ${impact.factor}`
								}
							</span>
						</div>
					</div> */}

				</div>

				<div className="d-flex flex-lg-row flex-column w-100 justify-content-between" style={{ padding: "15px 0px 20px 0px", borderBottom: "2.5px solid #CBE3FF" }}>

					<div style={{ width: "49%", height: "75px" }} className="d-flex flex-column align-items-start">

						<span className="normal-bold">Match Contributions</span>
						<div style={{ marginTop: "10px" }}>
							<SwitchButton style={{ top: "0" }} onChange={matchContributeOnChange} value={isMatchContribute} id="match-contribution-switch-button" /*key={Math.random()}*/ />
						</div>

					</div>
					{
						isMatchContribute &&
						<div style={{ width: "49%" }} className="align-items-start">
							{/* <Select title="Matching Ratio %" options={matchingRatioOptions} onSelect={(option) => { setMatchigRatio(option); }} placeholder="Select" /> */}
							<span className="b3">Match Contributions %</span>
							<div className="d-flex flex-row justify-content-around" style={{ minWidth: "250px" }}>
								<button
									className={btn25}
									onClick={e => onClickBtn(e, 25)}
									style={{
										border: "none",
										width: "40px",
										height: "40px",
										fontSize: "10pt",
										padding: "4px",
										marginTop: "10px",
										borderRadius: "5px"
									}}>25%</button>
								<button className={btn50} onClick={e => onClickBtn(e, 50)} style={{
									border: "none",
									width: "40px",
									height: "40px",
									fontSize: "10pt",
									padding: "4px",
									marginTop: "10px",
									// marginLeft: "30px",
									borderRadius: "5px"
								}}>50%</button>
								<button className={btn100} onClick={e => onClickBtn(e, 100)} style={{
									border: "none",
									width: "40px",
									height: "40px",
									fontSize: "10pt",
									padding: "4px",
									marginTop: "10px",
									// marginLeft: "30px",
									borderRadius: "5px"
								}}>100%</button>

								<Input
									style={{ width: "100px" }}
									type="number"
									onChange={e => {
										if (e.target.value <= 100) {
											setMatchigRatio(+e.target.value);
										}
									}}
									value={matchinRatio}
									id="custon-match-contribution-input"
									placeholder="Custom"
									onClick={e => onClickBtn(e, 0)}
									onKeyPress={(e) => isNaN(e.key) && e.preventDefault()}
								/>
							</div>
						</div>
					}

				</div>

				<div style={{ padding: "20px 0" }} className="d-flex w-100">

					<div className="w-100">

						{/* <Input
							style={{ paddingTop: "20px", paddingBottom: "15px" }}
							label="Campaign Name"
							name="campaignName"
							onChange={e => { handleChange(e); }}
							value={campaignName}
							placeholder="Enter campaign name"
							id="campaign-name-input"
						/> */}


						<div className="w-100 d-flex flex-column justify-content-between">
							<div className="w-100 d-flex  justify-content-between flex-column flex-md-row">
								<div className="d-flex flex-column align-items-start campaign-date-picker" style={{ marginTop: "15px", }}>
									<span className="normal-bold" >Campaign Start Date</span>

									<div className="w-100 " style={{ marginTop: "10px" }}>
										<div className="w-100" style={{ position: "relative", width: "max-content" }}>
											<DatePicker
												id="picker1"
												selected={startDate}
												onChange={date => setStartDate(date)}
												placeholderText="Select a date"
												minDate={new Date()}
												className="date-picker w-100"
												wrapperClassName="date-picker-container"
												dateFormat="MM-dd-yyyy"
												autoComplete="off"
											/>
											{/* <CalenderIcon style={{ position: "absolute", right: "8px", top: "8px", PointerEvent: "none" }} /> */}
										</div>

									</div>
								</div>

								<div className="d-flex flex-column align-items-start campaign-date-picker" style={{ marginTop: "15px", }}>
									<span className="normal-bold" >Campaign End Date</span>

									<div className="w-100" style={{ marginTop: "10px" }}>
										<div className="w-100" style={{ position: "relative", width: "max-content" }}>
											<DatePicker
												id="picker2"
												disabled={!startDate}
												selected={endDate}
												onChange={date => setEndDate(date)}
												placeholderText="Select a date"
												minDate={startDate || new Date()}
												className="date-picker"
												wrapperClassName="date-picker-container"
												dateFormat="MM-dd-yyyy"
												autoComplete="off"
											/>
											{/* <CalenderIcon style={{ position: "absolute", right: "8px", top: "8px", PointerEvent: "none" }} /> */}
										</div>
									</div>
								</div>
							</div>
							<div style={{ marginTop: "30px" }}>
								<span className="normal-bold" >Activate Campaign</span>
								<div
									className="d-flex align-items-start"
									style={{ marginTop: "10px" }}>
									<SwitchButton value={isCampaignActive} onChange={changeCampaignStatus} isLocked={!isToday} id="activate-campaign-switch-button" />
									<HelpIconComponent
										style={{ marginLeft: "22px", marginTop: "5px" }}
										plainInfo={["This toggle will activate the campaign, and make it available to your audience for making donations."]}
									/>
								</div>
							</div>

						</div>
					</div>

				</div>

				{alert}
				<div style={{ marginTop: "10px" }} className="w-100 d-flex justify-content-center align-self-center">
					{
						props.shouldSkip &&
						<Button onClick={() => skipStep()} style={{ marginRight: "10px" }} isHollow title={"Skip"} id="new-user-create-campaign-skip-button" />
					}

					{
						props.shouldDelete &&
						<div>
							<Button onClick={() => deleteCampaign(params.id)} style={{ marginRight: "10px" }} isHollow title={"Delete"} />
						</div>

					}

					<div style={{ width: "115px", height: "42px" }} className="align-self-center">

						<Button
							style={{ width: "100%", height: "100%" }}
							onClick={saveCampaign}
							id="submit-campaign-form-button"
							title={params.id ? "Update" : props.submitActionTitle}
							leftArrow={props.shouldGoNext ? true : false}
							busy={isBusy}
						/>
					</div>

				</div>



			</div >
		</div >
	);

}
