import React from "react";
import cross from "../assets/icons/basic/cross.svg";
import tick from "./../assets/icons/basic/Tick.svg";


export default function SettlementDoneCard() {

	return (
		<div id="popup" className="sattlement-card">
			<img src={cross} className="cross-sign" alt="close" onClick={() => window.location.reload()}
				style={{height: "20px", width: "20px", border: "1px solid", padding: "3px", borderRadius: "2.5px"}}
			/>
			<span className="s3" style={{ borderBottom: "1px solid #CED5DB", width: "80%", padding: "0 0 10px 0", marginBottom: "10px" }}>Amount Settlement</span>

			<img src={tick} style={{ paddingTop: "10px", width: "18%" }} alt="tick" />
			<span className="b2 hoki-color" style={{ margin: "25px 0", textAlign: "center" }}>
				<span>Amount settled</span>
				<br />
			</span>

		</div>
	);
}