import React from "react";
import FeatureImage from "../assets/images/FeatureImage.png";
import CampaignFeature from "../assets/images/CampaignFeature.png";
import campaignShirt from "../assets/images/template_2_images/shirt.svg";
export default function FeatureComponent(props) {
	if(parseInt(props.templateId)===1){
		if (props.homePageFeature) {

			return (
				<div className="feature-container-template-1" id="feature-container-template-1">
					<div className="feature-box">
						<div className="feature-content">
							<div className="feature-title">
								<span className="cs-hero3" style={{ color: `${props.primaryColor || "#4267B2"}` }}>{props.featureTitle}</span>
							</div>
							<div className="feature-cs-description">
								<p className="cs-p2-t2" style={{ color: "#364254", whiteSpace: "pre-wrap" }}>{props.featureDescription}</p>
							</div>
						</div>
					</div>
					<div className="feature-image">
						<img
							alt=""
							src={props.featureImage || FeatureImage}
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
						/>
					</div>
				</div>
			);
		}
		else if (props.campaignFeature) {
	
			return (
				<div className="feature-container-template-1" style={{ textAlign: "left" }}>
					<div className="reward-box changeOrder1" style={{ backgroundColor: `${props.primaryColor || "#4267B2"}` }}>
						<div className="reward-content">
							<div className="reward-title">
								<span className="cs-hero3 text-colour">{props.rewardTitle}</span>
							</div>
							<div className="reward-subtitle">
								<span className="heading2-t2 text-colour reward-subtitle">{props.rewardSubtitle}</span>
							</div>
							<div className="reward-cs-description">
								<p className="cs-p2-t2 text-colour" style={{ whiteSpace: "pre-wrap" }}>{props.rewardDescription}</p>
							</div>
						</div>
					</div>
					<div className="feature-image changeOrder2">
						<img
							src={props.rewardImage || CampaignFeature}
							alt=""
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
						/>
					</div>
				</div>
			);
		}
	}
	else if(parseInt(props.templateId)===2){
		if (props.homePageFeature) {
			return (
				<div className="feature-container-template-2 text-left pb-0" id="feature-container-template-2">
					<div className="dotBox row d-md-flex d-none justify-content-around ">
						{[...Array(16)].map((i,idx)=>{
							return(
								<>
									<div className="d-flex justify-content-center singleDotBox" key={idx} >
										<div className="dot" style={{backgroundColor:props.primaryColor,borderRadius:"50%"}}></div>	
									</div>
									
								</>
								
							);
						})}
					</div>
					<div className="featureImage2 col-12 col-lg-6">
						<img
							alt=""
							src={props.featureImage || FeatureImage}
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
						/>
					</div>

					<div className="feature-box col-12 col-lg-6">
						<div className="feature-content2 ">
							<div className="feature-title2 mb-3">
								<span className="cs-hero3" style={{ color: `${props.primaryColor || "#4267B2"}` }}>{props.featureTitle}</span>
							</div>
							<div className="feature-cs-description">
								<p className="cs-p1-t2" style={{ color: "#364254", whiteSpace: "pre-wrap" }}>{props.featureDescription}</p>
							</div>
						</div>
					</div>
				</div>
			);
		}
		else if (props.campaignFeature) {
	
			return (
				<div className="feature-container-template-2 row" style={{ textAlign: "left" }}>
					<div className="reward-box col-lg-6 col-12" >
						<div className="reward-content-template-2">
							<div className="reward-title">
								<span className="cs-hero3" style={{color:props.primaryColor}}>{props.rewardTitle}</span>
							</div>
							<div className="reward-subtitle">
								<span className="heading2 reward-subtitle">{props.rewardSubtitle}</span>
							</div>
							<div className="reward-cs-description2" style={{height:"fit-content"}}>
								<p className="cs-p2-t2 " style={{ whiteSpace: "pre-wrap" }}>{props.rewardDescription}</p>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-12 feature-container-template-2 ">

						<div className="largeCircle" style={{background:props.primaryColor}}></div>
						<div className="feature-image-template-2" style={{background:props.primaryColor,borderRadius:"50%"}}>
							<img
								src={props.rewardImage || campaignShirt}
								alt=""
								style={{ width: "100%", height: "100%", objectFit: "cover" }}
							/>
						</div>
						<div className="smallCircle ml-auto ml-lg-0" style={{background:props.primaryColor}}></div>
					</div>
				</div>
			);
		}
	}
	else{
		return(
			<>
			</>
		);
	}
}