import React, { useEffect, useState } from "react";
import tosFile from "./privacypolicy.md";
import UserConcentPage from "../../../../components/landing-page/UserConcentPage";
import Navbar from "../Navbar";
import Footer from "../Footer";

export default function PrivacyPolicy() {

	const [fileText, setFileText] = useState("");
	useEffect(() => {
		fetch(tosFile).then((response) => response.text()).then((text) => {
			setFileText(text);
		});
	}, []);

	return (
		<>
			<Navbar />
			<UserConcentPage fileText={fileText} title={"Privacy Policy"} />
			<Footer />
		</>
	);
}
