import React from "react";
import tick from "../assets/icons/basic/Tick.svg";
import cross from "../assets/icons/basic/cross.svg";

export default function DonationDoneCard() {

	// setTimeout(() => {
	// 	window.location.reload();
	// }, 5000);

	return (
		<div className="donation-modal">
			<img src={cross} className="cross-sign" alt="close" onClick={() => window.location.reload()} />
			<span className="s2">Donation</span>
			<hr style={{ border: "1px solid #CED5DB", width: "90%" }} />
			<img src={tick} style={{ paddingTop: "10px", maxHeight: "120px" }} alt="tick"/>
			<span className="b2 hoki-color" style={{ paddingTop: "25px", textAlign: "center" }}>
				<span>Thank you for your donation.</span>
				<br />
				<span style={{fontSize: "14px"}}>Reload page for another donation</span>
			</span>
		</div>
	);
}