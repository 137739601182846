import React from "react";
import HeaderImage from "../assets/images/header.png";
// import Button from "./sub-components/Button";
// import CampaignHeader from "../assets/images/CampaignHeader.png";

export default function HeroHeader(props) {
	if(parseInt(props.templateId)===1){
		return (
			<div className="cs-home-header-template-1 position-relative" id="cs-home-header-template-1">
				<div style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0", zIndex: 1, backgroundColor: `${props.primaryColor || "#4267B2"}`, opacity: "0.25" }}></div>
				<img
					src={props.heroHeaderImage || HeaderImage}
					alt=""
					style={{ position: "absolute", width: "100%", height: "100%", bottom: "0", objectFit: "cover" }}
				/>
				<div className="header-content">
					<div className="header-content-text" style={{ zIndex: "1" }}>
						<span className="cs-hero1 text-colour">{props.heroHeaderTitle}</span>
					</div>
					<div className="header-content-sub-text mt-5 " style={{ zIndex: "1" }}>
						<p className="cs-p2 text-colour" style={{ whiteSpace: "pre-wrap" }}>{props.heroHeaderSubTitle}</p>
					</div>
					{
						// props.homeHeroHeader &&
						// <div className="btn-spacing" style={{ display: "flex", zIndex: "2" }}>
						// 	{/* <div>
						// 		<Button whiteButton title="Become a Volunteer" style={{ width: "220px" }} />
						// 	</div> */}
						// 	<Button whiteButton title="Donate Now" />
						// </div>
					}
	
				</div>
			</div>
		);
	}

	else if(parseInt(props.templateId)===2){
		if(props.homeHeroHeader){
			return(
				<div className="cs-home-header-template-2 w-100 row" id="cs-home-header-template-2">
					<div className="col-12 col-md-6 d-flex justify-content-center align-items-center headerTitle">
						<span className="heading " style={{ color: `${props.primaryColor || "#4267B2"}`}}>
							{props.heroHeaderTitle}
						</span>
					</div>
					<div className="smallCircle	" style={{ backgroundColor: `${props.primaryColor || "#4267B2"}`}}></div>
					
					<div className="col-12 col-md-6 d-flex p-0 headerRestContent">
						<div className="imageContainer col-12 col-md-10 ">
							{/* <div className="backBox" style={{ backgroundColor: `${props.primaryColor }`}}> */}
							{/* </div> */}
							<img
								src={props.heroHeaderImage || HeaderImage}
								alt=""
								style={{boxShadow:`24px 24px 0 ${props.primaryColor}`}}
								className="img img-fluid headerImage"
							/>
	
						</div>
						<div className="largeCircle col-2 " style={{ backgroundColor: `${props.primaryColor || "#4267B2"}`}}></div>
					</div>
				</div>
			);
		}
		else if(props.campaignHeroHeader){
			return(
				<div className="cs-home-header-template-2 w-100 row">
					
					
					
					<div className="col-md-6 col-12 imgCard d-flex p-0">
						<div className="smallCircle d-none d-md-flex" style={{ backgroundColor: `${props.primaryColor || "#4267B2"}`,left:"-4rem"}}></div>
						<div className="imageContainer col-12 col-md-10 mt-0 mt-md-5 pr-2">
							
							<img
								src={props.heroHeaderImage || HeaderImage}
								alt=""
								style={{boxShadow:`24px 24px 0 ${props.primaryColor}`}}
								className="img img-fluid headerImage mt-md-3"
							/>
	
						</div>
						<div className="largeCircle col-2 d-none d-md-inline ml-md-2 " style={{ backgroundColor: `${props.primaryColor || "#4267B2"}`}}></div>
					</div>
					<div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
						<span className="heading" style={{ color: `${props.primaryColor || "#4267B2"}`}}>
							{props.heroHeaderTitle}
						</span>
					</div>
				</div>
			);
		}
		
	}
	else{
		return(
			<>
				{/* returning template id is {parseInt(props.templateId)} for header. */}
			</>
		);
	}
	
}