import React, { useEffect } from "react";
import image from "../../assets/images/pic.png";

export default function BlogCard(props) {

	useEffect(() => {
		console.log(props.image);
	}, []);
	if (props.size === "md") {
		return (
			<div className="blog-md-container">
				<img className="blog-md-img" src={image} />
				<div className="blog-md-details">
					<span className="ls-b0-5" style={{ fontWeight: "bold" }}>{props.header}</span>
					<span className="ls-b0-5">{props.description}</span>
					<div className="d-flex justify-content-start">
						<span className="ls-b0-5">{props.tag}</span>
						<span className="ls-b0-5" style={{ marginLeft: "21px" }}>{props.date}</span>
					</div>
				</div>
			</div>
		);
	}
	else if (props.size === "sm") {
		return (
			<div className="blog-sm-container">
				<img className="blog-sm-img" src={image} />
				<div className="blog-sm-details">
					<span className="ls-b0-5" style={{ fontWeight: "bold" }}>{props.header}</span>
					<div className="d-flex justify-content-start">
						<span className="ls-b0-5">{props.tag}</span>
						<span className="ls-b0-5" style={{ marginLeft: "21px" }}>{props.date}</span>
					</div>
				</div>
			</div>
		);
	}
}
