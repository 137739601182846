import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Button from "../sub-component/Button";
import Input from "../sub-component/Input";
import { ReactComponent as CrossIcon } from "../../assets/icons/basic/circle-x.svg";
import { openURL } from "../../utilities/Utilites";
import { useAuth } from "../../context/AuthContext";
import AlertBox from "../common/AlertBox";

export default function SubscriptionPlanCard(props) {

	console.log("SubscriptionPlanCard-==-=-",props);

	const auth = useAuth();
	const [couponCode, setCouponCode] = useState({
		isOpen: props?.couponCode?.isOpen || false,
		code: props?.couponCode?.code || null,
		isApplying: props?.couponCode?.isApplying || false,
		isApplied: props?.couponCode?.isApplied || false,
		couponDetails: props?.couponCode?.couponDetails || null
	});
	const [alert, setAlert] = useState();

	const applyCouponCode = async () => {
		setCouponCode({ ...couponCode, isApplying: true });
		const { result, error } = await openURL(`/v1/stripe_customers/coupon_details/${couponCode.code}`, "GET", auth);
		if (result) {
			setCouponCode({ ...couponCode, couponDetails: result.data.coupon, isOpen: false, isApplied: true, isApplying: false });
			props?.onCouponApplied({ ...couponCode, couponDetails: result.data.coupon, isOpen: false, isApplied: true, isApplying: false });

		}
		else {
			if(couponCode.code){
				setAlert(
					<AlertBox color="danger" title="Error" description={error.message} />
				);
				setCouponCode({ ...couponCode, isApplying: false });
			}
			else{
				setAlert(
					<AlertBox color="danger" title="Error" description={"Please enter a valid Coupon"} />
				);
				setCouponCode({ ...couponCode, isApplying: false });
			}
			
			
		}
		
		
		return;
	};

	// const nextPaymentDate = useMemo(() => {

	// 	if (!auth.subscription) {
	// 		return new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString();

	// 	}

	// 	if (props.packageInterval === "monthly") {
	// 		return new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString();
	// 	} else {
	// 		return new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString();
	// 	}

	// }, [props.packageInterval]);

	return (
		<div className="d-flex flex-column">
			<div className="d-flex">
				<h3 className="s3">{props.packageName}</h3>
				<Button
					isHollow={true}
					style={{ height: "22px", marginLeft: "14px" }}
					title="Change Plan"
					id="change-plan-subscription-button"
					fontStyle="roboto-b3"
					onClick={props.onChangePlanClick}
				/>
				<h3 className="b3 ml-auto" id="selected-subscription-plan-price">
					{
						props.processing ? (
							<span className="loading-skeleton" style={{ height: "15px" }} />
						) : (
							props.packageInterval === "yearly" ? (
								`$${(props.packagePrice) / 12}/mo`
							) : (
								`$${props.packagePrice}/mo`
							)
						)
					}
				</h3>
			</div>
			<span className="b4 hoki-color">{props.packageInterval === "yearly" ? "Yearly" : "Monthly"}</span>
			<div className="subscription-card-list">
				<Container fluid={true} className="p-0" style={{ borderBottom: "1px solid #CED5DB" }}>
					<Row >
						<Col xs="6" className="p-0 b3 hoki-color mt-3">
							<ul style={{ padding: 0 }}>
								<li className="checked-list">
									{props.packageName === "Basic" ? "Upto 20 Employees" : props.packageName === "Starter" ? "20+ Employees" : "Unlimited Employees"}
								</li>
								<li className="checked-list">Branded Website</li>
								<li className="checked-list">Impact Objectives</li>
								<li className="checked-list">Unlimited Campaigns</li>
								{
									Number(props.packageId) === 2 &&
									<li className="checked-list">Custom Website URL</li>

								}
							</ul>
						</Col>
						<Col xs="6" className="p-0 b3 hoki-color mt-3">
							<ul style={{ padding: 0 }}>
								<li className="checked-list">Unlimited Donors</li>
								<li className="checked-list">Checkout Giving</li>
								<li className="checked-list">Donor Leaderboard</li>
								{
									Number(props.packageId) === 2 &&
									<li className="checked-list">Removed Altrunic Branding</li>

								}
							</ul>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="d-flex mt-2">
				<h3 className="b3">Total Payment</h3>
				{
					props.processing ? (
						<span className="loading-skeleton ml-auto" style={{ height: "15px" }} />
					) : (
						<h3 className="b3 ml-auto">{`$${props.totalAmount}`}</h3>

					)
				}
			</div>
			<div className="d-flex mt-2">
				<h3 className="roboto-s2 ">Payment Due Today</h3>
				{
					props.processing ? (
						<span className="loading-skeleton ml-auto" style={{ height: "15px" }} />
					) : (
						<h3 className="roboto-s2  ml-auto">{`$${props.amountDue}`}</h3>
					)
				}
			</div>
			<div className="d-flex mt-2">
				{/* <h3 className="b4 hoki-color">
					Next Payment: {`$0`} on {new Date(props.nextPaymentAt * 1000).toLocaleDateString()}
				</h3> */}
				<h3 className="b4  ml-auto pointer" style={{ color: "#0054DC", textDecoration: "underline" }} onClick={() => { if (!couponCode.isApplied) { setCouponCode({ ...couponCode, isOpen: true }); } }} id="apply-coupon-code-field-opener-button">Apply Coupon Code</h3>
			</div>
			<div className="w-100 d-flex justify-content-end">
				{
					couponCode.isOpen &&
					<form style={{ width: "220px", height: "35px", position: "relative" }} onSubmit={applyCouponCode}>
						<Input hollow inputStyle={{ height: "35px", paddingRight: "80px", paddingLeft: "5px" }} value={couponCode.code || ""} onChange={(e) => { setCouponCode({ ...couponCode, code: e.target.value }); setAlert(null); }} />
						<Button busy={couponCode.isApplying} title="Apply" id="apply-coupon-button" style={{ padding: "5px 15px", position: "absolute", top: "16px", right: "8px" }} className="roboto-b2" onClick={() => applyCouponCode()} />
					</form>
				}

				{
					couponCode.isApplied &&
					<div className="coupon-code-container">
						<span className="roboto-b2 white-color">{couponCode.code}</span>
						<CrossIcon className="pointer" style={{ fill: "#FFFFFF", position: "absolute", top: "2px", right: "5px" }} onClick={() => {
							setCouponCode({ ...couponCode, isApplied: false, code: null, couponDetails: null });
							props?.onCouponApplied({ ...couponCode, isApplied: false, code: null, couponDetails: null });
						}} />
					</div>
				}

			</div>
			<div className="mt-3">
				{alert}
			</div>
		</div>
	);

}