import React from "react";
import ReactMarkdown from "react-markdown";

/**
 * 
 * @param {Object} props 
 * @param {text} props.text
 * @param {title} props.title
 * @returns 
 */

export default function UserConcentPage(props) {

	return (
		<div className="user-concent-page-container">
			<div className="blue-bar-header">
				<span className="ls-h3 white-color">{props.title}</span>
			</div>
			<div className="user-concent-details">
				<ReactMarkdown>{props.fileText}</ReactMarkdown>
			</div>
		</div>
	);
}
