import React from "react";
import { Fragment, useState } from "react";

import { ReactComponent as settings } from "../../../../assets/icons/Features Page/settings White.svg";
import { ReactComponent as analysis } from "../../../../assets/icons/Features Page/analysis.svg";
import { ReactComponent as employee } from "../../../../assets/icons/Features Page/employee white.svg";
import { ReactComponent as blueprint } from "../../../../assets/icons/Features Page/blueprint 1.svg";
import { ReactComponent as puzzle } from "../../../../assets/icons/Features Page/puzzle white.svg";
import { ReactComponent as Left } from "../../../../assets/icons/arrows/left-arrow.svg";
import { ReactComponent as Right } from "../../../../assets/icons/arrows/right-arrow.svg";


export default function OtherFeatures() {

	const otherFeaturesData = [{
		title: "Customize, Improvise, Publish",
		image: settings,
		subtitle: "Customize your impact charity website with multiple interactive features to make it look cool to your audience.",
		boxTitle1: "Visual Builder",
		boxDescription1: "Drag & Drop your favorite elements to complete your awesome page design.",
		boxTitle2: "UI/UX Sections",
		boxDescription2: "Pre-designed interactive sections made with the user point of view.",
		boxTitle3: "Fonts",
		boxDescription3: "An ease to choose the fonts of your own choice to stand out.",
		boxTitle4: "Colors",
		boxDescription4: "Choose your impact website colors according to your brand guidelines.",
	},
	{
		title: "Analyze, Strategize, Act",
		image: analysis,
		subtitle: "Stay on the same page and make decisions faster by having all of the information in one place.",
		boxTitle1: "Dashboard",
		boxDescription1: "A home of each and every information you need to take action upon and analyze.",
		boxTitle2: "Graphical Data",
		boxDescription2: "An easy way to interpret your data by high level graphical representation.",
		boxTitle3: "Progress Reports",
		boxDescription3: "You can check the progress of your running campaigns under one window.",
		boxTitle4: "Notifications",
		boxDescription4: "System will notify you with notifications in case of any discrepancies or completion.",
	},
	{
		title: "Search, Choose, Give Back",
		image: employee,
		subtitle: "Multiple options to start, edit and configure donation campaigns while staying on a single window.",
		boxTitle1: "Campaigns",
		boxDescription1: "You can create, edit, analyze the progress of your donation campaigns with some clicks.",
		boxTitle2: "World Map",
		boxDescription2: "Check the impact of your campaign in the World Map with an option to zoom in and out.",
		boxTitle3: "Participants",
		boxDescription3: "View the profile of contributors who participated in different campaigns.",
		boxTitle4: "Campaign Settings",
		boxDescription4: "Settings feature allows you to configure the running campaigns and their impacts.",
	},
	{
		title: "Decide, Act, Impact",
		image: blueprint,
		subtitle: "Decide your Giving impact with multiple causes from charities and start making an impact on society.",
		boxTitle1: "Goals",
		boxDescription1: "You can make your impact Goals helping to boost your company’s reputation.",
		boxTitle2: "Impact Objectives",
		boxDescription2: "Select your domain’s objectives which you are going to donate.",
		boxTitle3: "Matching Donations",
		boxDescription3: "An advanced feature of matching donation in which you can share the percentage of donation with the participants.",
		boxTitle4: "Impact Factor",
		boxDescription4: "Impact factor is the exact number of which your donation campaign is going to help the needy.",
	},
	{
		title: "Configure, Invite, Hype",
		image: puzzle,
		subtitle: "Invite every employee to take part in Social Impact activities so that maximum impression could be achieved.",
		boxTitle1: "Company",
		boxDescription1: "Set up your company profile with all the relevant information to stand out in front of your potential customers and employees.",
		boxTitle2: "Employee",
		boxDescription2: "Invite your colleagues to your Social Impact platform so that they can take part in Giving.",
		boxTitle3: "Users",
		boxDescription3: "Invite the users in your system with options of their roles and access.",
		boxTitle4: "Billing",
		boxDescription4: "All transactional information can be seen in Billing along with your subscribed Plan.",
	}
	];

	function blueFeatures(index) {
		return (
			<div className="card-section d-flex w-100 navy-blue-bg align-items-center justify-content-center" style={{ height: "500px", padding: "0 10%" }}>
				<div className="d-flex" style={{ width: "100%", maxWidth: "1200px" }}>

					<div className="d-flex flex-column justify-content-center w-50 position-relative" style={{ marginRight: "50px" }}>
						{
							index?.image &&
							<index.image fill={`${currentColor === "blue" ? "white" : "#0054DC"}`} className="on-laptop" style={{ position: "absolute", left: "-50px", opacity: "0.1" }} />
						}

						<div className="other-function-title-section">
							<span className="ls-s0 white-color" style={{ paddingBottom: "10px" }}>{index.title}</span>
							<span className="ls-b1 white-color">{index.subtitle}</span>
						</div>
					</div>

					<div className="d-flex flex-column justify-content-center w-50">
						<div className="d-flex justify-content-around w-100" style={{ paddingBottom: "10px" }}>
							<div className="white-card">
								<span className="ls-s0 nero-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle1}</span>
								<span className="ls-b1 nero-color">{index.boxDescription1}</span>
							</div>
							<div className="white-card">
								<span className="ls-s0 nero-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle2}</span>
								<span className="ls-b1 nero-color">{index.boxDescription2}</span>
							</div>
						</div>
						<div className="d-flex justify-content-around w-100 " style={{ paddingTop: "10px" }}>
							<div className="white-card">
								<span className="ls-s0 nero-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle3}</span>
								<span className="ls-b1 nero-color">{index.boxDescription3}</span>
							</div>
							<div className="white-card">
								<span className="ls-s0 nero-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle4}</span>
								<span className="ls-b1 nero-color">{index.boxDescription4}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	function whiteFeatures(index) {
		return (
			<div className="card-section d-flex w-100 navy-blue-bg align-items-center justify-content-center" style={{ height: "500px", padding: "0 10%" }}>
				<div className="d-flex justify-content-between" style={{ width: "100%", maxWidth: "1200px" }}>
					<div className="d-flex flex-column justify-content-center w-50 ">
						<div className="d-flex justify-content-around w-100" style={{ paddingBottom: "10px" }}>
							<div className="white-card">
								<span className="ls-s0 nero-color" style={{ paddingBottom: "20px" }}>{index.boxTitle1}</span>
								<span className="ls-b1 nero-color">{index.boxDescription1}</span>
							</div>
							<div className="white-card">
								<span className="ls-s0 nero-color" style={{ paddingBottom: "20px" }}>{index.boxTitle2}</span>
								<span className="ls-b1 nero-color">{index.boxDescription2}</span>
							</div>
						</div>
						<div className="d-flex justify-content-around w-100 " style={{ paddingTop: "10px" }}>
							<div className="white-card">
								<span className="ls-s0 nero-color" style={{ paddingBottom: "20px" }}>{index.boxTitle3}</span>
								<span className="ls-b1 nero-color">{index.boxDescription3}</span>
							</div>
							<div className="white-card">
								<span className="ls-s0 nero-color" style={{ paddingBottom: "20px" }}>{index.boxTitle4}</span>
								<span className="ls-b1 nero-color">{index.boxDescription4}</span>
							</div>
						</div>
					</div>

					<div className="other-function-title-section d-flex flex-column justify-content-center w-50 position-relative" style={{ marginLeft: "50px" }}>
						{
							index?.image &&
							<index.image className="on-laptop" fill={"white"} style={{ position: "absolute", right: "-50px", opacity: "0.1" }} />
						}
						{/* <img className="on-laptop" src={index?.image} style={{ position: "absolute", right: "-50px" }} /> */}
						<span className="ls-s0 white-color" style={{ paddingBottom: "10px" }}>{index.title}</span>
						<span className="ls-s1 white-color">{index.subtitle}</span>
					</div>
				</div>
			</div>
		);
	}

	function blueFeaturesMobileSlider(index) {
		return (
			<div className="other-features-mobile navy-blue-bg">
				<div className="slider">
					<div className="white-card" style={{ marginLeft: "5%" }}>
						<span className="ls-s0 nero-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle1}</span>
						<span className="ls-b1 nero-color">{index.boxDescription1}</span>
					</div>
					<div className="white-card">
						<span className="ls-s0 nero-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle2}</span>
						<span className="ls-b1 nero-color">{index.boxDescription2}</span>

					</div>
					<div className="white-card">
						<span className="ls-s0 nero-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle3}</span>
						<span className="ls-b1 nero-color">{index.boxDescription3}</span>

					</div>
					<div className="white-card">
						<span className="ls-s0 nero-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle4}</span>
						<span className="ls-b1 nero-color">{index.boxDescription4}</span>

					</div>
				</div>

				<div className="d-flex flex-column justify-content-center" style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "30px" }}>
					<span className="ls-s0 white-color" style={{ paddingBottom: "10px" }}>{index.title}</span>
					<span className="ls-b1 white-color">{index.subtitle}</span>
				</div>
				<div className="w-100 d-flex align-items-center" style={{ justifyContent: "space-evenly", marginTop: "30px" }}>
					<Left className={`left-arrow arrow-in-blue`}
						onClick={() => {
							if (currentSlide > 0) {
								setCurrentSlide(currentSlide - 1);
							}
							else {
								setCurrentSlide((otherFeaturesData.length - 1));
							}
							if (currentColor === "blue") {
								setCurrentColor("white");
							}
							else {
								setCurrentColor("blue");
							}
						}} />
					<Right className={`right-arrow arrow-in-blue`}
						onClick={() => {
							if (currentSlide < (otherFeaturesData.length - 1)) {
								setCurrentSlide(currentSlide + 1);
							}
							else {
								setCurrentSlide(0);
							}
							if (currentColor === "blue") {
								setCurrentColor("white");
							}
							else {
								setCurrentColor("blue");
							}

						}}
					/>
				</div>
			</div>
		);
	}

	// function whiteFeaturesMobileSlider(index) {
	// 	return (
	// 		<div className="other-features-mobile white-bg">
	// 			<div className="slider">
	// 				<div className="blue-card" style={{ marginLeft: "5%" }}>
	// 					<span className="s0 white-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle1}</span>
	// 					<span className="b3 white-color">{index.boxDescription1}</span>
	// 				</div>
	// 				<div className="blue-card">
	// 					<span className="s0 white-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle2}</span>
	// 					<span className="b3 white-color">{index.boxDescription2}</span>
	// 				</div>
	// 				<div className="blue-card">
	// 					<span className="s0 white-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle3}</span>
	// 					<span className="b3 white-color">{index.boxDescription3}</span>
	// 				</div>
	// 				<div className="blue-card">
	// 					<span className="s0 white-color" style={{ width: "290px", paddingBottom: "20px" }}>{index.boxTitle4}</span>
	// 					<span className="b3 white-color">{index.boxDescription4}</span>
	// 				</div>
	// 			</div>

	// 			<div className="d-flex flex-column justify-content-center" style={{ paddingLeft: "5%", paddingRight: "5%" }}>
	// 				<span className="s2 nero-color" style={{ paddingBottom: "10px" }}>{index.title}</span>
	// 				<span className="b3 nero-color">{index.subtitle}</span>
	// 			</div>
	// 		</div>
	// 	);
	// }


	const [currentSlide, setCurrentSlide] = useState(0);
	const [currentColor, setCurrentColor] = useState("blue");

	return (
		<div>
			<div className="other-features">
				<Left className={`left-arrow arrow-in-blue`}
					onClick={() => {
						if (currentSlide > 0) {
							setCurrentSlide(currentSlide - 1);
						}
						else {
							setCurrentSlide((otherFeaturesData.length - 1));
						}
						if (currentColor === "blue") {
							setCurrentColor("white");
						}
						else {
							setCurrentColor("blue");
						}
					}} />
				{
					otherFeaturesData.map((data, index) => {
						return (
							<Fragment key={index}>
								{
									index === currentSlide &&
									<Fragment>
										{
											currentColor === "blue"
												?
												blueFeatures(data)
												:
												whiteFeatures(data)
										}
									</Fragment>
								}
							</Fragment>
						);
					})
				}
				<Right className={`right-arrow arrow-in-blue`}
					onClick={() => {
						if (currentSlide < (otherFeaturesData.length - 1)) {
							setCurrentSlide(currentSlide + 1);
						}
						else {
							setCurrentSlide(0);
						}
						if (currentColor === "blue") {
							setCurrentColor("white");
						}
						else {
							setCurrentColor("blue");
						}

					}}
				/>
			</div>

			<div className="other-features-mobile-display">
				{
					otherFeaturesData.map((data, index) => {
						return (
							<Fragment key={index}>

								{
									index === currentSlide &&
									blueFeaturesMobileSlider(data)
								}
							</Fragment>
						);
					})
				}
			</div>
		</div>
	);
}