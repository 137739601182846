import React, { useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCardForm from "./StripeCardForm";
import UpdateStripCardForm from "./UpdateStripCardForm";

export default function StripeCard(props) {

	const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_PKEY);

	console.log("props inside stripe form",props);

	const UpdateStripCardFormComp = (props) => {
		return (
			<UpdateStripCardForm coupon={props.coupon} onCardUpdate={props.onCardUpdate} />
		);
	};
	const StripeCardFormComp = (props) => {
		return (
			<StripeCardForm coupon={props.coupon} packageId={props.packageId} pricingId={props.pricingId} />
		);
	};

	const updateStripCardForm = useMemo(() => UpdateStripCardFormComp(props), [props]);
	const stripeCardForm = useMemo(() => StripeCardFormComp(props), [props]);


	return (

		<Elements stripe={stripePromise}>
			{
				props?.stripCardCURD ?
					updateStripCardForm
					:
					stripeCardForm
			}

		</Elements>

	);

}