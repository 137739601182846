import React, { useState } from "react";
import AlertBox from "../../components/common/AlertBox";
import Button from "../../components/sub-component/Button";
import { useAuth } from "../../context/AuthContext";
import baseURL from "../../utilities/BaseURL";

export default function LinkExpired() {

	const auth = useAuth();

	const [alert, setAlert] = useState();
	const [processing, setProcessing] = useState(false);
	const [disabled, setDisabled] = useState(false);

	async function handleSubmit() {

		if (!auth.userProfile) {
			return;
		}

		setProcessing(true);

		const response = await fetch(`${baseURL}/v1/auth/activate/request`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
			},
			body: JSON.stringify({ email: auth.userProfile.email })
		});


		setProcessing(false);

		const json = await response.json();

		if (!response.ok) {
			setAlert(
				<AlertBox color="danger" title="Invalid Email" description={json.message} />
			);
			return;
		}

		setDisabled(true);

		setAlert(
			<AlertBox color="success" title="Success" description={json.message} />
		);


	}

	return (
		<div className="d-flex flex-column justify-content-center">
			<h3 className="h2">Link Expired</h3>
			
			<p className="b2 hoki-color mt-4 mb-4">
				Your account activation link has expired. Please regenerate the link.
			</p>
			
			<Button title="Resend" onClick={handleSubmit} style={{ height: "40px", width: "100%" }} busy={processing} disabled={disabled} />

			<div style={{ position: "relative", width: "100%" }}>
				<div style={{ position: "absolute", width: "100%" }}>
					{alert}
				</div>
			</div>

		</div>
	);
}