import React, { useEffect, useState } from "react";
import FinishedCampaignCard from "../../../sub-component/dashboard/campaign-cards/FinishedCampaignCard";
import { ReactComponent as LeftIcon } from "../../../../assets/icons/arrows/left-arrow.svg";
import { ReactComponent as RightIcon } from "../../../../assets/icons/arrows/right-arrow.svg";


// import { useMediaQuery } from "react-responsive";

export default function FinishedCampaignsContainer(props) {


	const [arrowControl, setArrowControl] = useState({
		leftArrowStatus: false,
		rightArrowStatus: false
	});

	const loadMore = () => {
		var container = document.getElementById("dashboard_completed_campaign_container");
		container.scrollLeft += 290;
	};


	const loadPrevious = () => {
		var container = document.getElementById("dashboard_completed_campaign_container");
		container.scrollLeft -= 290;
	};

	const onContainerScroll = () => {
		var container = document.getElementById("dashboard_completed_campaign_container");
		if (container.scrollLeft === 0) {
			setArrowControl({ ...arrowControl, leftArrowStatus: false });
		}
		else if (container.scrollLeft === (container.scrollWidth - 815)) {
			setArrowControl({ ...arrowControl, rightArrowStatus: false });
		}
		else {
			if (props.completedCampaigns.length > 3) {
				setArrowControl({ leftArrowStatus: true, rightArrowStatus: true });
			}
		}
	};

	useEffect(() => {

		if (props.completedCampaigns.length > 3) {
			setArrowControl({ ...arrowControl, rightArrowStatus: true });
		}

	}, [props.completedCampaigns.length]);

	const calculateUser = (dunations) => {
		const userId = [];
		let amount = 0;
		const donationSeries = [];
		dunations.Donation.map((obj) => {
			const date = new Date(obj.date.split("T")[0]);
			var newA = donationSeries.filter(function (item) {
				return item.x === date.toDateString();
			});
			if (newA[0])
				newA[0].y += Number(obj.amount);
			else
				donationSeries.push({ x: date.toDateString(), y: Number(obj.amount) });//[date.getDate() - 1] += Number(obj.amount);
			amount = amount + Number(obj.amount);
			var userIndex = userId.indexOf(obj.user_id);
			if (userIndex === -1) {
				userId.push([obj.user_id, Number(obj.amount)]);
			} else {
				userId[userIndex][1] += Number(obj.amount);
			}

		});
		return { particpents: userId.length, amountDonate: amount, dataSeries: donationSeries, particpentDetail: userId };
	};

	return (
		<div className="w-100 d-flex flex-column position-relative justify-content-center align-items-start">

			{
				arrowControl.leftArrowStatus &&
				<LeftIcon
					id="scrollback"
					onClick={() => loadPrevious()}
					style={{ position: "absolute", marginRight: "820px", marginTop: "40px" }}
					className="pointer" />
			}

			<span className="s3 nero-color align-self-start" >Completed Campaigns</span>
			<div className="d-flex justify-content-center"
				style={{ width: "895px", maxWidth: "100%",minWidth:"350px" }}
			>
				<div className="d-flex w-100 align-items-start hideScrollBar align-items-stretch"
					id="dashboard_completed_campaign_container"
					style={{ overflow: "auto", scrollBehavior: "smooth", padding: "20px 10px" }}
					onScroll={onContainerScroll}
				>
					{
						props.completedCampaigns.length > 0
							?
							props.completedCampaigns.map((completedCampaign, index) => {
								const { particpents, amountDonate } = calculateUser(completedCampaign);
								return (
									<FinishedCampaignCard
										cardColor={(completedCampaign.is_amount_settled) ? "#CE8DFF" : "#7ED0FF"}
										// cardColor="#7ED0FF"
										// hasBorder={index === 0 ? false : true}
										style={{ marginLeft: `${index !== 0 ? "20px" : "0px"}`, marginRight: `${index === props.completedCampaigns.length - 1 ? "20px" : "0px"}` }}
										key={index}
										charityName={completedCampaign.Charity.name}
										campaignName={completedCampaign.name}
										startDate={new Date(completedCampaign.start_date.split("T")[0]).toDateString()}
										endDate={new Date(completedCampaign.end_date.split("T")[0]).toDateString()}
										donations={amountDonate}
										participants={particpents}
										settlementAmount={completedCampaign.match_contribution ? (completedCampaign.matching_ratio * amountDonate) / 100 : 0}
										campaignId={completedCampaign.id}
										isAmountSettled={completedCampaign.is_amount_settled}
									/>

								);
							})
							:
							<FinishedCampaignCard
								onClick={() => { }}
							/>
					}

				</div>
			</div>

			{
				arrowControl.rightArrowStatus &&
				<RightIcon
					id="scrolnext"
					onClick={() => loadMore()}
					style={{ position: "absolute", marginLeft: "min(800px,90%)", marginTop: "40px" }}
					className="pointer"
				/>
			}

		</div>
	);
}
