import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch, useHistory, Redirect } from "react-router-dom";
import CharitySiteSetup from "../../components/SetupWizad/CharitySiteSetup";
import CreateCampaignForm from "../../components/SetupWizad/CreateCampaignForm";
import EmployeeSetupForm from "../../components/SetupWizad/EmployeeSetupForm";
import EnterpriseSetup from "../../components/SetupWizad/EnterpriseSetup";
import Goals from "../../components/SetupWizad/Goals";
import SetupWizardNavbar from "../../components/SetupWizad/SetupWizardNavbar";
import Topbar from "../../components/SetupWizad/Topbar";
import { useAuth } from "../../context/AuthContext";
import DomainSetup from "../../components/SetupWizad/DomainSetup";

export default function SetupWizard() {

	const auth = useAuth();

	const routes = ["enterprise", "charitysite", "goals", "campaign", "employees"];

	const match = useRouteMatch();
	const history = useHistory();
	const [step, setStep] = useState(() => {

		const stepName = history.location.pathname.split("/");
		return routes.indexOf(stepName[stepName.length - 1]);
		// return 0;
	});

	const [stepCleared, setStepCleared] = useState(0);

	function handleStep(step) {

		if (step <= stepCleared) {
			history.push(`${match.path}/${routes[step]}`);
			setStep(step);
		}
	}

	function onClickNext(onStep) {

		if (step + 1 != routes.length) {
			const nextStep = (step + 1) % routes.length;

			// console.log(nextStep, stepCleared, "Stemp");
			if (onStep > stepCleared) {
				setStepCleared(onStep);
				localStorage.setItem("setupWizardStepNo", `${onStep}`);
			}

			if (nextStep <= onStep) {
				history.push(`${match.path}/${routes[nextStep]}`);
				setStep(nextStep);
			}
			// handleStep(nextStep);
		}
		else {
			window.location.replace("/dashboard/");
			// history.push(`/dashboard/`);
		}
	}

	useEffect(() => {
		if (localStorage.getItem("setupWizardStepNo")) {
			setStepCleared(parseInt(localStorage.getItem("setupWizardStepNo")));
		} else {
			localStorage.setItem("setupWizardStepNo", `${stepCleared}`);
		}

	}, []);

	useEffect(() => {
		console.log(auth, "userProfile");
	}, [auth]);

	// console.log(auth.userProfile.role_assignment.id);

	return (
		<div style={{ height: "100vh", display: "flex", flexFlow: "column" }}>

			<div style={{ height: "calc(80px + 162.07px)" }}>
				<Topbar />
				<SetupWizardNavbar step={step} CurrentClaeredStep={stepCleared} onSelect={handleStep} />
			</div>

			<div className="setup-wizard">
				<div className="setup-wizard-form" >

					{
						auth?.userProfile?.role_assignment?.id === 12 ? (
							<Switch Switch >
								<Route exact path={`${match.path}/`}>
									<Redirect to="/setup/enterprise" />
								</Route>
								<Route path={`${match.path}/enterprise`}>
									<EnterpriseSetup shouldCreate onClick={() => onClickNext(1)} isPageLabeled label="Please enter your Company's details. The provided information can also be changed later." />
								</Route>
								<Route path={`${match.path}/domain-setup`}>
									<DomainSetup />
								</Route>
								<Route path={`${match.path}/charitysite`}>
									<CharitySiteSetup isLabel isOnBoardingScreen shouldCreate={true} onClick={() => onClickNext(2)} />
								</Route>
								<Route path={`${match.path}/goals`}>
									<Goals shouldNext isLabel onClick={() => onClickNext(3)} isPageLabeled />
								</Route>
								<Route path={`${match.path}/campaign`}>
									<CreateCampaignForm isLabel={true} shouldGoNext onClick={() => onClickNext(4)} loadData={true} shouldSkip />
								</Route>
								<Route path={`${match.path}/employees`}>
									<EmployeeSetupForm onClick={() => onClickNext(5)} shouldNext label="Invite team" description />
								</Route>
							</Switch>

						) : (
							<Redirect to="/dashboard/" />
						)

					}

				</div>
			</div>
		</div >
	);
}
