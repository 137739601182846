import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { VisualBuilderContext } from "../../../context/VisualBuilderContext";

import EditorLeftComp from "./EditorLeftComp";
import EditorRightComp from "./EditorRightComp";
import EditorMidComp from "./EditorMidComp";
import Navbar from "./Navbar";
import data from "./data.json";
import { openURL } from "../../../utilities/Utilites";
import { useAuth } from "../../../context/AuthContext";
import { Redirect, useHistory } from "react-router-dom";

// var vb_data = data;
export default function VisualBuilder() {

	// const [isGlobalChange, setIsGlobalChange] = useState(false);
	const isDesktop = useMediaQuery({
		query: "(min-width: 992px)"
	});
	const auth = useAuth();
	const history=useHistory();
	const [editorDataValues, setEditorDataValues] = useState({
		charity_site_id: 0,
		data: data,
		selectedPageIndex: 0,
		selectedPage: data.home,
		selectedComponentIndex: 0,
		totalCampaigns: 0,
		isPublishing: true,
		dataHasChanged: false,
		hasDraftSaves: false,
	});

	useEffect(async () => {

		const { result, error } = await openURL("/v1/charity_site_data/", "GET", auth);

		console.log("result after get: ",result);

		if (error) {
			console.log(error);
		} else if (result) {
			setEditorDataValues({
				...editorDataValues,
				data: result.data.charitySites[0].charitySiteData,
				charity_site_id: result.data.charitySites[0]._id,
				selectedPage: result.data.charitySites[0].charitySiteData.home,
				totalCampaigns: result.data.charitySites[0].charitySiteData.campaigns.length,
				isPublishing: result.data.charitySites[0].isPublishing,
				hasDraftSaves: result.data.charitySites[0].hasDraftSaves,
			});
		}
	}, []);

	useEffect(()=>{
		if(!editorDataValues.data.templateId){
			let tempData=editorDataValues.data;
			tempData={...tempData,templateId:1};
			setEditorDataValues({...editorDataValues,data:tempData});
		}
	},[editorDataValues.data]);
	useEffect(() => {

		if(editorDataValues.selectedPage?.length > 0){
			const tempArray = editorDataValues.selectedPage;
			const siteData = editorDataValues.data;
			if (editorDataValues.selectedPageIndex === 0) {
				const impactsiteDataWithUpdatedHomePage = { ...siteData, home: tempArray };
				setEditorDataValues({ ...editorDataValues, data: impactsiteDataWithUpdatedHomePage });
			}
			else {
				const prevCampaigns = siteData.campaigns;
				prevCampaigns[editorDataValues.selectedPageIndex - 1] = { ...prevCampaigns[editorDataValues.selectedPageIndex - 1], campaign_components: tempArray };
				const impactsiteDataWithUpdatedCampaignsData = { ...siteData, campaigns: prevCampaigns };
				setEditorDataValues({ ...editorDataValues, data: impactsiteDataWithUpdatedCampaignsData});
			}
		}

	}, [editorDataValues.selectedPage , editorDataValues.selectedPageIndex]);


	useEffect(() => {

		console.log(editorDataValues, "Editor Values");

	}, [editorDataValues]);

	// const getIfGloabalChangeOccured = (hasChageOccur) => {
	// 	setIsGlobalChange(hasChageOccur);
	// };

	// const [selectedPage, setSelectedPage] = useState(0);

	if (auth.userProfile.role_assignment.id === 12 || auth.userProfile.role_assignment.id === 14) {

		return (
			<>
				{
					isDesktop && <>
						<VisualBuilderContext.Provider value={{ editorDataValues, setEditorDataValues }}>
							<div className="layout-container">
								<div className="left-side-section" >
									<EditorLeftComp />
								</div>
								<div className="w-100">
									<div style={{ height: "75px" }}>
										<Navbar isGlobalChange={editorDataValues.data} siteURL={editorDataValues.data?.site_url} />
									</div>
									<div className="d-flex w-100" style={{ height: "calc(100% - 75px)" }}>
										<div className="mid-side-section">
											<EditorMidComp />
										</div>
										<div className="right-side-section">
											<EditorRightComp />
										</div>
									</div>
								</div>
							</div>
						</VisualBuilderContext.Provider>
					</>
				}
				{
					!isDesktop && <>
						<div className="h1 p-3">
							You can use sitebuilder only on desktop.
							<div onClick={()=>history.push("/dashboard")} style={{color:"blue",textDecoration:"underline"}}>Click Here</div> to go back to dashboard
						</div>
					</>
				}
			</>
			
		);
	} else {
		return (
			<Redirect to="/dashboard/" />
		);
	}

}