import React from "react";
export const Color = {
	ERROR : "danger",
	SUCCESS : "success"
};
export const Title = {
	TITLE_ERROR : "Error",
	TITLE_SUCCESS: "Success"
};
export const ErrorMessages = {
	INVALID_COMPANY_NAME: "Invalid company name",
	MISSING_STREET_ADDRESS: "Street address is required",
	INVALID_ZIP_CODE: "Invalid Zip code",
	STATE_NOT_SELECTED: "State is not selected",
	MISSING_PHONE: "Phone is required",
	MISSING_COUNTRY: "Country is required",
	MISSING_WEB_URL: "Web url is required",
	INTERNAL_SERVER_ERROR: "Internal Server Problem!"
};

export const SigningDetails = {
	EMAIL:"Invalid email",
	PASSWORD:"Invalid username or password"
};

export const CampaignCreation = {
	CAMPAIGN_NAME : "Campaign name is required",
	CAMPAIGN_NAME_DESCRIPTION : "Please enter campaign name",
	CHARITY_TITLE: "Charity is required",
	CHARITY_DESCRIPTION : "Please select a charity",
	TARGET_TITLE : "Target amount is required",
	TARGET_DESCRIPTION : "Target amount should be between 100 and 10,000,000",
	MIN_DURATION_TITLE_ERROR : "Minimun donation error",
	MIN_DURATION_DESCRIPTION_ERROR : "Minimum donation should not be greater than target",
	MIN_DURATION_TITLE_REQUIRED : "Minimun donation is required",
	MIN_DURATION_DESCRIPTION_REQUIRED : "Minimum donation should be between 1 and 9999",
	MATCH_CONTRIBUTE_TITLE : "Match contribution is required",
	MATCH_CONTRIBUTE_DESCRIPTION : "Contribution must be between 1% to 100%",
	START_DATE_TITLE : "Start date is requied",
	START_DATE_DESCRIPTION : "Please select a start date",
	END_DATE_TITLE : "End date is required",
	END_DATE_DESCRIPTION : "Please Select an end date",
	INVALID_END_DATE_TITLE : "Invalid End date",
	INVALID_END_DATE_DESCRIPTION : "End date should be greater than start date"
};


export const SignUP = {
	FIRST_NAME : "First Name is required.",
	LAST_NAME : "Last name is required.",
	EMAIL_KEY1 : "Please provide a email address.",
	EMAIL_KEY2 : "Public emails like this are not allowed.",
	PASSWORD_KEY_1_2 : "Password should contain atleast 1 special character.",
	PASSWORD_KEY_1_4 : "Password should contain minimum 10 characters.",
	PASSWORD_CONFIRM : "Password did not match."
};

export const GoalForm = {
	IMPACT_ID : "Please select an impact factor",
	TARGET : "Target value should be number and greater than 0",
	START_DATE : "Please select a start date",
	END_DATE : "Please select a end date",
	START_DATE_GREATER : "End date should be greater than start date"
};

export default function AppConstant() {
	return (
		<div>
			{/* {Color} */}
			{/* {ErrorMessages} */}
			{/* {SigningDetails} */}
			{/* {CampaignCreation} */}
		</div>
	);
}