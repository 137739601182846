import React from "react";
import Button from "../../../sub-component/Button";
import ProgressBar from "../../../sub-component/ProgressBar";
import { ReactComponent as FlagIcon } from "../../../../assets/icons/basic/flag.svg";

export default function CampaignCard(props) {
	return (
		<div className="campaign-card-container w-100 pr-2 pr-sm-5" style={props.style}>
			<div className="w-100 d-flex flex-column align-items-start" style={{ flex: 2 }}>
				<span className="b3 nero-color campaigns-title-text" style={{ fontWeight: "700" }}>{props?.title || "Title"} 
				</span>
				{
					props.type === "campaign"
						?
						<div className="d-flex align-items-center" style={{ marginTop: "12px" }}>
							<FlagIcon fill="#5E7488" width="20" height="16" />
							<span className="b3 ml-1">{props?.subTitle || "Impact Statement"}</span>
						</div>
						:
						<>
							<span className="b3 hoki-color " style={{ marginTop: "12px" }}>{props?.subTitle || "Start Date - End Date"}</span>
							{/* <span className="b3 hoki-color" style={{ marginTop: "12px" }}>{`Goal: ${props?.goal}` || "Goal"}</span> */}
						</>
				}

				{
					props.showProgressBar &&
					<div className="w-100" style={{ marginTop: "20px" }}>
						<ProgressBar
							achieved={props.completed}
							total={props.target}
							type={"DonationProgressBar"}
							height="10px"
							width="100%"
							completed={props?.completedInPercentage}
							barColor={props?.barColor || "#0054DC"}
							isPercentageLabeled
							suffix={props?.suffix}
						/>
					</div>
				}
			</div>
			<div className="d-flex flex-column align-items-end justify-content-center " style={{ flex: 1,maxWidth:"40%",gap: "8%" }}>
				{
					props.isViewEnable &&
					<Button isHollow style={{ padding: "7px 43px",maxWidth:"90%" }} onClick={props.onView} title="View" className="b4" id="view-campaigns-button" />
				}
				{
					props.isEditEnable &&
					<Button isHollow style={{ padding: "7px 45px",maxWidth:"90%" }} onClick={props.onEdit} title="Edit" className="b4" />
				}
				{
					props.isDeleteEnable &&
					<div className="d-flex align-items-center justify-content-center" style={{ marginTop: "10px", width: "113.7px", cursor: "pointer" }} onClick={props.onDelete}>
						<div className="delete-icon" style={{ backgroundColor: "#FC4848", width: "13px", height: "13px" }} />
						<span className="b4" style={{ color: "#FC4848" }}>{props.deleteButtonLable || "Delete Campaign"}</span>
					</div>
				}


			</div>
		</div>
	);
}
