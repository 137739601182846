import React from "react";
import EnterpriseSetup from "../../../../components/SetupWizad/EnterpriseSetup";
// import Input from "../../../../components/sub-component/Input";

export default function CompanySettings() {

	return (
		<div className="d-flex justify-content-center">
			<div className="form-handling">
				<EnterpriseSetup
					shouldUpdate={true}
				/>
			</div>
		</div>
	);
}