import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import StatusCard from "../../../sub-component/dashboard/objective-status/StatusCard";
import { Fragment } from "react";

import { ReactComponent as LeftIcon } from "../../../../assets/icons/arrows/left-arrow.svg";
import { ReactComponent as RightIcon } from "../../../../assets/icons/arrows/right-arrow.svg";





export default function ObjectiveCard(props) {

	const cardColors = [
		"#CE8DFF", "#7ED0FF", "#106290", "#A4AEB8"
	];

	const [addNewObjArr, setAddNewObjArr] = useState(0);

	const history = useHistory();

	const [arrowControl, setArrowControl] = useState({
		leftArrowStatus: false,
		rightArrowStatus: false
	});

	const loadMore = () => {
		var container = document.getElementById("statscontainer1");
		container.scrollLeft += 180;
	};


	const loadPrevious = () => {
		var container = document.getElementById("statscontainer1");
		container.scrollLeft -= 180;
	};

	const onContainerScroll = () => {
		var container = document.getElementById("statscontainer1");
		console.log(container.scrollLeft, "Scroll");
		if (container.scrollLeft === 0) {
			setArrowControl({ ...arrowControl, leftArrowStatus: false });
		}
		else if (container.scrollLeft === (container.scrollWidth - 690)) {
			setArrowControl({ ...arrowControl, rightArrowStatus: false });
		}
		else {

			if (props.objectiveStatuses.length > 4) {
				setArrowControl({ leftArrowStatus: true, rightArrowStatus: true });
			}
		}
	};

	useEffect(() => {
		if (props.objectiveStatuses.length > 4) {
			setArrowControl({ ...arrowControl, rightArrowStatus: true });
		}
		if (props.objectiveStatuses.length === 0) {
			setAddNewObjArr(4);
		}
		else if (props.objectiveStatuses.length - 4 < 0 && props.objectiveStatuses.length - 4 >= -3) {
			setAddNewObjArr(4 - props.objectiveStatuses.length);
		}
		else if (props.objectiveStatuses.length - 4 >= 0) {
			setAddNewObjArr(1);
		}
	}, [props.objectiveStatuses.length]);

	console.log(props.objectiveStatuses, "For Id");


	return (
		<div className="w-100 pointer d-flex align-items-center ">
			{
				arrowControl.leftArrowStatus &&
				<LeftIcon
					id="scrollback"
					onClick={() => loadPrevious()}
					style={{ position: "absolute", left: "-60px" }} />
			}

			<Row className="w-100 m-0 justify-content-start align-items-center objectiveStatusCardsWrapper" id={`statscontainer1`} onScroll={() => onContainerScroll()}
				style={{ overflow: "auto", display: "flex", flexWrap: "nowrap", scrollBehavior: "smooth" }}>
				{
					props.objectiveStatuses.map((item, index) => {
						console.log("i am here...");
						return (
							<Fragment key={index}>
								{
									// (index >= limit.minLimit && index <= limit.maxLimit) &&
									<Col key={index} sm="6" md="3" xs="6"
										className={"p-0 d-flex justify-content-center " + `${index > 1 ? "on-mobile-status-card" : ""}`}>
										<StatusCard
											onClick={item ? () => history.push(`goals/impact_and_objective/id=${item.id}`) : () => history.push(`goals/list`)}
											hasData={item ? true : false}
											text={item && item.text.length >= 62 ? (`${item.text.substring(0, 62)}...`) : item.text }
											style={{ backgroundColor: `${cardColors[(index % 4)]}`, color: "white" }}
											percentage={item && item.percentage}
										/>
									</Col>

								}
							</Fragment>
						);
					})

				}	

				{
					cardColors.map((item, index) => {
						return (
							<Fragment key={index}>
								{
									index < addNewObjArr &&
									// (index >= limit.minLimit && index <= limit.maxLimit) &&
									<Col key={index} lg="3" md="3" sm="3" xs="6"
										className={"p-0 d-flex justify-content-center " + `${index > 1 ? "on-mobile-status-card" : ""}`}>
										<StatusCard
											onClick={() => history.push(`goals/list`)}
											hasData={false}
											text={props.objectiveStatuses[index] && props.objectiveStatuses[index].text}
											style={{ backgroundColor: `${item}`, color: "white" }}
										// percentage={props.objectiveStatuses[index] && props.objectiveStatuses[index].percentage}
										/>
									</Col>

								}
							</Fragment>
						);
					})

				}

			</Row>
			{
				arrowControl.rightArrowStatus &&
				<RightIcon
					id="scrolnext"
					onClick={() => loadMore()}
					style={{ position: "absolute", right: "-60px" }} />
			}
		</div>

	);
}
