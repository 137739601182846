import React from "react";


export default function CampaignStatusCard(props) {
	return (
		<div className="d-flex campaign-status-card align-items-center" style={props.style}>
			<div style={{marginLeft:"20.46px"}}>
				<img 
					src={props.path}
					alt=""
					style={{width:"33px", height:"33px"}}
				/>
			</div>
			<div className="d-flex flex-column" style={{marginLeft:"10px"}}>
				<div>
					<span className="b2" style={{color:"white"}}>{props.number}</span>
				</div>
				<div style={{marginTop:"-4px", display: "flex"}}>
					<span className="b4" style={{color:"white"}}>{props.text}</span>
				</div>
			</div>
		</div>
	);
}