import React, { useContext, useState, useEffect } from "react";
import { VisualBuilderContext } from "../../../context/VisualBuilderContext";
import logo from "../../../assets/icons/visual-builder/logo_visualBuilder.png";
// import Button from "../../../components/sub-component/Button";

import { SketchPicker } from "react-color";

import { ReactComponent as PaintIcon } from "../../../assets/icons/visual-builder/brush.svg";
import { ReactComponent as ElementIcon } from "../../../assets/icons/visual-builder/elements.svg";
import { ReactComponent as DropDownIcon } from "../../../assets/icons/visual-builder/button_drop.svg";
import { ReactComponent as TemplatesIcon } from "../../../assets/icons/visual-builder/templatesIcon.svg";

import template1Image from "../../../assets/images/Charity Site/template1Image.png";
import template2Image from "../../../assets/images/Charity Site/template2Image.png";

import ImagePicker from "../../../components/sub-component/ImagePicker";
// import Checkbox from "../../../components/sub-component/Checkbox";
import AddLinks from "../../../components/sub-component/visual-builder/AddLinks";

import { ReactComponent as FeatureImageIcon } from "../../../assets/icons/visual-builder/components-icons/copy.svg";

import { ReactComponent as RewardImageIcon } from "../../../assets/icons/visual-builder/components-icons/gift.svg";
// import { ReactComponent as DonationIcon } from "../../../assets/icons/visual-builder/components-icons/Vector.svg";
import { ReactComponent as ImpactIcon } from "../../../assets/icons/visual-builder/components-icons/newspaper.svg";
import { ReactComponent as AboutUsIcon } from "../../../assets/icons/visual-builder/components-icons/text-align-center.svg";
import { ReactComponent as CampaignsIcon } from "../../../assets/icons/visual-builder/components-icons/list.svg";
// import { ReactComponent as BarChartIcon } from "../../../assets/icons/visual-builder/components-icons/bar-chart.svg";
import { ReactComponent as GalleryIcon } from "../../../assets/icons/visual-builder/components-icons/gallery.svg";
import { ReactComponent as TestimonialsIcon } from "../../../assets/icons/visual-builder/components-icons/form.svg";
import { ReactComponent as AchievementsIcon } from "../../../assets/icons/visual-builder/components-icons/grid-row-3.svg";
import { ReactComponent as VolunteersIcon } from "../../../assets/icons/visual-builder/components-icons/star.svg";
import { ReactComponent as SpacerIcon } from "../../../assets/icons/visual-builder/components-icons/SpacerIcon.svg";

import VBAddComponent from "../../../components/sub-component/visual-builder/VBAddComponent";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";


export default function EditorLeftComp() {

	const aboutUsComponentData = {
		"component_name": "AboutUs",
		"component_title": "About Us",
		"id": "about_us_component",
		"is_component_fixed": false,
		"is_component_non_removable": false,
		"has_about_us_props": true,
		"props": {
			"aboutUsTitle": "Your donation is really powerful",
			"aboutUsSubtitle": "SINCE 2010",
			"aboutUsDescription": "when it comes to giving back to society. We select hand-picked charities for the impactful causes around the World to make sure your donation goes directly to the needy.",
			"aboutUsImage": null
		},
		"props_types": [
			{
				"label": "About us Title",
				"id": "aboutUsTitle",
				"type": "input",
				"max_characters": 38,
				"max_word_length": 15
			},
			{
				"label": "About us Sub-Title",
				"id": "aboutUsSubtitle",
				"type": "input",
				"max_characters": 25,
				"max_word_length": 15
			},
			{
				"label": "About us Description",
				"id": "aboutUsDescription",
				"type": "textareaInput",
				"max_characters": 150,
				"max_word_length": 15
			},
			{
				"label": "About us image",
				"id": "aboutUsImage",
				"type": "image"
			}]
	};

	const featureComponentData = {
		"component_name": "FeatureComponent",
		"component_title": "Feature Component",
		"id": "feature_component",
		"is_component_fixed": false,
		"is_component_non_removable": false,
		"has_feature_props": true,
		"props": {
			"homePageFeature": true,
			"featureTitle": "Helping African kids to get better education.",
			"featureDescription": "Be a part of Charitoz to help 20 Million poor African kids to get better education. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam. Nibh urna mattis sit velit feugiat euismod purus. Be a part of Charitoz to help 20 Million poor African kids to get better education. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam. Nibh urna mattis sit velit feugiat euismod purus.",
			"featureImage": null
		},
		"props_types": [
			{
				"label": "Feature Title",
				"id": "featureTitle",
				"type": "input",
				"max_characters": 25,
				"max_word_length": 15
			},
			{
				"label": "Feature Description",
				"id": "featureDescription",
				"type": "textareaInput",
				"max_characters": 150,
				"max_word_length": 15
			},
			{
				"label": "Feature Image",
				"id": "featureImage",
				"type": "image"
			}
		]
	};

	const rewardFeatureComponentData = {
		"component_name": "FeatureComponent",
		"component_title": "Reward Feature",
		"id": "reward_feature_component",
		"is_component_fixed": false,
		"is_component_non_removable": false,
		"has_reward_feature_props": true,
		"props": {
			"campaignFeature": true,
			"rewardTitle": "Earn a chance to Win exciting prizes",
			"rewardSubtitle": "We will surprise you with a bunch of exciting prizes when you give and motivate others to participate in donation campaigns.",
			"rewardDescription": "When employees donate to charity, they expect something in return for their contribution. They want to help others, but they also want to reap rewards when they do so. It's important to consider what a customer wants out of a donation platform. Altrunic is an all-in-one solution: it lets you donate to charity and earn prizes back with motivating others.",
			"rewardImage": null
		},
		"props_types": [
			{
				"label": "Reward Title",
				"id": "rewardTitle",
				"type": "input",
				"max_characters": 25,
				"max_word_length": 15
			},
			{
				"label": "Reward Sub-Title",
				"id": "rewardSubtitle",
				"type": "input",
				"max_characters": 45,
				"max_word_length": 15
			},
			{
				"label": "Reward Description",
				"id": "rewardDescription",
				"type": "textareaInput",
				"max_characters": 150,
				"max_word_length": 15
			},
			{
				"label": "Reward Image",
				"id": "rewardImage",
				"type": "image"
			}
		]
	};

	const ImpactComponentData = {
		"component_name": "ImpactAndObjectives",
		"component_title": "Impact & Objective",
		"id": "impact_object_component",
		"is_component_fixed": false,
		"is_component_non_removable": false,
		"has_impact_objective_props": true,
		"props": {
			"impactBackGroundImage": null,
			"impactTitle": "We are on a mission to help people around the globe"
		},
		"props_types": [
			{
				"label": "Impact Image",
				"id": "impactBackGroundImage",
				"type": "image"
			},
			{
				"label": "Impact Title",
				"id": "impactTitle",
				"type": "input",
				"max_characters": 24,
				"max_word_length": 15
			}
		]
	};

	const OnGoingCampaignsComponentData = {
		"component_name": "OnGoingCampaigns",
		"component_title": "Campaigns",
		"id": "ongoing_campaigns_component",
		"is_component_fixed": false,
		"is_component_non_removable": false,
		"has_ongoing_campaings_props": true,
		"props": {
			"OnGoingCampaignsTitle": "On Going Campaigns"
		},
		"props_types": [
			{
				"label": "Campaigns Header Title",
				"id": "OnGoingCampaignsTitle",
				"type": "input",
				"max_characters": 24,
				"max_word_length": 15
			}
		]
	};

	const CompletedCampaingsComponentData = {
		"component_name": "CompletedCampaigns",
		"component_title": "Achievements",
		"id": "completed_campaigns_component",
		"is_component_fixed": false,
		"is_component_non_removable": false,
		"has_completed_campaings_props": true,
		"props": {
			"CompletedCampaignsTitle": "Completed Campaigns"
		},
		"props_types": [
			{
				"label": "Achievements Header Title",
				"id": "CompletedCampaignsTitle",
				"type": "input",
				"max_characters": 24,
				"max_word_length": 15
			}
		]
	};

	const VolunteersComponentData =
	{
		"component_name": "OurVolunteers",
		"component_title": "Volunteers",
		"id": "volunteers_component",
		"is_component_fixed": false,
		"is_component_non_removable": false,
		"has_volunteers_props": true,
		"props": {
			"volunteersHeaderTitle": "Our Volunteers",
			"volunteersDetails": []
		},
		"props_types": [
			{
				"label": "Volunteers Header Title",
				"id": "volunteersHeaderTitle",
				"type": "input",
				"max_characters": 48,
				"max_word_length": 15
			},
			{
				"label": "Add Volunteers",
				"id": "volunteersDetails",
				"type": "multi-input",
				"maxLenght": 3,
				"labels": [
					"Image",
					"Name",
					"Designation"
				],
				"input_types": [
					"image",
					"input",
					"input"
				]
			}
		]
	};


	const testimonyComponentData = {
		"component_name": "TestimonyComponent",
		"component_title": "Testimony Component",
		"id": "testimony_component",
		"has_testimony_component_props": true,
		"props": {
			"componentTitle": "Testimony",
			"testimonyTitle": "Feedback from our participants",
			"testimonySubtitle": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
			"testimonyDetails": []
		},
		"props_types": [
			{
				"label": "Component Title",
				"id": "componentTitle",
				"type": "input",
				"max_characters": 24,
				"max_word_length": 15
			},
			{
				"label": "Testimony Title",
				"id": "testimonyTitle",
				"type": "input",
				"max_characters": 24,
				"max_word_length": 15
			},
			{
				"label": "Testimony Sub Title",
				"id": "testimonySubtitle",
				"type": "input",
				"max_characters": 80,
				"max_word_length": 15
			},
			{
				"label": "Add Testimony",
				"id": "testimonyDetails",
				"type": "multi-input",
				"maxLenght": 3,
				"labels": [
					"Image",
					"Name",
					"Designation",
					"Description"
				],
				"input_types": [
					"image",
					"input",
					"input",
					"textareaInput"
				]
			}
		]
	};

	const galleryComponentData = {
		"component_name": "Gallery",
		"component_title": "Gallery",
		"id": "gallery_component",
		"has_gallery_component_props": true,
		"props": {
			"galleryTitle": "Gallery",
			"galleryImages": []
		},
		"props_types": [
			{
				"label": "Gallery Title",
				"id": "galleryTitle",
				"type": "input",
				"max_characters": 24,
				"max_word_length": 15
			},
			{
				"label": "Gallery Images",
				"id": "galleryImages",
				"type": "multi-image"
			}
		]
	};

	const spacerComponentData = {
		"component_name": "Spacer",
		"component_title": "Spacer",
		"id": "spacer_component",
		"is_component_fixed": false,
		"is_component_non_removable": false,
		"has_spacer_component_props": true,

		"props": {
			"spacerHeight": "100",
		},
		"props_types": [
			{
				"label": "Spacer Height",
				"id": "spacerHeight",
				"type": "input",
				"inputType": "number",
				"postLable": "px"
			}
		]
	};

	const { editorDataValues, setEditorDataValues } = useContext(VisualBuilderContext);
	// const [templateId,setTemplateId]=useState(1);
	const componentsData = [
		{ compPlacementPageIndex: 2, icon: <FeatureImageIcon />, title: "Feature Image", data: featureComponentData },
		{ compPlacementPageIndex: 0, icon: <ImpactIcon />, title: "Impact & Objective", data: ImpactComponentData },
		{ compPlacementPageIndex: 1, icon: <RewardImageIcon />, title: "Reward Image", data: rewardFeatureComponentData },
		{ compPlacementPageIndex: 0, icon: <AboutUsIcon />, title: "About Us", data: aboutUsComponentData },
		{ compPlacementPageIndex: 0, icon: <CampaignsIcon />, title: "Campaigns", data: OnGoingCampaignsComponentData },
		// {compPlacementPageIndex: 1, icon: <BarChartIcon />, title: "Milestones Acheived", data: featureComponentData },
		{ compPlacementPageIndex: 2, icon: <GalleryIcon />, title: "Gallery", data: galleryComponentData },
		{ compPlacementPageIndex: 2, icon: <TestimonialsIcon />, title: "Testimonials", data: testimonyComponentData },
		{ compPlacementPageIndex: 0, icon: <AchievementsIcon />, title: "Achievements", data: CompletedCampaingsComponentData },
		{ compPlacementPageIndex: 2, icon: <VolunteersIcon />, title: "Volunteers", data: VolunteersComponentData },
		// { compPlacementPageIndex: 1, icon: <DonationIcon />, title: "Donations", data: featureComponentData },
		{ compPlacementPageIndex: 2, icon: <SpacerIcon fill="#5E7488" stroke="#5E7488" />, title: "Spacer", data: spacerComponentData },
	];

	const [dropdown, setDropdown] = useState({
		isSelectBrandDropDdownOpen: false,
		isTemplatesDropDownOpen:false,
		isSetHeaderFooterDropDdownOpen: false
	});

	const [colorPicker, setColorPicker] = useState({
		color: "#FFFFFF"
	});


	const addComponent = (data) => {
		var tempArray = editorDataValues.selectedPage;
		tempArray = [...tempArray, data.data];
		setEditorDataValues({ ...editorDataValues, selectedPage: tempArray, dataHasChanged: true });
	};

	// const changeColor = (e) => {
	// 	var tempData = editorDataValues.data;
	// 	var tempColorArr = tempData.colors;
	// 	tempColorArr[0].hashCode = e.target.value;
	// 	tempData = { ...tempData, colors: tempColorArr };
	// 	setEditorDataValues({ ...editorDataValues, data: tempData });
	// };

	const changeColor = (color) => {
		console.log("here");
		const colorHex = color.hex;
		const hexWithOutHash = colorHex.substring(1, colorHex.length);
		setColorPicker({ color: hexWithOutHash });
		setEditorDataValues({ ...editorDataValues, dataHasChanged: true });
		// var tempData = editorDataValues.data;
		// var tempColorArr = tempData.colors;
		// tempColorArr[0].hashCode = colorPicker.color;
		// tempData = { ...tempData, colors: tempColorArr };
		// setEditorDataValues({ ...editorDataValues, data: tempData });
	};

	const getImageFile = (file) => {
		var tempData = editorDataValues.data;
		tempData = { ...tempData, logo: file.file };
		setEditorDataValues({ ...editorDataValues, data: tempData, dataHasChanged: true });
	};

	// const getCheckOtionStatus = (status) => {
	// 	console.log(status);
	// };
	// useEffect(()=>{
	// 	let tempArray=editorDataValues.data;
	// 	if(templateId===1){
			
	// 		tempArray={...tempArray,templateId:1};
			
	// 		setEditorDataValues({...editorDataValues,data:tempArray});

	// 	}
	// 	else{
	// 		tempArray={...tempArray,templateId:2};
	// 		console.log(editorDataValues,"aaaaaaaaaaaaaaaaaaaaa");
	// 		console.log(tempArray,"bbbbbbbbbbbbbbbbbbbbbbb");
	// 		setEditorDataValues({...editorDataValues,data:tempArray});
	// 	}
	// },[templateId]);
	useEffect(() => {
		var tempData = editorDataValues.data;
		var tempColorArr = tempData.colors;
		tempColorArr[0].hashCode = colorPicker.color;
		tempData = { ...tempData, colors: tempColorArr };
		setEditorDataValues({ ...editorDataValues, data: tempData });
	}, [colorPicker.color]);

	const getOption = (pageIndex, componentData, index) => {
		if (pageIndex === 2 || (pageIndex === 0 && pageIndex === editorDataValues.selectedPageIndex) || (pageIndex === 1 && editorDataValues.selectedPageIndex > 0))
			return (
				<VBAddComponent
					icon={componentData.icon}
					title={componentData.title}
					key={index}
					style={{ marginTop: "25px", paddingRight: "4.5px" }}
					onClick={() => addComponent(componentData)} />
			);
	};

	const history = useHistory();

	return (
		<div className="left-section-container" id="left-section-container" >
			<img className="pointer" src={logo} onClick={() => { history.push("dashboard/"); }} />
			<div className="left-section-option-container">

				{/* <Button onClick={() => addComponent(componentData)} visualBuilderButton="blue" title="Add Feature Component" />
				<div style={{ marginTop: "15px" }}>
					<Button onClick={() => addComponent(aboutUs)} visualBuilderButton="blue" title="Add About Us Component" />
				</div> */}
				<div style={{ borderBottom: "1.5px solid #CBE3FF", paddingBottom: "20px" }}>
					<div className="templateDropDown">
						{/* <select className="w-100 mb-3" value={editorDataValues.data.templateId} onChange={(e)=>{
							setEditorDataValues({ ...editorDataValues, data: {...editorDataValues.data, templateId: parseInt(e.target.value)}, dataHasChanged: true });
						}}>
							<option selected value={1}>
								Template 1
							</option>
							<option value={2}>
								Template 2
							</option>
						</select> */}
					</div>
					<div className={`w-100 d-flex justify-content-start align-items-center ${dropdown.isSelectBrandDropDdownOpen ? "selected-row" : "unselected-row"}`}
						id="colors-dropdown-open"
						style={{borderBottom:"1.5px solid #CBE3FF",paddingBottom:"20px"}}
						onClick={() => setDropdown({ ...dropdown, isSelectBrandDropDdownOpen: !dropdown.isSelectBrandDropDdownOpen })}
					>
						<PaintIcon />
						<span className="b4" style={{ marginLeft: "12px" }}> {"Layout & Brand Colors"}</span>
						<DropDownIcon  style={{ marginLeft: "auto" }} />
					</div>
					
					{
						dropdown.isSelectBrandDropDdownOpen &&

						<div style={{ paddingLeft: "15px" }}>
							<div style={{ marginTop: "25px" }}>
								{
									editorDataValues.data.colors.map((color, key) => {
										return (
											<div key={key}>
												{
													key === 0 &&
													<div className="d-flex flex-column justify-content-between" style={{ marginTop: "7px" }}>
														<span className="b4 licorice-color ">{color.label} Brand Color</span>
														{/* <div className="d-flex align-items-center">
															<input maxLength="6" value={color.hashCode} onChange={(e) => changeColor(e)} className="vb-color-input"></input>
															<div style={{ backgroundColor: `#${color.hashCode}`, width: "10px", height: "10px", marginLeft: "10px" }}></div>
														</div> */}
														<div style={{ marginTop: "10px" }}>
															<SketchPicker
																width={"170px"}
																color={colorPicker.color}
																onChangeComplete={changeColor}
															/>
														</div>
													</div>
												}
											</div>
										);
									})
								}
								<div style={{ marginTop: "10px" }}>
									<span className="b4 licorice-color">Primary Logo</span>
									<div >
										<ImagePicker image={editorDataValues.data.logo} getFile={getImageFile} height={40} width={40} />
									</div>
								</div>
							</div>
						</div>
					}
					<div className={`w-100 d-flex justify-content-start align-items-center mt-3 ${dropdown.isTemplatesDropDownOpen ? "selected-row" : "unselected-row"}`}
						id="templates-dropdown-open"
						onClick={() => setDropdown({ ...dropdown, isTemplatesDropDownOpen: !dropdown.isTemplatesDropDownOpen })}
					>
						<TemplatesIcon />
						<span className="b4" style={{ marginLeft: "12px" }}> {"Templates"}</span>
						<DropDownIcon style={{ marginLeft: "auto" }} />
					</div>
					{
						dropdown.isTemplatesDropDownOpen && 
						<>
							<div className="selectTemplateBlock row pt-3 pl-0 pr-0">
								<div className="d-flex flex-column pointer" id="template-1-select" style={{width:"3.5vw",maxHeight:"3.5vw"}}  onClick={()=>{
									setEditorDataValues({ ...editorDataValues, data: {...editorDataValues.data, templateId: 1}, dataHasChanged: true });
								}}>
									<img src={template1Image} alt=""  className={`${(editorDataValues.data.templateId===1)?"selectedTemplate":""}`}/>
									<div className={`mt-2 b4 ${(editorDataValues.data.templateId===1)?"selectedText":""}`}>
										Template 1
									</div>
								</div>
								<div className=" d-flex flex-column ml-auto pointer" id="template-2-select" style={{width:"3.5vw",maxHeight:"3.5vw"}}  onClick={()=>{
									setEditorDataValues({ ...editorDataValues, data: {...editorDataValues.data, templateId: 2}, dataHasChanged: true });
								}}>
									<img src={template2Image} alt="" className={`${(editorDataValues.data.templateId===2)?"selectedTemplate":""}`}/>
									<div className={`mt-2 b4 ${(editorDataValues.data.templateId===2)?"selectedText":""}`} >
										{"Template 2"}
									</div>
								</div>
							</div>
						</>
					}
				</div>
					
				<div style={{ borderBottom: "1.5px solid #CBE3FF", marginTop: "20px", paddingBottom: "15px" }}>
					<div className={`w-100 d-flex justify-content-start align-items-center ${dropdown.isSetHeaderFooterDropDdownOpen ? "selected-row" : "unselected-row"}`}
						id="header-and-footer-dropdown-open"
						onClick={() => setDropdown({ ...dropdown, isSetHeaderFooterDropDdownOpen: !dropdown.isSetHeaderFooterDropDdownOpen })}
					>
						<ElementIcon />
						<span className="b4" style={{ marginLeft: "12px" }}> {"Header & Footer"}</span>
						<DropDownIcon style={{ marginLeft: "auto" }} />
					</div>
					{
						dropdown.isSetHeaderFooterDropDdownOpen &&

						<div style={{ paddingLeft: "15px", marginTop: "25px" }}>
							{/* <Checkbox label={"Show Social Icons in Footer"} isChecked={false} checkedFor={"social-icons-footer"} getCheckStatus={getCheckOtionStatus} />
							<Checkbox
								label={"Show Contact Details in Footer"} isChecked={false} checkedFor={"contact-details-footer"} getCheckStatus={getCheckOtionStatus}
								style={{ marginTop: "8px" }} /> */}
							<AddLinks style={{ marginTop: "20px" }} headerLinks />
							<AddLinks style={{ marginTop: "20px" }} socialLinks />
						</div>
					}
				</div>
				<div style={{ marginBottom: "25px" }}>
					{
						componentsData && componentsData.map((componentData, index) => {
							return (
								<Fragment key={index}>
									{
										getOption(componentData.compPlacementPageIndex, componentData, index)
									}
								</Fragment>
							);
						})
					}

				</div>

			</div>

		</div >

	);
}