import React, { useState, useEffect } from "react";

import { ReactComponent as Arrow_down } from "../../../assets/icons/visual-builder/button_drop.svg";

export default function VBDropDown(props) {

	const options = props.options;

	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(props.selectedOption || "");

	function dropDownValue(option) {
		setSelectedOption(option);
		setIsDropDownOpen(false);
		props.getSelectedOption(option, { for: `${props.selectedOptionFor}` || null });
	}

	useEffect(() => {
		setSelectedOption(props.selectedOption || "");
	}, [props.selectedOption]);

	return (
		<div className="pointer" onClick={() => setIsDropDownOpen(!isDropDownOpen)} style={{ width: `${props.width || "100%"}`, position: "relative" }}>
			<div
				style={{
					width: "100%", height: `${props.height || "40px"} `, backgroundColor: "#FFFFFF", borderRadius: "5px", boxShadow: `${props.isVbDrobdown ? "none" : " 2px 8px 15px rgba(0, 0, 0, 0.1)"}`,
					padding: `${props.isVbDrobdown ? "0px 0px 0px 10px" : "6px 15px"}`, ...props.style
				}}
				onClick={props.onClick}
				className="d-flex justify-content-between align-items-center unselected-row" >
				<div className="d-flex justify-content-center align-items-center">
					<span className="visual-builder-button nero-color" style={{ color: "#A4AEB8" }}>{selectedOption.value || "Select"}</span>
				</div>
				<div>
					<Arrow_down className={props.iconColorClass} id="open-dropdown-arrow" style={{ marginLeft: "15px", marginRight: `${props.isVbDrobdown ? "4.5px" : "0px"}` }} />
				</div>
			</div>
			{
				isDropDownOpen &&
				<div
					className="w-100 custom-select-option-container d-flex flex-column align-items-center"
					style={{ position: "absolute", marginTop: "0.15em", boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.1)", borderRadius: "10px" }}>
					{
						options.map((item, index) => {
							return (
								<span key={index} id="vb-dropdown-items" onClick={() => dropDownValue(item)} style={{ padding: `${props.isVbDrobdown ? "8px 10px" : "8px 15px"}`, cursor: "pointer", height: `${props.isVbDrobdown ? "max-content" : "fit-content"}` }} className="w-100 visual-builder-button select-option nero-color">
									{item.value}
								</span>
							);
						})
					}
				</div>
			}
		</div>
	);
}
