import React, { Fragment } from "react";
import TestimonialCard from "../components/sub-components/TestimonialCard";
import CarouselComponent from "./sub-components/CarouselComponents";
// import Image from "../assets/images/Image2.png";


export default function TestimonyComponent(props) {

	// const testimonials = [
	// 	{
	// 		Image: Image,
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 		Name: "Ben Jack",
	// 		Profession: "BUSINESS ANAYLYST"
	// 	},
	// 	{
	// 		Image: Image,
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 		Name: "Natalya",
	// 		Profession: "DESIGNER"
	// 	},
	// 	{
	// 		Image: Image,
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 		Name: "John Br.",
	// 		Profession: "SOFTWARE ENGINEER"
	// 	}
	// ];
	if(parseInt(props.templateId)===1){
		return (
			<div id="testimony-component-template-1">
				{
					props.isInEditingInProcess
						?
						<div className="testimonyComponent"  style={{ backgroundColor: `${props.primaryColor}` }}>
							<div>
								<span className="text-colour heading1">{props?.componentTitle}</span>
							</div>
							<div style={{ padding: "10px 30px 30px 20px" }}>
								<span className="cs-hero3 white-color">{props.testimonyTitle || "Feedback from our participants"}</span>
							</div>
							<div style={{ paddingBottom: "25px" }}>
								<span className="cs-p1 text-colour">{props.testimonySubtitle || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam."}</span>
							</div>
							<div className="testimony-box">
								{props.testimonyDetails && props.testimonyDetails.map((testimony, index) => {
									return (
										<div className="margin-card" key={index} style={{ marginLeft: `${index !== 0 ? "35px" : "0"}` }}>
											<TestimonialCard
												// defaultTestimonialCard
												templateId={parseInt(props.templateId)}
												color={props.primaryColor}
												image={testimony.inputDetails[0].value}
												name={testimony.inputDetails[1].value}
												profession={testimony.inputDetails[2].value}
												description={testimony.inputDetails[3].value}
	
											/>
										</div>
									);
								})
								}
								{
									Array.from({ length: 3 - props.testimonyDetails.length }).map((testimony, index) => {
										return (
											<div className="margin-card" key={index} style={{ marginLeft: `${props.testimonyDetails.length > 0 ? "35px" : index !== 0 ? "35px" : "0"}` }}>
												<TestimonialCard
													templateId={parseInt(props.templateId)}
													defaultTestimonialCard
													color={props.primaryColor}
												/>
											</div>
										);
									})
								}
							</div>
						</div>
						:
						<Fragment>
							{
								props.testimonyDetails?.length > 0
								&&
								<div className="testimonyComponent" style={{ backgroundColor: `${props.primaryColor}` }}>
									<div style={{ paddingTop: "40px" }}>
										<span className="text-colour heading1">Testimony</span>
									</div>
									<div style={{ padding: "10px 30px 30px 20px" }}>
										<span className="cs-hero3 white-color">{props.testimonyTitle}</span>
									</div>
									<div style={{ paddingBottom: "25px" }}>
										<span className="cs-p1 text-colour">{props.testimonySubtitle}</span>
									</div>
									<div className="testimony-box">
										{/* {props.testimonyDetails && props.testimonyDetails.map((testimony, index) => {
											return (
												<div className="margin-card" key={index}>
													<TestimonialCard
														templateId={parseInt(props.templateId)}
														color={props.primaryColor}
														image={testimony.inputDetails[0].value}
														name={testimony.inputDetails[1].value}
														profession={testimony.inputDetails[2].value}
														description={testimony.inputDetails[3].value}
	
													/>
												</div>
											);
										})
										} */}
										{props.testimonyDetails && <CarouselComponent data={props.testimonyDetails} primaryColor={props.primaryColor} templateId={parseInt(props.templateId)} isTestimonyCard/>}
									</div>
								</div>
							}
						</Fragment>
	
				}
			</div>
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<div id="testimony-component-template-2">
				{
					props.isInEditingInProcess
						?
						<div className="testimonyComponent" style={{ backgroundColor: "white" }}>
							<div>
								<span className="">{props?.componentTitle}</span>
							</div>
							<div style={{ padding: "10px 30px 30px 20px" }}>
								<span className="cs-hero3 " style={{color:props.primaryColor}}>{props.testimonyTitle || "Feedback from our participants"}</span>
							</div>
							<div style={{ paddingBottom: "25px" }} >
								<span className="cs-p1 ">{props.testimonySubtitle || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam."}</span>
							</div>
							<div className="testimony-box">
								{props.testimonyDetails && props.testimonyDetails.map((testimony, index) => {
									return (
										<div className="margin-card" key={index} style={{ marginLeft: `${index !== 0 ? "35px" : "0"}` }}>
											<TestimonialCard
												templateId={parseInt(props.templateId)}
												// defaultTestimonialCard
												color={props.primaryColor}
												image={testimony.inputDetails[0].value}
												name={testimony.inputDetails[1].value}
												profession={testimony.inputDetails[2].value}
												description={testimony.inputDetails[3].value}
	
											/>
										</div>
									);
								})
								}
								{
									Array.from({ length: 3 - props.testimonyDetails.length }).map((testimony, index) => {
										return (
											<div className="margin-card" key={index} style={{ marginLeft: `${props.testimonyDetails.length > 0 ? "35px" : index !== 0 ? "35px" : "0"}` }}>
												<TestimonialCard
													templateId={parseInt(props.templateId)}
													defaultTestimonialCard
													color={props.primaryColor}
												/>
											</div>
										);
									})
								}
							</div>
						</div>
						:
						<Fragment>
							{
								props.testimonyDetails?.length > 0
								&&
								<div className="testimonyComponent pt-0" style={{ backgroundColor:"white" }} >
									<div className="pt-1 pt-md-3 pt-lg-4">
										<span className="">Testimony</span>
									</div>
									<div style={{ padding: "10px 30px 0px 20px" }} className="">
										<span className="cs-hero3" style={{ color: `${props.primaryColor}` }}>{props.testimonyTitle}</span>
									</div>
									<div  className="d-none d-md-inline pt-1 pb-0 pb-md-1">
										<span className="cs-p1">{props.testimonySubtitle}</span>
									</div>
									<div className="testimony-box " >
										{/* {props.testimonyDetails && props.testimonyDetails.map((testimony, index) => {
											return (
												<div className="margin-card" key={index} >
													<TestimonialCard
														templateId={parseInt(props.templateId)}
														color={props.primaryColor}
														image={testimony.inputDetails[0].value}
														name={testimony.inputDetails[1].value}
														profession={testimony.inputDetails[2].value}
														description={testimony.inputDetails[3].value}
	
													/>
												</div>
											);
										})
										} */}
										{props.testimonyDetails && <CarouselComponent data={props.testimonyDetails} primaryColor={props.primaryColor} templateId={parseInt(props.templateId)} isTestimonyCard/>}
									</div>
								</div>
							}
						</Fragment>
	
				}
			</div>
		);
	}
	else{
		return(
			<>
			</>
		);
	}
	
}