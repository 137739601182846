import React, { Fragment, useState } from "react";
import Button from "./../../../../components/sub-component/Button";
import AlertBox from "./../../../../components/common/AlertBox";
import Modal from "./../../../../components/common/Modal";
import StripeCard from "./../../../../components/subscription/StripeCard";
import { useAuth } from "./../../../../context/AuthContext";
import { openURL } from "../../../../utilities/Utilites";

export default function SubscriptionWebHooks() {

	const auth = useAuth();
	const { subscription } = auth;

	const [subscriptionNotifications, setSubscriptionNotifications] = useState({
		isPaymentUnsuccessful: false,
		isAccountSuspended: false,
		isCardExpired: true
	});

	const [ModalsStatus, setModalsStatus] = useState({
		isUpdatePaymentModalOpen: false,
		isModalOpen: false
	});
	const [alert, setAlert] = useState(null);

	const retryPayment = async () => {

		const { result, error } = await openURL(`/v1/subscriptions/retry`, "POST", auth, {
			subscription_id: auth.subscription.id
		});

		if (error) {
			setAlert(
				<AlertBox color="danger" title="There was a problem with the payment. Update your payment method, or retry your payment." description={error.message} />
			);
		} else if (result) {

			setAlert(
				<AlertBox color="success" title="Success" description={"Subscription Payed"} />
			);
			setSubscriptionNotifications({ ...subscriptionNotifications, isPaymentUnsuccessful: false });
			setModalsStatus({ ...ModalsStatus, isModalOpen: false, isUpdatePaymentModalOpen: false });
			window.location.reload();
		}


	};

	const paymentUnsuccessfulComp = () => {

		// console.log(Number(subscription.expires_at), "ASDQWE");
		const expiry_date = new Date(Number(subscription.expires_at) * 1000).toLocaleDateString();

		return (

			<div className="d-flex justify-content-center" style={{ position: "relative" }}>
				<div style={{ width: "calc(100vw - 120px)", left: "30px", paddingTop: "20px" }}>
					<AlertBox type="global-notification" color="danger" title={"Alert!"}
						description={
							<p className="b3 m-0" style={{ color: "#FC4848" }}>Your payment was unsuccessful in last retry. We will try to charge your card again in upcoming days. If unseuccesful, your account will be suspended on {expiry_date}.
								<font style={{ color: "#0054DC" }} className="pointer" onClick={() => { setModalsStatus({ ...ModalsStatus, isModalOpen: true }); }}> Click here</font> to retry payment or update your payment method.
							</p>
						} />
				</div>
				{
					ModalsStatus.isModalOpen &&
					<Modal title="Payment Unsuccessful"
						onClose={() => { setModalsStatus({ ...ModalsStatus, isModalOpen: false, isUpdatePaymentModalOpen: false }); setAlert(null); }}>
						<div className="w-100 d-flex justify-content-center">
							<div style={{ width: "777px", marginTop: "28px", marginBottom: "63px", textAlign: "center" }}>
								<p className="b2 hoki-color m-0">Your payment was unsuccessful in last retry. We will try to charge your card again in upcoming days. If unsuccessful, your account will be suspended on DATE.</p>

								<div className="d-flex flex-column" style={{ marginTop: "60px" }}>
									{alert &&
										<div className="mb-3">
											{alert}
										</div>
									}
									<div className="d-flex" style={{ justifyContent: "space-evenly" }}>
										<Button
											title={"Retry Payment"}
											style={{ width: "max-content", height: "40px", margin: "auto", padding: "10px 68px" }}
											onClick={() => {
												retryPayment();
											}}
										/>
										<Button
											title={"Update Payment Method"}
											style={{ width: "max-content", height: "40px", margin: "auto", padding: "10px 48px" }}
											onClick={() => { setModalsStatus({ ...ModalsStatus, isModalOpen: false, isUpdatePaymentModalOpen: true }); setAlert(null); }}
										/>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				}
				{
					ModalsStatus.isUpdatePaymentModalOpen &&
					<Modal title="Update Payment Method"
						onClose={() => { setModalsStatus({ ...ModalsStatus, isModalOpen: false, isUpdatePaymentModalOpen: false }); setAlert(null); }}>
						<div className="w-100 mt-5  d-flex justify-content-center" style={{ marginBottom: "45px" }}>
							<div style={{ width: "550px", boxShadow: "6px 15px 30px rgba(0, 0, 0, 0.15)", background: "white", borderRadius: "25px", padding: "20px" }}>
								<StripeCard stripCardCURD onCardUpdate={() => { setModalsStatus({ ...ModalsStatus, isModalOpen: true, isUpdatePaymentModalOpen: false }); setAlert(null); }} />
							</div>
						</div>
					</Modal>
				}

			</div>
		);
	};

	const cardExpiredComp = () => {
		return (

			<div className="d-flex justify-content-center" style={{ position: "relative" }}>
				<div style={{ width: "calc(100vw - 120px)", left: "30px", paddingTop: "20px" }}>
					<AlertBox type="global-notification" color="danger" title={"Alert!"}
						description={<p className="b3 m-0" style={{ color: "#FC4848" }}>Your payment method has expired.
							<font style={{ color: "#0054DC" }} className="pointer" onClick={() => { setModalsStatus({ ...ModalsStatus, isUpdatePaymentModalOpen: true }); }}> Click here</font> to add a new payment method.</p>
						} />
				</div>
				{
					ModalsStatus.isUpdatePaymentModalOpen &&
					<Modal title="Update Payment Method"
						onClose={() => { { setModalsStatus({ ...ModalsStatus, isModalOpen: false, isUpdatePaymentModalOpen: false }); } }}>
						<div className="w-100 mt-5  align-items-center d-flex justify-content-center" style={{ marginBottom: "45px" }}>
							<p className="s2 hoki-color">To continue your subscription, please enter new card details below:</p>
							<div style={{ width: "550px", boxShadow: "6px 15px 30px rgba(0, 0, 0, 0.15)", background: "white", borderRadius: "25px", padding: "20px", marginTop: "40px" }}>
								<StripeCard stripCardCURD onCardUpdate={() => {
									setModalsStatus({ ...ModalsStatus, isUpdatePaymentModalOpen: false });
									setSubscriptionNotifications({ ...subscriptionNotifications, isCardExpired: false });
								}} />
							</div>
						</div>
					</Modal>
				}

			</div>
		);
	};
	return (
		<Fragment>
			{
				subscription.status === "unpaid" &&
				paymentUnsuccessfulComp()
			}
			{
				subscriptionNotifications.isPaymentUnsuccessful &&
				cardExpiredComp()
			}
		</Fragment>
	);
}
