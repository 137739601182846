import React from "react";
import GoalSetupForm from "../../../components/SetupWizad/Goals";
export default function Goals() {
	return (

		<div className="d-flex justify-content-center">
			<div className="form-handling" style={{ margin: "50px auto" }}>
				<GoalSetupForm shouldCreate hasHeaderLine/>
			</div>
		</div>

	);
}
