/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CampaignCard from "./CampaignCard";
import { useMediaQuery } from "react-responsive";
import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import DonationCardDetails from "./DonationCardDetails";
import { getUnauthorizedURL } from "../utilities/Utilites";
// import { useRouteMatch } from "react-router";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import DonationDoneCard from "./DonationDoneCard";
import VBAddCampaign from "./VBAddCampaign";
import DonationLoginCard from "./DonationLoginCard";
import DonationSignupCard from "./DonationSignupCard";
import { useAuth } from "../context/AuthContext";
// import { useAuth } from "../context/AuthContext";
import CarouselComponent from "./sub-components/CarouselComponents";

export default function OnGoingCampaigns(props) {
	const auth = useAuth();

	const [popup, setPopup] = useState(null);
	const [donationSuccess, setDonationSuccess] = useState(false);
	const [featuredCampaign, setFeaturedCampaign] = useState(null);

	const [isSigningUp, setIsSigninUp] = useState(false);

	const history = useHistory();

	const defaultArr = ["1", "2", "3"];
	function showPopup(donationAmount, campaignId) {

		setPopup({ donationAmount, campaignId });

	}

	function handlePaymentSuccess() {
		setDonationSuccess(true);
	}

	function handleAuthPopupSwitch() {
		setIsSigninUp(!isSigningUp);
	}

	function getPopup() {

		if (!popup) {
			return <></>;
		}

		if (donationSuccess) {
			return (
				<div className="pop-up-card">
					<DonationDoneCard />
				</div>
			);
		}

		if (auth && auth.access_token) {

			return (
				<div className="pop-up-card">
					<DonationCardDetails
						hidePopup={hidePopup}
						handlePaymentSuccess={handlePaymentSuccess}
						color={props.primaryColor}
						donationAmount={popup.donationAmount}
						campaignId={popup.campaignId}
						primaryColor={props.primaryColor}
					/>
				</div>
			);

		} else {
			return (
				<div className="pop-up-card">
					{
						isSigningUp ? (
							<DonationSignupCard hidePopup={hidePopup} handleAuthPopupSwitch={handleAuthPopupSwitch} primaryColor={props.primaryColor} />
						) : (
							<DonationLoginCard hidePopup={hidePopup} handleAuthPopupSwitch={handleAuthPopupSwitch} primaryColor={props.primaryColor} />
						)

					}
				</div>
			);
		}

	}

	function hidePopup() {

		setPopup(null);

	}

	const isMobile = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	const [campaignBoxLimit, setCampaignBoxLimit] = useState(isMobile ? 4 : 3);

	// const campaigns = [
	// 	{
	// 		id: 1, title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	},
	// 	{
	// 		id: 2, title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	},
	// 	{
	// 		id: 3, title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	},
	// 	{
	// 		id: 4, title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	},
	// 	{
	// 		id: 5, title: "Raise $20,000 to help poor children", impact: "Student will get education on every $20 donation",
	// 		matching: "We will participate as much as you donate", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, in tellus orci sollicitudin interdum diam laoreet dictum aliquam.",
	// 	}
	// ];

	const [onGoingCampaigns, setOnGoingCampaigns] = useState([]);
	const match = useRouteMatch();

	const getOnGoingCampaigns = async () => {
		console.log("tenant Id is",props.tenantId);
		console.log("prrops in ongoing",props);
		const { result, error } = await getUnauthorizedURL("/v1/campaign/?parameter=ON_GOING", { id: null, tenant_id: props.tenantId });
		console.log(result, "Result in backend parser");
		if (error) {
			setOnGoingCampaigns([]);
		}
		else {
			const tempArr = [];
			console.log(result, "Props");

			console.log(result.message, "Props Message");
			
			// eslint-disable-next-line array-callback-return
			result.message.map((campaign) => {

				if (campaign.is_featured && !isMobile) {
					setFeaturedCampaign({
						id: campaign.id,
						heroHeaderImage: props.campaigns.length > 0
							? props.campaigns.find(item => item?.campaign_id === campaign.id)?.campaign_components[0]?.props.heroHeaderImage
							: null,
						// heroHeaderImage: props.OnGoingCampaignImage,
						title: campaign.name,
						impact: campaign.impact.factor,
						matching: `We will participate ${Number(campaign.matching_ratio).toFixed(1).replace(/[.,]0$/, "")}% as much as you donate`,
						description: campaign.charity.name,
						target: campaign.target,
						min_donate: campaign.min_donate,
						donations: campaign.donations
					});
				} else {
					tempArr.push({
						id: campaign.id,
						title: campaign.name,
						heroHeaderImage: props.campaigns.length > 0
							? props.campaigns.find(item => item?.campaign_id === campaign.id)?.campaign_components[0]?.props.heroHeaderImage
							: null,
						// heroHeaderImage: props.OnGoingCampaignImage,
						impact: campaign.impact.factor,
						matching: campaign.match_contribution ? `We will participate ${Number(campaign.matching_ratio).toFixed(1).replace(/[.,]0$/, "")}% as much as you donate` : "Off",
						description: campaign.charity.name,
						target: campaign.target,
						min_donate: campaign.min_donate,
						donations: campaign.donations
					});
					
				
					
					
				}
			});
			setOnGoingCampaigns(tempArr);
		}
	};

	useEffect(() => {
		setCampaignBoxLimit(isMobile ? 4 : 3);
	}, [isMobile]);

	useEffect(() => {
		getOnGoingCampaigns();
	}, [props]);

	useEffect(() => {
	}, [onGoingCampaigns]);

	useEffect(() => {
		console.log(featuredCampaign, "Feature");
	}, [featuredCampaign]);
	if(parseInt(props.templateId)===1){
		return (
			<div id="ongoing-campaign-container-template-1">
				{
					(onGoingCampaigns.length > 0 || featuredCampaign)
						?
						<div className="cs-ongoing-campaign-container-template-1">
							{
								getPopup()
							}
							<span className="cs-hero3" style={{ color: `${props.primaryColor}` }}>{props.OnGoingCampaignsTitle}</span>
							<div className="cs-ongoing-campaign-inner-container ">
								{
									featuredCampaign &&
									<div className="cs-featured-campaign-container">
										<CampaignCard
											componentId={featuredCampaign.id}
											to={`${match.path}campaign/${featuredCampaign.id}`}
											// showPopup={showPopup}
											heroHeaderImage={featuredCampaign.heroHeaderImage}
											campaignCardTitle={featuredCampaign.title}
											campaignCardImpact={featuredCampaign.impact}
											campaignCardMatching={featuredCampaign.matching}
											campaignCardDescription={featuredCampaign.description}
											primaryColor={props.primaryColor}
											isFeatureCampaignCard
											campaignId={featuredCampaign.id}
											minDonate={Number(featuredCampaign.min_donate)}
											target={Number(featuredCampaign.target)}
											donations={featuredCampaign.donations}
											isActive={true}
											templateId={parseInt(props.templateId)}
										/>
									</div>
								}
								<Row className="cs-campaign-container" style={{ justifyContent: (onGoingCampaigns.length <= 2) ? "space-arround" : (onGoingCampaigns.length > 3) ? "space-evenly" : "space-between" }}>
								
									<CarouselComponent data={onGoingCampaigns} templateId={parseInt(props.templateId)} isCampaignCard={true} 
										onGoingCampaigns primaryColor={props.primaryColor} itemsToShow={3}/>
								</Row >
							</div >
	
						</div >
						:
						<Fragment>
							{
								props.isInEditingInProcess &&
								<div className="cs-ongoing-campaign-container-template-1">
									<span className="heading1">Our Causes</span>
									<span className="cs-hero3" style={{ color: `${props.primaryColor}`, marginTop: "25px" }}>{props.OnGoingCampaignsTitle || "On going campaigns"}</span>
	
									<Row className="cs-campaign-container w-100 " style={{ marginTop: "50px", maxWidth: "1244px", justifyContent: "space-between" }}>
										{
											defaultArr.map((_, index) => {
												return (
													<Fragment key={index}>
														{
															(index < campaignBoxLimit) &&
															<Fragment>
																{
																	<Col xs="6" md="4" className="cs-campaign-box-container"
																		style={isMobile ? (index % 2 === 0 ? { padding: "0px 12px 0px 0px" } : { padding: "0px 0px 0px 12px" }) : /*(index !== 0 ? { marginLeft: "70px", padding: "0px" } : */{ padding: "0px", width: "368px", maxWidth: "368px" }}
																	>
																		<VBAddCampaign color={`${props.primaryColor}`} />
																	</Col>
																}
															</Fragment>
														}
													</Fragment>
												);
	
											})
										}
	
									</Row >
								</div>
	
							}
						</Fragment>
				}
			</div>
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<div id="ongoing-campaign-container-template-2">
				{
					(onGoingCampaigns.length > 0 || featuredCampaign)
						?
						<div className="cs-ongoing-campaign-container-template-2">
							{
								getPopup()
							}
							<span className="">Our Causes</span>
							<span className="cs-hero3" style={{ color: `${props.primaryColor}`, marginTop: "6px" }}>{props.OnGoingCampaignsTitle || "Donate to charity Caouses around the world1"}</span>
							<div className="cs-ongoing-campaign-inner-container">
								{
									featuredCampaign &&
									<div className="cs-featured-campaign-container">
										<CampaignCard
											componentId={featuredCampaign.id}
											to={`${match.path}campaign/${featuredCampaign.id}`}
											// showPopup={showPopup}
											heroHeaderImage={featuredCampaign.heroHeaderImage}
											campaignCardTitle={featuredCampaign.title}
											campaignCardImpact={featuredCampaign.impact}
											campaignCardMatching={featuredCampaign.matching}
											campaignCardDescription={featuredCampaign.description}
											primaryColor={props.primaryColor}
											isFeatureCampaignCard
											campaignId={featuredCampaign.id}
											minDonate={Number(featuredCampaign.min_donate)}
											target={Number(featuredCampaign.target)}
											donations={featuredCampaign.donations}
											isActive={true}
											templateId={parseInt(props.templateId)}
										/>
									</div>
								}
								<Row className="cs-campaign-container " style={{ justifyContent:"space-around" }}>
									<CarouselComponent data={onGoingCampaigns} templateId={parseInt(props.templateId)} isCampaignCard={true} 
										onGoingCampaigns primaryColor={props.primaryColor} itemsToShow={3}/>
								</Row >
							</div >
	
						</div >
						:
						<Fragment>
							{
								props.isInEditingInProcess &&
								<div className="cs-ongoing-campaign-container-template-2 ">
									<span className="heading1">Our Causes</span>
									<span className="cs-hero3" style={{ color: `${props.primaryColor}`, marginTop: "25px" }}>{props.OnGoingCampaignsTitle || "On Going Campaigns"}</span>
	
									<Row className="cs-campaign-container w-100 " style={{ marginTop: "50px", justifyContent: "center" }}>
										{
											defaultArr.map((_, index) => {
												return (
													<Fragment key={index}>
														{
															(index < campaignBoxLimit) &&
															<div className="pl-5 pr-5">
																{
																	<Col xs="6" md="4" className="cs-campaign-box-container"
																		style={isMobile ? (index % 2 === 0 ? { padding: "0px 12px 0px 0px" } : { padding: "0px 0px 0px 12px" }) : /*(index !== 0 ? { marginLeft: "70px", padding: "0px" } : */{ padding: "0px", width: "368px", maxWidth: "368px" }}
																	>
																		<VBAddCampaign color={`${props.primaryColor}`} />
																	</Col>
																}
															</div>
														}
													</Fragment>
												);
	
											})
										}
	
									</Row >
								</div>
	
							}
						</Fragment>
				}
			</div>
		);
	}
	else{
		return(
			<>
			</>
		);
	}
	
}