/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ImpactAndObjectivesDetailsCard from "../../../../components/dashboard/mid-page/goals-details/ImpactAndObjectivesDetailsCard";
import Table from "../../../../components/sub-component/Table/Tabel";
import Button from "../../../../components/sub-component/Button";
import { numberFormatter, openURL } from "../../../../utilities/Utilites";
import { useAuth } from "../../../../context/AuthContext";

export default function ImpactAndObjectivesDeatils(props) {
	const params = useParams();
	const auth = useAuth();
	const history = useHistory();
	const [impactInfo, setImpactInfo] = useState({
		impactObjective: null,
		impactFactor: null,
		objective_period: null
	});

	const header = ["Campaign Name", "Impact", "Contributions", "Details"];
	const [list, setList] = useState([]);

	function getTotalImpact(goal) {

		if (!goal) {
			return {};
		}

		const individualImpact = [];
		const factorAmount = goal.charity_impact.factor_amount;

		const campaignImpacts = goal.impact.campaigns.map(campaign => {
			const donations = campaign.donations.reduce((total, donation) => {

				if (
					Date.parse(donation.date) >= Date.parse(goal.start_date) &&
					Date.parse(donation.date) <= Date.parse(goal.end_date)
				) {
					return total + Number(donation.amount);
				} else {
					return total;
				}
			}, 0);

			individualImpact.push([
				"",
				campaign.name,
				`${(donations / factorAmount).toFixed(1).replace(/[.,]0$/, "")} ${goal.impact.suffix}`,
				`$${numberFormatter(donations)}`,
				<Button title="view" key={1} style={{ padding: "5px 28px" }} className="b4" onClick={() => history.push(`/dashboard/campaigns/id=${campaign.id}`)} />
			]);

			return donations / factorAmount;

		});

		const totalImpact = campaignImpacts.reduce((total, campaignImpact) => total + campaignImpact, 0);

		setList(individualImpact);

		return {
			// ImpactObjective: goal.impact.name,
			impact_factor: goal.impact.factor,
			objective_period: `${convertToLongDate(goal.start_date.split("T")[0])} To ${convertToLongDate(goal.end_date.split("T")[0])}`,
			achievedTarget: ((totalImpact / goal.target) * 100).toFixed(1).replace(/[.,]0$/, ""),
			achieved: totalImpact,
			total: goal.target,
			suffix: goal.impact.suffix,
		};

	}
	const getGoleByGoalPeriod = async (id) => {

		const { result, error } = await openURL(`/v1/goal/${id}`, "GET", auth, null);

		if (error) {
			history.push("/error");

		} else if (result) {
			console.log(result.data, "FLAG 1");
			setImpactInfo(getTotalImpact(result.data));

		}
	};


	/**
	 * 
	 * @param {short date "2020-02-23"} date 
	 * @returns long date string "Wed 2, 2020"
	 */
	const convertToLongDate = (date) => {
		var msec = Date.parse(date);
		return new Date(msec).toDateString().split(" ").slice(1).join(" ");
	};

	

	useEffect(() => {
		getGoleByGoalPeriod(params.id);
		props.getSelectedItem(2, "Impact Objective Details", 1, false);
	}, []);



	return (
		<div className="d-flex flex flex-column align-items-center">
			<div className="dashboard-form-wrapper">
				<ImpactAndObjectivesDetailsCard impactInfo={impactInfo} />
				<div className="w-100" style={{ marginTop: "25px" }}>
					<span className="s3">Contributing Campaigns</span>
					<div className="w-100" style={{ marginTop: "7px" }}>
						<Table
							headers={header}
							rows={list}
							alignText="center"
						/>
					</div>
				</div>
			</div>


		</div>

	);
}
