import React from "react";
import Button from "../../components/sub-component/Button";

export default function CurrentPlanCard(props) {
	return (
		<div className="current-plan-card">
			<div className="mobile-padding" style={{ height: "max-content" }}>
				<div className="spacing-plan-card">
					<span className="b4" style={{ color: "white" }}>Subscription Plan</span>
				</div>

				{
					props.processing ? (
						<div
							className="busy-indicator-blue d-flex align-self-center"
							style={{
								borderColor: "#FFF #FFF #FFF transparent",
								height: "25px",
								width: "25px",
								borderRadius: "100%",
								margin: "15px auto"
							}}
						/>


					) : (
						<>
							<div style={{ marginTop: "5px" }}>
								<span className="h3" style={{ color: "white", fontWeight: "800" }}>
									${props.plan}
									<span className="b1" style={{ color: "white", fontWeight: "800" }}>/
										{props.interval === "monthly" ? "mo" : "yr"}
									</span>
								</span>
							</div>
							<div className="mt-1">
								<span className="b3" style={{ color: "white" }}>{props.text}</span>
							</div>
						</>
					)
				}
			</div>
			<div className="d-flex flex-column align-items-center justify-content-center btn-padding">
				<Button
					onClick={props.onChangePlan}
					whiteButton
					title="Change Plan"
					titleStyle={{ color: "#5E7488" }}
					style={{ width: "100%", height: "23px", border: "none", padding: "5px 24px" }}
				/>

				<div className="w-100 d-flex justify-content-center align-items-center" style={{ marginTop: "10px" }}>
					<span className="b4 white-color pointer" style={{ fontSize: "10px", textDecoration: "underline" }} onClick={props.onCancelSubscription}>Cancel Subscription</span>
				</div>
			</div>

		</div>
	);
}