import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
// import img1 from "../../../assets/images/landing/Hero-min.png";
import gloabAnimation from "../../../assets/videos and clips/ver 1.mp4";
// import popups from "../../../assets/images/Landing Page 2/PopUps.svg";
// import Button from "../../../components/sub-component/Button";

export default function Section1() {

	return (
		<div className="section1 ">

			<div className="section1-left">
				<span className="ls-h2 navy-blue-color " style={{ fontWeight: "600", lineHeight: "55px" }}>We help businesses make measurable social impact.</span>
				<span className="ls-h3 hoki-color mt-1" style={{ fontWeight: "600", fontStyle: "italic", fontSize: "36px" }}>Free to start, setup in minutes!</span>
				<span className="b2 hoki-color w-100 section1-right-text" style={{ marginTop: "20px", lineHeight: "23px", fontWeight: "400" }}>
					Altrunic connects you with top rated charities so that you can make social impact with the help of your customers and employees.<br></br><br></br>

					Setup your branded impact website now!</span>
				<div className="d-flex flex-column align-items-center">
					<Link to="/pricing">
						<div className="get-started-button">
							<span className="ls-b0-5 white-color">Start Free Trial</span>
						</div>
					</Link>
					{/* <span style={{ marginTop: "10px" }} className="b3">It’s free forever!</span> */}
				</div>
			</div>
			<div className="section1-right">
				{/* <img src={img1} className="section1-img w-100 h-100" /> */}
				<ReactPlayer
					url={[{ src: gloabAnimation, type: "video/mp4" }]}
					playing={true}
					loop={true}
					muted={true}
					height={"80%"}
					width={"100%"}
					// controls={true}
				/>
				
			</div>
		</div >
	);
}