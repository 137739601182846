import React from "react";
import Navbar from "./Navbar";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import {FaBell} from "react-icons/fa";
// import Section5 from "./Section5";
// import Section6 from "./Section6";
import Footer from "./Footer";
// import CommentSlider from "./CommentSlider";

// import { IntercomProvider, useIntercom, RawDataAttributes } from "react-use-intercom";


export default function LandingPage() {

	return (
		<>
			<div className="m-0" style={{ boxSizing: "borderBox", lineHeight: "60px", backgroundColor: "rgb(0,84,220)", color: "white", textAlign: "center", fontSize: "1.35rem", fontWeight: "bold" }}>
				<p className="m-0"><FaBell/> Welcome to our beta version! Excuse any hiccups as we strive for perfection.</p>
				
			</div>

			<Navbar>
				<div className="d-flex flex-column landing-home-page-typography">

					<div>
						<Section1 />
					</div>
					<div>
						<Section2 />
					</div>
					<div>
						<Section3 />
					</div>
					{/* <div>
				<CommentSlider />
			</div> */}
					{/* <div>
				<Section6 />
			</div> */}
					<div>
						<Section4 />
					</div>
					{/* <div>
				<Section5 />
			</div>

			<div className="d-flex flex-column align-items-center" style={{ textAlign: "center", paddingTop: "75px" }}>
				<span className="s1 heading-text-mobile">Altrunic is free forever</span>
				<span className="b2 price-text" style={{ width: "52%", alignSelf: "center", paddingTop: "15px" }}>We believe that doing social good is not just for large enterprises. Our core offering is free for anyone and will always stay like that.</span>
			</div> */}


					<div>
						<Footer />
					</div>
				</div>
			</Navbar>
		</>
		// </IntercomProvider>
	);

}