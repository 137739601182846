import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

export default function CookiesConsent() {

	useEffect(() => {
		setTimeout(() => {
			document.getElementById("CookieConsentId")?.classList.add("contentClassesContainer");
		}, 100);

	}, []);
	return (
		<div
			id="CookieConsentId"
		>
			<CookieConsent

				location="bottom"
				buttonText="Accept"
				enableDeclineButton
				declineButtonText="Decline"
				cookieName="UserCookieConsent"
				cookieValue={true}
				declineCookieValue={false}
				expires={365}

				contentClasses="contentClasses"
				containerClasses="containerClasses"
				buttonWrapperClasses="buttonWrapperClasses"
				buttonClasses="buttonClasses"
				declineButtonClasses="declineButtonClasses"

				style={{ background: "#0054DC", zIndex: "999",height:"220px" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px", zIndex: "999",position:"absolute",right:"10px",bottom:"10px" }}
				declineButtonStyle={{position:"absolute",right:"150px",bottom:"15px"}}
			>
				<div className="d-flex flex-column" style={{maxWidth:"100vw"}}>
					<span className="s2 white-color" style={{ fontWeight: "800" }}>Cookies and Privacy Policy</span>
					<span className="b3 white-color" style={{ marginTop: "24px" }}>
						<span style={{ fontWeight: "700" }}>Notice.</span> Altrunic.com uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our
						<Link to="/privacy_policy" target="_blank" style={{ textDecoration: "underline" }} className="b3 white-color ml-1">Privacy Policy</Link> and our Cookies usage.
					</span>
				</div>
			</CookieConsent>
		</div>
	);
}
