import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
// import { openURL } from "../utilities/Utilites";
import * as rudderanalytics from "rudder-sdk-js";
import { useHistory, useRouteMatch } from "react-router-dom";


export default function RudderStackWrapper({ children }) {

	const auth = useAuth();
	const match = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		rudderanalytics.ready(() => { console.log("we are all set!!!"); });
		rudderanalytics.load(window._env_.REACT_APP_RUDDERSTACK_WRITEKEY, window._env_.REACT_APP_RUDDERSTACK_DATA_PLANE_URL);
	}, []);

	useEffect(async () => {

		// console.log(match.path, "--------------", history.location.pathname);

		if (auth.access_token) {
			rudderanalytics.identify(
				auth.user_id
			);

		}
		else {
			rudderanalytics.page();
		}



	}, [auth.access_token, match.path, history.location.pathname]);




	return (
		<>
			{children}
		</>
	);
}
