import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Button from "../components/sub-components/Button";
import google from "../assets/icons/charity/google_icon.svg";
import microsoftIcon from "../assets/icons/microsoft.png";
import cross from "../assets/icons/basic/cross.svg";
import { useAuth } from "../context/AuthContext";
import baseURL from "../utilities/BaseURL";
import { setCookie } from "../utilities/Utilites";
import { Link } from "react-router-dom";
import AlertBox from "./sub-components/AlertBox";
import { useDonation } from "../context/DonationContext";

export default function DonationLoginCard(props) {

	const auth = useAuth();
	const donation = useDonation();

	const [credentials, setCredentials] = useState({
		email: null,
		password: null,
	});

	const [alert, setAlert] = useState();
	const [processing, setProcessing] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const [toggleModal,setToggleModal] = useState(true)


	function handleInput(event) {

		setCredentials({ ...credentials, [event.target.name]: event.target.value });

	}

	function closeModal(event) {

		
		setToggleModal(false)

	}

	async function handleSubmit() {

		// event.preventDefault();

		setProcessing(true);

		const response = await fetch(`${baseURL}/v1/auth/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json"
			},
			body: JSON.stringify(credentials)
		});

		setProcessing(false);

		const data = await response.json();

		if (!response.ok) {
			setAlert(
				<AlertBox color="danger" title="Error" description={data.message} />
			);
			return;
		}


		setCookie("token", data.refresh_token, 7);

		auth.handleAuth(data);

		if (props.loginSuccess) {
			props.loginSuccess();
		}

	}

	function googleOAuth() {
		window.location.replace(`${baseURL}/v1/oauth/google`);
	}

	function azureOAuth() {
		window.location.replace(`${baseURL}/v1/oauth/azure`);
	}

	return (
		<>{toggleModal &&
		<div className="donation-login-card border">
			<img src={cross} className="cross-sign" onClick={donation.clearDonation} alt="cros" />
			<span className="s2">Donation</span>
			<hr className="w-100" style={{ border: "1px solid #CED5DB" }} />

			<div className="login-fields px-4" onKeyPress={(event) => (event.key === "Enter") && handleSubmit()}>

				<span className="s2">Login</span>

				<input className="b3 hoki-color" type="text" placeholder="Email" style={{ border: "none", borderBottom: "3px solid #5E7488", padding: "8px", marginTop: "10px" }} name="email" onChange={handleInput} />

				<div className="position-relative">
					<input 
						className="b3 hoki-color w-100" 
						type={showPassword ? "text" : "password"}
						placeholder="Password" 
						style={{ border: "none", borderBottom: "3px solid #5E7488", padding: "8px", marginTop: "10px" }} 
						name="password" 
						onChange={handleInput}
					/>
					{showPassword ? 
						<FaRegEye style={{ position: "absolute", right: "8px", top: "20px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
						:
						<FaRegEyeSlash style={{ position: "absolute", right: "8px", top: "20px" }} onClick={() => setShowPassword(prev => !prev)} size={20} />
					}
				</div>

				<div className="d-flex align-items-center" style={{ padding: "10px 0 50px 0" }}>
					<input type="checkbox" className="remember-check" />
					<span className="b3" style={{ paddingLeft: "10px", color: "#2A3440" }}>Remember me</span>
					<span className="b3 d-flex ml-auto"><Link onClick={closeModal} to="/forgotpassword">Forgot Password?</Link></span>
				</div>

				<div className="d-flex align-items-center flex-column">
					<div style={{ width: "220px", height: "35px" }}>
						<Button title="Login" className="d-flex" onClick={handleSubmit} busy={processing} style={{ backgroundColor: props.primaryColor, width: "100%", height: "100%" }} />
					</div>
					<span style={{ paddingTop: "10px" }}>
						<span>Don’t have an account?</span>
						<span onClick={() => donation.setIsSigningUp(true)} style={{ color: "#0086ff", marginLeft: "10px", cursor: "pointer" }} >Sign Up</span>
					</span>
				</div>

				<div className="d-flex align-items-center justify-content-between" style={{ padding: "30px 0 30px 0" }}>
					<hr style={{ border: "2px solid #CED5DB", width: "30%" }} />
					<div className="b3 d-flex align-items-center licocrice-color">Login using</div>
					<hr style={{ border: "2px solid #CED5DB", width: "30%" }} />
				</div>

				<div className="d-flex justify-content-center">
					<img src={google} style={{ cursor: "pointer" }} alt="google" onClick={googleOAuth} />
					<img src={microsoftIcon} style={{ cursor: "pointer", width: "30px", marginLeft: "20px" }} alt="google" onClick={azureOAuth} />
				</div>

				<div style={{ position: "relative" }}>
					<div style={{ position: "absolute", width: "100%" }}>
						{alert}
					</div>
				</div>

			</div>



		</div>
		}</>
	);
}