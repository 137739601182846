import React, { useEffect, useMemo, useState } from "react";
import { Fragment } from "react";
import bg from "../assets/images/Rectangle 195.png";
import { getUnauthorizedURL } from "../utilities/Utilites";

export default function ImpactAndObjectives(props) {

	const [campaigns, setCampaigns] = useState();


	// eslint-disable-next-line no-unused-vars
	// const [impactAndStats, setImpactAndStats] = useState([
	// 	{ value: props.impactTitle || "We are on a mission to help people around the globe", label: null },
	// 	{ value: 0, label: "Running Campaigns" },
	// 	{ value: 0, label: "Funds Raised" },
	// 	{ value: 0, label: "Volunteers Participated" }
	// ]);

	const stats = useMemo(() => {

		if (!campaigns || campaigns.length <= 0) {
			return { active_campaigns: 0, total_donations: 0, volunteers: 0 };
		}

		const participants = [];

		const individualDonations = campaigns.map(campaign => {
			return campaign.donations.reduce((total, donation) => {
				if (!participants.includes(donation.doner_email)) {
					participants.push(donation.doner_email);
				}
				return total + Number(donation.amount);
			}, 0);
		});

		const activeCampaigns = campaigns.filter(campaign => campaign.is_active).length;

		const totalDonations = individualDonations.reduce((total, amount) => total + amount, 0);

		return { active_campaigns: activeCampaigns, total_donations: totalDonations, volunteers: participants.length };

	}, [campaigns]);


	useEffect(() => {

		(async () => {
			const { result } = await getUnauthorizedURL("/v1/campaign/", { id: null, tenant_id: props.tenantId });

			if (result) {

				setCampaigns(result.message);

			}
		})();


	}, [props.tenantId]);
	if(parseInt(props.templateId)===1){
		return (
			<div className="impact-and-objective-container-template-1" id="impact-and-objective-container-template-1" style={{ margin: "25px 0px", backgroundImage: `url("${props.impactBackGroundImage || bg}")` }}>
				<div className="impact-image-layer" style={{ backgroundColor: `${props.primaryColor || "#4267B2"}` }}></div>
	
	
				<div className="impact-stat-title">
	
					<span className="white-color heading1" style={{  marginBottom: "0px" }} > {props.impactTitle}</span>
	
				</div>
	
				<div className="impact-stat">
	
					<Fragment>
						<span className="white-color cs-hero2"
							style={{ textAlign: "left", marginBottom: "0px" }}>{stats.active_campaigns}</span>
						<span className="cs-p1 white-color">Campaigns</span>
					</Fragment>
	
				</div>
	
				<div className="impact-stat">
	
					<Fragment>
						<span className="white-color cs-hero2"
							style={{ textAlign: "left", marginBottom: "0px" }}>${stats.total_donations.toLocaleString()}</span>
						<span className="cs-p1 white-color">Funds Raised</span>
					</Fragment>
	
				</div>
	
				<div className="impact-stat">
	
					<Fragment>
						<span className="white-color cs-hero2"
							style={{ textAlign: "left", marginBottom: "0px" }}>{stats.volunteers}</span>
						<span className="cs-p1 white-color">Donors</span>
					</Fragment>
	
				</div>
	
			</div>
		);
	}
	else if(parseInt(props.templateId)===2){
		return (
			<div className="impact-and-objective-container-template-2" id="impact-and-objective-container-template-2">
				<div className="campaigns">
					<div className="background" style={{backgroundColor:props.primaryColor}}>
					</div>
					<div className="content" style={{color:props.primaryColor}}>
						<div className="dynamic">{stats.active_campaigns}</div>
						<div className="static">Campaigns</div>
					</div>
				</div>
				<div className="campaigns">
					<div className="background" style={{backgroundColor:props.primaryColor}}>
					</div>
					<div className="content" style={{color:props.primaryColor}}>
						<div className="dynamic">${stats.total_donations.toLocaleString()}</div>
						<div className="static">Funds Raised</div>
					</div>
				</div>
				<div className="campaigns">
					<div className="background" style={{backgroundColor:props.primaryColor}}>
					</div>
					<div className="content" style={{color:props.primaryColor}}>
						<div className="dynamic">{stats.volunteers}</div>
						<div className="static">Donors</div>
					</div>
				</div>
	
			</div>
		);
	}
	else{
		return(
			<>
			</>
		);
	}
	
}