import React from "react";

export default function Tag(props) {
	return (
		<div style={{ width: "fit-content", padding: "3px 10px", borderRadius: "50px", ...props.style }}
			onClick={props.onClick}
			className="d-flex justify-content-center align-items-center navy-blue-bg " >
			<div className="d-flex justify-content-center align-items-center">
				<span className={"button-text-sm " + ` ${props.className}`}>{props.title || "Next"}</span>
				<span className={"roboto-b2 white-color pointer"} style={{ marginLeft: "5px" }}>X</span>
			</div>

		</div>
	);
}
