import React from "react";
import logo from "../../assets/icons/logo_sm.svg";
import Button from "../../components/sub-component/Button";
import image from "../../assets/images/mechanic.svg";
import { Link } from "react-router-dom";
import altrunc_logo from "../../assets/icons/logo.png";


export default function ErrorPage() {
	return(
		<div className="d-flex flex-column" style={{position:"relative",height:"100vh"}}>
			<div className="d-flex flex-row align-self-center" style={{width:"90%",height:"",paddingTop:"12px",paddingBottom:"12px"}}>
				<Link to="/"><img className="message-card-mobile" src={logo} /></Link>
			</div>
			<div className="message-card-full" style={{justifyContent:"",paddingTop:""}}>

				<div className="message-card-logo-mobile" style={{marginBottom:""}}>

					<img src={altrunc_logo} />

				</div>

				<span className="h1 navy-blue-color error-text" style={{fontSize:"120px",fontWeight:"800"}}>404</span>
				<span className="h2 error-description" style={{zIndex:"99999"}}>Oops! We can’t find the page you’re looking for.</span>
				<Link to="/"><Button title="Go To Homepage" style={{width:"243px",marginTop:"100px",zIndex:"9999"}} /></Link>
				<img className="error-image-mobile" src={image} style={{position:"absolute",right:"50px",height:"45%",bottom:"0px"}} />

			</div>

		</div>
	);}