import React from "react";
import { Row, Col } from "react-bootstrap";
import campaign from "../../../assets/icons/landing page 2/Campaign.svg";
import funds from "../../../assets/icons/landing page 2/Funds.svg";
import volunteers from "../../../assets/icons/landing page 2/Volunteers.svg";
import brand_logos from "../../../assets/icons/landing page 2/Logos.svg";
import imapctIcon from "../../../assets/icons/landing page 2/Impact.svg";
import CountUp from "react-countup";

export default function Section3() {
	return (
		<div>
			<div className="section3 cocntainer-fluid">

				<span className="ls-h4 licorice-color" style={{ paddingTop: "50px", lineHeight: "30px", width: "90%" }}>Helping You in Helping Others</span>

				<div className="social-info ">
					<Row className="d-flex justify-content-between p-0" style={{ width: "100%" }}>
						<Col className="p-0 d-flex justify-content-center" lg="3">
							<div className="social-card ">
								<img src={campaign} />
								<CountUp className="ls-h4 licorice-color " start={0} end={1250} duration={4} suffix="+" style={{ paddingTop: "20px" }} />
								<span className="ls-b1 licorice-color" style={{ paddingTop: "20px" }}>Campaigns</span>
							</div>
						</Col>
						<Col className="p-0 d-flex justify-content-center" lg="3">
							<div className="social-card ">
								<img src={imapctIcon} style={{ width: "101px", height: "80px" }} />
								<CountUp className="ls-h4 licorice-color" start={0} end={25} decimal="," decimals={3} duration={4} suffix="+" style={{ paddingTop: "20px" }} />
								<span className="ls-b1 licorice-color" style={{ paddingTop: "20px" }}>Measurable Impact Goals</span>
							</div>
						</Col>
						<Col className="p-0 d-flex justify-content-center" lg="3">
							<div className="social-card ">
								<img src={funds} style={{ width: "101px", height: "80px" }} />
								<CountUp className="ls-h4 licorice-color" start={0} end={750} decimal="," decimals={3} duration={4} prefix="$" suffix="+" style={{ paddingTop: "20px" }} />
								<span className="ls-b1 licorice-color" style={{ paddingTop: "20px" }}>Funds Raised</span>
							</div>
						</Col>
						<Col className="p-0 d-flex justify-content-center" lg="3">
							<div className="social-card ">
								<img className="" src={volunteers} />
								<CountUp className="ls-h4 licorice-color" start={0} end={300} duration={4} suffix="k+" style={{ paddingTop: "20px", width: "100%" }} />
								<span className="ls-b1 licorice-color" style={{ paddingTop: "20px", width: "250px" }}>Donors</span>
							</div>
						</Col>
					</Row>
				</div>
				<div className="brand-logos">
					<img src={brand_logos} style={{ width: "100%" }} />
				</div>
			</div>
		</div>
	);
}