import React from "react";

import { ReactComponent as AddIcon } from "../../../assets/icons/visual-builder/add.svg";
export default function VBAddComponent(props) {
	return (
		<div className="d-flex w-100 align-items-center justify-content-between pointer unselected-row" style={{ ...props.style }} onClick={props.onClick}>
			<div>
				{props.icon}
				<span style={{ marginLeft: "12px" }} className="b4">{props.title || "Title NotFound"}</span>
			</div>
			<AddIcon />

		</div>
	);
}
