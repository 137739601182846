import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Pagination } from "react-bootstrap";

import { openURL } from "../../../../utilities/Utilites";
import { useAuth } from "../../../../context/AuthContext";

export default function Audit() {

	const auth = useAuth();
	const [donations, setDonations] = useState({
		from: 0,
		size: 0,
		total_count: 0,
		results: [],
		with_pagination: ""
	});

	const [currentPage, setCurrentPage] = useState(1);
  
	const headers = ["S.No.", "Donor Name", "Donor Email", "Donor Type", "Campaign", "Amount", "Date"];

	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const exportToCSV = async () => {
		const { result } = await openURL("/v1/donations/?with_campaign=true", "GET", auth);
		const donationData = result.message.results.map(item => ({
			"Donor Name": item.doner_name || "N/A",
			"Donor Email": item.doner_email || "N/A",
			"Donor Type": item.doner_type || "N/A",
			"Campaign": item.campaign.name || "N/A",
			"Amount": Number(item.amount).toLocaleString() || "N/A",
			"Date": item.date.split("T")[0] || "N/A",
		}));

		const ws = XLSX.utils.json_to_sheet(donationData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, "altrunic_donations.xlsx");
	};

	function sortData(headerIndex) {

		if (headerIndex === 0) {

			const d = donations.sort((a, b) => {
				if (a.doner_name.toUpperCase() < b.doner_name.toUpperCase()) {
					return -1;
				}
				if (a.doner_name.toUpperCase() > b.doner_name.toUpperCase()) {
					return 1;
				}

				return 0;

			});
			setDonations([...d]);
		}

		if (headerIndex === 1) {

			const d = donations.sort((a, b) => {
				if (a.doner_email.toUpperCase() < b.doner_email.toUpperCase()) {
					return -1;
				}
				if (a.doner_email.toUpperCase() > b.doner_email.toUpperCase()) {
					return 1;
				}

				return 0;

			});
			setDonations([...d]);
		}

		if (headerIndex === 2) {

			const d = donations.sort((a, b) => {
				if (a.doner_type.toUpperCase() < b.doner_type.toUpperCase()) {
					return -1;
				}
				if (a.doner_type.toUpperCase() > b.doner_type.toUpperCase()) {
					return 1;
				}

				return 0;

			});
			setDonations([...d]);
		}

		if (headerIndex === 3) {

			const d = donations.sort((a, b) => {
				if (a.campaign.name.toUpperCase() < b.campaign.name.toUpperCase()) {
					return -1;
				}
				if (a.campaign.name.toUpperCase() > b.campaign.name.toUpperCase()) {
					return 1;
				}

				return 0;

			});
			setDonations([...d]);
		}

		if (headerIndex === 4) {

			const d = donations.sort((a, b) => {
				if (a.amount < b.amount) {
					return -1;
				}
				if (a.amount > b.amount) {
					return 1;
				}

				return 0;

			});
			setDonations([...d]);
		}

		if (headerIndex === 5) {

			const d = donations.sort((a, b) => {
				if (Date.parse(a.date) < Date.parse(b.date)) {
					return -1;
				}
				if (Date.parse(a.date) > Date.parse(b.date)) {
					return 1;
				}

				return 0;

			});
			setDonations([...d]);
		}

	}

	const prevPage = () => {
		if(currentPage === 1)
			return;

		setCurrentPage(prev => prev - 1);
	};
	
	const nextPage = () => {
		const { size, total_count } = donations;
		if(currentPage === Math.ceil(total_count / size))
			return;
		
		setCurrentPage(prev => prev + 1);
	};

	const pages = () => {
		const items = [];
		const { size, total_count } = donations;
		for (let index = 0; index < Math.ceil(total_count / size); index++) {
			items.push(
				<Pagination.Item key={index} active={index + 1 === currentPage}>
					{index + 1}
				</Pagination.Item>
			);
		}
		return items;
	};

	useEffect(async () => {
		const { result } = await openURL(`/v1/donations/?with_campaign=true&with_pagination=true&page=${currentPage}&limit=20`, "GET", auth);
		console.log("result of donation", result);
		if (result) {
			setDonations(result.message);
		}
	}, [currentPage]);

	return (
		<div>
			<div style={{ marginTop: "25px" }} >
				<div><button className="btn btn-primary" onClick={exportToCSV} >Export As Excel File  <FaDownload style={{ paddingLeft: "3px" }} /></button></div>
				<div style={{ overflow: "auto", padding: "10px" }} >

					<div className="d-flex flex-column justify-content-between form-table">

						<div style={{ marginTop: "15px" }} >

							<div className="d-flex flex-row justify-content-between align-items-center table-header pt-3 pb-3">
								{
									headers.map((header, index) => {
										return (
											<div
												key={index}
												className="b4 pointer"
												onClick={() => sortData(index)}
												style={{ width: "calc((100% / 6) - 5px)", textAlign: "center" }}>{header}</div>
										);
									})
								}
							</div>

							<div className="table-body" >
								{
									donations?.results && donations.results.map((donation, index) => {
										return (
											<div className="d-flex flex-row justify-content-between table-row pt-2 pb-2" key={index}>
												<div className="d-flex align-self-center justify-content-center p-1 b4" style={{ wordBreak: "break-word", width: "calc((100% / 6)  - 5px)", textAlign: "center" }}>
													{donations?.from + index + 1 ?? "N/A"}
												</div>
												<div className="d-flex align-self-center justify-content-center p-1 b4" style={{ wordBreak: "break-word", width: "calc((100% / 6)  - 5px)", textAlign: "center" }}>
													{donation.doner_name || "N/A"}
												</div>
												<div className="d-flex align-self-center justify-content-center p-1 b4" style={{ wordBreak: "break-word", width: "calc((100% / 6)  - 5px)", textAlign: "center" }}>
													{donation.doner_email || "N/A"}
												</div>
												<div className="d-flex align-self-center justify-content-center p-1 b4" style={{ wordBreak: "break-word", width: "calc((100% / 6)  - 5px)", textAlign: "center" }}>
													{donation.doner_type || "N/A"}
												</div>
												<div className="d-flex align-self-center justify-content-center p-1 b4" style={{ wordBreak: "break-word", width: "calc((100% / 6)  - 5px)", textAlign: "center" }}>
													<Link to={`/dashboard/campaigns/id=${donation.campaign.id}`}>{donation.campaign.name || "N/A"} </Link>
												</div>
												<div className="d-flex align-self-center justify-content-center p-1 b4" style={{ wordBreak: "break-word", width: "calc((100% / 6)  - 5px)", textAlign: "center" }}>
													{"$" + Number(donation.amount).toLocaleString() || "N/A" }
												</div>
												<div className="d-flex align-self-center justify-content-center p-1 b4" style={{ wordBreak: "break-word", width: "calc((100% / 6)  - 5px)", textAlign: "center" }}>
													{new Date(donation.date).toLocaleDateString() || "N/A"}
												</div>

											</div>
										);
									})
								}
							</div>
							<div className="my-4">
								<Pagination>
									<Pagination.Prev onClick={prevPage} disabled={currentPage === 1}/>
									{pages()}
									<Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(donations?.total_count / donations?.size)}/>
								</Pagination>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}
