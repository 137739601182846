import React, { useState, useEffect } from "react";
import Input from "../Input";
import { ReactComponent as Add } from "../../../assets/icons/basic/plus.svg";
import { ReactComponent as Tick } from "../../../assets/icons/basic/check-mark.svg";
import { Fragment } from "react";
import Dropdown from "./Dropdown";

export default function AddRow(props) {
	const [row, setRow] = useState([]);

	const handleValue = (event, index, action, inputType) => {

		var dummyRow = row;
		if (dummyRow === []) {
			dummyRow.push(event.target.value);
			// setstate(event.target.value);
			// console.log("123321");

		}
		else {
			if (inputType === "select") {
				dummyRow[index] = event;
				// console.log("here");
			}
			else {
				if (dummyRow[index] === undefined) {
					// console.log("hererere");
					dummyRow[index] = "" + event.target.value;

				}
				else {
					if (action === 2) {
						// console.log("123321");
						if (row.length > 0) {
							document.getElementById(`index${index}`).value = event.target.value;
							dummyRow[index] = document.getElementById(`index${index}`).value;
						}
						// console.log("edited ho gya")
					}
					else {
						dummyRow[index] = event.target.value;
					}

				}
			}
		}
		setRow(dummyRow);

	};

	const addRow = () => {
		if (row.length > 0) {
			if (props.nonEditableColumns.length > 0) {
				var TempRow = [];
				var nonEditableArrayIndex = 0;
				var rowIndex = 0;

				Array.from({ length: props.rowLength }).map((_, index) => {
					// console.log(props.nonEditableColumns[nonEditableArrayIndex].col, index + 1);
					if (index + 1 === props.nonEditableColumns[nonEditableArrayIndex].col + 1) {
						TempRow.push(props.nonEditableColumns[nonEditableArrayIndex].item);
						if (nonEditableArrayIndex < props.nonEditableColumns.length - 1) {
							nonEditableArrayIndex += 1;
						}
						//
					}
					else {
						TempRow.push(row[rowIndex]);
						rowIndex += 1;
					}
				});
				props.updateTable(0, TempRow);
				// console.log(TempRow, "New Row");
			}
			else {
				// console.log(row, "Row");
				props.updateTable(0, row);
			}


			Array.from({ length: row.length }).map((_, index) => {
				if (index > 0) {
					if (document.getElementById(`index${index}`).value) {
						document.getElementById(`index${index}`).value = "";
					}
					else {
						// console.log(document.getElementById(`index${index}`));
						document.getElementById(`index${index}`).innerHTML = "";
						// console.log(document.getElementById(`index${index}`));
					}
				}
			});
			setRow([]);
		}



	};

	useEffect(() => {
		if (props.rowData !== undefined) {

			setRow(props.rowData);
			console.log(props.rowData, "Row Data");
			// console.log(props.rowData, "rowww");
			// console.log(row, "rowww");
			Array.from({ length: props.rowData.length }).map((_, index) => {
				// console.log(props.rowData[index]);
				// console.log(props.rowData[index]);
				if (index > 0) {
					// console.log(props.rowData[index], "rowww");
					if (props.rowData[index] instanceof Object) {
						// console.log(document.getElementById(`index${index}`));
						document.getElementById(`index${index}`).innerHTML = props.rowData[index].option;
					}
					else {
						document.getElementById(`index${index}`).value = props.rowData[index];
						document.getElementById(`index${index}`).innerHTML = props.rowData[index];
					}
				}

			});

		}
	}, [props.rowData]);

	useEffect(() => {
		// console.log(row);
	}, [row]);

	useEffect(() => {

	}, [props.columnsWidth]);


	return (
		<div className="outer-row w-100">
			<div className="add-new-row w-100">
				{
					Array.from({ length: props.rowLength + 1 }).map((_, index) => {
						// { console.log(props.columnsWidth, index, props.columnsWidth.find(item => item.col === index + 1)); }
						return (
							<Fragment key={index}>
								{
									index > 0 &&

									<div className={"row-cell d-flex " + `${`justify-content-${props.alignText ? props.alignText : `${index === 1 ? "start " : index === (props.rowLength) ? "end " : "center "} `}`}`}

										style={props.columnsWidth ? ((props.columnsWidth.find(item => item.col + 1 === index + 1)) ? { width: `${props.columnsWidth[index - 1].width}` } : { width: `${100 / (props.rowLength - 1)}%` }) : { width: `${100 / (props.rowLength - 1)}%` }}
									>
										{
											(props.rowLength) !== index
												?
												<Fragment>
													{
														(props.activeSelectInput && props.activeSelectInput !== null) ?
															<Fragment>
																{
																	props.activeSelectInput.find(select => select.col === index)
																		?
																		<div className={`w-100 d-flex + ${`justify-content-${props.alignText ? props.alignText : "center "}`}`} >
																			{/* <Input id={`index${index}`} className={`b4 d-flex ${!props.alignText && `${index === 1 && " row-margin-left "}`}`} tableInput onChange={e => handleValue(e, index, props.action)} /> */}
																			<Dropdown
																				id={`index${index}`}
																				className="tabel-dropdown"
																				options={props.activeSelectInput.find(select => select.col === index).data}
																				onSelect={e => { handleValue(e, index, props.action, "select"); }}
																			/>
																		</div>
																		:
																		props.stepperInput && props.stepperInput.find(select => select.col === index) ?
																			<Input id={`index${index}`} className={`b4 d-flex text-center ${!props.alignText && `${index === 1 && "  "}`}`} tableNumberInput onChange={e => handleValue(e, index, props.action, "input")} placeholder="Enter here" min="0" />
																			:
																			<Fragment>
																				{
																					props.nonEditableColumns
																						?
																						props.nonEditableColumns.find(select => select.col === index)
																						&&
																						<div id={`index${index}`} className="d-none"></div>
																						:
																						<Input id={`index${index}`} className={`b4 d-flex text-center ${!props.alignText && `${index === 1 && ""}`}`} tableInput onChange={e => handleValue(e, index, props.action, "input")} placeholder="Enter here" />
																				}
																			</Fragment>
																}
															</Fragment>
															:
															<Fragment>
																{
																	props.nonEditableColumns && props.nonEditableColumns.find(select => select.col === index)
																		?
																		<div>here</div>
																		:
																		<Input id={`index${index}`} className={`b4 d-flex ${!props.alignText && `${index === 1 && "  "}`}`} tableInput onChange={e => handleValue(e, index, props.action, "input")} />

																}
															</Fragment>
													}

												</Fragment>
												:
												<Fragment>
													{
														props.action === 2
															?
															<div onClick={props.onClick} className={`d-flex ${!props.alignText && " row-margin-right "}`}>
																<Tick className="table-icons pointer" />
															</div>
															:
															<div onClick={() => addRow()} className={`d-flex ${!props.alignText && " row-margin-right "}`}>
																<Add className="table-icons pointer" />
															</div>
													}
												</Fragment>
										}

									</div>
								}
							</Fragment>
						);
					})
				}
			</div>
		</div >
	);
}
