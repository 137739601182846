/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import CurrentPlanCard from "../../../../components/Settings/CurrentPlanCard";
import BillingDetailsCard from "../../../../components/Settings/BillingDetailsCard";
import Table from "../../../../components/sub-component/Table/Tabel";
import Status from "../../../../components/sub-component/Status";
import SubscriptionPlanChangeFlow from "../../../../components/subscription/SubscriptionPlanChangeFlow";
// import { Packages } from "../../../../utilities/Packages";
import { useAuth } from "../../../../context/AuthContext";
import Modal from "../../../../components/common/Modal";
import Select from "../../../../components/sub-component/Select";
import AlertBox from "../../../../components/common/AlertBox";
import { openURL } from "../../../../utilities/Utilites";
import StripeCard from "../../../../components/subscription/StripeCard";
import Button from "../../../../components/sub-component/Button";
import { ReactComponent as DownloadReciptIcon } from "../../../../assets/icons/basic/feather_download.svg";
const billingTableHeader = ["Invoice ID", "Date & Time", "Status", "Amount Paid", "Receipts"];



const reasonsToLeave = [
	"Altrunic didn’t work for my company",
	"I don’t like the pricing or fees",
	"The software doesn’t perform well",
	"We are not using the platform actively",
	"Other reasons"
];

export default function BillingSettings() {
	const auth = useAuth();

	const [isOnChangePlanModalOpen, setIsOnChangePlanModalOpen] = useState(false);
	const [alert, setAlert] = useState(null);
	const [invoiceHistoryRows, setInvoiceHistoryRows] = useState([]);

	const onCloseModal = () => {
		setIsOnChangePlanModalOpen(false);
	};

	const [customerPaymentMethod, setCustomerPaymentMethod] = useState({
		name: "",
		address: "",
		phone: "",
		expiry_date: "",
		last_digits: ""
	});

	const [upcomingInvoice, setUpcompingInvoice] = useState({
		processing: false,
		amount: "",
		package_interval: "",
		package_name: "",
	});

	const [plan, setPlan] = useState(auth.subscription.subscription_package);

	const onPlanChanged = (planChanged) => {
		setPlan(planChanged.selectedPackage);
	};

	const [cencelSubscription, setCencelSubscription] = useState({
		isConfirmationModalOpen: false,
		isSubmitModalOpen: false
	});

	const [isUpdateCardModaOpen, setIsUpdateCardModaOpen] = useState(false);


	const [reasonToLeave, setReasonToLeave] = useState("");
	const [confirmBtnBusyStatus, setConfirmBtnBusyStatus] = useState(false);

	const subscriptionCancelation = async () => {
		setConfirmBtnBusyStatus(true);
		if (reasonToLeave === "") {
			setAlert(
				<AlertBox color="danger" title="Error" description={"reason is not selected"} />
			);
			return;
		}

		const { result, error } = await openURL(`/v1/subscriptions/${auth.subscription.id}`, "DELETE", auth);

		if (error) {
			setAlert(
				<AlertBox color="danger" title="Error" description={error.message} />
			);
		} else if (result) {

			setAlert(
				<AlertBox color="success" title="Success" description={"Subscription Canceled"} />
			);
			setCencelSubscription({ isConfirmationModalOpen: false, isSubmitModalOpen: false });
			setAlert(null);
			window.location.replace("/");
		}
		setConfirmBtnBusyStatus(false);


	};

	async function getCustomerInvoices() {

		const { result, error } = await openURL(`/v1/stripe_customers/customer_invoices`, "GET", auth);

		if (result) {
			const tempArr = [];
			result.data.data.map((item, index) => {
				return (
					tempArr.push(
						[
							index, index + 1, timeConverter(item.created),
							<Status key={index} isSuccess={item.status === "paid" ? true : false} isFail={item.status !== "paid" ? true : false} status={item.status} />,
							`$${item.amount_paid / 100}`,
							<Button key={index} isHollow title="Download"
								leftIcon={<DownloadReciptIcon className="mr-1" />}
								style={{ padding: "6px" }}
								fontStyle="b4"
								onClick={() => { window.open(item.hosted_invoice_url, "_blank"); }} />
						]
					)
				);
			});
			setInvoiceHistoryRows(tempArr);
		}

	}

	async function getCustomerCardInfo() {

		const { result } = await openURL("/v1/stripe_customers/list_payment_methods/", "GET", auth);

		if (result) {

			console.log(result.data.methods.data[0].billing_details);
			setCustomerPaymentMethod({
				name: result.data.methods.data[0].billing_details.name,
				// address: result.data.methods.data[0].billing_details.address
				phone: result.data.methods.data[0].billing_details.phone,
				expiry_date: `${result.data.methods.data[0].card.exp_month}/${result.data.methods.data[0].card.exp_year}`,
				last_digits: result.data.methods.data[0].card.last4
			});

		}

	}

	async function getUpcomingInvoice () {

		setUpcompingInvoice({...upcomingInvoice, processing: true});
		
		const {result} = await openURL("/v1/stripe_customers/upcoming_invoice", "GET", auth);

		setUpcompingInvoice({...upcomingInvoice, processing: false});

		if (result) {
			setUpcompingInvoice({
				amount: result.data.amount_due / 100,
				package_interval: result.data.lines.data[0].price.metadata.package_payment_interval,
				package_name: result.data.lines.data[0].price.metadata.package_name,
			});
		}

	}

	useEffect(async () => {
		getCustomerInvoices();
		getCustomerCardInfo();
		getUpcomingInvoice();
	}, [isUpdateCardModaOpen]);

	function timeConverter(UNIX_timestamp) {
		return new Date(UNIX_timestamp * 1000).toLocaleString();
	}

	return (
		<div className="w-100 d-flex flex-column align-items-center" style={{ marginTop: "25px" }}>
			<div className="responsive-screen">
				<CurrentPlanCard
					processing={upcomingInvoice.processing}
					plan={upcomingInvoice.amount}
					text={upcomingInvoice.package_name}
					interval={upcomingInvoice.package_interval}
					onChangePlan={() => { setIsOnChangePlanModalOpen(true); }}
					onCancelSubscription={() => { setCencelSubscription({ ...cencelSubscription, isConfirmationModalOpen: true }); }}
				/>


				<BillingDetailsCard
					name={customerPaymentMethod.name}
					address={customerPaymentMethod.address}
					phone={customerPaymentMethod.phone}
					expiryDate={customerPaymentMethod.expiry_date}
					lastDigits={customerPaymentMethod.last_digits}
					plan={upcomingInvoice.amount}
					onUpdateCard={() => setIsUpdateCardModaOpen(true)}
				/>

			</div>
			<div className="w-100" style={{ marginTop: "25px", display: "flex", flexDirection: "column", maxWidth: "1000px" }}>
				<span className="b3" >Payment History</span>
				<div style={{ marginTop: "15px" }} className="d-flex flex-column align-items-center">
					<Table
						isEditable={false}
						headers={billingTableHeader}
						rows={invoiceHistoryRows}
						style={{ marginTop: "15px" }}
						alignText="center"
					/>
				</div>
			</div>

			<SubscriptionPlanChangeFlow
				selectedUserPlan={plan}
				isOnChangePlanModalOpen={isOnChangePlanModalOpen}
				onCloseModal={onCloseModal}
				onPlanChanged={onPlanChanged} />

			{
				cencelSubscription.isConfirmationModalOpen &&
				<Modal
					title="Confirm Cancellation" confirmBtnTitle={"Continue"}
					onOtherConfirmTitle="I don’t want to cancel my subscription"
					onClose={() => { setCencelSubscription({ isConfirmationModalOpen: false, isSubmitModalOpen: false }); }}
					onConfirm={() => { setCencelSubscription({ isConfirmationModalOpen: false, isSubmitModalOpen: true }); }}
					onOtherConfirmMethod={() => { setCencelSubscription({ ...cencelSubscription, isConfirmationModalOpen: false }); }}>
					<div className="d-flex flex-column justify-content-between align-items-center">
						<span className="b1 hoki-color" style={{ fontWeight: "800" }}>Are you sure you want to cancel your subscription?</span>
						<span className="b2 hoki-color" style={{ marginTop: "40px" }}>After {moment.unix(auth.subscription.expires_at).format("DD MMM YYYY")}, you will stop having access to these features:</span>
						<Row style={{ width: "500px", padding: "25px 22px", boxShadow: "6px 15px 30px rgba(0, 0, 0, 0.15)", borderRadius: "12px", marginTop: "22px" }}>
							<Col xs="6" className="p-0 b3 hoki-color mt-3">
								<ul style={{ padding: 0 }}>
									<li className="checked-list">Branded Website</li>
									<li className="checked-list">Impact Objectives</li>
									<li className="checked-list">Unlimited Campaigns</li>
									{/* {
										props.selectedPackage.id === 2 &&
										<li className="checked-list">Custom Website URL</li>

									} */}
								</ul>
							</Col>
							<Col xs="6" className="p-0 b3 hoki-color mt-3">
								<ul style={{ padding: 0 }}>
									<li className="checked-list">Unlimited Donors</li>
									<li className="checked-list">Checkout Giving</li>
									<li className="checked-list">Donor Leaderboards</li>
									{/* {
										props.selectedPackage.id === 2 &&
										<li className="checked-list">Removed Altrunic Branding</li>

									} */}
								</ul>
							</Col>
						</Row>
						<span className="b3 hoki-color" style={{ marginTop: "60px" }}>If you would like to proceed with canceling your subscription, please select “Continue” below.</span>
					</div>
				</Modal>
			}
			{
				cencelSubscription.isSubmitModalOpen &&
				<Modal title="Confirm Cancellation" confirmBtnTitle={"Continue"} confirmBtnBusyStatus={confirmBtnBusyStatus}
					onClose={() => { setCencelSubscription({ isConfirmationModalOpen: false, isSubmitModalOpen: false }); setAlert(null); }}
					onConfirm={() => {
						subscriptionCancelation();
						setConfirmBtnBusyStatus(false);
					}}>
					<div className="d-flex flex-column justify-content-between align-items-center" style={{ textAlign: "center" }}>
						<span className="b2 hoki-color" style={{ marginTop: "20px" }}>Your montly subscription is paid until {moment.unix(auth.subscription.expires_at).format("DD MMM YYYY")}. We would appreciate if you could tell us your reason for leaving below.</span>
						<Select
							style={{ marginTop: "40px", maxWidth: "428px" }}
							// title="State"
							options={reasonsToLeave}
							selected={reasonToLeave}
							placeholder={"Reason for leaving"}
							onSelect={(reason) => setReasonToLeave(reason)} />
						<div className="m-3" style={{ width: "500px" }}>
							{alert}
						</div>
					</div>


				</Modal>
			}
			{
				isUpdateCardModaOpen &&
				<Modal title="Update Payment Method"
					onClose={() => { setIsUpdateCardModaOpen(false); }}>
					<div className="w-100  d-flex justify-content-center" style={{ marginBottom: "45px" }}>
						<div style={{ width: "550px", boxShadow: "6px 15px 30px rgba(0, 0, 0, 0.15)", background: "white", borderRadius: "25px", padding: "20px" }}>
							<StripeCard stripCardCURD onCardUpdate={() => { setIsUpdateCardModaOpen(false); }} />
						</div>
					</div>
				</Modal>
			}

		</div >
	);
}