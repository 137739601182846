import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import detailArrow from "../../../../assets/icons/arrows/Detail Arrow.svg";
import addSign from "../../../../assets/icons/arrows/icon.svg";


// const percentage = 42.5;

export default function StatusCard(props) {


	return (
		<div className="status-card" style={props.style} onClick={props.onClick}>
			{!props.hasData ?
				(
					<div style={{ padding: "56.67px" }}>
						<img
							src={addSign}
							alt="add Sign"
						>
						</img>
					</div>
				) : (
					<div style={{ padding: "20px 10px 0px 10px", }}>
						<p className="b4" style={props.style}>{props.text}</p>
						
						<div style={{ display: "flex", position: "absolute", bottom:  "10px"}}>
							<div style={{ height: "76px", width: "76px" }}>
								<CircularProgressbar value={props.percentage} text={`${props.percentage.toFixed(1).replace(/[.,]0$/, "")}%`} strokeWidth={13} styles={buildStyles({ textColor: "black", pathColor: "#007AFF", trailColor: "white" })} />
							</div>
							<div style={{ width: "28px", height: "28px", marginLeft: "26px", paddingTop: "48px" }}>
								<img src={detailArrow} alt="detail arrow"></img>
							</div>

						</div>
					</div>
				)

			}

		</div>
	);
}