import "../assets/styles/App.scss";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";

//Components
import SetupWizard from "./pages/SetupWizard";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import DashboardPageContainer from "./pages/DashboardPageContainer";
import ResetPassword from "./pages/ResetPassword";
import AuthProvider from "../context/AuthContext";
import Editor from "./pages/visual-builder/Editor";
import LandingPage from "./pages/Landing Website/LandingPage";

import SettlementProvider from "../context/SettlementContext";

import UserActivation from "./pages/UserActivation";
import FeaturesPage from "./pages/Landing Website/Features Page/FeaturesPage";
import PricingPage from "./pages/Landing Website/Pricing Page/PricingPage";
// import AccountActivated from "./pages/AccountActivated";
// import LinkExpired from "./pages/LinkExpired";
import ErrorPage from "./pages/ErrorPage";
import MiniSignup from "./pages/MiniSignup";
// import SiteTest from "../builder/SiteTest";
import IntercomProvider from "../context/Intercom";
import RudderStackWrapper from "../utilities/Rudderstack";
import AboutUs from "./pages/Landing Website/AboutUs/AbousUs";
import UserInactive from "./pages/UserInactive";
import Subscription from "./pages/Subscription";
import AccountSuspended from "./pages/Dashboard/subscription-webhooks/AccountSuspended";
import MapWithCharities from "./pages/Landing Website/MapWithCharities";
import TermsAndCondtions from "./pages/Landing Website/terms-and-conditions/TermsAndCondtions";
import PrivacyPolicy from "./pages/Landing Website/privacy-policy/PrivacyPolicy";
import ScrollToTop from "../components/common/ScrollToTop";
import CookiesConsent from "../utilities/CookiesConsent";
import Blogs from "./pages/blogs/Blogs";
import Blog from "./pages/blogs/Blog";
import baseURL from "../utilities/BaseURL";
// import CookieConsent, { Cookies } from "react-cookie-consent";

function App() {
	useEffect(()=>{
		console.log("base url in web folder is",baseURL);
	});
	return (
		<div className="App">
			<Router>
				<ScrollToTop />
				<CookiesConsent />
				<AuthProvider>
					<IntercomProvider>
						<RudderStackWrapper>
							<Switch>
								<Route exact path="/" component={LandingPage} />
								<Route path="/features" component={FeaturesPage} />
								<Route path="/pricing" component={PricingPage} />
								<Route path="/about" component={AboutUs} />
								<Route path="/charities" component={MapWithCharities} />
								<Route path="/terms_and_condition" component={TermsAndCondtions} />
								<Route path="/privacy_policy" component={PrivacyPolicy} />
								<Route path="/blogs" component={Blogs} />
								<Route path="/blog/:id" component={Blog} />
								<Route path="/login" component={Login} />
								<Route path="/signup" component={Signup} />
								<Route path="/profile_setup" component={MiniSignup} />
								<Route path="/forgotpassword" component={ForgotPassword} />
								<Route path="/error" component={ErrorPage} />

								{/* <Route path="/impactsite_testing" component={SiteTest} /> */}

								<Route
									path="/resetpassword/:password_token"
									component={ResetPassword}
								/>
								<Route
									path="/activation/:activation_token"
									component={UserActivation}
								/>

								<Route
									path="/activation"
									component={UserInactive}
								/>

								<Route
									path="/subscription"
									component={Subscription}
								/>

								<Route path="/setup" component={SetupWizard} />
								<Route path="/sitebuilder" component={Editor} />
								<Route path="/account_suspended" component={AccountSuspended} />

								<SettlementProvider>
									<Route path="/dashboard" component={DashboardPageContainer} />
								</SettlementProvider>

								{/* <Route path="/charity-login" component={CharityLogin} /> */}
								{/* <Route path="/charity-signup" component={CharitySignup} /> */}

								{/* <Route path="/charity-forgotPassword" component={CharityForgotPassword} /> */}
								{/* <Route path="/charity-resetPassword" component={CharityResetPassword} /> */}
								{/* <Route path="/charity-accountActivated" component={CharityAccountActivated} /> */}
								{/* <Route path="/charity-linkExpired" component={CharityLinkExpired} /> */}
								{/* <Route path="/charity-error404" component={CharityError404} /> */}
								{

								}
								{/* <Route path="/sitebuilder" component={Editor} /> */}
								{/* <Route path="/editprofile" component={EditProfile} /> */}
								{/* <Route path="/profile" component={ProfilePage} /> */}
								<Route path="/error" component={ErrorPage} />
								<Redirect to="/error" />

							</Switch>
						</RudderStackWrapper>
					</IntercomProvider>
				</AuthProvider>
			</Router>
		</div>
	);
}

export default App;