/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { Packages } from "../../utilities/Packages";
import SegmentToggle from "../sub-component/SegmentToggle";
import PriceCardComponent from "./PriceCardComponent";
import Modal from "../common/Modal";
import Input from "../sub-component/Input";
import ContactUsFrom from "../common/ContactUsForm";
import { useEffect, useContext } from "react";
import { PriceCardContext } from "../../context/PriceCardContext";
import { indexOf } from "free-email-domains";

export default function PriceCardContainer(props) {

	const [dataValues, setDataValues] = useState({
		segmentOptions: [
			{ key: 1, value: "Monthly", package_key: "monthly" },
			{ key: 2, value: "Yearly", package_key: "yearly" }
		],
		selectedOption: {}
	});





	const packages = [
		{
			id: 1,
			package: Packages.starter,

			description: <div className=" d-flex flex-column h-100" style={{ justifyContent: "space-evenly" }}>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} style={{ fontFamily: "Source Serif Pro", fontWeight: "700" }}>Everything in Basic</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} style={{ fontFamily: "Source Serif Pro", fontWeight: "700" }}>+</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >More than 20 </span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Employess</span>
			</div>





			//description: <div className=" d-flex flex-column h-100 justify-content-between">
			//<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Branded Website</span>
			//	<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Impact Objectives</span>
			//	<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Unlimited Campaigns</span>
			//	<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Unlimited Donors</span>
			//	<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Checkout Giving</span>
			//	<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Donor Leaderboards</span>
			//</div>
		},
		{
			id: 2,
			package: Packages.professional,
			description: <div className=" d-flex flex-column h-100" style={{ justifyContent: "space-evenly" }}>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} style={{ fontFamily: "Source Serif Pro", fontWeight: "700" }}>Everything in Starter</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} style={{ fontFamily: "Source Serif Pro", fontWeight: "700" }}>+</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Custom Website URL</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Remove Altrunic Branding</span>
			</div>
		},
		{
			id: 3,
			package: {
				name: "Enterprise",
				price: "Custom"
			},
			description: < div className=" d-flex flex-column h-100" style={{ justifyContent: "space-evenly" }}>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} style={{ fontFamily: "Source Serif Pro", fontWeight: "700" }}>Everything in Professional</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} style={{ fontFamily: "Source Serif Pro", fontWeight: "700" }}>+</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Multiple Websites</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Dedicated Impact Consulting</span>
			</div >
		},
		{
			id: 4,
			package: Packages.basic,
			description: <div className=" d-flex flex-column h-100 justify-content-between">
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Branded Website</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Impact Objectives</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Unlimited Campaigns</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Unlimited Donors</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Checkout Giving</span>
				<span className={`${props?.isOnLandingSite ? "ls-b0-5" : "b2"}`} >Donor Leaderboards</span>
			</div>
		}
	];

	const handleSegmentPackageInterval = (option) => {
		setSelectedPackgeInterval(option);
	};

	const [selectedPackgeInterval, setSelectedPackgeInterval] = useState(dataValues.segmentOptions[0]);

	const [isContactModalOpen, setIsContactModalOpen] = useState(false);

	const history = useHistory();

	return (
		<PriceCardContext.Provider value={{ dataValues, setDataValues }}>
			<Row className="price-card-container" style={{ width: "100%" }}>
				<Col xs="12" className="w-100 d-flex justify-content-center" style={{ marginTop: "40px" }}>
					<div style={{ width: "320px" }}>
						<SegmentToggle
							isOnLandingSite={props?.isOnLandingSite}
							options={dataValues.segmentOptions}
							selected={selectedPackgeInterval}
							onSwitch={(option => handleSegmentPackageInterval(option))}
						/>
						<p className={`${props?.isOnLandingSite ? "ls-b1" : "b4"} text-right mt-2`} style={{ color: "#0054DC" }}>Save 25% Annually</p>
					</div>
				</Col>
				<Col key={packages[3].package.id} className="d-flex justify-content-center flex-wrap" style={{ marginTop: "22px", marginBottom: "50px", gap: "20px" }}>

					<PriceCardComponent
						isOnLandingSite={props?.isOnLandingSite}
						headerStyle={{ backgroundColor: "#0054DC", color: "white" }}
						shouldBtnShow
						isStarter
						isOnHeaderBlue
						package={packages[3].package.name}
						price={
							packages[3].package.id === 2
								? packages[3].package.price
								: (
									<>
										<div>
											${selectedPackgeInterval.key === 1
												? packages[3].package.monthly.price
												: packages[3].package.yearly.price / 12}
											<span className="b3 white-color">/mo</span>
										</div>
										<div className="b3 white-color" style={{ fontFamily: '"Source Serif Pro", serif', fontStyle: "normal", fontWeight: "bold", fontSize: "1.50rem", lineHeight: "22px", letterSpacing: "-0.0015em",margin:"5px 0"  }}>Upto 20 Employees</div>
									</>
								)
						}
						//price={packages[3].package.id === 2 ? packages[3].package.price : <>${selectedPackgeInterval.key === 1 ? packages[3].package.monthly.price : packages[3].package.yearly.price / 12}< span className="b3 white-color">/mo</span></>}
						description={packages[3].description}
						btnTitle={packages[3].package.id === 3 ? "Contact Us" : "Start Free Trial"}
						yearlyTotalPrice={selectedPackgeInterval.key === 1 ? "" : (packages[3].package.id !== 2 && `billed $${packages[3].package.yearly.price} yearly`)}
						onClick={packages[3].id !== 3
							? () => {
								localStorage.setItem("selected-package", JSON.stringify({ packageId: packages[3].package.id }));
								localStorage.setItem("package-interval", JSON.stringify({ packageInterval: selectedPackgeInterval }));
								history.push("/signup");
							}
							: () => {
								setIsContactModalOpen(true);
							}}
					/>
					<PriceCardComponent
						isOnLandingSite={props?.isOnLandingSite}
						headerStyle={{ backgroundColor: "#0054DC", color: "white" }}
						shouldBtnShow
						isStarter
						isOnHeaderBlue
						package={packages[0].package.name}
						price={
							packages[0].package.id === 2
								? packages[0].package.price
								: (
									<>
										<div>
											${selectedPackgeInterval.key === 1
												? packages[0].package.monthly.price
												: packages[0].package.yearly.price / 12}
											<span className="b3 white-color">/mo</span>
										</div>
										<div className="b3 white-color" style={{ fontFamily: '"Source Serif Pro", serif', fontStyle: "normal", fontWeight: "bold", fontSize: "1.50rem", lineHeight: "22px", letterSpacing: "-0.0015em",margin:"5px 0" }}>20+ Employees</div>
									</>
								)
						}
						//price={packages[0].package.id === 2 ? packages[0].package.price : <>${selectedPackgeInterval.key === 1 ? packages[0].package.monthly.price : packages[0].package.yearly.price / 12}< span className="b3 white-color">/mo</span></>}
						description={packages[0].description}
						btnTitle={packages[0].package.id === 3 ? "Contact Us" : "Start Free Trial"}
						yearlyTotalPrice={selectedPackgeInterval.key === 1 ? "" : (packages[0].package.id !== 2 && `billed $${packages[0].package.yearly.price} yearly`)}
						onClick={packages[0].id !== 3
							? () => {
								localStorage.setItem("selected-package", JSON.stringify({ packageId: packages[0].package.id }));
								localStorage.setItem("package-interval", JSON.stringify({ packageInterval: selectedPackgeInterval }));
								history.push("/signup");
							}
							: () => {
								setIsContactModalOpen(true);
							}}
					/>
					<PriceCardComponent
						isOnLandingSite={props?.isOnLandingSite}
						headerStyle={{ backgroundColor: "#0054DC", color: "white" }}
						shouldBtnShow
						isOnHeaderBlue
						package={packages[1].package.name}
						price={<>${selectedPackgeInterval.key === 1 ? packages[1].package.monthly.price : packages[1].package.yearly.price / 12}< span className="b3 white-color">/mo</span></>}
						description={packages[1].description}
						btnTitle={packages[1].package.id === 3 ? "Contact Us" : "Start Free Trial"}
						yearlyTotalPrice={selectedPackgeInterval.key === 1 ? "" : (packages[1].package.id !== 1 && `billed $${packages[1].package.yearly.price} yearly`)}
						onClick={packages[1].id !== 3
							? () => {
								localStorage.setItem("selected-package", JSON.stringify({ packageId: packages[1].package.id }));
								localStorage.setItem("package-interval", JSON.stringify({ packageInterval: selectedPackgeInterval }));
								history.push("/signup");
							}
							: () => {
								setIsContactModalOpen(true);
							}}
					/>
					<PriceCardComponent
						isOnLandingSite={props?.isOnLandingSite}
						headerStyle={{ backgroundColor: "#0054DC", color: "white" }}
						shouldBtnShow
						isOnHeaderBlue
						package={packages[2].package.name}
						price={packages[2].package.price}
						yearlyTotalPrice={selectedPackgeInterval.key === 1 ? "" : (packages[2].package.id !== 1 && `billed yearly`)}
						description={packages[2].description}
						btnTitle={packages[2].id === 3 ? "Contact Us" : "Start Free Trial"}
						onClick={packages[2].id !== 3
							? () => {
								localStorage.setItem("selected-package", JSON.stringify({ packageId: packages[2].package.id }));
								localStorage.setItem("package-interval", JSON.stringify({ packageInterval: selectedPackgeInterval }));
								history.push("/signup");
							}
							: () => {
								setIsContactModalOpen(true);
							}}
					/>
				</Col>

				{
					isContactModalOpen &&
					<ContactUsFrom contactUsModalStatus={isContactModalOpen} onClose={() => { setIsContactModalOpen(false); }} onDone={() => { setIsContactModalOpen(false); }} />
				}

			</Row>
		</PriceCardContext.Provider>
	);
}