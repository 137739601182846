import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./views/App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

//bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducer from "./redux/reducers";

Sentry.init({
	dsn: window._env_.REACT_APP_SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
});


const store = createStore(
	allReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();