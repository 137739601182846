import React from "react";
import { ReactComponent as InfoIcon } from "../../assets/icons/notifications/icon.svg";
export default function HelpIconComponent(props) {
	return (

		<div className="help-icon-container" style={props.style}>
			<InfoIcon className="help-icon" />
			<div className="info-msj-container">
				<div className="icon-section">
					<InfoIcon />
				</div>
				<div className="info-msj-inner-container">
					{
						props.plainInfo && props.plainInfo.map((info, index) => {
							return (
								<div key={index} style={{ marginTop: `${index != 0 ? "10px" : "0px"}` }}>
									<p className="roboto-b3 m-0">{info}</p>
								</div>
							);
						})
					}
					{
						props.warnInfo && props.warnInfo.map((info, index) => {
							return (
								<div key={index} style={{ marginTop: "10px" }} x>
									<p style={{ color: "#FC4848" }} className="roboto-b3 m-0">{info}</p>
								</div>
							);
						})
					}
				</div>

			</div>
		</div>

	);
}
